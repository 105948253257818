import React, { useEffect, useState } from "react";
import { Button, Checkbox, Row, Col, Pagination, Input } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import SubCategoryItem from "../../components/SubCategoryItem";
import { FiEdit2 } from "react-icons/fi";

import { FaEnvelopeOpenText } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import CategoryList from "../../components/CategoryList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { selectAll as selectAllSubcategories } from "../../redux/slices/subcategory.slice";
import { pageSize } from "../../variables";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import styles from "./index.module.scss";
import useNotification from "antd/es/notification/useNotification";
import { editSuccess } from "../../redux/slices/auth.slice";
import { editSuccesList } from "../../redux/slices/subspec.slice";

const sizeIcon = 24;

function SubCategoryManagement() {
  const [openListArea, setOpenListArea] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loadingList, count, errorList, success } = useAppSelector(
    (state) => state.subcategory
  );
  const subcategories = useAppSelector(selectAllSubcategories);
  const [searchText, setSearchText] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [api, contextHolder] = useNotification();
  useEffect(() => {
    dispatch({ type: "GET_ALL_SUBCATEGORY" });
  }, []);

  useEffect(() => {
    if (success && success === "The subcategory has been deleted") {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 3,
      });
      dispatch(editSuccesList(null));
    }
  }, [success]);

  useEffect(() => {
    if (!searchText) dispatch({ type: "GET_ALL_SUBCATEGORY" });
  }, [searchText]);

  const closeListCategory = () => {
    setOpenListArea(false);
  };

  const onChangeOne = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const goOneElement = (id: string) => {
    navigate("/admin/categories/" + id);
  };

  const onChangePage = (page: any) => {
    const payload: { page: number; search?: string; sort?: string } = { page };
    if (searchText) {
      payload.search = searchText;
      payload.sort = "name";
    }
    dispatch({ type: "GET_ALL_SUBCATEGORY", payload });
    setPage(page);
  };

  const onSearch = (value: string) => {
    console.log(value);
    dispatch({
      type: "GET_ALL_SUBCATEGORY",
      payload: { sort: "name", search: value, page: 1 },
    });
    setPage(1);
  };

  const onChange = (e: any) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };
  return (
    <div className={styles.wrapper}>
      {contextHolder}
      <div className={styles.title}>
        <div className={styles.title__text}>Subategory management</div>
        <div className={styles.title__search}>
          <Input.Search
            placeholder="Enter name of subcategory"
            enterButton="Search"
            size="large"
            loading={loadingList}
            onSearch={onSearch}
            onChange={onChange}
            allowClear
          />
        </div>

        {!openListArea && (
          <div className={styles.listButtons}>
            <Button
              onClick={() => goOneElement("null")}
              className={styles.button}
            >
              <LiaCartPlusSolid size={sizeIcon} />
              <div> Add new subcategory</div>
            </Button>

            <Button
              onClick={() => setOpenListArea(true)}
              className={styles.button}
            >
              <FaEnvelopeOpenText size={sizeIcon - 7} />
              <div> Show all category</div>
            </Button>
          </div>
        )}
      </div>
      <div style={{ marginBottom: 15 }}>
        <ResponseStatusComponent error={errorList} />
      </div>
      <Row>
        {subcategories.map((item) => (
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <SubCategoryItem
              direction="vertical"
              hiddenLike={true}
              hiddenCheck={true}
              subcategory={item}
              /*     header={() => (
                <div className={styles.header}>
                  <Checkbox onChange={onChangeOne} />
                </div>
              )} */
              footer={() => (
                <div className={styles.footer}>
                  <div className={styles.footer_price}>
                    <div>Category </div>
                    <div>{item?.category?.name || "-"}</div>
                  </div>
                  <Button
                    className={styles.footer_button}
                    onClick={() => goOneElement(item.id)}
                  >
                    <FiEdit2 style={{ marginRight: 5 }} />
                    Edit
                  </Button>
                </div>
              )}
            />
          </Col>
        ))}
      </Row>
      {openListArea && <CategoryList closeListCategory={closeListCategory} />}
      <Pagination
        current={page}
        defaultCurrent={1}
        total={count}
        pageSize={pageSize}
        className={styles.pagination}
        onChange={onChangePage}
      />
    </div>
  );
}

export default SubCategoryManagement;
