import React, { useEffect, useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Badge,
  Button,
  Modal,
  Menu,
  Col,
  Divider,
  Row,
  Avatar,
  Dropdown,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import testImgForCard1 from "../../assets/imgs/test_img_for_card_1.png";
import testImgForCard2 from "../../assets/imgs/test_img_for_card_2.png";
import test_avatar from "../../assets/imgs/test_avatar.svg";
import other_product from "../../assets/other_goods.png";
import notAv from "../../assets/imgs/images.png";

import Contacts from "../Contacts";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { selectAll as selectAllCategory } from "../../redux/slices/category.slice";
import { CommentType } from "../../redux/ts/type";
import {
  setIsOtherProduct,
  setPickCategory,
  setPickSubCategory,
} from "../../redux/slices/product_main.slice";
import { useNavigate, useParams } from "react-router-dom";
import { setAllProductSpec } from "../../redux/slices/product_spec.slice";
import { pageSize } from "../../variables";
import ResponseStatusComponent from "../ResponseStatusComponent";
const { Search } = Input;

function MenuC() {
  //https://ant.design/components/cascader
  const categories = useAppSelector(selectAllCategory);
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const categoryName = params.categoryName;
  const { loadingList, isOtherProduct } = useAppSelector(
    (state) => state.product
  );

  console.log(isOtherProduct);

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(+e.key);
    if (categories?.[+e.key]?.["sub_categories"].length > 0) {
      setSubMenuOpen(true);
      const category_id = categories?.[+e.key].id;
      dispatch(setIsOtherProduct(false));
      dispatch({
        type: "GET_ALL_PRODUCT",
        payload: {
          otherProduct: true,
          queryParams: `?page=1&take=${pageSize}&sort=category_id&search=${category_id}&sort=subCategory_id&search=null`,
        },
      });
      /*  if (current !== +e.key) {
        dispatch(setIsOtherProduct(false));
        dispatch({
          type: "GET_ALL_PRODUCT",
          payload: {
            otherProduct: true,
            queryParams: `?page=1&take=${pageSize}&sort=category_id&search=${category_id}&sort=subCategory_id&search=null`,
          },
        });
      } */
    } else {
      // getProduct({ category_id: categories?.[+e.key]?.id });
      dispatch(setPickCategory(categories?.[+e.key]));
      dispatch(setPickSubCategory(null));
      setSubMenuOpen(false);
      navigate(`/c/${categories?.[+e.key]?.name}/sbc`);
    }
  };
  const [mouseEnterItem, setMouseEnterItem] = useState<string | null>(null);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "GET_ALL_AREA" });
  }, []);

  useEffect(() => {
    const i = categories.findIndex((c) => c.name === categoryName);
    if (i > -1) setCurrent(i);
  }, [categories]);

  const getMenu = (): any => {
    return categories.map((item, i) => {
      return {
        label: item.name,
        key: i,
      };
    });
  };
  const items = getMenu();
  const dataMenu = categories?.[current]?.["sub_categories"]
    ? [...categories?.[current]?.["sub_categories"]]
    : [];
  if (isOtherProduct) {
    dataMenu.push({ id: "null", name: "OTHER", image_url: null });
  }

  return (
    <div className={styles.menu_wrapper}>
      <Menu
        onClick={onClick}
        selectedKeys={[current + ""]}
        mode="horizontal"
        items={items}
      />
      <div className={styles.contacts}>
        <Contacts />
      </div>

      {subMenuOpen && (
        <div
          className={styles.sub_categories}
          onMouseLeave={() => setSubMenuOpen(false)}
        >
          {loadingList ? (
            <ResponseStatusComponent loading={loadingList} />
          ) : (
            <Row gutter={16}>
              {dataMenu.map((sub_cat) => (
                <Col className="gutter-row" span={6}>
                  <div
                    onClick={() => {
                      if (sub_cat.name === "OTHER") {
                        dispatch(setPickCategory(categories?.[current]));
                        dispatch(setPickSubCategory(null));
                      } else {
                        dispatch(setPickCategory(null));
                        dispatch(setPickSubCategory(sub_cat));
                      }

                      setSubMenuOpen(false);
                      navigate(
                        `/c/${categories?.[current]?.name}/sbc/${sub_cat?.name}`
                      );
                    }}
                    className={
                      sub_cat.id === mouseEnterItem
                        ? styles.one_sub_category_enter_mouse
                        : styles.one_sub_category
                    }
                    onMouseEnter={() => setMouseEnterItem(sub_cat.id)}
                  >
                    <div style={{ width: 60, height: 60 }}>
                      <Image
                        src={
                          (sub_cat.image_url === "null"
                            ? other_product
                            : sub_cat.image_url) || other_product
                        }
                        alt={sub_cat.name}
                        preview={false}
                      />
                    </div>
                    <div
                      className={
                        sub_cat.id === mouseEnterItem
                          ? styles.desc_enter_mouse
                          : styles.desc
                      }
                    >
                      {sub_cat.name}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}
    </div>
  );
}

export default MenuC;
