import { takeLeading, call, put } from "redux-saga/effects";
import {
  specProductService,
  specNamesService,
  specValuesService,
  productService,
} from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllProductSpec,
  addOneProductSpec,
  updateOneProductSpec,
  removeOneProductSpec,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  SpecProductI,
} from "../slices/product_spec.slice";

import {
  ProductType,
  setAllProduct,
  setCount as setProductCount,
  editLoadingList as editLoadingListProduct,
  editErrorList as editErrorListProduct,
} from "../slices/product_main.slice";

interface ProductSpecFormI {
  product_id: string;
  specName_id: string;
  specValue_id: string; //change when on backend added mult values string[]
}

function* addOne(
  action: PayloadAction<{
    formData: {};
  }>
) {
  yield put(editLoading(true));
  try {
    const body = action.payload.formData;
    const response: { id: string } = yield call(
      specProductService.create,
      body,
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );

    const responseGetOneSpecProduct: { rows: SpecProductI[]; count: number } =
      yield call(
        specProductService.getByFilter,
        `?sort=id&search=${response.id}&`
      );

    yield put(addOneProductSpec(responseGetOneSpecProduct.rows[0]));
    yield put(editSuccess(`The spec has been added`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAll(action: PayloadAction<{ page: number; params: string }>) {
  yield put(editLoadingList(true));
  try {
    /*  const response: { rows: { specNames: SpecProductI[] }[]; count: number } =
      yield call(
        productService.filterWithSpecs,
        action?.payload?.params || action.payload
      ); */

    const response: { rows: SpecProductI[]; count: number } = yield call(
      specProductService.getByFilter,
      action?.payload?.params || action.payload
    );

    yield put(setAllProductSpec(response.rows));
    yield put(setCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

// getAllProductBySpec

function* getAllProduct(action: PayloadAction<{}>) {
  yield put(editLoadingListProduct(true));
  try {
    const response: { rows: ProductType[]; count: number } = yield call(
      specProductService.getAllProductBySpec,
      action.payload
    );

    yield put(setAllProduct(response.rows));
    yield put(setProductCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorListProduct(error));
    } else {
      yield put(
        editErrorListProduct("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingListProduct(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  /*   yield put(editLoading(true));
  try {
    const response: SpecNameI = yield call(
      specNamesService.getOne,
      action.payload
    );
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }  */
}

function* editOne(
  action: PayloadAction<{
    formData: {};
    idSpecName: string;
    idCharacteristicsSpec: string;
  }>
) {
  yield put(editLoading(true));
  try {
    const body = action.payload.formData;
    const response: { product: { specNames: SpecProductI[] } } = yield call(
      specProductService.update,
      action.payload.idCharacteristicsSpec,
      body,
      "PATCH",
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    );
    const responseGetOneSpecProduct: { rows: SpecProductI[]; count: number } =
      yield call(
        specProductService.getByFilter,
        `?sort=id&search=${action.payload.idCharacteristicsSpec}&`
      );

    yield put(addOneProductSpec(responseGetOneSpecProduct.rows[0]));
    //yield put(addOneProductSpec(response.product));
    //yield put(setAllProductSpec(response.product.specNames));
    yield put(editSuccess(`The spec has been update`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }

  /* yield put(editLoading(true));
  try {
    const { id, name } = action.payload;
    const response: {} = yield call(
      specNamesService.update,
      id,
      action.payload,
      "PATCH"
    );
    yield put(updateOneSpecName(response));
    yield put(editSuccess(`The spec ${name}  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  } */
}

function* deleteOne(
  action: PayloadAction<{
    idSpecName: string;
    idCharacteristicsSpec: string;
  }>
) {
  yield put(editLoading(true));
  try {
    const response: {} = yield call(
      specProductService.delete,
      action.payload.idCharacteristicsSpec
    );
    yield put(removeOneProductSpec(action.payload.idCharacteristicsSpec));
    yield put(editSuccess("The spec has been deleted from the product"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchProductSpecSaga() {
  yield takeLeading("GET_ALL_SPEC_ONE_PRODUCT", getAll);
  yield takeLeading("GET_ALL_PRODUCT_BY_FILTER", getAllProduct);
  //yield takeLeading("GET_ALL_SPEC_NAME_BY_FILTER", getAllByFilter);
  yield takeLeading("GET_ONE_SPEC_ONE_PRODUCT", getOne);
  yield takeLeading("ADD_SPEC_ONE_PRODUCT", addOne);
  yield takeLeading("EDIT_SPEC_ONE_PRODUCT", editOne);
  yield takeLeading("DELETE_SPEC_ONE_PRODUCT", deleteOne);
}
