import { ExtendUserDataI, PrimaryUserDataI } from "../redux/ts/interface";
import { RequestService } from "./RequestService";

export class AuthService extends RequestService {
  constructor() {
    super("auth");
  }
  signUp = (body: ExtendUserDataI) =>
    this.request({ method: "post", path: "registration", body });
  signIn = (body: PrimaryUserDataI) =>
    this.request({ method: "post", path: "login", body });
  logOut = () => this.request({ method: "get", path: "logout" });

  //___________________
  sendVerificationCode = (body?: {}) =>
    this.request({ method: "post", path: "send-verification-code", body });
  verifyCode = (body?: {}) =>
    this.request({ method: "post", path: "verify-phone", body });
  getTokens = () => this.request({ method: "PATCH", path: "verify-phone" }); //added check header TS

  createNewPassword = (body: {}) =>
    this.request({ method: "post", path: "restore-password", body });
}
