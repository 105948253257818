import React, { useState } from "react";
import { Spin } from "antd";
import styles from "./index.module.scss";

function ResponseStatusComponent({
  loading,
  success,
  error,
}: {
  loading?: boolean;
  success?: null | string;
  error?: null | string;
}) {
  return (
    <>
      {loading && (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      )}
      {success && <div className={styles.success}>{success}</div>}
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
}

export default ResponseStatusComponent;
