import React, { useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  Avatar,
  Rate,
  Checkbox,
  InputNumber,
  Row,
  Col,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiFillHeart,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import test_sub_category from "../../assets/imgs/test_sub_category.png";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

export function GoodFooter({
  direction,
}: {
  direction: "horizontal" | "vertical";
}) {
  const sizeIcon = 24;
  const [count, setCount] = useState<number>(1);
  const [isIntoCart, setIsIntoCart] = useState<boolean>(false);
  const changeCount = (step: -1 | 1) => {
    setCount((count) => {
      if (count === 1 && step === -1) return count;
      return count + step;
    });
  };

  const handleSetCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isNumber = !isNaN(+e.target.value);
    isNumber && setCount(+e.target?.value);
  };
  const changeStatusGoodIntoCart = () => {
    setIsIntoCart((isIntoCart) => !isIntoCart);
  };
  return (
    <div
      className={[
        styles.good_footer,
        direction === "horizontal" && styles.good_footer__horiz,
      ].join(" ")}
    >
      <div className={styles.good_coast}>
        <div className={styles.count_block}>
          <button onClick={() => changeCount(-1)}>-</button>
          <input value={count} onChange={handleSetCount} />
          <button onClick={() => changeCount(1)}>+</button>
        </div>
        <div>$50.05</div>
      </div>
      <Button
        className={isIntoCart ? styles.button_item_out : styles.button_item}
        onClick={changeStatusGoodIntoCart}
      >
        <div className={styles.good_button}>
          {isIntoCart ? (
            <AiOutlineMinusCircle size={sizeIcon - 5} />
          ) : (
            <AiOutlineShoppingCart size={sizeIcon - 5} />
          )}

          <div>{isIntoCart ? "Out of Stock" : "Add to Cart"} </div>
        </div>
      </Button>
    </div>
  );
}

export function SubCategoryItem({
  leaveFeedback,
  direction,
  hiddenLike,
  hiddenCheck,
  hiddenFooter,
  footer,
  header,
  subcategory,
}: {
  leaveFeedback?: boolean;
  direction: "vertical" | "horizontal";
  hiddenLike?: true;
  hiddenCheck?: true;
  hiddenFooter?: true;
  footer?: () => JSX.Element;
  header?: () => JSX.Element;
  subcategory?: {
    id: string;
    name: string;
    image_url: string;
  };
}) {
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const [like, setLike] = useState<boolean>(false);

  const navigate = useNavigate();

  const sizeIcon = 24;
  const [classNameDesc, setClassNameDesc] = useState<
    "good_desc" | "good_desc__underline"
  >("good_desc");

  const goToDesc = () => {
    navigate("/c/:categoryName/sb/:subCategoryName/g/goodId");
  };

  console.log(subcategory, "subcategory");
  return (
    <div
      className={[
        styles.good,
        direction === "horizontal" && styles.good_horiz,
      ].join(" ")}
    >
      <div
        className={[
          styles.leftPart,
          direction === "horizontal" && styles.good_leftPart__horiz,
        ].join(" ")}
      >
        {header === undefined ? (
          <div className={styles.good_header}>
            {hiddenCheck === undefined && (
              <Checkbox onChange={onChange}>
                {direction !== "horizontal" && "Compare"}
              </Checkbox>
            )}

            {direction !== "horizontal" && hiddenLike === undefined && (
              <div onClick={() => setLike((like) => !like)}>
                {!like ? (
                  <AiOutlineHeart size={sizeIcon} />
                ) : (
                  <AiFillHeart color="#F15A29" size={sizeIcon} />
                )}
              </div>
            )}
          </div>
        ) : (
          header()
        )}
        <div
          className={[
            styles.good_img,
            direction === "horizontal" && styles.good_img__horiz,
          ].join(" ")}
        >
          <Image
            src={subcategory?.image_url}
            style={{ maxWidth: "100%", maxHeight: 200 }}
          />
        </div>
        <div className={styles.good_content}>
          {direction === "horizontal" && hiddenLike === undefined && (
            <div
              onClick={() => setLike((like) => !like)}
              className={styles.good_likes__horiz}
            >
              {!like ? (
                <AiOutlineHeart size={sizeIcon} />
              ) : (
                <AiFillHeart color="#F15A29" size={sizeIcon} />
              )}
            </div>
          )}
          <div className={styles.name}>{subcategory?.name}</div>
        </div>
      </div>
      {footer === undefined || hiddenFooter ? (
        <GoodFooter direction="vertical" />
      ) : (
        footer()
      )}
    </div>
  );
}

export default SubCategoryItem;
