import { RequestService } from "./RequestService";
import { pageSize } from "../variables";
export class CommonService extends RequestService {
  entity;

  constructor(name_entity: string) {
    super(`${name_entity}`);
    this.entity = name_entity;
  }

  getAll = (queryParams?: {} | string) =>
    this.request({
      method: "get",
      queryParams:
        typeof queryParams === "string"
          ? queryParams
          : {
              page: 1,
              take: pageSize,
              ...queryParams,
            },
    });

  getAllArchive = (queryParams?: {}) =>
    this.request({
      method: "get",
      path: "archived",
      queryParams: {
        page: 1,
        take: pageSize,
        ...queryParams,
      },
    });

  search = (queryParams: {} | string) =>
    this.request({
      method: "get",
      path: "search",
      queryParams:
        typeof queryParams !== "string"
          ? {
              page: 1,
              take: pageSize,
              ...queryParams,
            }
          : queryParams,
    });

  searchByName = (queryParams: {}) =>
    this.request({
      method: "get",
      path: "searchByName",
      queryParams: {
        page: 1,
        take: pageSize,
        ...queryParams,
      },
    });

  getByName = (name: string, withoutName?: true) => {
    let objRequet: {
      method: "get";
      path: "getByName";
      queryParams?: {};
      id?: string;
    } = {
      method: "get",
      path: "getByName",
    };

    if (!withoutName) {
      objRequet.queryParams = { name };
    } else {
      objRequet.id = name;
    }

    return this.request(objRequet);
  };

  getByFilter = (queryParams: {} | string) =>
    this.request({
      method: "get",
      path: "filter",
      queryParams:
        typeof queryParams !== "string"
          ? {
              page: 1,
              take: pageSize,
              ...queryParams,
            }
          : queryParams,
    });
  getOne = (id: string, queryParams?: {}) =>
    this.request({ method: "get", id, queryParams });

  restoreOne = (id: string, queryParams?: {}) =>
    this.request({
      method: "get",
      path: "restore",
      id,
      queryParams,
    });
    deleteXlsxOne = (id: string, queryParams?: {}) =>
        this.request({
            method: "delete",
            path: "excel-products",
            id,
            queryParams,
        });

  update = (id: string, body: {}, method?: "PATCH" | "put", headers?: {}) =>
    this.request({ method: method || "put", id, body, headers });
  create = (body: {},  headers?: {}) =>
    this.request({ method: "post", body, headers });
  delete = (id: string, body?: {}, queryParams?: {}) =>
    this.request({ method: "delete", body, queryParams, id });
}
