import React, { useEffect, useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  notification,
  Tooltip,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import { useNavigate } from "react-router-dom";
import { MdOutlineAccountCircle } from "react-icons/md";
import { BsCartPlus } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import { CiSearch } from "react-icons/ci";
import {
  RiWallet3Line,
  RiLogoutBoxRLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import Contacts from "../Contacts";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  clearResponseStatus,
  editSuccess,
  setIsOpenConfirmResetLikeCompareInAccount,
} from "../../redux/slices/auth.slice";
import { setCurrentListType } from "../../redux/slices/product_with_status.slice";
import FilterSelect from "../FilterSelect";
import {
  ProductType,
  selectAll as selectAllProduct
} from "../../redux/slices/product_main.slice";
import OneGood from "../Goods/OneGood";
import { clearLikeCompareInLocalStorage } from "../../redux/sagas/auth.saga";
import { getUserId } from "../../utilities";
import {RootState} from "../../redux/store";
import {selectAll} from "../../redux/slices/count_product_with_status.slice";
const { Search } = Input;

const colorIcon = "#878D9C";
const sizeIconAI = "30px";
const sizeIconLia = "32px";
const sizeIconAcc = 18;
const colorBage = "#005FEF";

export const componentsAccount: Array<{
  component: () => JSX.Element;
  navigate: string | null;
}> = [
  {
    component: () => (
      <div className={styles.oneIcon}>
        <AiOutlineHeart size={sizeIconAcc} />
        <span className={styles.textIcon}>My details</span>
      </div>
    ),
    navigate: "/account",
  },
  {
    component: () => (
      <div className={styles.oneIcon}>
        <BsCartPlus size={sizeIconAcc} />
        <span className={styles.textIcon}>History of Purchases</span>
      </div>
    ),
    navigate: "/account/purchases",
  },

  {
    component: () => (
      <div className={styles.oneIcon}>
        <TbTruckDelivery size={sizeIconAcc + 2} />
        <span className={styles.textIcon}>Deliveries</span>
      </div>
    ),
    navigate: "/account/deliveries",
  },
  {
    component: () => (
      <div className={styles.oneIcon}>
        <RiWallet3Line size={sizeIconAcc} />
        <span className={styles.textIcon}>Payment Methods</span>
      </div>
    ),
    navigate: "/account/payment",
  },
  /*   {
    component: () => (
      <div className={styles.oneIcon}>
        <RiLogoutBoxRLine color={colorIcon} size={sizeIconAcc} />
        <span className={styles.textIcon}>Log Out</span>
      </div>
    ),
    navigate: "/",
  }, */
];

const componentsMenu: Array<{
  component: () => JSX.Element;
  navigate: string | null;
}> = [
  {
    component: () => (
      <div
        className={styles.oneIcon}
        onClick={() => {
          /* navigate("/likes") */
        }}
      >
        <Badge count={100} overflowCount={99} color={colorBage}>
          <AiOutlineHeart color={colorIcon} size={sizeIconAI} />
        </Badge>
        <span className={styles.textIcon}>Likes</span>
      </div>
    ),
    navigate: "/likes",
  },
  {
    component: () => (
      <div
        className={styles.oneIcon}
        onClick={() => {
          /* navigate("/cart") */
        }}
      >
        <Badge count={5} overflowCount={99} color={colorBage}>
          <AiOutlineShoppingCart color={colorIcon} size={sizeIconAI} />
        </Badge>
        <span className={styles.textIcon}>Cart</span>
      </div>
    ),
    navigate: "/cart",
  },
];

export const getItemsForDropDowm = (
  components: Array<{
    component: () => JSX.Element;
    navigate: string | null;
  }>,
  goTo: (path: string | null) => void,
  startIndex: number = 0
): MenuProps["items"] => {
  let index = startIndex;
  return components.map((item) => {
    return {
      label: <div onClick={() => goTo(item.navigate)}>{item.component()}</div>,
      key: index++,
    };
  });
};

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const getItemsForCascader = (
  components: Array<{
    component: () => JSX.Element;
    navigate: string | null;
  }>
): Array<Option> => {
  return components.map((item) => {
    return {
      value: "zhejiang",
      label: "Zhejiang",
      children: [
        {
          value: "hangzhou",
          label: "Hangzhou",
          children: [
            {
              value: "xihu",
              label: "West Lake",
            },
          ],
        },
      ],
    };
  });
};

export const filterOption = (
  input: string,
  option?: {
    label: {
      props: {
        data: {
          name: string;
        };
      };
    };
    value: string;
  }
) => {
  const name = option?.label?.props?.data?.name;
  return (name ?? "").toLowerCase().includes(input.toLowerCase());
};

function HeaderC() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isAuthorized,
    success,
    isAdmin,
    isOpenConfirmResetLikeCompareInAccount,
  } = useAppSelector((state) => state.auth);
  const allProductsArray = useSelector((state: RootState) => selectAll(state));
  const [openToolCart, setOpenToolCart] = useState(false);
  const [openToolLike, setOpenToolLike] = useState(false);
  const navigate = useNavigate();
  const auth = isAuthorized;
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const {loadingList,
    numberOfCart,
    numberOfLikes,
    searchProduct: products,
    searchCount: count,
  } = useAppSelector((state) => state.product);
  const HeaderProducts = useSelector(selectAllProduct);
  const infoSuccessAuth = () => {
    setIsModalOpen(false);
    api.success({
      message: <div className={styles.succes}>{success}</div>,
      duration: 3,
    });
    dispatch(clearResponseStatus());
  };

  useEffect(() => {
    if (success && !isOpenConfirmResetLikeCompareInAccount) {
      infoSuccessAuth();
    }
  }, [success]);

  useEffect(() => {
    if (isOpenConfirmResetLikeCompareInAccount) {
      setIsModalOpen(true);
    }
  }, [isOpenConfirmResetLikeCompareInAccount]);

  const onAuth = () => {
    setIsModalOpen(true);
  };
  const goTo = (path: string | null) => {
    if (path) navigate(path);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("hello");
    setIsModalOpen(false);
  };
  const onSearch = () => console.log("onSearch");
  const logout = () => {
    dispatch({ type: "LOG_OUT" });
    navigate("/"); //change . If user see list product, not navigate
  };

  console.log('headerProducts',HeaderProducts)
  const items: MenuProps["items"] = [
    {
      label: (
        <div className={styles.oneIcon}>
          <AiOutlineHeart color={colorIcon} size={sizeIconAI} />
          <span className={styles.textIcon}>Likes</span>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className={styles.oneIcon}>
          <AiOutlineShoppingCart color={colorIcon} size={sizeIconAI} />
          <span className={styles.textIcon}>Cart</span>
        </div>
      ),
      key: "1",
    },

    {
      type: "divider",
    },
    {
      label: (
        <div className={styles.oneIcon} onCanPlay={onAuth}>
          <LiaUserCircle color={colorIcon} size={sizeIconLia} />
          <span className={styles.textIcon}>Log in</span>
        </div>
      ),
      key: "3",
    },
  ];

  const itemsAccount: MenuProps["items"] = [
    {
      label: (
        <div className={styles.oneIcon}>
          <AiOutlineHeart color={colorIcon} size={sizeIconAcc} />
          <span className={styles.textIcon}>My details</span>
        </div>
      ),
      key: "4",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className={styles.oneIcon}>
          <BsCartPlus color={colorIcon} size={sizeIconAcc} />
          <span className={styles.textIcon}>History of Purchases</span>
        </div>
      ),
      key: "5",
    },

    {
      type: "divider",
    },
    {
      label: (
        <div className={styles.oneIcon} onCanPlay={onAuth}>
          <TbTruckDelivery color={colorIcon} size={sizeIconAcc + 2} />
          <span className={styles.textIcon}>Deliveries</span>
        </div>
      ),
      key: "6",
    },
    {
      label: (
        <div className={styles.oneIcon} onCanPlay={onAuth}>
          <RiWallet3Line color={colorIcon} size={sizeIconAcc} />
          <span className={styles.textIcon}>Payment Methods</span>
        </div>
      ),
      key: "7",
    },
    {
      label: (
        <div className={styles.oneIcon} onCanPlay={onAuth} onClick={logout}>
          <RiLogoutBoxRLine color={colorIcon} size={sizeIconAcc} />
          <span className={styles.textIcon}>Log Out</span>
        </div>
      ),
      key: "8",
    },
  ];

  const getTextForToolCart = (): string => {
    let text: string = "Please login in your account";
    if (isAuthorized && numberOfCart < 1)
      text = "You don't have  products in the cart";
    return text;
  };

  const openLikes = () => {
    if (numberOfLikes < 1) {
      setOpenToolLike(true);
      setOpenToolCart(false);
    }
    if (numberOfLikes > 0) {
      dispatch(setCurrentListType("Likes"));
      navigate("/likes");
    }
  };
  const openCart = () => {
    if (numberOfCart < 1) {
      setOpenToolCart(true);
      setOpenToolLike(false);
    }
    if (numberOfCart > 0) {
      dispatch(setCurrentListType("Cart"));
      navigate("/cart");
    }
  };
  let menu = getItemsForDropDowm(componentsAccount, goTo);
  menu = menu && [
    ...menu,
    {
      label: (
        <div className={styles.oneIcon} onCanPlay={onAuth} onClick={logout}>
          <RiLogoutBoxRLine color={colorIcon} size={sizeIconAcc} />
          <span className={styles.textIcon}>Log Out</span>
        </div>
      ),
      key: "8",
    },
  ];

  const setValueUserId = (currentValueId: string, fullEntity?: {}) => {
    console.log(currentValueId, fullEntity);
    navigate("/c/:categoryName/sb/:subCategoryName/g/" + currentValueId);
  };
  const getValuesOptions = (data: ProductType[]) => {
    return data.map((item) => ({
      value: item.id,
      label: <OneGood data={item} direction="horizontal" hiddenLike={true} />,
      key: item.id,
    }));
  };

  const confirmResetLikeCompareInAccount = (confirm: boolean) => {
    if (!confirm) {
      dispatch(setIsOpenConfirmResetLikeCompareInAccount(false));
      clearLikeCompareInLocalStorage();
      infoSuccessAuth();
    } else {
      dispatch({
        type: "TRANSFER_LIKE_COMPARE",
        payload: { userId: getUserId() },
      });
    }
  };


  return (
    <>
      {contextHolder}

      <div className={styles.wrapper}>
        <div className={styles.wrapper_logo}>
          <div className={styles.logo} onClick={() => navigate("/")}>
            <Image
              src={logo}
              alt="logo"
              width={132}
              height={40}
              preview={false}
            />
          </div>
          {/*   <Search
            className={styles.search}
            placeholder="enter request"
            allowClear
            onSearch={onSearch}
            size="large"
          /> */}
          <FilterSelect
            data={products}
            defValuesOptions={getValuesOptions(products)}
            count={count}
            dispatchType="GET_ALL_PRODUCT_FOR_SEARCH"
            setValue={setValueUserId}
            blockClear={true}
            defFilterOption={filterOption}
            suffixIconJSX={<CiSearch size={24} />}
            disableSetNewValue={true}
          />
        </div>
        {/*         <div className={styles.burger}>
          <Dropdown menu={{ items: {} }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <RxHamburgerMenu color={colorIcon} size={sizeIconAI} />
              </Space>
            </a>
          </Dropdown>
        </div> */}

        <div className={styles.icons}>
          <Tooltip
            style={{ marginBottom: 20 }}
            placement="topRight"
            title="You don't have like's products"
            open={openToolLike}
          ></Tooltip>
          <div
            className={styles.oneIcon}
            onClick={openLikes}
            onMouseLeave={() => setOpenToolLike(false)}
          >
            <Badge
              count={numberOfLikes}
              overflowCount={99}
              color={colorBage}
              className={styles.badge}
            >
              <AiOutlineHeart color={colorIcon} size={sizeIconAI} />
            </Badge>
            <span className={styles.textIcon}>Likes</span>
          </div>
          <Tooltip
            style={{ marginBottom: 5 }}
            placement="topRight"
            title={getTextForToolCart}
            open={openToolCart}
          ></Tooltip>
          <div
            className={styles.oneIcon}
            onClick={openCart}
            onMouseLeave={() => setOpenToolCart(false)}
          >
            <Badge
              count={numberOfCart}
              overflowCount={99}
              color={colorBage}
              className={styles.badge}

            >
              <AiOutlineShoppingCart color={colorIcon} size={sizeIconAI} />
            </Badge>
            <span className={styles.textIcon}>{loadingList?'Loading':'Cart'}</span>
          </div>
          {auth ? (
            <>
              {!isAdmin ? (
                <Dropdown menu={{ items: menu }}>
                  <div className={styles.oneIcon}>
                    <LiaUserCircle color={colorIcon} size={sizeIconLia} />
                    <span className={styles.textIcon}>My account</span>
                  </div>
                </Dropdown>
              ) : (
                <div
                  className={styles.oneIcon}
                  onCanPlay={onAuth}
                  onClick={logout}
                >
                  <RiLogoutBoxRLine color={colorIcon} size={sizeIconAcc + 6} />
                  <span className={styles.textIcon}>Log Out</span>
                </div>
              )}
            </>
          ) : (
            <div className={styles.oneIcon} onClick={onAuth}>
              <LiaUserCircle color={colorIcon} size={sizeIconLia} />
              <span className={styles.textIcon}>Log in</span>
            </div>
          )}
          <div className={styles.contacts}>
            <Contacts />
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]} // delete all buttons . You can add any component
      >
        {isOpenConfirmResetLikeCompareInAccount ? (
          <div className={styles.confirm}>
            <div>
              You have compare/like products in your account.
              <div> You want to delete them and add new ?</div>
            </div>
            <div>
              <Button
                type="primary"
                style={{ width: 100, marginRight: 5, marginTop: 20 }}
                onClick={() => confirmResetLikeCompareInAccount(true)}
              >
                Yes
              </Button>
              <Button
                style={{ width: 100 }}
                onClick={() => confirmResetLikeCompareInAccount(false)}
              >
                No
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.logo}>
            <AuthForm isModalOpen={isModalOpen} />
          </div>
        )}
      </Modal>
    </>
  );
}

export default HeaderC;
