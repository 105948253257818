import React, {useEffect, useState} from "react";

import {Button, Pagination} from "antd";
import styles from "./index.module.scss";
import test_banner from "../../assets/imgs/test_banner.png";
import ListGoods from "../../components/Goods/ListGoods";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAll as selectAllProduct,
    setPickCategory,
    setPickSubCategory,
} from "../../redux/slices/product_main.slice";
import {useAppSelector} from "../../redux/ts/hooks/hooks";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import {pageSize} from "../../variables";
import {useParams} from "react-router-dom";
import FiltersOfProduct from "../../components/FiltersOfProduct";
import {
    selectAll as selectAllProductWithStatus
} from "../../redux/slices/product_with_status.slice";
import {RootState} from "../../redux/store";
import {selectAll} from "../../redux/slices/count_product_with_status.slice";

function WrapperListGoods() {
    const products = useSelector(selectAllProduct);
    const [page, setPage] = useState<number>(1);
    const dispatch = useDispatch();
    const {count, loadingList, errorList, pickCategory, pickSubCategory} =
        useAppSelector((state) => state.product);
    const allCountPages = Math.ceil(count / pageSize);

    const changePageProduct = (page: any) => {
        const payload: { page: number; search?: string } = {page};

        let params = "?";
        //if (searchTextProduct) params += `sort=name&search=${searchTextProduct}&`;
        if (pickCategory) params += `sort=category_id&search=${pickCategory.id}&`;
        if (pickSubCategory)
            params += `sort=subCategory_id&search=${pickSubCategory.id}&`;

        params += `page=${page}&take=${pageSize}&`;

        dispatch({type: "GET_ALL_PRODUCT", payload: {queryParams: params}});
        setPage(page);
    }
console.log("Products", products);
    return (
        <>
            {/*  <ResponseStatusComponent loading={loadingList} error={errorList} /> */}
            {!loadingList && <ListGoods listGoods={products}/>}
            {allCountPages > 1 && (
                <Pagination
                    current={page}
                    defaultCurrent={1}
                    pageSize={pageSize}
                    total={count}
                    className={styles.pagination}
                    onChange={changePageProduct}
                />
            )}
        </>
    );
}

function Main() {
    const {loadingList, errorList, pickCategory, pickSubCategory} =
        useAppSelector((state) => state.product);
    const dispatch = useDispatch();
    const params = useParams();
    const categoryName = params.categoryName;
    const subCategoryName = params.subCategoryName;
    //const [page, setPage] = useState<number>(1);
    const showBanner = !params.categoryName;
    const allProductsArray = useSelector((state: RootState) => selectAll(state));
    const mainStatusProducts = useAppSelector(selectAllProductWithStatus);

    useEffect(() => {
        /* console.log(categoryName, subCategoryName);
        dispatch({ type: "GET_ALL_PRODUCT" }); */
        if (!categoryName && !subCategoryName) {
            dispatch({type: "GET_ALL_PRODUCT"});
        }

        if (subCategoryName && subCategoryName !== "OTHER") {
            dispatch({
                type: "GET_ONE_SUBCATEGORY",
                payload: {name: subCategoryName},
            });
        }

        if (categoryName && (!subCategoryName || subCategoryName === "OTHER")) {
            dispatch({type: "GET_ONE_CATEGORY", payload: {name: categoryName}});
        }

        return () => {
            dispatch(setPickCategory(null));
            dispatch(setPickSubCategory(null));
        };
    }, []);

    useEffect(() => {
        if (pickCategory || pickSubCategory) {
            //setPage(1);

            if (subCategoryName === "OTHER" && pickCategory?.id) {
                dispatch({
                    type: "GET_ALL_PRODUCT",
                    payload: {
                        otherProduct: true,
                        queryParams: `?page=1&take=${pageSize}&sort=category_id&search=${pickCategory.id}&sort=subCategory_id&search=null`,
                    },
                });
            }

            if (subCategoryName !== "OTHER") {
                const filter = {
                    sort: pickCategory ? "category_id" : "subCategory_id",
                    search: pickCategory?.id || pickSubCategory?.id,
                };
                dispatch({type: "GET_ALL_PRODUCT", payload: {filter}});
            }
        }
    }, [pickCategory, pickSubCategory]);


    console.log(pickCategory, pickSubCategory, "pickCategory, pickSubCategory");
    console.log(allProductsArray,  'allProductsArray');
    console.log('mainStatusProducts',  mainStatusProducts);
    return (
        <div className={styles.wrapper}>
            {showBanner && (
                <>
                    <div className={styles.banner}>
                        <img
                            alt="banner"
                            src={test_banner}
                            style={{width: "100%", objectFit: "cover", height: "100%"}}
                        />

                        <div className={styles.banner_text}>
                            <div className={styles.banner_name}>Top Ventilation System
                            </div>
                            <div className={styles.banner_desc}>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                                Aenean
                                commodo ligula eget dolor. Aenean massa.
                            </div>
                            <Button className={styles.banner_button}>Get a Quote</Button>
                        </div>
                    </div>
                    <div className={styles.text_desc}>
                        <div>Top Sellers</div>
                        <div></div>
                    </div>
                </>
            )}

            {/*  <ResponseStatusComponent loading={loadingList} error={errorList} /> */}

            <div className={styles.goods}>
                {(pickCategory || pickSubCategory) && (
                    <FiltersOfProduct
                        category_id={pickCategory?.id}
                        subCategory_id={pickSubCategory?.id}
                    />
                )}
                <WrapperListGoods/>
            </div>
        </div>
    );
}

export default Main;
