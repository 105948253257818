import { TreeSelectI } from "../components/OneProductDescAdmin/type";
import { CategoryI } from "../redux/slices/category.slice";
import {
  ProductType,
  ProductTypeIForForm,
} from "../redux/slices/product_main.slice";
import { ProductWithStatusI } from "../redux/slices/product_with_status.slice";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload";

type ReturnObjectT = {
  compare?: { id: string; status: true };
  like?: { id: string; status: true };
  cart?: { id: string; status: true };
};
export const getDefaultProductStatus = (
  data: { id: string; status: "Likes" | "Compare" | "Order" | "Cart" }[]
): ReturnObjectT => {
  const return_object: ReturnObjectT = {};
  if (data.length < 1) return return_object;
  data.forEach((item) => {
    if (item.status === "Likes")
      return_object.like = {
        id: item.id,
        status: true,
      };
    if (item.status === "Compare")
      return_object.compare = {
        id: item.id,
        status: true,
      };
    if (item.status === "Cart")
      return_object.cart = {
        id: item.id,
        status: true,
      };
  });
  return return_object;
};

export const getProductIdsWithStatusFromLocalStorage = (): {
  likesIds: null | string[];
  comparesIds: null | string[];
} => {
  let listOfLike: string | null | ProductType[] =
    localStorage.getItem("listLikeProduct");
  let listOfCompare: string | null | ProductType[] =
    localStorage.getItem("listComparedGoods");
  listOfLike = listOfLike ? JSON.parse(listOfLike) : listOfLike;
  listOfCompare = listOfCompare ? JSON.parse(listOfCompare) : listOfCompare;
  const likesIds =
    Array.isArray(listOfLike) && listOfLike.length > 0
      ? listOfLike.map((pr) => pr.id)
      : null;
  const comparesIds =
    Array.isArray(listOfCompare) && listOfCompare.length > 0
      ? listOfCompare.map((pr) => pr.id)
      : null;

  return { likesIds, comparesIds };
};

export const getItemObject = (item: ProductWithStatusI): ProductType => {
  if (!item?.productItems) {
    return item.product;
  }
  const { cartItem, compareItem, likesItem } = item?.productItems;
  const product: ProductType = { ...item.product, items: [] };
  const extraItems = {
    product_id: product.id,
    user_id: item.user_id,
    order_id: null,
  };
  if (cartItem)
    product.items.push({
      id: cartItem,
      status: "Cart",
      ...extraItems,
    });
  if (compareItem)
    product.items.push({
      id: compareItem,
      status: "Compare",
      ...extraItems,
    });
  if (likesItem)
    product.items.push({
      id: likesItem,
      status: "Likes",
      ...extraItems,
    });
  return product;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getTreeData = (data: CategoryI[]): TreeSelectI[] => {
  return data?.map(
    (item: CategoryI) =>
      ({
        value: item.id,
        title: item.name,
        category_id: item.id,
        key: item.id,
        children: item.sub_categories?.map(
          (sc) =>
            ({
              key: sc.id,
              value: sc.id,
              category_id: item.id,
              category_name: item.name,
              subCategory_id: sc.id,
              title: sc.name,
            } || [])
        ),
      } || [])
  );
};

export const filterOption = (input: string, treeNode: any) => {
  const isReturnedByCatgeory = (treeNode?.title ?? "")
    .toLowerCase()
    .includes(input.toLowerCase());

  const ar = treeNode?.children?.map((it: any) =>
    it?.title.toLowerCase().includes(input.toLowerCase())
  );

  const isReturnedBySubCategory = ar.length > 0 ? true : false;
  return isReturnedByCatgeory || isReturnedBySubCategory;
};

export interface IReturnDataGetPayloadProductDesc {
  bodyInFormData: {};
  name: string;
  id?: string;
  confirmDeleteAllSpec?: boolean;
  formDataForImgs?: {};
}

export const getPayloadProductDesc = (
  body: ProductTypeIForForm,
  file: File,
  fileListExtra: UploadFile[] | null,
  updateFiles: {
    deleteId: string[];
    uploadImage: UploadFile[];
  } | null
): IReturnDataGetPayloadProductDesc => {
  let formData: any = new FormData();
console.log(body)
  for (let key in body) {
    if (key === "confirmDeleteAllSpec") continue;
    const value = body[key as keyof ProductTypeIForForm];
    if (key === "highlights" && Array.isArray(value)) {
      value.forEach((hlt) => formData.append("highlights[]", hlt));
    } else {
      if (key !== "id" && value) formData.append([key], value);
      if (key === "freeShipping" && !value) formData.append([key], false);
      if (key === "easyReturns" && !value) formData.append([key], false);
    }
  }
  if (file) {
    formData.append("avatar", file);
  }
  if (fileListExtra) {
    fileListExtra.map((file) => formData.append("images", file?.originFileObj));
  }

  let returnData: IReturnDataGetPayloadProductDesc = {
    bodyInFormData: formData,
    name: body.name,
    id: body.id,
    confirmDeleteAllSpec: Boolean(body.confirmDeleteAllSpec),
  };

  if (updateFiles && body.id) {
    const { deleteId, uploadImage } = updateFiles;
    let formDataForImgs: any = new FormData();
    deleteId.map((id) => formDataForImgs.append("deleteImages[]", id));
    uploadImage.map((img) =>
      formDataForImgs.append("uploadImages", img?.originFileObj)
    );
    formDataForImgs.append("product_id", body.id);
    returnData.formDataForImgs = formDataForImgs;
  }

  return returnData;
};
