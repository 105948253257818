import React, { useState } from "react";
import styles from "./index.module.scss";
import Details from "../../pages/Details";
import MenuAccount from "../../components/MenuAccount";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { MdOutlineAccountCircle } from "react-icons/md";
import { BsCartPlus } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import Deliveries from "../../pages/Deliveries";
import Purchases from "../../pages/Purchases";
import { Select, Button, Input } from "antd";

import {
  RiWallet3Line,
  RiLogoutBoxRLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { Menu } from "antd";
import { BsClipboardCheck } from "react-icons/bs";
import { LuUsers } from "react-icons/lu";
import { BsFilterCircleFill, BsShieldLock } from "react-icons/bs";
import { BottomFixBlock } from "../../components/OneProductDescAdmin";

const colorIcon = "#878D9C";
const sizeIconAI = "30px";
const sizeIconLia = "32px";
const sizeIconAcc = 18;
const colorBage = "#005FEF";

function Component1() {
  return (
    <div className={styles.oneIcon}>
      <BsShieldLock color={colorIcon} size={sizeIconAcc} />
      <span className={styles.textIcon}>Privat Policy</span>
    </div>
  );
}
function Component2() {
  return (
    <div className={styles.oneIcon}>
      <BsClipboardCheck color={colorIcon} size={sizeIconAcc} />
      <span className={styles.textIcon}>Terms of use</span>
    </div>
  );
}
function Component3() {
  return (
    <div className={styles.oneIcon}>
      <LuUsers color={colorIcon} size={sizeIconAcc + 2} />
      <span className={styles.textIcon}>About us</span>
    </div>
  );
}
const items = [
  {
    key: "police",
    label: <Component1 />,
  },
  {
    key: "terms",
    label: <Component2 />,
  },
  {
    key: "about",
    label: <Component3 />,
  },
];

export function LegalManagement() {
  const [current, setCurrent] = useState<"police" | "terms" | "about">(
    "police"
  );
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key as "police" | "terms" | "about");
    setSubMenuOpen(true);
  };
  const handleChangeSelect = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  return (
    <>
      <div className={styles.account}>
        <div className={styles.account_menu}>
          <div className={styles.title}>
            {current === "police" && "Privat Policy"}
            {current === "terms" && "Terms of use"}
            {current === "about" && "About us"}
          </div>
          <Menu
            style={{ background: "transparent", border: "none" }}
            onClick={onClick}
            selectedKeys={[current]}
            mode="vertical"
            items={items}
          />
        </div>
        <div className={styles.account_content}>
          <Input.TextArea className={styles.input} />
        </div>
      </div>
      <BottomFixBlock>
        <div className={styles.buttons}>
          <Button>Save changes</Button>
        </div>
      </BottomFixBlock>
    </>
  );
}

export default LegalManagement;
