import React, { useEffect, useState } from "react";
import { Input, Button, Modal, Table, Tooltip, notification } from "antd";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import type { ColumnsType } from "antd/es/table";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  clearResponseStatusofForm,
  selectAll,
} from "../../redux/slices/category.slice";
import ResponseStatusComponent from "../ResponseStatusComponent";

interface DataType {
  id: string;
  name: string;
  edit?: string;
  delete?: string;
}
const sizeIcon = 24;
const area = ["Heating", "Ventilation", "Air", "Conditioning", "Refrigeration"];

function CategoryList({
  closeListCategory,
}: {
  closeListCategory: () => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickCategory, setPickCategory] = useState<null | {
    name: string;
    id: string;
  }>();
  const [name, setName] = useState<string>("");
  const [errorName, setErrorName] = useState<string | null>(null);
  const initDeletItem = { delete: false, confirm: false };
  const [deleteItem, setDeleteItem] = useState<{
    delete: boolean;
    confirm: boolean;
  }>(initDeletItem);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setErrorName("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(clearResponseStatusofForm());
    setName("");
    setPickCategory(null);
    setDeleteItem(initDeletItem);
    setErrorName("");
  };

  const sendRequest = () => {
    if (!name) {
      setErrorName("The field can't be empty");
      return;
    }
    setErrorName("");
    const payload: { name: string; id?: string } = { name };
    if (pickCategory) payload.id = pickCategory.id;
    dispatch({ type: pickCategory ? "EDIT_AREA" : "ADD_AREA", payload });
  };

  const dispatch = useAppDispatch();

  const category: DataType[] = useSelector(selectAll);
  const { error, success, loading, loadingList, errorList, successList } =
    useAppSelector((state) => state.category);
  const onDeleteItem = (item: { name: string; id: string }) => {
    setPickCategory(item);
    setDeleteItem((deleteItem) => ({
      ...deleteItem,
      delete: true,
    }));
    setIsModalOpen(true);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
      dataIndex: "edit",
      render: (_, { name, id }) => {
        return (
          <div
            className={styles.rightNavbarList_icon}
            onClick={() => {
              setPickCategory({ name, id });
              showModal();
              setName(name);
            }}
          >
            <GrFormEdit size={sizeIcon} />
          </div>
        );
      },
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, { name, id }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => onDeleteItem({ name, id })}
        >
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: "GET_ALL_AREA" });
  }, []);

  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    if (success && !pickCategory) {
      setName("");
    }
    if (success && deleteItem.delete) {
      if (success) {
        handleCancel();
        api.success({
          message: <div className={styles.succes}>{success}</div>,
          duration: 10,
        });
      }
    }
  }, [success]);

  const onDelete = () => {
    setDeleteItem((deleteItem) => ({ ...deleteItem, confirm: true }));
    if (pickCategory) dispatch({ type: "DELETE_AREA", payload: pickCategory });
  };

  const getTextForModal = (button: boolean = false): string => {
    if (deleteItem.delete) return "Delete the category";
    if (!pickCategory) return "Add a new category";
    return button ? "Save changes" : "Edit the category";
  };

  return (
    <div className={styles.wrapper}>
      {contextHolder}
      <div className={styles.rightNavbarList}>
        <div className={styles.rightNavbarList_header}>
          <div
            className={styles.rightNavbarList_icon}
            onClick={() => showModal()}
          >
            <Tooltip placement="leftBottom" title="Add new category">
              <IoIosAddCircleOutline size={sizeIcon} />
            </Tooltip>
          </div>

          <div
            onClick={() => closeListCategory()}
            className={styles.rightNavbarList_icon}
          >
            <AiOutlineClose size={sizeIcon} />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <ResponseStatusComponent error={errorList} success={successList} />
        </div>
        <Table
          columns={columns}
          dataSource={category}
          loading={loadingList}
          rowClassName={styles.row}
          className={styles.table}
          bordered
        />
      </div>
      <Modal
        title={getTextForModal()}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className={styles.modalButton}>
            <Button
              loading={loading}
              className={styles.button}
              onClick={deleteItem.delete ? onDelete : sendRequest}
            >
              {getTextForModal(true)}
            </Button>
          </div>,
        ]}
      >
        <ResponseStatusComponent error={error} success={success} />
        {deleteItem.delete && (
          <>
            <div style={{ textAlign: "center" }}>
              Are you sure you want delete the category?
              <br />
              {<b>{pickCategory?.name}</b>}. All subcategories and products will
              be delete.
            </div>
          </>
        )}
        {!deleteItem.delete && (
          <>
            <label className={styles.label}>Name</label>
            <Input
              className={styles.input}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <ResponseStatusComponent error={errorName} />
          </>
        )}
      </Modal>
    </div>
  );
}

export default CategoryList;
