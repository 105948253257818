import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";

export type statusComment = "Approved" | "Waiting";
export interface CommentI {
  id: string;
  user_id: string;
  product_id: string;
  text: string | null;
  stars: number;
  status: statusComment;
  user: { id: string; name: string; surname: string; email: string };
  product: ProductType;
  updatedAt: Date;
}
export const commentsAdapter = createEntityAdapter<CommentI>({
  selectId: (comments) => comments.id,
});

export const initialState = commentsAdapter.getInitialState({
  ...requestStatusInitState,
  count: 0,
});

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setAllComments: (state, action) => {
      commentsAdapter.setAll(state, action.payload);
    },
    addOneComments: (state, action) => {
      commentsAdapter.addOne(state, action.payload);
    },
    updateOneComments: (state, action) => {
      commentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneComments: (state, action) => {
      commentsAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearCommentsSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action: PayloadAction<number | -1 | 1>) => {
      let newValue = state.count;
      if (action.payload === -1 || action.payload === 1) {
        newValue += action.payload;
      } else {
        newValue = action.payload;
      }
      state.count = newValue;
    },
  },
});

const { actions, reducer } = commentsSlice;

export const {
  setAllComments,
  addOneComments,
  updateOneComments,
  removeOneComments,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearCommentsSlice,
  setCount,
} = actions;

export default reducer;
export const { selectAll } = commentsAdapter.getSelectors<RootState>(
  (state) => state.comments
);
