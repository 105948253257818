import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";
import { UserDataI } from "./account.slice";
import { InitStateRequestStatusI } from "../ts/interface";
import { TMedia, IMedia } from "./image.slice";

export const videoAdapter = createEntityAdapter<IMedia>({
  selectId: (video) => video.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | IMedia;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};

export const initialState = videoAdapter.getInitialState(init);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    setAllVideo: (state, action) => {
      videoAdapter.setAll(state, action.payload);
    },
    addOneVideo: (state, action) => {
      videoAdapter.addOne(state, action.payload);
    },
    updateOneVideo: (state, action) => {
      videoAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    setManyVideo: (state, action) => {
      videoAdapter.setMany(state, action.payload);
    },
    removeOneVideo: (state, action) => {
      videoAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearImgSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | IMedia>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = videoSlice;

export const {
  setAllVideo,
  addOneVideo,
  updateOneVideo,
  removeOneVideo,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearImgSlice,
  setCount,
  setPickItem,
  setManyVideo,
} = actions;

export default reducer;
export const { selectAll } = videoAdapter.getSelectors<RootState>(
  (state) => state.video
);
