import React, { useEffect, useState } from "react";
import OneGood from "../../components/Goods/OneGood";
import styles from "./index.module.scss";
import {
  Image,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Pagination,
  Tooltip,
  Table,
  notification,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { FiEdit2 } from "react-icons/fi";
import { clearResponseStatusofForm } from "../../redux/slices/highlights.slice";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import testIcon from "../../assets/imgs/calendar-event.png";
import { useNavigate } from "react-router-dom";
import HighlightsModal from "../../components/HighlightsModal";
import { useDispatch } from "react-redux";
import { selectAll as selectAllHighlights } from "../../redux/slices/highlights.slice";
import {
  editSuccess,
  selectAll as selecAllProducts,
} from "../../redux/slices/product_main.slice";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { pageSize } from "../../variables";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import {selectAll as selectAllCategory} from "../../redux/slices/category.slice";

interface DataType {
  id: string;
  name: string;
  image_url?: string;
  img?: string;
  edit?: string;
  delete?: string;
}
const CheckboxGroup = Checkbox.Group;
const sizeIcon = 24;

function AllProducts() {
  const [pickHighlights, setPickHighlights] = useState<null | {
    name: string;
    id: string;
    image_url: string | undefined;
  }>();
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [openListArea, setOpenListArea] = useState(false);
  const [isModalH, setIsModalH] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchTextProduct, setSearchTextProduct] = useState<string>("");
  const products = useAppSelector(selecAllProducts);
  const { errorList, loadingList, count } = useAppSelector(
    (state) => state.highlights
  );
  const { success } = useAppSelector((state) => state.product);
  const [api, contextHolder] = notification.useNotification();
  const categories = useAppSelector(selectAllCategory);
  const {
    errorList: errorListProduct,
    loadingList: loadingListProduct,
    count: countProduct,
  } = useAppSelector((state) => state.product);

  const columns: ColumnsType<DataType> = [
    {
      title: <div className={styles.headerColumn}>Icon</div>,
      dataIndex: "icon",
      render: (_, { name, id, image_url }) => (
        <Image src={image_url} style={{ width: 30, height: 30 }} />
      ),
    },
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
      //render: (text: string) => <a>{text}</a>,
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
      dataIndex: "edit",
      render: (_, { name, id, image_url }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            setPickHighlights({ name, id, image_url });
            setIsModalH(true);
          }}
        >
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, { name, id, image_url }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            setPickHighlights({ name, id, image_url });
            setDeleteItem(true);
            setIsModalH(true);
          }}
        >
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
    },
  ];
  const highlights = useAppSelector(selectAllHighlights);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: "GET_ALL_HIGHLIGHTS" });
    dispatch({ type: "GET_ALL_PRODUCT" });
  }, []);

  useEffect(() => {
    if (!searchText) dispatch({ type: "GET_ALL_HIGHLIGHTS" });
  }, [searchText]);

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 3,
      });
      dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (!searchTextProduct) dispatch({ type: "GET_ALL_PRODUCT" });
  }, [searchTextProduct]);

  const goOneElement = (id: string) => {
    navigate("/admin/products/" + id);
  };

  const closeModalH = () => {
    setIsModalH(false);
    dispatch(clearResponseStatusofForm());
    setPickHighlights(null);
    setDeleteItem(false);
  };

  const changePage = (page: any) => {
    const payload: { page: number; search?: string; sort?: "name" } = { page };
    if (searchText) {
      payload.search = searchText;
      payload.sort = "name";
    }
    dispatch({ type: "GET_ALL_HIGHLIGHTS", payload });
  };

  const changePageProduct = (page: any) => {
    const payload: { page: number; search?: string; sort?: "name" } = { page };
    if (searchTextProduct) {
      payload.search = searchTextProduct;
      payload.sort = "name";
    }
    dispatch({ type: "GET_ALL_PRODUCT", payload });
  };

  const onSearch = (value: string) => {
    dispatch({ type: "GET_ALL_HIGHLIGHTS", payload: { search: value } });
  };

  const onSearchProduct = (value: string) => {
    dispatch({
      type: "GET_ALL_PRODUCT",
      payload: { search: value, sort: "name" },
    });
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const onChangeSearchProduct = (e: any) => {
    setSearchTextProduct(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      {contextHolder}
      <div className={styles.title}>
        <div className={styles.title__text}>Product management</div>
        <div className={styles.title__search}>
          <Input.Search
            placeholder="Enter name of a product"
            enterButton="Search"
            size="large"
            loading={loadingList}
            onSearch={onSearchProduct}
            onChange={onChangeSearchProduct}
            allowClear
          />
        </div>
        {!openListArea && (
          <div className={styles.listButtons}>
            <Button
              onClick={() => goOneElement("null")}
              className={styles.button}
            >
              <LiaCartPlusSolid size={sizeIcon} />
              <div> Add new product</div>
            </Button>

            <Button
              onClick={() => setOpenListArea(true)}
              className={styles.button}
            >
              <FaEnvelopeOpenText size={sizeIcon - 7} />
              <div> Show all highlights</div>
            </Button>
          </div>
        )}
      </div>
      <div style={{ marginBottom: 15 }}>
        <ResponseStatusComponent error={errorListProduct} />
      </div>
      <Row>
        {products.map((product) => (
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <OneGood
              direction="vertical"
              hiddenLike={true}
              hiddenCheck={true}
              data={product}
              header={() => (
                <div className={styles.header}>
                  <div></div>
                  <div
                    className={[
                      styles.status,
                      product.amount > 0 ? "" : styles.status_disabled,
                    ].join(" ")}
                  >
                    {product.amount > 0 ? "Active" : "Out of stock"}
                  </div>
                </div>
              )}
              footer={() => (
                <div className={styles.footer}>
                  <div className={styles.footer_price}>
                    <div>Price for 1 item</div>
                    <div>${product.price}</div>
                  </div>
                  <Button
                    className={styles.footer_button}
                    onClick={() => goOneElement(product.id)}
                  >
                    <FiEdit2 style={{ marginRight: 5 }} />
                    Edit
                  </Button>
                </div>
              )}
            />
          </Col>
        ))}
      </Row>
      {openListArea && (
        <div className={styles.rightNavbarList}>
          <HighlightsModal
            isModalH={isModalH}
            closeModalH={closeModalH}
            pickHighlights={pickHighlights}
            deleteItem={deleteItem}
          />
          <div className={styles.rightNavbarList_header}>
            <div
              className={styles.rightNavbarList_icon}
              onClick={() => {
                setIsModalH(true);
              }}
            >
              <Tooltip placement="leftBottom" title="Add new highlights">
                <IoIosAddCircleOutline size={sizeIcon} />
              </Tooltip>
            </div>
            <div className={styles.search}>
              <Input.Search
                placeholder="Enter name of a highlights"
                enterButton="Search"
                size="large"
                loading={loadingList}
                onSearch={onSearch}
                onChange={onChangeSearch}
                allowClear
              />
            </div>
            <div
              onClick={() => setOpenListArea(false)}
              className={styles.rightNavbarList_icon}
            >
              <AiOutlineClose size={sizeIcon} />
            </div>
          </div>
          <div style={{ marginBottom: 15 }}>
            <ResponseStatusComponent error={errorList} />
          </div>
          <div className={styles.wrapperTable}>
            <Table
              columns={columns}
              dataSource={highlights}
              //loading={true}
              pagination={false}
              rowClassName={styles.row}
              className={styles.table}
              bordered
            />
          </div>
        </div>
      )}
      {!openListArea && (
        <Pagination
          defaultCurrent={1}
          pageSize={pageSize}
          total={countProduct}
          className={styles.pagination}
          onChange={changePageProduct}
        />
      )}
      {openListArea && (
        <Pagination
          defaultCurrent={1}
          pageSize={pageSize}
          total={count}
          className={styles.pagination_h}
          onChange={changePage}
        />
      )}
    </div>
  );
}

export default AllProducts;
