import { RiFeedbackLine } from "react-icons/ri";
import { FaRegFileVideo } from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import { BsFilterCircle } from "react-icons/bs";
import { Tabs, Button } from "antd";
import styles from "./index.module.scss";
import OneProductDescAdmin, {
  BottomFixBlock,
} from "../../components/OneProductDescAdmin";

import ProductFilter from "../../components/ProductFilter";
import ProductDoc from "../../components/ProductDoc";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ListSpecOfXLSXProduct from "../ListSpecOfXLSXProduct";
import { SpecProductI } from "../../redux/slices/product_spec.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import {selectAll as selectAllProduct} from "../../redux/slices/product_main.slice";

const menu = [
  {
    icon: MdOutlineDescription,
    label: "Main description",
    value: "desc",
    component: OneProductDescAdmin,
    key: 1,
    type: "xlsx",
  },
  {
    icon: BsFilterCircle,
    label: "Spec",
    value: "video",
    component: ProductFilter,
    key: 2,
    props: {
      //type: "xlsx",
      ListComponent: (
        isOpenProductSpec?: boolean,
        closeListSpecOfProduct?: () => void,
        addPickSpecName?: (data: SpecProductI | null) => void
      ) => (
        <ListSpecOfXLSXProduct
          addPickSpecName={addPickSpecName}
          isOpenProductSpec={isOpenProductSpec}
          closeListSpecOfProduct={closeListSpecOfProduct}
        />
      ),
      save: () => console.log("I am in ManageOneXLSXProduct"),
    },
  },
  {
    icon: FaRegFileVideo,
    label: "Video and manual",
    value: "filters",
    component: ProductDoc,
    key: 4,
    props: { hiddenList: true },
  },
];

export function ManageOneXLSXProduct() {
  const params = useParams();
  const { keyMenu, idProduct } = params;
  const [activeKey, setActiveKey] = useState(keyMenu ? keyMenu : "1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pickItem } = useAppSelector((state) => state.xlsx_product);
  const products = useAppSelector(selectAllProduct);

  useEffect(() => {
    if (idProduct)
      dispatch({ type: "GET_ONE_XLSX_PRODUCTS", payload: idProduct });
  }, []);

  const itemsMenu = (params.idProduct === "null" ? [menu[0]] : menu).map(
    (info, i) => {
      const id = String(i + 1);
      const Icon = info.icon;
      const Component = info.component;
      const props = info.props;
      return {
        label: (
          <div className={styles.menu}>
            <Icon size={22} />
            <div>{info.label}</div>
          </div>
        ),
        key: id,
        children:
          info.value === "desc" ? (
            <Component data={pickItem} type="xlsx" />
          ) : (
            <Component {...props} />
          ),
      };
    }
  );



  const loading = false;
  useEffect(() => {
    if (keyMenu) setActiveKey(keyMenu);
  }, [keyMenu]);

  const changeTab = (activeKey: string) => {
    setActiveKey(activeKey);
    //navigate("/admin/xlsxProducts/" + idProduct + "/" + activeKey);
  };
  console.log(pickItem, "pickItem");
  return (
    <div className={styles.product}>
      {!loading ? (
        <Tabs
          defaultActiveKey="1"
          items={itemsMenu}
          activeKey={activeKey}
          onChange={changeTab}
        />
      ) : (
        <ResponseStatusComponent loading={loading} />
      )}

    </div>
  );
}

export default ManageOneXLSXProduct;
