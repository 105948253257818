import { getToken } from "../redux/sagas/auth.saga";
import { TToken } from "../redux/ts/type";
type method = "get" | "post" | "put" | "delete" | "PATCH";

interface IParamsRequest {
  method: method;
  path?: string;
  id?: string;
  body?: {};
  queryParams?: {} | string;
  headers?: {};
}
export class RequestService {
  //url = "http://a-zshop.vironitdev.com";
  //url = "http://192.168.1.23:3000";
  //url = "http://ecommerce-parts-api.dev.vironitdev.com";
  // url=  "http://192.168.2.3:3000";
  // url=  "http://192.168.2.9:3000";
  // url = "http://68.65.210.62:8000";
  // url=  "http://192.168.2.9:3000";
  url=  "https://ecommerce-parts-api.rc.vironitdev.com";

  constructor(name_entity: string) {
    this.url += `/${name_entity}`;
  }

  getToken = (typeToken: "accessToken" | "refreshToken") => {
    return getToken(typeToken);
  };

  request = async ({
    method = "get",
    path = undefined,
    id = undefined,
    body = undefined,
    queryParams = undefined,
    headers = undefined,
  }: IParamsRequest) => {
    let fetchURL = this.url;
    const token = this.getToken("accessToken");
    const options: any = {
      method,
      headers: {
        //"Content-Type": "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        ...headers,
      },
    };

    if (path) fetchURL += `/${path}`;
    if (queryParams) {
      if (typeof queryParams === "string") {
        fetchURL += queryParams;
      } else {
        fetchURL += `?${new URLSearchParams(queryParams)}`;
      }
    }
    if (body) {
      if (options.headers["Content-Type"] === "application/json")
        options["body"] = JSON.stringify(body);
      /*       if (
        options.headers["Content-Type"] === "application/x-www-form-urlencoded"
      ) {
        let formBody: any = [];
        for (let property in body) {
          const encodedKey = encodeURIComponent(property);
          const encodedValue = encodeURIComponent(
            body[property as keyof typeof body] as string
          );
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        options["body"] = formBody;
      } */

      if (options.headers["Content-Type"] === "multipart/form-data") {
        options["body"] = body;
        delete options.headers["Content-Type"];
      }

      if (
        options.headers["Content-Type"] === "application/x-www-form-urlencoded"
      ) {
        options["body"] = body;
      }
    }
    if (id) fetchURL += `/${id}`;
    console.log(fetchURL, options);

    const res = await fetch(fetchURL, options);
    let resJson = null;

    try {
      resJson = await res.json();
      console.log("resJson", resJson);
    } catch (e) {
      console.log(e, "e");
    }

    console.log(res, "res");
    if (res?.ok && !resJson?.error) {
      return resJson;
    } else {
      let message = resJson?.message;
      if (resJson?.error) message = resJson;
      return Promise.reject(message);
    }
  };
}
