import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Select,
  Button,
  Input,
  Table,
  Tooltip,
  Modal,
  notification,
  Alert,
  Spin,
} from "antd";
import { FcFolder } from "react-icons/fc";
import { LiaCartPlusSolid } from "react-icons/lia";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  IFileXLSX,
  editError,
  setAllImportXLS,
  editSuccess,
} from "../../redux/slices/importXLSX.slice";
import { selectAll as selectAllXLSX } from "../../redux/slices/importXLSX.slice";
import { structureFolders } from "../../variables";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { BsFiletypeXlsx } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { TFolders } from "../../redux/sagas/importXLSX.saga";
import styles from "./index.module.scss";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";

const sizeIconFolder = 24;
const sizeIconArrow = 18;
const sizeIcon = 24;

export function FolderFiles({
  folders,
}: {
  folders: { value: string; label: string };
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const listFile: IFileXLSX[] = useAppSelector((state) => {
    const xlsxList = state.importXLSX?.xlsxList;
    const nameFolder = folders.value as TFolders;
    if (nameFolder in xlsxList) {
      // @ts-ignore
      return xlsxList[nameFolder];
    }

    return [];
  });

  const { loading, success, error } = useAppSelector(
    (state) => state.importXLSX
  );

  /*   const { loading, success, error } = {
    loading: false,
    success: null,
    error: "c",
  };
 */

  const showModal = () => {
    setIsOpenModal(true);
  };

  const handleOk = () => {
    setIsOpenModal(false);
  };

  const handleCancel = () => {
    dispatch(editError(null));
    dispatch(editSuccess(null));
    setIsOpenModal(false);
  };

  const goToFile = (data: IFileXLSX) => {
    navigate(`/admin/importFiles/${data.name}`);
  };

  const getFiles = (folder: TFolders) => {
    dispatch({
      type: "GET_ALL_XLSX_FILES",
      payload: { sort: "folderName", search: folder },
    });
  };
  const upload = () => {
    if (success) {
      handleCancel();
    } else {
      dispatch({
        type: "UPLOAD_XLSX_FILES",
        payload: folders.value as TFolders,
      });
    }
  };

  return (
    <div>
      <div className={styles.folder} style={{ marginLeft: 20 }}>
        <div className={styles.folder__arrow}>
          {isOpen ? (
            <IoIosArrowDown
              onClick={() => setIsOpen(false)}
              size={sizeIconArrow}
            />
          ) : (
            <IoIosArrowForward
              onClick={() => {
                getFiles(folders.value as TFolders);
                setIsOpen(true);
              }}
              size={sizeIconArrow}
            />
          )}
        </div>
        <FcFolder size={sizeIconFolder} />
        <div className={styles.folder__name}>
          <div style={{ marginRight: 5 }}>{folders.label}</div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setIsOpenModal(true)}
          >
            <Tooltip title="Click for updating files in the folder">
              <AiOutlineReload size={16} />
            </Tooltip>
          </div>
        </div>
      </div>
      {isOpen && (
        <div>
          {listFile.map((dataxlsx) => (
            <div
              className={styles.folder}
              style={{ marginLeft: 40, marginTop: 10 }}
              onClick={() => goToFile(dataxlsx)}
            >
              <div>
                <IoIosArrowForward size={sizeIconArrow} />
              </div>
              <BsFiletypeXlsx size={sizeIconFolder} color="green" />
              <div>{dataxlsx.name}</div>
            </div>
          ))}
        </div>
      )}
      {
        <Modal
          title=""
          open={isOpenModal}
          onOk={upload}
          onCancel={handleCancel}
        >
          {loading && (
            <div
              style={{
                width: "100%",
                marginTop: 25,
              }}
            >
              <Alert
                message="Wait, this process can take a lot of time"
                type="warning"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Spin size="large" />
              </div>
            </div>
          )}
          {(error || success) && (
            <div style={{ margin: 25 }}>
              <ResponseStatusComponent error={error} success={success} />
            </div>
          )}

          {loading || error || success ? (
            <></>
          ) : (
            <div style={{ textAlign: "center" }}>
              <p>
                Are you sure?
                <br /> All previos files will be deleted.
                <br />
                And this process can take a lot of time
              </p>
            </div>
          )}
        </Modal>
      }
    </div>
  );
}

export function ExploreFolder({
  main_folders,
}: {
  main_folders: keyof typeof structureFolders;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  /* const getData = () => {
    if (typeof main_folders !== "string") return structureFolders[main_folders];
  }; */

  return (
    <div>
      <div className={styles.folder}>
        <div className={styles.folder__arrow}>
          {isOpen ? (
            <IoIosArrowDown
              onClick={() => setIsOpen(false)}
              size={sizeIconArrow}
            />
          ) : (
            <IoIosArrowForward
              onClick={() => {
                console.log("hello");
                setIsOpen(true);
              }}
              size={sizeIconArrow}
            />
          )}
        </div>
        <FcFolder size={sizeIconFolder} />
        <div className={styles.folder__name}>{main_folders}</div>
      </div>
      {isOpen && (
        <div>
          {structureFolders[main_folders].map((fo) => (
            <FolderFiles folders={fo} />
          ))}
        </div>
      )}
    </div>
  );
}
export function ListFolders() {
  let location = useLocation();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (location?.state?.message) {
      api.success({
        message: location.state.message,
        duration: 3,
      });
    }
  }, []);
  return (
    <div className="wrapper">
      {contextHolder}
      <div className={styles.title}>
        <div className={styles.title__text}>List of Folders</div>

        <div className={styles.listButtons}>
          <Button
            //onClick={() => goOneElement("null")}
            className={styles.button}
          >
            <LiaCartPlusSolid size={sizeIcon} />
            <div> Add a new folder </div>
          </Button>
        </div>
      </div>

      <div>
        {Object.keys(structureFolders).map((main_folders) => (
          <ExploreFolder
            main_folders={main_folders as keyof typeof structureFolders}
          />
        ))}
      </div>
    </div>
  );
}

export default ListFolders;
