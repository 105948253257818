import React, { useEffect } from "react";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import MenuAdmin from "../MenuAdmin";
import styles from "./index.module.scss";

const getKeyForPath = (path: undefined | string) => {
  if (!path) return "0";
  if (path?.includes("/admin/categories")) return "1";
  if (path?.includes("/admin/products")) return "2";
  if (path?.includes("/admin/filters")) return "3";
  if (path?.includes("/admin/comments")) return "4";
  if (path?.includes("/admin/orders")) return "5";
  if (path?.includes("/admin/legal")) return "6";
  return "0";
};

function WrapperAdmin({
  BaseComponent,
  items,
}: {
  BaseComponent: any;
  items?: Array<BreadcrumbItemType>;
}) {
  let location = useLocation();
  const isAccount =
    location?.pathname && location?.pathname.slice(1, 8) === "account";

  return (
    <div className={styles.layout}>
      {/* <Sider className={styles.sider} theme="light" width={280}>
        Sider
      </Sider> */}
      <div className={styles.sider}>
        <MenuAdmin />
      </div>
      <div className={styles.content}>
        <BaseComponent />
      </div>
    </div>
  );
}

export default WrapperAdmin;
