import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
import { count } from "console";
export const usersAdapter = createEntityAdapter<CommentType>({
  selectId: (users) => users.id,
});

export const initialState = usersAdapter.getInitialState({
  ...requestStatusInitState,
  count: 0,
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      usersAdapter.setAll(state, action.payload);
    },

    addOneUsers: (state, action) => {
      usersAdapter.addOne(state, action.payload);
    },
    updateOneUsers: (state, action) => {
      usersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneUsers: (state, action) => {
      usersAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

const { actions, reducer } = usersSlice;

export const {
  setAllUsers,
  addOneUsers,
  updateOneUsers,
  removeOneUsers,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
} = actions;

export default reducer;
export const { selectAll } = usersAdapter.getSelectors<RootState>(
  (state) => state.users
);
