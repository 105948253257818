import React from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutForm from "../PaymentStripe";

const stripePromise = loadStripe('pk_test_51LpT6hKZRq0NoiPwHgIKpf5i2xUW5XAzOqY53ewIMy31M8dbKMkw3yAB7ytL1FaxKzZnETLCd830y0GWZKa2pcmv00bCtjDDPy');

function Payment() {

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm/>
        </Elements>
    );
}

export default Payment;


