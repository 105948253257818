import React, { useEffect, useRef, useState } from "react";
import { Rate } from "antd";
import styles from "./index.module.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import moment from "moment";

export function OneFeedback({
  fb,
  header,
  isAdmin,
}: {
  fb: any;
  header?: () => JSX.Element;
  isAdmin?: boolean;
}) {
  const date = moment(fb.updatedAt).format("LL");
  return (
    <div className={styles.feedback_block}>
      <div className={header ? styles.feedback_header : ""}>
        <div className={styles.feedback_rate}>
          <Rate disabled allowHalf defaultValue={fb.stars} />
          <span style={{ marginLeft: 10 }}>
            {(fb.stars ^ 0) === fb.stars ? fb.stars + ".0" : fb.stars}
          </span>
        </div>
        {header && header()}
      </div>
      {isAdmin ? (
        <>
          <div className={styles.feedback_who}>
            <span>user: </span>
            {fb?.user?.name} <span>({fb?.user.email})</span>
          </div>
          <div className={styles.feedback_who}>
            <span>product: </span>
            {fb?.product?.name}
          </div>
          <div className={styles.feedback_who}>
            <span>date: </span>
            {date}
          </div>
          <div className={styles.feedback_who}>
            <span>text: </span>
            {fb?.text || "-"}
          </div>
        </>
      ) : (
        <>
          <div className={styles.feedback_who}>
            {fb?.user?.name}
            <span>{date}</span>
          </div>
          <div className={styles.feedback_text}>{fb?.text || "-"}</div>
        </>
      )}
    </div>
  );
}

export default OneFeedback;
