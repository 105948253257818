import React, { useEffect, useState } from "react";
import OneGood from "../../components/Goods/OneGood";
import styles from "./index.module.scss";
import {
  Image,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Pagination,
  Tooltip,
  Table,
  notification,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { FiEdit2 } from "react-icons/fi";
import { clearResponseStatusofForm } from "../../redux/slices/highlights.slice";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import testIcon from "../../assets/imgs/calendar-event.png";
import { useNavigate } from "react-router-dom";
import HighlightsModal from "../../components/HighlightsModal";
import { useDispatch } from "react-redux";
import { selectAll as selectAllHighlights } from "../../redux/slices/highlights.slice";
import {
  editSuccess,
  selectAll as selecAllProducts,
} from "../../redux/slices/xlsx_product.slice";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { pageSize } from "../../variables";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import { ProductType } from "../../redux/slices/product_main.slice";

interface DataType {
  id: string;
  name: string;
  image_url?: string;
  img?: string;
  edit?: string;
  delete?: string;
}
const CheckboxGroup = Checkbox.Group;
const sizeIcon = 24;

function AllXLSXProducts() {
  const [pickHighlights, setPickHighlights] = useState<null | {
    name: string;
    id: string;
    image_url: string | undefined;
  }>();
  const [deleteItem, setDeleteItem] = useState<boolean>(false);
  const [openListArea, setOpenListArea] = useState(false);
  const [isModalH, setIsModalH] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchTextProduct, setSearchTextProduct] = useState<string>("");
  const products = useAppSelector(selecAllProducts);
  const { errorList, loadingList, count } = useAppSelector(
    (state) => state.highlights
  );
  const { success } = useAppSelector((state) => state.product);
  const [api, contextHolder] = notification.useNotification();
  const {
    errorList: errorListProduct,
    loadingList: loadingListProduct,
    count: countProduct,
  } = useAppSelector((state) => state.xlsx_product);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: "GET_ALL_XLSX_PRODUCTS" });
  }, []);

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 3,
      });
      dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (!searchTextProduct) dispatch({ type: "GET_ALL_XLSX_PRODUCTS" });
  }, [searchTextProduct]);

  const goOneElement = (id: string) => {
    navigate("/admin/xlsxProducts/" + id);
  };

  const closeModalH = () => {
    setIsModalH(false);
    dispatch(clearResponseStatusofForm());
    setPickHighlights(null);
    setDeleteItem(false);
  };

  const changePageProduct = (page: any) => {
    const payload: { page: number; search?: string; sort?: "name" } = { page };
    if (searchTextProduct) {
      payload.search = searchTextProduct;
      payload.sort = "name";
    }
    dispatch({ type: "GET_ALL_XLSX_PRODUCTS", payload });
  };

  const onSearchProduct = (value: string) => {
    dispatch({
      type: "GET_ALL_XLSX_PRODUCTS",
      payload: { search: value, sort: "name" },
    });
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const onChangeSearchProduct = (e: any) => {
    setSearchTextProduct(e.target.value);
  };

  console.log(products);

  return (
    <div className={styles.wrapper}>
      {contextHolder}
      <div className={styles.title}>
        <div className={styles.title__text}>Product management</div>
        <div className={styles.title__search}>
          <Input.Search
            placeholder="Enter name of a product"
            enterButton="Search"
            size="large"
            loading={loadingList}
            onSearch={onSearchProduct}
            onChange={onChangeSearchProduct}
            allowClear
          />
        </div>
      </div>
      <div style={{ marginBottom: 15 }}>
        <ResponseStatusComponent error={errorListProduct} />
      </div>
      <Row>
        {products.map((product) => (
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <OneGood
              direction="vertical"
              hiddenLike={true}
              hiddenCheck={true}
              //@ts-ignore
              data={product}
              header={() => (
                <div className={styles.header}>
                  <div></div>
                  <div
                    className={[
                      styles.status,
                      product.amount > 0 ? "" : styles.status_disabled,
                    ].join(" ")}
                  >
                    {product.amount > 0 ? "Active" : "Out of stock"}
                  </div>
                </div>
              )}
              footer={() => (
                <div className={styles.footer}>
                  <div className={styles.footer_price}>
                    <div>Price for 1 item</div>
                    <div>${product.price}</div>
                  </div>
                  <Button
                    className={styles.footer_button}
                    onClick={() => goOneElement(product.id)}
                  >
                    <FiEdit2 style={{ marginRight: 5 }} />
                    Edit
                  </Button>
                </div>
              )}
            />
          </Col>
        ))}
      </Row>
      <Pagination
        defaultCurrent={1}
        pageSize={pageSize}
        total={countProduct}
        className={styles.pagination}
        onChange={changePageProduct}
      />
    </div>
  );
}

export default AllXLSXProducts;
