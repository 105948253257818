import React, { useEffect, useState } from "react";
/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  Avatar,
  Rate,
  Checkbox,
  InputNumber,
  Row,
  Col,
  notification,
  Pagination,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiFillHeart,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import test_img_for_card_1 from "../../assets/imgs/test_img_for_card_1.png";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { ProductType } from "../../redux/slices/product_main.slice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { selectAll } from "../../redux/slices/product_main.slice";
import OneFeedback from "../OneFeedback";
import { AiOutlineCalendar, AiOutlineEdit } from "react-icons/ai";
import { FaRegSave } from "react-icons/fa";
import { send } from "process";
import ResponseStatusComponent from "../ResponseStatusComponent";
import { editError, editSuccess } from "../../redux/slices/comments.slice";
import { selectAll as selectAllComments } from "../../redux/slices/comments.slice";
import { pageSize } from "../../variables";

function ListFeedback() {
  const dispatch = useDispatch();
  const listGoods = useAppSelector(selectAll);
  console.log(listGoods, "listGoods");
  const [api, contextHolder] = notification.useNotification();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [stars, setStars] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorStar, setErrorStar] = useState<string | null>(null);
  const { isAuthorized, success: successAuth } = useAppSelector(
    (state) => state.auth
  );
  const { success, error, count } = useAppSelector((state) => state.comments);
  const params = useParams();
  const idProduct = params.goodId;
  const [page, setPage] = useState(1);
  const defaultParams = `?sort=status&search=Approved&sort=product_id&search=${idProduct}&`;
  const feedback = useAppSelector(selectAllComments);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const closeFormFb = () => {
    setStars(0);
    setText("");
    setErrorStar(null);
    setOpenForm(false);
  };

  const handleCancel = () => {
    console.log("hello");
    setIsModalOpen(false);
  };
  useEffect(() => {
    /* let params = defaultParams
    params += `page=${page}&take=${pageSize}&`;
     dispatch({ type: "GET_ALL_COMMENT", payload: { params } });
    */

    if (idProduct) {
      dispatch({
        type: "GET_ALL_COMMENT",
        payload: {
          params: defaultParams + `page=${page}&take=${pageSize}`,
          withText: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (successAuth) setIsModalOpen(false);
  }, [successAuth]);

  useEffect(() => {
    if (success) {
      closeFormFb();
      api.success({
        message: <div className={styles.success}>{success}</div>,
        placement: "topRight",
        duration: 10,
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      closeFormFb();
      api.error({
        message: <div className={styles.error}>{error}</div>,
        placement: "topRight",
        duration: 10,
      });
    }
  }, [error]);

  const sendFeedback = () => {
    if (stars <= 0) {
      setErrorStar("Please set number of stars");
      return;
    }
    setErrorStar(null);
    dispatch(editError(null));
    dispatch(editSuccess(null));
    const payload: {
      text?: string;
      stars: number;
      product_id: string | undefined;
    } = {
      product_id: idProduct,
      stars,
    };
    if (text) payload.text = text;
    dispatch({ type: "ADD_COMMENT", payload });
  };

  const onChangePage = (page: any) => {
    let params = defaultParams;
    params += `page=${page}&take=${pageSize}&`;
    dispatch({ type: "GET_ALL_COMMENT", payload: { params } });
    setPage(page);
  };

  return (
    <div className={styles.feedback}>
      {contextHolder}
      <div className={styles.feedback_title}>
        <div> {count > 0 && `Reviews (${count})`}</div>
        {!openForm && (
          <Button
            className={styles.button_item}
            onClick={() => {
              if (!isAuthorized) setIsModalOpen(true);
              if (isAuthorized) setOpenForm(true);
            }}
          >
            <div className={styles.feedback_button}>
              <AiOutlineEdit size={24} />
              <div>Leave Feedback </div>
            </div>
          </Button>
        )}
      </div>
      {openForm && (
        <div className={styles.feedback_block}>
          <ResponseStatusComponent error={errorStar} />
          <div className={styles.textAreaWrapper}>
            <div className={styles.feedback_rate}>
              <Rate
                defaultValue={0}
                value={stars}
                onChange={(value) => setStars(value)}
              />
              <span style={{ marginLeft: 10 }}></span>
            </div>
            <div>
              <IoMdClose size={26} onClick={closeFormFb} />
            </div>
          </div>
          <Input.TextArea
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div className={styles.fb}>
            <Button className={styles.button_item} onClick={sendFeedback}>
              <div></div>
              <div className={styles.feedback_button}>
                <FaRegSave size={20} />
                <div>Send</div>
              </div>
            </Button>
          </div>
        </div>
      )}
      {feedback.map((fb) => (
        <OneFeedback fb={fb} />
      ))}
      {count > 1 && (
        <Pagination
          current={page}
          defaultCurrent={1}
          total={count}
          pageSize={pageSize}
          className={styles.pagination}
          onChange={onChangePage}
        />
      )}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]} // delete all buttons . You can add any component
      >
        <div className={styles.logo}>
          <AuthForm isModalOpen={isModalOpen} />
        </div>
      </Modal>
    </div>
  );
}

export default ListFeedback;
