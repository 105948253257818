import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Modal,
  Upload,
  Image,
  Table,
  Tooltip,
  Tag,
  Divider,
  Space,
  InputRef,
  notification,
} from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { BsCardImage } from "react-icons/bs";
import { Select } from "antd";
import { Form, Input, Radio } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import testIcon from "../../assets/imgs/calendar-event.png";
import styles from "./index.module.scss";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { BottomFixBlock } from "../OneProductDescAdmin";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  addOneSpecName,
  editSuccess as editSuccessSpecName,
} from "../../redux/slices/spec_name.slice";
import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  SpecNameI,
  editErrorList,
  selectAll as selectAllSpecName,
} from "../../redux/slices/spec_name.slice";
import { useParams } from "react-router-dom";
import ResponseStatusComponent from "../ResponseStatusComponent";
import {
  SpecProductI,
  addOneProductSpec,
  editError,
  editSuccess,
} from "../../redux/slices/product_spec.slice";
import FilterSelect from "../FilterSelect";
import { pageSize } from "../../variables";
import {
  selectAll as selectAllSpecValue,
  editSuccess as editSuccessSpecValue,
} from "../../redux/slices/spec_value.slice";
import ListSpecOfProduct from "../../pages_admin/ListSpecOfProduct";
import { FaEnvelopeOpenText } from "react-icons/fa";
type LayoutType = Parameters<typeof Form>[0]["layout"];
interface DataTypeSubSpec {
  key: React.Key;
  filter: string;
  values: string;
  edit?: string;
  delete?: string;
}

const sizeIcon = 24;

type FieldType = {
  spec?: string;
  values?: string;
};
export function ProductFilter({
  ListComponent,
  save,
  type,
}: {
  type?: "xlsx";
  ListComponent?: (
    isOpenProductSpec?: boolean,
    closeListSpecOfProduct?: () => void,
    addPickSpecName?: (data: SpecProductI | null) => void
  ) => JSX.Element;
  save?: () => void;
}) {
  //variables
  const params = useParams();
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const data = useAppSelector(selectAllSpecName);
  console.log();
  const {
    success: successSpecName,
    loadingList,
    errorList,
    count: countSpecName,
  } = useAppSelector((state) => state.spec_name);
  const {
    loading,
    error,
    success,
    count: countSpecOfProduct,
  } = useAppSelector((state) => state.product_spec);
  const {
    success: successSpecValue,
    pickItem: pickItemValue,
    count: countSpecValue,
  } = useAppSelector((state) => state.spec_value);
  const { pickItem } = useAppSelector((state) => state.product);
  const [searchText, setSearchText] = useState<string>("");
  const [disabledValues, setDisabledValues] = useState(true);
  const [name, setName] = useState<string>("");
  const [errorName, setErrorName] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [optionsValues, setOptionsValues] = useState<
    { value: string; label: string }[]
  >([]);

  const [isOpenProductSpec, setIsOpenProductSpec] = useState(false);

  //
  const [api, contextHolder] = notification.useNotification();

  const specNames = Array.isArray(data) ? data : [];
  const specValues = useAppSelector(selectAllSpecValue);
  const idProduct = params.idProduct;
  const [specNameOptions, setSpecNameOptions] = useState<
    { value: string; label: string; key: string }[]
  >(
    data.map((sn) => ({
      label: sn.name,
      value: sn.id,
      key: sn.id,
    }))
  );
  console.log("spec", specNames, specNameOptions);
  const [defaultParams, setDefaultParams] = useState("");
  const [pickSpecName, setPickSpecName] = useState<null | SpecProductI>();

  useEffect(() => {
    if (pickItem) {
      getSpecNames();
    } else {
      if (!save) {
        dispatch({ type: "GET_ONE_PRODUCT", payload: idProduct });
      }
    }

    return () => {
      dispatch(editErrorList(false));
      dispatch(editSuccessSpecValue(null));
    };
  }, []);

  useEffect(() => {
    if (pickItem) getSpecNames();
  }, [pickItem]);

  useEffect(() => {
    if (successSpecValue) setName("");
  }, [successSpecValue]);

  useEffect(() => {
    //if (!searchText) dispatch({ type: "GET_ALL_SPEC_NAME_BY_FILTER" , payload: [{ sort: pickItem?.subCategory_id ? 'subCategory_id' : 'category_id', search: pickItem?.subCategory_id ||pickItem?.category_id }]});
  }, [searchText]);

  useEffect(() => {
    if (specNames) {
      const value = form.getFieldValue("spec");
      if (value) {
        handleChangeSelect(value);
        console.log("hello");
      }
    }
  }, specNames);

  useEffect(() => {
    if (pickItemValue) {
      setOptionsValues((optionsValues) => [
        { label: pickItemValue.name, value: pickItemValue.id },
        ...optionsValues,
      ]);
    }
  }, [pickItemValue]);

  useEffect(() => {
    if (data) {
      setSpecNameOptions(
        data.map((sn) => ({
          label: sn.name,
          value: sn.id,
          key: sn.id,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.error}>{success}</div>,
        placement: "topRight",
        duration: 10,
      });

      if (!pickSpecName) form.resetFields();
      dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: <div className={styles.error}>{error}</div>,
        placement: "topRight",
        duration: 10,
      });

      dispatch(editError(null));
    }
  }, [error]);

  //functions

  const getSpecNames = () => {
    const sort = pickItem?.subCategory_id ? "subCategory_id" : "category_id";
    const search = pickItem?.subCategory_id || pickItem?.category_id;
    const params = `?sort=${sort}&search=${search}&`;
    setDefaultParams(params);
    dispatch({
      type: "GET_ALL_SPEC_NAME",
      payload: {
        query_params: params + `page=1&take=${pageSize}`,
      },
    });
  };
  const handleChangeSelect = (value: string) => {
    form.setFieldValue("spec", value);
    const findItem = specNames.find((spn) => spn.id === value);
    const newOptionsValue = findItem?.specValues.map((v) => ({
      value: v.id,
      label: v.name,
    }));

    console.log(newOptionsValue, pickSpecName, "newOptionsValue");
    form.setFieldValue(
      "values",
      pickSpecName?.id === value
        ? pickSpecName?.specValues.map(({ id }) => id)
        : []
    );

    if (newOptionsValue) setOptionsValues(newOptionsValue);
    setDisabledValues(false);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className={styles.tag}
      >
        {label}
      </Tag>
    );
  };

  /*   const changePage = (page: any) => {
    const payload: { page: number; search?: string } = { page };
    if (searchText) {
      payload.search = searchText;
    }
    dispatch({ type: "GET_ALL_SPEC_NAME", payload });
  };

  const onSearch = (value: string) => {
    console.log(value);
    dispatch({ type: "GET_ALL_SPEC_NAME", payload: { search: value } });
  };

  const onChange = (e: any) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  }; */

  const saveFiltersForProduct = () => {
    try {
      form.validateFields();
      const { spec, values } = form.getFieldsValue();
      let formData: any = new URLSearchParams();
      if (!pickSpecName) {
        formData.append("product_id", idProduct);
        formData.append("specName_id", spec);
      }
      values.forEach((v: string) => formData.append("specValuesIds[]", v));
      const idCharacteristicsSpec = pickSpecName?.id;
      const idSpecName = spec;

      /*     x
      const body = {
        product_id: idProduct,
        specName_id: spec,
        specValue_id: values.map((v: string) => v),
      };
      console.log(body); */
      console.log(pickSpecName);
      dispatch({
        type: pickSpecName ? "EDIT_SPEC_ONE_PRODUCT" : "ADD_SPEC_ONE_PRODUCT",
        payload: { formData, idSpecName, idCharacteristicsSpec },
      });
    } catch (e) {}
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const createNewValue = () => {
    if (!name) {
      setErrorName("Please enter name's values");
      return;
    }
    setErrorName(null);
    const idSpecName = form.getFieldValue("spec");
    console.log(optionsValues);
    dispatch({
      type: "ADD_SPEC_VALUE_IN_SPEC_NAME",
      payload: {
        name,
        idSpecName,
        currentValues: optionsValues.map((v) => v.value),
      },
    });
  };

  const onClickSelect = () => {
    if (disabledValues) setOpen(true);
  };

  const addPickSpecName = (data: null | SpecProductI) => {
    setPickSpecName(data);
    if (data) {
      form.setFieldValue("spec", data.id);
      form.setFieldValue(
        "values",
        data?.specValues.map((sv) => sv.id)
      );
      const findItemSpecName = specNames.find((spn) => spn.id === data.id);
      if (!findItemSpecName) {
        setSpecNameOptions((specNameOptions) => [
          ...specNameOptions,
          { label: data.specName.name, value: data.id, key: data.id },
        ]);
      }

      const newOptionsValue = data?.specValues.map((v) => ({
        value: v.id,
        label: v.name,
      }));

      if (newOptionsValue) setOptionsValues(newOptionsValue);

      setDisabledValues(false);
    }
  };

  const clearForm = () => {
    form.resetFields();
    setPickSpecName(null);
  };
  const closeListSpecOfProduct = () => {
    setIsOpenProductSpec(false);
  };

  return (
    <div className={styles.product}>
      {/*     <div className={styles.headerMain}>Add New Filter </div> */}
      <div className={styles.wrapper}>
        {contextHolder}
        <div className={styles.rightPart}>
          <div className={styles.header__text}>
            <div>
              {pickSpecName ? "Edit " : "Add "}
              specs
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {pickSpecName && (
                <div onClick={clearForm} style={{ cursor: "pointer" }}>
                  <Tooltip title="Click for adding a new spec">
                    <AiOutlinePlusCircle size={sizeIcon} />{" "}
                  </Tooltip>
                </div>
              )}
              {!isOpenProductSpec && (
                <Button
                  onClick={() => setIsOpenProductSpec(true)}
                  className={styles.button}
                >
                  <FaEnvelopeOpenText size={sizeIcon - 7} />
                  <div style={{ color: "white" }}> Show all spec</div>
                </Button>
              )}
            </div>
          </div>
          <Form
            /*  {...formItemLayout} */
            layout="vertical"
            form={form}
            className={styles.form}
          >
            <div className={styles.form__row}>
              <Form.Item<FieldType>
                label="Spec"
                className={styles.form__item}
                name="spec"
                rules={[{ required: true, message: "Please select spec" }]}
              >
                {/*       <Select
                  className={styles.select}
                  labelInValue
                  onChange={handleChangeSelect}
                  options={specName_options}
                /> */}
                <FilterSelect
                  data={specNames}
                  defValuesOptions={specNameOptions}
                  count={countSpecName}
                  dispatchType="GET_ALL_SPEC_NAME"
                  extraQueryParams={defaultParams}
                  setValue={handleChangeSelect}
                  defValue={pickSpecName?.id || undefined}
                  disabled={pickSpecName ? true : undefined}
                  excludeSelectValueFormList={true}
                />
              </Form.Item>
              <Tooltip
                open={open}
                title="Please select spec"
                placement="leftBottom"
              ></Tooltip>
              <Form.Item<FieldType>
                label="Values"
                className={styles.form__item}
                name="values"
                rules={[
                  { required: true, message: "Please select/input values" },
                ]}
              >
                <Select
                  disabled={disabledValues}
                  onMouseEnter={onClickSelect}
                  onMouseLeave={() => setOpen(false)}
                  onClick={onClickSelect}
                  className={styles.select}
                  mode="multiple"
                  tagRender={tagRender}
                  style={{ width: "100%" }}
                  options={optionsValues}
                  dropdownRender={(values) => (
                    <>
                      {values}
                      <Divider style={{ margin: "8px 0" }} />
                      <div style={{ width: "100%", textAlign: "start" }}>
                        <ResponseStatusComponent error={errorName} />
                      </div>
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          width: "100%",
                        }}
                      >
                        <Input
                          placeholder="Please enter value"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                          onKeyDown={(e) => e.stopPropagation()}
                        />

                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={createNewValue}
                        >
                          Create а new value
                        </Button>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </div>
            <div className={styles.buttons} onClick={saveFiltersForProduct}>
              <Button loading={loading}>Save </Button>
            </div>
            {/* <Form.Item {...buttonItemLayout}>
        <Button type="primary">Submit</Button>
      </Form.Item> */}
          </Form>
        </div>
        {ListComponent ? (
          ListComponent(isOpenProductSpec, closeListSpecOfProduct)
        ) : (
          <ListSpecOfProduct
            addPickSpecName={addPickSpecName}
            isOpenProductSpec={isOpenProductSpec}
            closeListSpecOfProduct={closeListSpecOfProduct}
          />
        )}
      </div>
    </div>
  );
}

export default ProductFilter;
