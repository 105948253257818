import { takeLeading, call, put } from "redux-saga/effects";
import {
  specValuesService,
  usersService,
  specNamesService,
} from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllSpecValue,
  addOneSpecValue,
  updateOneSpecValue,
  removeOneSpecValue,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  SpecValueI,
} from "../slices/spec_value.slice";
import { updateOneSpecName } from "../slices/spec_name.slice";

function* getAll(
  action: PayloadAction<{ search?: string; page?: number; filter?: {} }>
) {
  yield put(editLoadingList(true));
  try {
    const { search, page, filter } = action?.payload || {};
    let queryParams = action?.payload;
    if (search) {
      const data = { sort: "name", search };
      queryParams = data;
    }
    if (filter) {
      queryParams = {
        ...queryParams,
        ...filter,
      };
      delete queryParams.filter;
    }
    let servicesMethod: "getAll" | "searchByName" | "getByFilter" | "search" =
      "getAll";
    if (filter) servicesMethod = "getByFilter";
    if (search) servicesMethod = "search";
    const response: { rows?: []; count: number } = yield call(
      specValuesService[servicesMethod],
      queryParams
    );

    yield put(setCount(response?.count || 0));
    yield put(setAllSpecValue(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  try {
    const response: SpecValueI = yield call(
      specValuesService.getOne,
      action.payload
    );
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* editOne(action: PayloadAction<SpecValueI>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const body = action.payload;
    const id = action.payload.id || "";
    const response: {} = yield call(
      specValuesService.update,
      id,
      body,
      "PATCH"
    );
    yield put(updateOneSpecValue(response));
    yield put(editSuccess(`The value ${body.name}  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(action: PayloadAction<{ name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const body = action.payload;
    const response: {} = yield call(specValuesService.create, body);
    yield put(addOneSpecValue(response));
    yield put(editSuccess(`The value ${body.name}  has been added`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(
      specValuesService.delete,
      action.payload.id
    );
    yield put(removeOneSpecValue(action.payload.id));
    yield put(editSuccess(`The value ${action.payload.name} has been deleted`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getSpecValue(
  action: PayloadAction<{
    name: string;
    idSpecName: string;
    currentValues: string[];
  }>
) {
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const responseSpecValue: { id: string; name: string } = yield call(
      specValuesService.create,
      { name: action.payload.name }
    );

    return responseSpecValue.id;
  } catch (error: unknown) {
    if (typeof error === "object" && error !== null && "sameName" in error) {
      let sameName = error?.sameName;
      if (
        typeof sameName === "object" &&
        sameName !== null &&
        "id" in sameName
      ) {
        return sameName.id;
      }
    } else {
      return Promise.reject(error);
    }
  }
}
function* addValueInSpec(
  action: PayloadAction<{
    name: string;
    idSpecName: string;
    currentValues: string[];
  }>
) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const id: string = yield call(getSpecValue, action);
    const responseSpecName: { id: string } = yield call(
      specNamesService.update,
      action.payload.idSpecName,
      { specValuesIds: [...action.payload.currentValues, id] },
      "PATCH"
    );
    yield put(updateOneSpecName(responseSpecName));
    yield put(editSuccess(`The value  has been added`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAllArchive(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  yield put(editLoadingList(true));
  yield put(editErrorList(null));

  try {
    const response: { rows?: []; count: number } = yield call(
      specValuesService.getAllArchive,
      { page: action.payload.page }
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllSpecValue(response.rows ? response.rows : response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* restoreFormArchive(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(
      specValuesService.restoreOne,
      action.payload
    );
    console.log("response", response);
    yield put(removeOneSpecValue(action.payload));
    yield put(editSuccess("The spec value has been restored"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchSpecValueSaga() {
  yield takeLeading("GET_ALL_SPEC_VALUE", getAll);
  yield takeLeading("GET_ONE_SPEC_VALUE", getOne);
  yield takeLeading("ADD_SPEC_VALUE", addOne);
  yield takeLeading("ADD_SPEC_VALUE_IN_SPEC_NAME", addValueInSpec);
  yield takeLeading("EDIT_SPEC_VALUE", editOne);
  yield takeLeading("DELETE_SPEC_VALUE", deleteOne);

  yield takeLeading("GET_ALL_SPEC_VALUE_ARCHIVE", getAllArchive);
  yield takeLeading("RESTORE_SPEC_VALUE", restoreFormArchive);
}
