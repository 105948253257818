import React, { useEffect, useState } from "react";
import { Layout, Button, Input, Divider } from "antd";

import OneGood from "../../components/Goods/OneGood";
import { AiOutlineShoppingCart } from "react-icons/ai";
import styles from "./index.module.scss";
import OrderManagment from "../../pages_admin/OrderManagment";
import { getUserId } from "../../utilities";
import { OrderI, statusOrder } from "../../redux/slices/orders.slice";
import OneOrder from "../../components/OneOrder";
import { useLocation, useParams } from "react-router-dom";
import PageOneOrder from "../PageOneOrder";

const getStatusOrder = (path: any): statusOrder[] | undefined => {
  if (path === "deliveries") return ["Created", "Delivery"];
  if (path === "purchases") return ["Completed"];
  return undefined;
};
function Deliveries() {
  const iconSize = 24;
  const userId = getUserId();
  const params = useParams();
  const idOrder = params.idOrder;
  const location = useLocation();
  const path = location.pathname.slice(9);
  const [defStatus, setDefStatus] = useState<statusOrder[] | undefined>(
    getStatusOrder(path)
  );

  useEffect(() => {
    setDefStatus(getStatusOrder(path));
  }, [path]);

  return (
    <div>
      {idOrder ? (
        <PageOneOrder
          hiddenDetails={true}
          getGoodFooter={(count, price) => (
            <div className={styles.feedback_header}>
              <div className={styles.feedback_who}>
                <span>count: </span>
                {count}
              </div>
              <div className={styles.feedback_who}>
                <span>sum: $</span>
                {price * count}
              </div>
            </div>
          )}
        />
      ) : (
        <OrderManagment
          title="Current orders"
          hiddenFilter={true}
          blockChangeStatus={true}
          hiddenUser={true}
          userId={userId}
          defStatus={defStatus}
          getOneOrderC={(data: OrderI) => (
            <OneOrder
              data={data}
              moreDetails={true}
              blockChangeStatus={true}
              hiddenUserInfo={true}
              navigateMoreDetails={
                path === "deliveries"
                  ? "/account/deliveries/"
                  : "/account/purchases/"
              }
            />
          )}
        />
      )}
    </div>
  );
}

export default Deliveries;

{
  /* <>
{arrayTest.map((_) => (
  <OneGood
    hiddenCheck={true}
    //hiddenFooter={true}
    footer={() => (
      <div className={styles.footer}>
        <div className={styles.footer_status}>On Stock</div>
        <div className={styles.footer_count}>1 item</div>
        <div className={styles.footer_price}>$50.05 each</div>
      </div>
    )}
    hiddenLike={true}
    direction="horizontal"
    leaveFeedback={true}
  />
))}
</> */
}
