import { TFolders } from "../redux/sagas/importXLSX.saga";
import { CommonService } from "./CommonService";

export class ExelFileService extends CommonService {
  constructor() {
    super("excel-files");
  }

  createExelProducts = (body: URLSearchParams, headers?: {}) =>
    this.request({
      method: "post",
      path: "createExcelProducts",
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        ...headers,
      },
    });

  uploadExelProducts = (folderName: TFolders, headers?: {}) =>
    this.request({
      method: "get",
      path: "uploadFolder",
      headers: {
        ...headers,
      },
      queryParams: {
        folderName,
      },
    });
}
