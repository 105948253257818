import React, { useState } from "react";

import bgImageBottom from "../../assets/imgs/bgImgBottom.png";
import bgImageTop from "../../assets/imgs/bgImgTop.png";
import { Image } from "antd";
import styles from "./index.module.scss";
import AuthForm from "../../components/Forms/AuthForm";
export function AuthAdmin() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topImg}>
        <Image src={bgImageTop} preview={false} />
      </div>

      <div className={styles.bottomImg}>
        <Image src={bgImageBottom} preview={false} />
      </div>
      <div className={styles.form}>
        <div className={styles.form__wrapper}>
          <AuthForm isAdmin={true} />
        </div>
      </div>
    </div>
  );
}

export default AuthAdmin;
