import { takeLeading, call, put } from "redux-saga/effects";
import { highlightsService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  setAllHighlights,
  addOneHighlights,
  updateOneHighlights,
  removeOneHighlights,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  clearResponseStatusofForm,
} from "../slices/highlights.slice";
//
function* getAll(action: PayloadAction<{ search?: string; page?: number }>) {
  yield put(editLoadingList(true));
  try {
    const params: any = { ...action.payload };
    if (params.search) {
      params["sort"] = "name";
    }
    const response: { rows?: []; count: number } = !action?.payload?.search
      ? yield call(highlightsService.getAll, params)
      : yield call(highlightsService.search, params);
    yield put(setCount(response?.count || 0));
    yield put(setAllHighlights(response.rows));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* editOne(
  action: PayloadAction<{ id: string; name: string; bodyInFormData: {} }>
) {
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    console.log(
      action.payload.bodyInFormData,
      "action.payload.bodyInFormData;"
    );
    const body = action.payload.bodyInFormData;
    const headers = { "Content-Type": "multipart/form-data" };
    const id = action.payload.id;
    const response: {} = yield call(
      highlightsService.update,
      id,
      body,
      "PATCH",
      headers
    );

    console.log(response, "response");
    yield put(updateOneHighlights(response));
    yield put(editSuccess(`The highlight  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(
  action: PayloadAction<{
    bodyInFormData: {};
    name: string;
    id: undefined | string;
  }>
) {
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    const body = action.payload.bodyInFormData;
    const headers = { "Content-Type": "multipart/form-data" };
    const response: {} = yield call(highlightsService.create, body, headers);
    if (response) yield put(addOneHighlights(response));
    yield put(
      editSuccess(`The highlight "${action.payload.name}" has been added`)
    );
  } catch (error: unknown) {
    console.log("error", error);
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  try {
    yield call(highlightsService.delete, action.payload.id);
    yield put(removeOneHighlights(action.payload.id));
    yield put(
      editSuccess(`The highlight "${action.payload.name}" has been deleted`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}
///highlights
export default function* watchHighlightsSaga() {
  yield takeLeading("GET_ALL_HIGHLIGHTS", getAll);
  yield takeLeading("ADD_HIGHLIGHTS", addOne);
  yield takeLeading("EDIT_HIGHLIGHTS", editOne);
  yield takeLeading("DELETE_HIGHLIGHTS", deleteOne);
}
