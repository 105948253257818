import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";
export const subcategoryAdapter = createEntityAdapter<{
  id: string;
  name: string;
  category: { id: string; name: string };
  image_url: string;
}>({
  selectId: (subspec) => subspec.id,
});

export interface PickSubCategoryI {
  id: string;
  name: string;
  image_url: string;
  category: { id: string; name: string };
}

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | PickSubCategoryI;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};
export const initialState = subcategoryAdapter.getInitialState(init);

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    setAllSubcategory: (state, action) => {
      subcategoryAdapter.setAll(state, action.payload);
    },
    addOneSubcategory: (state, action) => {
      subcategoryAdapter.addOne(state, action.payload);
    },
    updateOneSubcategory: (state, action) => {
      subcategoryAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneSubcategory: (state, action) => {
      subcategoryAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | PickSubCategoryI>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = subcategorySlice;

export const {
  setAllSubcategory,
  addOneSubcategory,
  updateOneSubcategory,
  removeOneSubcategory,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
} = actions;

export default reducer;
export const { selectAll } = subcategoryAdapter.getSelectors<RootState>(
  (state) => state.subcategory
);
