export const pageSize = 2;

export const structureFolders = {
  Browning: [
    { label: "Browning Bearings", value: "BrowningBearings" },
    { label: "Browning Couplings", value: "BrowningCouplings" },
    { label: "Browning Gearing", value: "BrowningGearing" },
    { label: "Browning Industrial Chain", value: "BrowningIndustrialChain" },
    { label: "Browning Mechanical", value: "BrowningMechanical" },
  ],

  Century: [
    { label: "Century Blowers", value: "CenturyBlowers" },
    { label: "Century Electric Motors", value: "CenturyElectricMotors" },
  ],

  Fasco: [
    { label: "Fasco Blowers", value: "FascoBlowers" },
    { label: "Fasco Electric Motors", value: "FascoElectricMotors" },
  ],

  Genteq: [
    { label: "Genteq Blowers", value: "GenteqBlowers" },
    { label: "Genteq Electric Motors", value: "GenteqElectricMotors" },
  ],

  Leeson: [
    { label: "Leeson AC & DC Controls", value: "LeesonACDCControls" },
    { label: "Leeson Electric Motors", value: "LeesonElectricMotors" },
  ],

  Marathon: [
    { label: "Marathon AC & DC Controls", value: "MarathonACDCControls" },
    { label: "Marathon Electric Motors", value: "MarathonElectricMotors" },
  ],
};
