import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import styles from "./index.module.scss";
import { Select, Space } from "antd";
import {
  IImportXLSX,
  removeOneImportXLS,
  updateOneImportXLS,
} from "../../redux/slices/importXLSX.slice";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
const sizeIcon = 24;

function EditRow({ data }: { data: IImportXLSX }) {
  const dispatch = useAppDispatch();
  const { options } = useAppSelector((state) => state.importXLSX);
  const handleChange = (value: string) => {
    dispatch(
      updateOneImportXLS({
        ...data,
        defValue: value,
        oldDefValue: data.defValue,
      })
    );
  };

  return (
    <div>
      <Select
        defaultValue={data.defValue}
        style={{ width: "100%" }}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
}
function DeleteRow({ data }: { data: IImportXLSX }) {
  const dispatch = useAppDispatch();
  const deleteValue = () => {
    dispatch(removeOneImportXLS(data.key));
  };
  return (
    <div onClick={deleteValue}>
      <AiOutlineDelete size={sizeIcon - 6} />
    </div>
  );
}

export const columns: ColumnsType<{ name: string; key: string }> = [
  {
    title: <div className={styles.headerColumn}>Name</div>,
    dataIndex: "name",
    //render: (text: string) => <div>{text}</div>,
  },
  {
    title: (
      <div className={styles.headerColumn}>
        <GrFormEdit size={sizeIcon} />
      </div>
    ),
    dataIndex: "edit",
    render: (_, data) => <EditRow data={data} />,
  },
  {
    title: (
      <div className={styles.headerColumn}>
        <AiOutlineDelete size={sizeIcon - 6} />
      </div>
    ),
    dataIndex: "delete",
    render: (_, data) => <DeleteRow data={data} />,
  },
];
