import { CommonService } from "./CommonService";

export class ItemService extends CommonService {
  constructor() {
    super("items");
  }

  setLikesProduct = (body: string[], user_id: string) =>
    this.request({
      id: user_id,
      method: "post",
      path: "likedProducts",
      body,
    });

  getAllCompareItemsWithSpec = (queryParams?: {} | string) =>
    this.request({
      method: "get",
      path: "filterWithCompareAndSpecs",
      queryParams: queryParams,
    });

  deleteCompareList = (body: {}, headers: {}) =>
    this.request({ method: "delete", body, path: "compareItems", headers });

  likedOrCompareProducts = (body: {}, idUser: string, headers?: {}) =>
    this.request({
      method: "post",
      body,
      id: idUser,
      path: "likedOrCompareProducts",
      headers,
    });
}
