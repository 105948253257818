import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";

export interface CategoryI {
  id: string;
  name: string;
  sub_categories: Array<{ id: string; name: string; image_url: string | null }>;
}
export const categoryAdapter = createEntityAdapter<CategoryI>({
  selectId: (category) => category.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
};
export const initialState = categoryAdapter.getInitialState(init);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setAllCategory: (state, action) => {
      categoryAdapter.setAll(state, action.payload);
    },
    addOneCategory: (state, action) => {
      categoryAdapter.addOne(state, action.payload);
    },
    updateOneCategory: (state, action) => {
      categoryAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    updateCount:(state, action) => {
      state.count=action.payload
    },
    removeOneCategory: (state, action) => {
      categoryAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearCategorySlice: (state) => {
      state = initialState;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
  },
});

const { actions, reducer } = categorySlice;

export const {
  setAllCategory,
  addOneCategory,
  updateOneCategory,
  removeOneCategory,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearCategorySlice,
  setCount,
  updateCount
} = actions;

export default reducer;
export const { selectAll } = categoryAdapter.getSelectors<RootState>(
  (state) => state.category
);
