import React, { useEffect, useState, useRef, ReactNode } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Tag, notification, Divider, Space, TreeSelect } from "antd";
import { Button, message } from "antd";
import { Select } from "antd";
import { Form, Input, Radio } from "antd";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import type { InputRef } from "antd";
import styles from "./index.module.scss";
import { pageSize } from "../../variables";
import { useDispatch } from "react-redux";
import { MdExpandMore } from "react-icons/md";
import { filterOption } from "../../utilities";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

const getValuesOptions = (data: { id: string; name: string }[]) => {
  return data.map((item) => ({
    value: item.id,
    label: item.name,
    key: item.id,
  }));
};

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="green"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className={styles.tag}
    >
      {label}
    </Tag>
  );
};
//specNameOptions.filter((o) => !selectedItems.includes(o));
function FilterSelect({
  count,
  data,
  dispatchType,
  setValue,
  suffixIcon,
  extraQueryParams,
  multiple,
  extraDropdownRender,
  defValuesOptions,
  blockClear,
  defFilterOption,
  suffixIconJSX,
  defValue,
  disabled,
  disableSetNewValue,
  excludeSelectValueFormList,
}: {
  excludeSelectValueFormList?: true;
  suffixIconJSX?: ReactNode;
  dispatchType: string;
  count: number;
  data: { id: string; name: string }[];
  setValue?: (currentValueId: string, fullEntity?: {}) => void;
  suffixIcon?: string;
  extraQueryParams?: string;
  multiple?: boolean;
  extraDropdownRender?: ReactNode;
  defValuesOptions?: { key?: string; label: any; value: string }[];
  blockClear?: true;
  defValue?: string;
  disabled?: true;
  disableSetNewValue?: true;
  defFilterOption?: (
    input: string,
    option?: {
      label: {
        props: {
          data: {
            name: string;
          };
        };
      };
      value: string;
    }
  ) => boolean;
}) {
  const [selectValue, setSelectValue] = useState<string>(defValue || "");
  const allCountPages = Math.ceil(count / pageSize);
  const dispatch = useDispatch();
  const valuesOptions = defValuesOptions
    ? defValuesOptions
    : getValuesOptions(data);
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState("");

  const allowClear = blockClear === undefined ? true : false;
  /*   useEffect(() => {
    if (!searchValue) {
      console.log("hello");
      dispatch({
        type: dispatchType,
        payload: !extraQueryParams
          ? { page: 1 }
          : { query_params: extraQueryParams + `page=1&take=${pageSize}` },
      });
    }
  }, [searchValue]); */

  const getMoreValues = () => {
    const newPage = allCountPages === page ? 1 : page + 1;
    dispatch({
      type: dispatchType,
      payload: !extraQueryParams
        ? { page: newPage }
        : {
            query_params: extraQueryParams + `page=${newPage}&take=${pageSize}`,
          },
    });
    setPage(newPage);
  };

  const onChangeSelect = (newValueId: string, selectEntity: any) => {
    if (!disableSetNewValue) setSelectValue(newValueId);
    if (setValue) setValue(newValueId, selectEntity);
  };

  const onSearchValue = (value: string) => {
    let findItem;
    if (!defValuesOptions) {
      findItem = valuesOptions.find((vo) =>
        (vo?.label ?? "")?.toLowerCase()?.includes(value?.toLowerCase())
      );
    }
    if (!findItem) {
      dispatch({
        type: dispatchType,
        payload: !extraQueryParams
          ? { sort: "name", search: value }
          : {
              query_params:
                extraQueryParams +
                `page=${1}&take=${pageSize}&sort=name&search=${value}`,
            },
      });
    }
    setSearchValue(value);
  };

  let props: { [prop: string]: any } = {};
  if (multiple) {
    props.mode = "multiple";
    props.tagRender = tagRender;
  }

  useEffect(() => {
    if (defValue) setSelectValue(defValue);
  }, [defValue]);

  return (
    <Select
      disabled={disabled}
      suffixIcon={
        <div className={styles.suffix} style={{ marginRight: 10 }}>
          {suffixIconJSX || (
            <>
              <div>{suffixIcon}</div>
              <MdExpandMore size={24} />
            </>
          )}
        </div>
      }
      showSearch
      filterOption={defFilterOption || filterOption}
      onSearch={onSearchValue}
      className={styles.select}
      value={selectValue}
      searchValue={searchValue}
      onChange={onChangeSelect}
      style={{ width: "100%" }}
      options={valuesOptions}
      allowClear={allowClear}
      {...props}
      dropdownRender={(values) => (
        <>
          {values}
          <div
            style={{
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={getMoreValues}
          >
            {allCountPages > 1 && <MdExpandMore size={24} />}
          </div>
          {extraDropdownRender}
        </>
      )}
    />
  );
}

export default FilterSelect;
