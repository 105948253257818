import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CreditCard, PaymentMethod} from "../../components/OneProductDescAdmin/type";

type InitialState = {
    payments: PaymentMethod[];
    loading: boolean,
    error: string | null,
    success: string | null
}
const init: InitialState = {
    payments: [],
    loading: false,
    error:  null,
    success: null
};

const creditCardSlice = createSlice({
    name: "creditCards",
    initialState: init,
    reducers: {
        setCreditCards: (state, action: PayloadAction<PaymentMethod[]>) => {
            state.payments = action.payload;
        },
        addCreditCard: (state, action: PayloadAction<PaymentMethod>) => {
            state.payments.push(action.payload);
        },
        deleteOneCard: (state, action: PayloadAction<string>) => {
            state.payments = state.payments.filter(card => card.id !== action.payload);
        },
        editLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        editError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        editSuccess: (state, action: PayloadAction<string | null>) => {
            state.success = action.payload;
        },
    }
});

const {actions, reducer} = creditCardSlice;

export const {
    setCreditCards,
    addCreditCard,
    deleteOneCard,
    editLoading,
    editError,
    editSuccess
} = actions;

export default reducer;

