import React, { useEffect, useState, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Tag, notification, Divider, Space, TreeSelect } from "antd";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import { RiDeleteBinLine } from "react-icons/ri";
import type { UploadFile } from "antd/es/upload/interface";
import { Button, message } from "antd";
import { Select } from "antd";
import { Form, Input, Radio } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import {
  TreeSelectCategoryI,
  TreeSelectSubategoryI,
  TreeSelectI,
} from "../../components/OneProductDescAdmin/type";
import { BottomFixBlock } from "../../components/OneProductDescAdmin/index";
import { getTreeData } from "../../utilities/product";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { SpecValueI } from "../../redux/slices/spec_value.slice";
import { selectAll as selectAllSpecValue } from "../../redux/slices/spec_value.slice";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import { pageSize } from "../../variables";
import styles from "./index.module.scss";
import type { InputRef } from "antd";
import ListSpecValues from "../../components/ListSpecValues";
import { BsFilterCircleFill } from "react-icons/bs";
import { TopFixBlock } from "../../components/ProductDoc";
import { useParams } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import {
  CategoryI,
  selectAll as selectAllCategory,
} from "../../redux/slices/category.slice";
import {
  editError,
  editSuccess,
  setPickItem,
} from "../../redux/slices/spec_name.slice";

import {
  editError as editErrorSpecValue,
  editSuccess as editSuccessSpecValue,
} from "../../redux/slices/spec_value.slice";

interface SpecNameFormI {
  id?: string;
  specValues?: string[];
  name: string;
  category_id: string | null;
  subCategory_id: string | null;
  specValuesIds: string[];
}

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={"green"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className={styles.tag}
    >
      {label}
    </Tag>
  );
};

const sizeIcon = 24;
type LayoutType = Parameters<typeof Form>[0]["layout"];
export function OneSpec() {
  //variables
  const [searchText, setSearchText] = useState<string>("");
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openListValue, setOpenListValues] = useState(false);
  const params = useParams();
  const idSpec = params.idFilter;
  const [page, setPage] = useState<number>(1);
  const [valueCategory, setValueCategory] = useState<string>("");
  const [selectValues, setSelectValues] = useState<string[]>([]);
  const [entityCategory, setEntityCategory] = useState<
    TreeSelectSubategoryI | TreeSelectI
  >();
  const initDeletItem = { delete: false, confirm: false };
  const categories = useAppSelector(selectAllCategory);
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const inputRef = useRef<InputRef>(null);
  const specValue = useAppSelector(selectAllSpecValue);
  const [pickSpecValue, setPickSpecValue] = useState<null | {
    name: string;
    id: string;
  }>();
  const [name, setName] = useState<string>(pickSpecValue?.name || "");
  const [errorName, setErrorName] = useState<string | null>(null);
  const {
    loading: loadingSpecName,
    success,
    error,
    pickItem,
  } = useAppSelector((state) => state.spec_name);
  const {
    count: countSpecValue,
    error: errorSpecValue,
    success: successSpecValue,
  } = useAppSelector((state) => state.spec_value);
  const { loadingList: loadingListCategory } = useAppSelector(
    (state) => state.category
  );
  const [category, setCategory] = useState<null | {
    category: {
      name: string;
      id: string;
    };
    subCategory?: {
      name: string;
      id: string;
    };
  }>(null);
  const [deleteItem, setDeleteItem] = useState<{
    delete: boolean;
    confirm: boolean;
  }>(initDeletItem);
  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
      : null;
  const allCountPages = Math.ceil(countSpecValue / pageSize);

  ////useEffect
  useEffect(() => {
    dispatch({ type: "GET_ALL_SPEC_VALUE" });
    dispatch({ type: "GET_ALL_AREA" });
    idSpec !== "null" &&
      dispatch({ type: "GET_ONE_SPEC_NAME", payload: idSpec });

    return () => {
      dispatch(setPickItem(null));
      dispatch(editError(false));
      dispatch(editSuccess(null));
    };
  }, []);
  const [defaultValue, setDefaultValue] = useState<string[]>([]);
  useEffect(() => {
    if (pickItem) {
      console.log(form.getFieldsValue());
      form.setFieldValue("name", pickItem.name);

      if (pickItem.subCategory) {
        setCategory({
          category: {
            name: pickItem.category?.name as string,
            id: pickItem.category?.id as string,
          },
          subCategory: {
            name: pickItem.subCategory.name,
            id: pickItem.subCategory.id,
          },
        });
      } else {
        setCategory({
          category: {
            name: pickItem.category?.name as string,
            id: pickItem.category?.id as string,
          },
        });
      }
      form.setFieldValue("isCategoryPick", true);
      form.setFieldValue(
        "values",
        pickItem?.specValues?.map((v) => v.id)
      );
      setValueCategory(pickItem.subCategory?.id || pickItem.category?.id || "");
      /*     setSelectValues(pickItem.specValue.map((v) => v.id));
      setDefaultValue(pickItem.specValue.map((v) => v.name)); */
    }
  }, [pickItem]);

  useEffect(() => {
    if (successSpecValue && !openListValue) {
      api.success({
        message: <div className={styles.succes}>{successSpecValue}</div>,
        placement: "topRight",
        duration: 5000,
      });
      setName("");
    }
  }, [successSpecValue]);

  useEffect(() => {
    if (errorSpecValue && !openListValue) {
      api.error({
        message: <div className={styles.error}>{errorSpecValue}</div>,
        placement: "topRight",
        duration: 5000,
      });
    }
  }, [errorSpecValue]);

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        placement: "topRight",
        duration: 10,
      });
      if (!pickItem) {
        setName("");
        form.resetFields();
        setValueCategory("");
        setSelectValues([]);
      }
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: <div className={styles.error}>{error}</div>,
        placement: "topRight",
        duration: 10,
      });
    }
  }, [error]);

  //functions
  const handleOk = () => {
    setIsModalOpen(false);
    setErrorName("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    //dispatch(clearResponseStatusofForm());
    setName("");
    setPickSpecValue(null);
    setDeleteItem(initDeletItem);
    setErrorName("");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChangeSelect = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  const handleDrop = (event: React.DragEvent) => {
    console.log("handleDrop");
  };

  const changePage = (page: any) => {
    const payload: { page: number; search?: string } = { page };
    if (searchText) {
      payload.search = searchText;
    }
    dispatch({ type: "GET_ALL_SPEC_VALUE", payload });
  };

  const onSearch = (value: string) => {
    dispatch({ type: "GET_ALL_SPEC_VALUE", payload: { search: value } });
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const closeListValues = (): void => {
    setOpenListValues(false);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const getMoreValues = () => {
    dispatch({
      type: "GET_ALL_SPEC_VALUE",
      payload: { page: allCountPages === page ? 1 : page + 1 },
    });

    if (allCountPages === page) setPage(1);
    if (allCountPages !== page) setPage((page) => ++page);
  };

  const onChangeCategory = (
    //same in one product desc admun  => optimization
    value: string,
    data: TreeSelectSubategoryI | TreeSelectI
  ) => {
    console.log(data);
    if ("subCategory_id" in data) {
      setCategory({
        category: {
          name: data.category_name,
          id: data.category_id,
        },
        subCategory: {
          name: data.title,
          id: data.subCategory_id,
        },
      });
      setValueCategory(data.value);
      form.setFieldValue("isCategoryPick", true);
    } else {
      if (data.children.length > 0) {
        api.error({
          message: (
            <div className={styles.error}>
              The category {data.title} has subcategory. Please select
              subcategory
            </div>
          ),
          placement: "topRight",
          duration: 5000,
        });
      } else {
        setCategory({
          category: {
            name: data.title,
            id: data.value,
          },
        });
        setValueCategory(data.value);
        form.setFieldValue("isCategoryPick", true);
      }
    }
  };

  const createNewValue = () => {
    if (!name) {
      setErrorName("Please enter name's values");
      return;
    }
    setErrorName(null);
    dispatch({ type: "ADD_SPEC_VALUE", payload: { name } });
  };

  const saveChanges = async () => {
    try {
      await form.validateFields();
      const fields = await form.getFieldsValue();
      const payload: SpecNameFormI = {
        specValues: fields.values,
        specValuesIds: fields.values,
        name: fields.name,
        category_id: category?.category?.id || null,
        subCategory_id: category?.subCategory?.id || null,
      };

      if (pickItem) payload.id = pickItem.id;

      dispatch({
        type: pickItem ? "EDIT_SPEC_NAME" : "ADD_SPEC_NAME",
        payload,
      });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const getValuesOptions = () => {
    const defaultValue = pickItem?.specValues || [];
    const valuesOptions = specValue.filter((value) => {
      const findItem = defaultValue.find((el) => el.id === value.id);
      return value.id !== findItem?.id;
    });

    return [...valuesOptions, ...defaultValue].map((value) => ({
      value: value.id,
      label: value.name,
      key: value.id,
    }));
  };
  const valuesOptions = getValuesOptions();

  return (
    <>
      <div className={styles.product}>
        <TopFixBlock>
          {!openListValue && (
            <div className={styles.listButtons}>
              {idSpec !== "null" && (
                <Button className={styles.button}>
                  <RiDeleteBinLine size={sizeIcon - 2} />
                  <div> Delete the spec</div>
                </Button>
              )}

              <Button
                onClick={() => {
                  dispatch(editErrorSpecValue(false));
                  dispatch(editSuccessSpecValue(null));
                  setOpenListValues(true);
                }}
                className={styles.button}
              >
                <BsFilterCircleFill size={sizeIcon - 7} />
                <div> Show all values for specs</div>
              </Button>
            </div>
          )}
        </TopFixBlock>
        <div className={styles.wrapper}>
          <div className={styles.rightPart}>
            <div className={styles.header__text}>
              {idSpec === "null" ? "Add a new spec" : "Edit the spec"}
            </div>
            <Form
              {...formItemLayout}
              layout={formLayout}
              form={form}
              initialValues={{ layout: formLayout }}
              //onValuesChange={onFormLayoutChange}
              className={styles.form}
              ///style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
            >
              <div className={styles.form__row}>
                <Form.Item
                  label="Name"
                  name="name"
                  className={styles.form__item}
                  rules={[{ required: true, message: "Please input name!" }]}
                >
                  <Input
                    placeholder="input placeholder"
                    className={styles.form__input}
                    name="name"
                  />
                </Form.Item>
                <Form.Item
                  name="isCategoryPick"
                  label="Category/Subcategory"
                  className={styles.form__item}
                  rules={[
                    {
                      required: true,
                      message: "Please select category or subcatgory ",
                    },
                  ]}
                >
                  <div></div>
                  <TreeSelect
                    /* showSearch */
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                      minWidth: 300,
                    }}
                    //value={category?.category.id || category?.subCategory?.id}
                    value={valueCategory}
                    placeholder="Please select"
                    placement="bottomLeft"
                    treeDefaultExpandAll
                    treeData={getTreeData(categories)}
                    className={styles.select}
                    onSelect={onChangeCategory}
                    loading={loadingListCategory}
                  />
                </Form.Item>
                <Form.Item
                  label="Values"
                  className={styles.form__item}
                  name="values"
                  rules={[{ required: true, message: "Please select values!" }]}
                >
                  <Select
                    onSearch={(value) => console.log(value)}
                    className={styles.select}
                    defaultValue={defaultValue}
                    mode="multiple"
                    tagRender={tagRender}
                    value={selectValues}
                    onChange={(newValuesId: string[], newValuesEntity) => {
                      setSelectValues(newValuesId);
                    }}
                    style={{ width: "100%" }}
                    options={valuesOptions}
                    dropdownRender={(values) => (
                      <>
                        {values}
                        <div
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={getMoreValues}
                        >
                          {allCountPages > 1 && <MdExpandMore size={24} />}
                        </div>
                        <Divider style={{ margin: "8px 0" }} />
                        <div style={{ width: "100%", textAlign: "start" }}>
                          <ResponseStatusComponent error={errorName} />
                        </div>
                        <Space
                          style={{
                            padding: "0 8px 4px",
                            width: "100%",
                          }}
                        >
                          <Input
                            placeholder="Please enter value"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                          />

                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={createNewValue}
                          >
                            Create а new value
                          </Button>
                        </Space>
                      </>
                    )}
                  />
                </Form.Item>

                <div className={styles.buttons}>
                  <Button onClick={saveChanges} loading={loadingSpecName}>
                    Save{" "}
                  </Button>
                </div>
              </div>

              {/* <Form.Item {...buttonItemLayout}>
        <Button type="primary">Submit</Button>
      </Form.Item> */}
            </Form>
          </div>
        </div>
        {contextHolder}
        {openListValue && <ListSpecValues closeListValues={closeListValues} />}
        {/*      <BottomFixBlock>
          <div className={styles.buttons}>
            <Button>Save changes</Button>
          </div>
        </BottomFixBlock> */}
      </div>
    </>
  );
}

export default OneSpec;
