import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  Select,
  Button,
  Input,
  Table,
  Tooltip,
  Modal,
  notification,
} from "antd";
import * as XLSX from "xlsx";
import { columns } from "./columns";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { setAllImportXLS } from "../../redux/slices/importXLSX.slice";
import { selectAll as selectAllXLSX } from "../../redux/slices/importXLSX.slice";
import { FaRegSave } from "react-icons/fa";

const sizeIcon = 24;

export function ProductImportManagement() {
  // onchange states
  const [excelFile, setExcelFile] = useState<any>(null);
  const [typeError, setTypeError] = useState<string | null>(null);

  // submit state
  const [excelData, setExcelData] = useState<any>(null);
  //const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectAllXLSX);
  const params = useParams();
  const nameFiles = params.nameFiles;
  const { loading } = useAppSelector((state) => state.importXLSX);
  const [api, contextHolder] = notification.useNotification();
  const navigation = useNavigate();

  // onchange event

  useEffect(() => {
    dispatch({
      type: "GET_ONE_XLSX_FILES",
      payload: { name: nameFiles },
    });
  }, []);

  /*   const handleFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      let reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = (e: any) => {
        dispatch(setAllImportXLS(e.target.result));
      };
    }
  }; */

  const sendData = () => {
    let error = false;

    for (let i = 0; i < data.length; i++) {
      if (!data[i].defValue) {
        error = true;
        break;
      }
    }
    if (error) {
      api.error({
        message: "Please fill all fields or delete unnecessary",
        duration: 3,
      });
      return;
    }

    if (nameFiles) {
      const params = new URLSearchParams();
      params.append("fileName", nameFiles);
      params.append("maxInsertAmount", "5"); //delete after testing
      data.forEach((ent) => {
        let name =
          ent.defValue === "specname"
            ? "specNames[]"
            : (ent.defValue as string);
        let value = ent.key;

        params.append(name, value);
      });

      navigation("/admin/importFiles", {
        state: {
          message:
            "The Request to create xlsx product has been sent. We will notificate you  after creating products",
        },
      });
      dispatch({ type: "CREATE_XLSX_PRODUCT", payload: params });
    }
  };

  return (
    <div className="wrapper">
      {contextHolder}
      <div className={styles.title}>
        <div className={styles.title__text}>File Management - {nameFiles}</div>
        <div className={styles.listButtons}>
          <Button onClick={() => {}} className={styles.button}>
            <FaRegSave size={sizeIcon - 2} />
            <div onClick={sendData}> Send to create xlsx-products</div>
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        /*
        pagination={{
          pageSize,
          total: count,
          position: ["bottomRight"],
          onChange: changePage,
        }} */
        rowClassName={styles.row}
        className={styles.table}
        //scroll={{ y: 700 }}
        bordered
      />
    </div>
  );
}

export default ProductImportManagement;
