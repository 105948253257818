import React, { useState } from "react";
import { Layout, Button, Input, Divider } from "antd";

import OneGood from "../../components/Goods/OneGood";
import { AiOutlineShoppingCart } from "react-icons/ai";
import styles from "./index.module.scss";

const arrayTest = [1, 1, 1];

function Purchases() {
  const iconSize = 24;
  return (
    <div>
      <>
        {arrayTest.map((_) => (
          <OneGood
            hiddenCheck={true}
            //hiddenFooter={true}
            footer={() => (
              <div className={styles.footer}>
                <div className={styles.footer_count}>1 item</div>
                <div className={styles.footer_price}>$50.05 each</div>
              </div>
            )}
            hiddenLike={true}
            direction="horizontal"
            leaveFeedback={true}
          />
        ))}
      </>
    </div>
  );
}

export default Purchases;
