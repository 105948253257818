import React, {useEffect, useState} from "react";
import {Modal, Upload, Table, Tooltip, notification} from "antd";
import type {RcFile, UploadProps} from "antd/es/upload";
import type {UploadFile} from "antd/es/upload/interface";
import {Button, message, RadioChangeEvent} from "antd";
import {BsCardImage} from "react-icons/bs";
import {AiOutlineClose} from "react-icons/ai";
import {Form, Input} from "antd";
import styles from "./index.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {IoIosAddCircleOutline} from "react-icons/io";
import {selectAll as selectAllHighlights} from "../../redux/slices/highlights.slice";

import {
    selectAll as selecAllSpecName,
    setSpecialItemsForProduct,
} from "../../redux/slices/spec_name.slice";
import {useAppSelector} from "../../redux/ts/hooks/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {
    IFormMainData,
    ProductTypeIForForm,
    editError,
    editSuccess,
    setPickItem,
} from "../../redux/slices/product_main.slice";
import ResponseStatusComponent from "../ResponseStatusComponent";
import {selectAll as selectAllProduct} from "../../redux/slices/product_main.slice";
import FilterSelect from "../FilterSelect";
import HighlightsModal from "../HighlightsModal";
import {AiOutlineDelete} from "react-icons/ai";
import {pageSize} from "../../variables";
import {selectAll as selectAllImg} from "../../redux/slices/image.slice";
import ExtraImg from "./ExtraImg";
import {getColumns} from "./columns";
import {
    getTreeData,
    getBase64,
    getPayloadProductDesc,
} from "../../utilities/product";
import {FormMainData} from "./FormMainData";
import {IXLSXProductType} from "../../redux/slices/xlsx_product.slice";

export function BottomFixBlock({children}: { children: any }) {
    return <div className={styles.bottomFixBlock}>{children}</div>;
}

export interface IExtraImg {
    fileListExtra: UploadFile[];
    updateFiles: { deleteId: string[]; uploadImage: UploadFile[] };
}

export function OneProductDescAdmin({
                                        data,
                                        type,
                                    }: {
    data?: IXLSXProductType | null;
    type?: "xlsx";
}) {
    //variables
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [api, contextHolder] = notification.useNotification();

    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const products = useAppSelector(selectAllProduct);
    const {loadingList: loadingListSearch} = useAppSelector(
        (state) => state.highlights
    );
    const {loadingList: loadingListSpecName} = useAppSelector(
        (state) => state.spec_name
    );

    const [desc, setDesc] = useState<string>("");
    const [hl, setHl] = useState<{ id: string; name: string }[] | []>([]);
    const [errorH, setErrorH] = useState<boolean>(false);
    const [errorD, setErrorDesc] = useState<boolean>(false);
    const [statusProduct, setStatusProduct] = useState<{
        value: string;
        label: React.ReactNode;
    }>({
        value: "Active",
        label: "Active",
    });

    const [valueCategory, setValueCategory] = useState<string>("");
    const [file, setFile] = useState<File | null>();
    const [errorImg, setErrorImg] = useState<null | string>(null);
    const {
        loading: loadingProduct,
        pickItem: pickItemProduct,
        error: errorProduct,
        success: succesProduct,
    } = useAppSelector((state) => state.product);
    const {success:successXlsx}=useAppSelector((state) => state.xlsx_product);
    const highlights = useAppSelector(selectAllHighlights);
    const {count: countHlts} = useAppSelector((state) => state.highlights);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [valueStatus, setValueStatus] = useState<"Active" | "Out Of Stock">(
        "Active"
    );
    const [isModalH, setIsModalH] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    //const [confirmDeleteSpec, setConfirmDeleteSpec] = useState(false);
    const closeModalH = () => setIsModalH(false);
    const [onSave, setOnSave] = useState(false);
const [nameItem,setNameItem]=useState(data?.name)
    const [fileListExtra, setFileListExtra] = useState<UploadFile[]>([]);
    const [updateFiles, setUpdateFiles] = useState<{
        deleteId: string[];
        uploadImage: UploadFile[];
    }>({deleteId: [], uploadImage: []});

    /*  const [infoAboutExtraImgs, setInfoAboutExtraImgs] = useState<IExtraImg>({
      fileListExtra: [],
      updateFiles: {deleteId: [], uploadImage: []},
    }); */

    //useEffect

    useEffect(() => {
        dispatch({type: "GET_ALL_AREA"});
        if (type !== "xlsx") {
            // dispatch({type: "GET_ALL_AREA"});
            dispatch({type: "GET_ALL_HIGHLIGHTS"});
            if (params.idProduct && params.idProduct !== "null") {
                dispatch({type: "GET_ONE_PRODUCT", payload: params.idProduct});
                //dispatch({ type: "GET_ONE_PRODUCT", payload: params.idProduct });
                dispatch({
                    type: "GET_ALL_SPEC_ONE_PRODUCT",
                    payload: {
                        params: `?sort=product_id&search=${
                            params.idProduct
                        }&page=${1}&take=${pageSize}&`,
                    },
                });
            }
        }

        return () => {
            dispatch(setPickItem(null));
            setDesc("");
            setHl([]);
        };
    }, []);

    useEffect(() => {
        if (pickItemProduct) {
            console.log(pickItemProduct);
            setPreviewImage(pickItemProduct?.avatar_url);
            setHl(pickItemProduct.highlights || []);
            setStatusProduct(
                pickItemProduct.amount > 1
                    ? {label: "Active", value: "active"}
                    : {label: "Out of stock", value: "outOfStock"}
            );
            setDesc(pickItemProduct.description);
        } else {
            dispatch(setPickItem(null));
            setHl([]);
        }
    }, [pickItemProduct]);

    useEffect(() => {
        if (succesProduct) {
            if (pickItemProduct && succesProduct !== "The product has been deleted") {
                api.success({
                    message: <div className={styles.error}>{succesProduct}</div>,
                    placement: "topRight",
                    duration: 3,
                });
                dispatch(editSuccess(null));
            }
            if (succesProduct === "The product has been deleted") {
                navigate("/admin/products");
            }
            if (succesProduct === `The product ${nameItem} has been added`) {
                navigate("/admin/products");
            }
            if (!pickItemProduct) {
                setIsModalOpen(true);
            }
        }
    }, [succesProduct]);

    useEffect(() => {
        if (errorProduct) {
            api.error({
                message: <div className={styles.error}>{errorProduct}</div>,
                placement: "topRight",
                duration: 10,
            });

            dispatch(editError(null));
        }
    }, [errorProduct]);

    useEffect(() => {
        setErrorImg(null);
    }, [file]);

    //functions

    const handleOk = () => {
        if (confirmDelete) {
            if (pickItemProduct?.id)
                dispatch( {type: data?.id? "DELETE_XLSX_PRODUCT" :"DELETE_PRODUCT", payload: pickItemProduct.id});
        } else {
            setIsModalOpen(false);
            const newProduct = products[products?.length - 1];
            const path = "/admin/products/" + newProduct.id + "/2";
            navigate(path);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if (confirmDelete) {
            setConfirmDelete(false);
        } else {
            //clearForm();
            setValueCategory("");
            dispatch(setSpecialItemsForProduct(null));
        }
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage(file.url || (file.preview as string));
        /* setPreviewTitle(
          file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
        ); */
    };

    const handleChange: UploadProps["onChange"] = async ({
                                                             fileList: newFileList,
                                                         }) => {
        setFileList(newFileList);
        const file = newFileList[newFileList.length - 1];
        file && (await handlePreview(file));
    };

    const deleteImage = () => {
        setFileList([]);
        setFile(null);
        setPreviewImage("");
    };

    const handleChangeSelect = (value: {
        value: string;
        label: React.ReactNode;
    }) => {
        setStatusProduct(value);
    };

    const handleDrop = (event: React.DragEvent) => {
        console.log("handleDrop");
    };

    const onSearch = (value: string) => {
        dispatch({type: "GET_ALL_HIGHLIGHTS", payload: {search: value}});
    };

    const handleChangeSelectHL = (id: string) => {
        if (id) {
            const v = highlights.find((hl) => hl.id === id);
            setHl((hl) => {
                let pickItem: null | { name: string; id: string } | undefined = v;
                const newHl = hl.map((h) => {
                    if (h.id === v?.id) {
                        pickItem = null;
                    }
                    return h;
                });

                if (pickItem) newHl.push(pickItem);
                return newHl;
            });
        }
    };

    /*   const clearForm = () => {
      form.resetFields();
      setHl([]);
      setEasyReturns(false);
      setFreeShipping(false);
      setDesc("");
    }; */

    const customRequest = (options: any) => {
        setFile(options.file);
    };

    const deleteProduct = () => {
        setConfirmDelete(true);
        setIsModalOpen(true);
    };

    const deleteHlFormList = (id: string) => {
        setHl((hl) => hl.filter((item) => item.id !== id));
    };

    const isValidForm = (result: false | IFormMainData): void => {
        console.log(result)
        setOnSave(false);
        let isErrorInForm = false;
        if (!result) isErrorInForm = true;
        if (!file && !pickItemProduct) {
            setErrorImg("Please upload avatar");
            isErrorInForm = true;
        } else {
            setErrorImg(null);
        }
        if (!desc) {
            setErrorDesc(true);
            isErrorInForm = true;
        } else {
            setErrorDesc(false);
        }

        if (isErrorInForm) {
            api.error({
                message: (
                    <div className={styles.error}>
                        Please, scroll and fill all fields.
                    </div>
                ),
                placement: "topRight",
                duration: 3,
            });
            return;
        }

        if (result) saveChanges(result);
    };

    const saveChanges = (result: IFormMainData) => {
        api.destroy();
        const body: ProductTypeIForForm = {
            ...result,
            highlights: hl?.map((h) => h.id) || [],
            description: desc,
        };


        if (pickItemProduct) body.id = pickItemProduct.id;
        setNameItem(body.name)

        dispatch({
            type: data?.id ? 'UPLOAD_XLSX_TO_PRODUCT' : !pickItemProduct ? "ADD_PRODUCT" : "EDIT_PRODUCT",
            payload:  getPayloadProductDesc(
                body,
                file as File,
                !pickItemProduct ? fileListExtra : null,
                updateFiles.deleteId || updateFiles.uploadImage ? updateFiles : null
            ),
        });
    };

    const setExtraFiles = ({
                               file: uploadFile,
                               fileList: newFileList,
                           }: {
        file: UploadFile | null;
        fileList: UploadFile[];
    }) => {
        if (uploadFile !== null) {
            setUpdateFiles((updateFiles) => {
                const {deleteId, uploadImage} = updateFiles;
                let newUpdateFiles = {
                    deleteId: [...deleteId],
                    uploadImage: [...uploadImage],
                };

                const newUploadFile = uploadFile?.uid?.includes("rc-upload");

                if (
                    fileListExtra.length + 1 === newFileList.length &&
                    pickItemProduct?.id
                ) {
                    //add file.
                    newUpdateFiles.uploadImage.push(uploadFile);
                }

                if (
                    fileListExtra.length - 1 === newFileList.length &&
                    pickItemProduct?.id &&
                    newUploadFile
                ) {
                    //delete file. The File isn't on a server
                    newUpdateFiles.uploadImage = updateFiles.uploadImage.filter(
                        (file) => file.uid !== uploadFile.uid
                    );
                }
                if (
                    fileListExtra.length - 1 === newFileList.length &&
                    pickItemProduct?.id &&
                    !newUploadFile
                ) {
                    //delete file. The File is on a server
                    newUpdateFiles.deleteId.push(uploadFile.uid);
                }

                return newUpdateFiles;
            });
        }

        setFileListExtra(newFileList);
    };


    return (
        <>
            {contextHolder}
            <div className={styles.product}>
                <div className={styles.wrapper}>
                    {/*LEFT_PART*/}
                    <div className={styles.leftPart}>
                        {/*Status*/}

                        <div className={styles.header}>
                            <div className={styles.header__text}>
                                <div>Status</div>
                                <div></div>
                            </div>
                            <div className={styles.header__select}>
                                <Input
                                    disabled={true}
                                    value={valueStatus}
                                    className={styles.form__input}
                                />
                            </div>
                        </div>

                        {/*Avatar*/}

                        <div
                            className={[
                                styles.img_block,
                                errorImg ? styles.img_block_error : "",
                            ].join(" ")}
                        >
                            <div className={styles.imgTitle}>
                                <div>
                                    <label className={styles.label} title="Des">
                                        Avatar
                                    </label>
                                </div>
                                {previewImage && (
                                    <div className={styles.close} onClick={deleteImage}>
                                        <AiOutlineClose size={24}/>
                                    </div>
                                )}
                            </div>
                            <div className={styles.picter}>
                                {previewImage ? (
                                    <div
                                        className={styles.img}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <img
                                            alt="example"
                                            style={{
                                                width: "90%",
                                                maxHeight: 200,
                                            }}
                                            src={previewImage}
                                        />
                                    </div>
                                ) : (
                                    <Upload
                                        maxCount={1}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        onDrop={handleDrop}
                                        className={
                                            previewImage ? styles.upload_preview : styles.upload
                                        }
                                        customRequest={customRequest}
                                    >
                                        <div>
                                            <BsCardImage size={70} color="#878D9C"/>
                                        </div>
                                    </Upload>
                                )}
                            </div>
                        </div>

                        {/*ExtraImg*/}

                        <ExtraImg setExtraFiles={setExtraFiles}/>
                    </div>

                    {/*RIGHT_PART*/}

                    <div className={styles.rightPart}>
                        <div className={styles.details}>
                            <div> Details</div>
                            <div onClick={deleteProduct}>
                                <AiOutlineDelete size={20}/>
                            </div>
                        </div>

                        {/*DataForm => name, SKU, amount, price,  category/subcategory, free shipping, easy returns*/}
                        <FormMainData
                            isValidForm={isValidForm}
                            onSave={onSave}
                            data={data}
                        />

                        {/*Highlights*/}

                        <div className={styles.form__row} style={{padding: 20}}>
                            <div
                                className={styles.titleSelect}
                                style={{display: "flex", alignItems: "center"}}
                            >
                                <div style={{marginRight: 10}}>Product Highlights</div>
                                <div onClick={() => setIsModalH(true)}>
                                    <Tooltip title="Click to create a new  highlight">
                                        <IoIosAddCircleOutline size={24}/>
                                    </Tooltip>
                                </div>
                            </div>

                            <FilterSelect
                                data={highlights}
                                count={countHlts}
                                dispatchType="GET_ALL_HIGHLIGHTS"
                                setValue={handleChangeSelectHL}
                            />
                        </div>
                        <div className={styles.highlights}>
                            <Table
                                columns={getColumns(deleteHlFormList)}
                                dataSource={hl}
                                //loading={true}
                                showHeader={false}
                                rowClassName={styles.row}
                                //className={styles.table}
                                pagination={false}
                                bordered
                            />
                        </div>
                    </div>
                </div>
                {/*Description*/}
                <div className={styles.desc}>
                    <div>
                        <label className={styles.label} title="Des">
                            Description
                        </label>
                    </div>
                    <div className={styles.desc_text}>
                        <Form.Item>
                            <Input.TextArea
                                status={errorD ? "error" : ""}
                                rows={10}
                                defaultValue={desc}
                                value={desc}
                                onChange={(e) => {
                                    setDesc(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>

            {/*Save */}
            <BottomFixBlock>
                <div className={styles.buttons}>
                    <Button onClick={() => setOnSave(true)} loading={loadingProduct}>
                        Save changes
                    </Button>
                </div>
            </BottomFixBlock>

            <Modal
                title={confirmDelete ? "Delete product" : ""}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {confirmDelete ? (
                    <div style={{textAlign: "center"}}>
                        <ResponseStatusComponent error={errorProduct}/>
                        Are you sure you want delete the product
                        <b>{" " + pickItemProduct?.name}?</b>
                        <br/>
                        Main description, images,specs, video and manual of product will
                        be
                        deleted
                    </div>
                ) : (
                    <>
                        <ResponseStatusComponent success={`The product  has been added`}/>
                        <div style={{textAlign: "center"}}>
                            Do you want to add filters or video/manuals to the product?
                        </div>
                    </>
                )}
            </Modal>
            <HighlightsModal isModalH={isModalH} closeModalH={closeModalH}/>
        </>
    );
}

export default OneProductDescAdmin;
