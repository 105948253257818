import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";
import { UserDataI } from "./account.slice";
import { InitStateRequestStatusI } from "../ts/interface";

export type statusOrder = "Created" | "Delivery" | "Completed";
interface ProductInOrder extends ProductType {
  Item: {
    id: string;
    count: number;
  };
}
export interface OrderI {
  id: string;
  user_id: string;
  card_id: string;
  status: statusOrder;
  address: string;
  products: ProductInOrder[];
  user: UserDataI;
  updatedAt: Date;
}

export const ordersAdapter = createEntityAdapter<OrderI>({
  selectId: (orders) => orders.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | OrderI;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};

export const initialState = ordersAdapter.getInitialState(init);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setAllOrders: (state, action) => {
      ordersAdapter.setAll(state, action.payload);
    },
    addOneOrders: (state, action) => {
      ordersAdapter.addOne(state, action.payload);
    },
    updateOneOrders: (state, action) => {
      ordersAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneOrders: (state, action) => {
      ordersAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearOrdersSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | OrderI>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = ordersSlice;

export const {
  setAllOrders,
  addOneOrders,
  updateOneOrders,
  removeOneOrders,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearOrdersSlice,
  setCount,
  setPickItem,
} = actions;

export default reducer;
export const { selectAll } = ordersAdapter.getSelectors<RootState>(
  (state) => state.orders
);
