import { CommonService } from "./CommonService";
import { RequestService } from "./RequestService";
import jwt_decode from "jwt-decode";
import { TBodyUpdateContact, IBodyUpdateAboutMe } from "./interfaces";
import { pageSize } from "../variables";

export class SpecForProductService extends CommonService {
  constructor() {
    super("characteristicsSpecs");
  }

  deletAllByProductId = (id: string, body?: {}, queryParams?: {}) =>
    this.request({
      method: "delete",
      body,
      queryParams,
      id,
      path: "byProduct_id",
    });

  getAllProductBySpec = (body: {}) =>
    this.request({
      method: "post",
      path: "getProductBySpecs",
      body,
    });
}
