import React, { useEffect, useState } from "react";
import { Input, Button, Modal, notification, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { BsCardImage } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  clearResponseStatusofForm,
  editSuccess,
} from "../../redux/slices/highlights.slice";
import ResponseStatusComponent from "../ResponseStatusComponent";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import styles from "./index.module.scss";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
function HighlightsModal({
  isModalH,
  closeModalH,
  pickHighlights,
  deleteItem,
}: {
  isModalH: boolean;
  closeModalH: () => void;
  pickHighlights?: null | {
    name: string;
    id: string;
    image_url: string | undefined;
  };
  deleteItem?: boolean;
}) {
  const [isModalOpen, setIsModalOpen] = useState(isModalH);
  const [y, setY] = useState<string>("");
  const dispatch = useAppDispatch();
  const { error, success, loading, loadingList, errorList, successList } =
    useAppSelector((state) => state.highlights);
  const navigate = useNavigate();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(
    pickHighlights?.image_url || ""
  );
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [name, setName] = useState<string>(pickHighlights?.name || "");
  const [api, contextHolder] = notification.useNotification();
  const [pickCategory, setPickCategory] = useState<null | {
    name: string;
    id: string;
  }>();
  const [file, setFile] = useState<File | null>();
  const [errorForm, setErrorForm] = useState<null | string>("");

  useEffect(() => {
    if (success && pickHighlights) {
      setIsModalOpen(false);
      closeModalH();
      dispatch(clearResponseStatusofForm());
      setPickCategory(null);
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 10,
      });
      dispatch(clearResponseStatusofForm());
    }

    if (success && !pickHighlights) {
      setName("");
      setFile(null);
      setPreviewImage("");
      setFileList([]);
    }
  }, [success]);

  useEffect(() => {
    setIsModalOpen(isModalH);
  }, [isModalH]);

  useEffect(() => {
    if (pickHighlights?.name) {
      setName(pickHighlights?.name);
    }
  }, [pickHighlights]);

  useEffect(() => {
    setPreviewImage(pickHighlights?.image_url || "");
  }, [pickHighlights]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
      const x = await getBase64(file.originFileObj as RcFile);
      setY(x);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
    file: pickFile,
  }) => {
    setFileList(newFileList);
    const file = newFileList[newFileList.length - 1];
    file && (await handlePreview(file));
  };

  const handleDrop = (event: React.DragEvent) => {
    console.log("handleDrop");
  };

  const onAuth = () => {
    setIsModalOpen(true);
  };
  const goTo = (path: string | null) => {
    if (path) navigate(path);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("hello");
    setIsModalOpen(false);
    closeModalH();
    dispatch(clearResponseStatusofForm());
    setName("");
    setPickCategory(null);
    setFile(null);
    setPreviewImage("");
    setFileList([]);
  };
  const onSearch = () => console.log("onSearch");

  const sendRequest = () => {
    setErrorForm(null);
    if (!file && !previewImage) {
      setErrorForm("Please upload image");
      return;
    }
    if (!name) {
      setErrorForm("Please enter input");
      return;
    }
    let formData: any = new FormData();
    let id: undefined | string = pickHighlights?.id;
    formData.append("name", name);
    if (file) formData.append("file", file);
    if (id) formData.append("id", id);
    dispatch({
      type: pickHighlights ? "EDIT_HIGHLIGHTS" : "ADD_HIGHLIGHTS",
      payload: { bodyInFormData: formData, name, id },
    });
  };

  const getTextForModal = (): string => {
    if (deleteItem) return "Delete the highlight";
    if (!pickHighlights) return "Add a new highlight";
    return "Edit the highlight";
  };

  const onDelete = () => {
    if (pickHighlights)
      dispatch({ type: "DELETE_HIGHLIGHTS", payload: pickHighlights });
  };

  const deleteImage = () => {
    setFileList([]);
    setPreviewImage("");
    setFile(null);
  };

  const customRequest = (options: any) => {
    setFile(options.file);
  };

  console.log(pickHighlights);

  return (
    <>
      {contextHolder}
      <Modal
        title={getTextForModal()}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className={styles.modalButton}>
            <Button
              loading={loading}
              className={styles.button}
              onClick={deleteItem ? onDelete : sendRequest}
            >
              {getTextForModal()}
            </Button>
          </div>,
        ]} // delete all buttons . You can add any component
      >
        <ResponseStatusComponent error={error} success={success} />
        <ResponseStatusComponent error={errorForm} />
        {deleteItem && (
          <>
            <div style={{ textAlign: "center" }}>
              Are you sure you want delete the highlights?
              <br />
              {<b>{pickCategory?.name}</b>}This highlights will be deleted in
              all products
            </div>
          </>
        )}
        {!deleteItem && (
          <div className={styles.preview}>
            <div className={styles.preview__img}>
              {previewImage && (
                <div className={styles.close} onClick={deleteImage}>
                  <AiOutlineClose size={16} />
                </div>
              )}
              <div className={styles.img_block}>
                {previewImage ? (
                  <div className={styles.img}>
                    <img
                      alt="example"
                      style={{ width: "100%", height: "100%" }}
                      src={previewImage}
                    />
                  </div>
                ) : (
                  <Upload
                    maxCount={1}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onDrop={handleDrop}
                    className={
                      previewImage ? styles.upload_preview : styles.upload
                    }
                    customRequest={customRequest}
                  >
                    <div>
                      <BsCardImage size={24} color="#878D9C" />
                    </div>
                  </Upload>
                )}
              </div>
            </div>
            <div className={styles.preview__input}>
              <label className={styles.label}>Name</label>
              <Input
                className={styles.input}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default HighlightsModal;
