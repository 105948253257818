import { takeLeading, call, put } from "redux-saga/effects";
import {
  highlightsService, productService,
  xlsxFileService,
  xlsxProductService,
} from "../../services";
import { Action, PayloadAction } from "@reduxjs/toolkit";

import {
  setAllProduct,
  addOneProduct,
  updateOneProduct,
  removeOneProduct,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  setAllSearchProduct,
  setSearchCount,
  editLoadingSearch,
  IXLSXProductType,
} from "../slices/xlsx_product.slice";
import axios from "axios";
import { ExelFileService } from "../../services/ExelFileService";
import { IFileXLSX } from "../slices/importXLSX.slice";
import {deleteXlsxOneAxios} from "../../services/api";
//

export type TFolders =
  | "MarathonElectricMotors"
  | "MarathonACDCControls"
  | "LeesonElectricMotors"
  | "LeesonACDCControls"
  | "GenteqElectricMotors"
  | "GenteqBlowers"
  | "FascoElectricMotors"
  | "FascoBlowers"
  | "CenturyElectricMotors"
  | "CenturyBlowers"
  | "BrowningMechanical"
  | "BrowningBearings"
  | "BrowningCouplings"
  | "BrowningGearing"
  | "BrowningIndustrialChain";

function* getAll(action: PayloadAction<{ page?: number; name?: string }>) {
  yield put(editLoadingList(true));
  try {
    const response: { rows?: IXLSXProductType[]; count: number } = yield call(
      xlsxProductService.getAll,
      action.payload
    );
    yield put(setAllProduct(response.rows));
    yield put(setCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoading(true));

  try {
    const response: IXLSXProductType = yield call(
      xlsxProductService.getOne,
      action.payload
    );
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}
function* deleteXlsxOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(deleteXlsxOneAxios, action.payload);
    yield put(removeOneProduct(action.payload));
    yield put(editSuccess("The product has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchXLSXProductSaga() {
  yield takeLeading("GET_ALL_XLSX_PRODUCTS", getAll);
  yield takeLeading("GET_ONE_XLSX_PRODUCTS", getOne);
  // /*  yield takeLeading("GET_ONE_XLSX_FILES", getOne);
  yield takeLeading("DELETE_XLSX_PRODUCT", deleteXlsxOne);
  /* yield takeLeading("ADD_HIGHLIGHTS", addOne);
  yield takeLeading("EDIT_HIGHLIGHTS", editOne);
  yield takeLeading("DELETE_HIGHLIGHTS", deleteOne); */
}
