import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Modal,
  Upload,
  Image,
  Table,
  Tooltip,
  notification,
  Alert,
} from "antd";
import XLSX from "xlsx";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { BsCardImage } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Select } from "antd";
import { Form, Input, Radio } from "antd";
//import { Player } from 'video-react';
import testVideo from "./testVideo.mp4";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Tabs } from "antd";
import { FaFileAlt } from "react-icons/fa";
import { CiVideoOn } from "react-icons/ci";
import { Player } from "video-react";

import type { ColumnsType } from "antd/es/table";

import testIcon from "../../assets/imgs/calendar-event.png";
import styles from "./index.module.scss";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
//import testVideo from './testVideo.mp4';
import { selectAll as selectAllFiles } from "../../redux/slices/file.slice";
import { selectAll as selectAllVideos } from "../../redux/slices/video.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { IMedia, TMedia } from "../../redux/slices/image.slice";
import { PDFRenderer } from "react-doc-viewer";

type LayoutType = Parameters<typeof Form>[0]["layout"];

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const sizeIcon = 24;

export function BottomFixBlock({ children }: { children: any }) {
  return <div className={styles.bottomFixBlock}>{children}</div>;
}

export function TopFixBlock({ children }: { children: any }) {
  return <div className={styles.topFixBlock}>{children}</div>;
}

export function ProductDoc({
  isOpportunityEditBlock,
  hiddenList,
}: {
  isOpportunityEditBlock?: boolean;
  hiddenList?: boolean;
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const [videoUrl, setVideoUrl] = useState<any>();
  const [keyMenu, setKeyMenu] = useState("1");
  const params = useParams();
  const product_id = params.idProduct || params.goodId;
  const dispatch = useDispatch();
  const files = useAppSelector(selectAllFiles);
  const videos = useAppSelector(selectAllVideos);
  const { loadingList, success, error, loading } = useAppSelector(
    (state) => state.file
  );
  const [api, contextHolder] = notification.useNotification();
  const { isAuthorized } = useAppSelector((state) => state.auth);
  const isOpportunityEdit = !isOpportunityEditBlock;
  const [errorPreview, setErrorPreview] = useState<string>("");
  const keys = [
    { key: "1", label: "Videos", icon: CiVideoOn, data: videos },
    { key: "2", label: "Files", icon: FaFileAlt, data: files },
  ];

  useEffect(() => {
    dispatch({ type: "GET_ALL_MEDIA", payload: { product_id } });
  }, []);

  useEffect(() => {
    if (success) {
      api.success({ message: success, duration: 3 });
      setFileList([]);
      setPreviewImage("");
    }
  }, [success]);

  useEffect(() => {
    if (success) api.error({ message: error, duration: 3 });
  }, [error]);

  const columns: ColumnsType<IMedia> = [
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
      render: (_, data) => (
        <div
          onClick={() => {
            console.log("hello");
            if (data.name.includes(".docx") || data.name.includes(".doc")) {
              setErrorPreview(
                "Sorry. There is no opportunity preview files with extensions .docx  and .doc. Please, download "
              );
              setPreviewImage(data.media_url);
              return;
            }
            setErrorPreview("");

            setPreviewImage(data.media_url);
          }}
        >
          {data.name}
        </div>
      ),
    },
  ];

  if (isOpportunityEdit) {
    columns.push({
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineClose size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, { id }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            dispatch({
              type: "DELETE_MEDIA",
              payload: {
                id: id,
                type: keyMenu === "1" ? "Video" : "File",
              },
            });
            console.log("delete", id);
          }}
        >
          <AiOutlineClose size={sizeIcon - 6} />
        </div>
      ),
    });
  }

  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };

  const handlePreview = async (file: any) => {
    if (file.name.includes(".docx") || file.name.includes(".doc")) {
      setErrorPreview(
        "Sorry. There is no opportunity preview files with extensions .docx  and .doc. Please, download "
      );
      setPreviewImage(window.URL.createObjectURL(file.originFileObj));
      return;
    }
    setErrorPreview("");
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
    if (file.originFileObj) {
      setPreviewImage(window.URL.createObjectURL(file.originFileObj));
    } else {
      console.log(file, "hello esle");
      setPreviewImage(await getBase64(file as RcFile));
    }
  };

  const handleChange: UploadProps["onChange"] = async ({
    file,
    fileList: newFileList,
  }) => {
    console.log(file, "status");
    setFileList(newFileList);
    file && (await handlePreview(newFileList[0]));
  };

  const deleteImage = () => {
    setFileList([]);
    setPreviewImage("");
  };

  const handleChangeSelect = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  const handleDrop = (event: React.DragEvent) => {
    console.log("handleDrop");
  };

  const saveDoc = () => {
    let formDataFile: any = new FormData();
    let formDataVideo: any = new FormData();
    let existFile = false;
    let existVideo = false;
    fileList.forEach((file) => {
      let type = file.type?.slice(0, 5);
      console.log(type, "type");
      if (type === "video") {
        formDataVideo.append("files", file.originFileObj);
        existVideo = true;
      } else {
        formDataFile.append("files", file.originFileObj);
        existFile = true;
      }
    });
    if (product_id) {
      formDataVideo.append("product_id", product_id);
      formDataFile.append("product_id", product_id);
    }
    //type: "video/mp4"
    //type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //type: 'type: "application/pdf";
    let payload: { formDataFile?: {}; formDataVideo?: {} } = {};
    if (existFile) {
      payload["formDataFile"] = formDataFile;
    }
    if (existVideo) {
      payload["formDataVideo"] = formDataVideo;
    }
    dispatch({ type: "ADD_MEDIA", payload });
  };

  const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
  /* const propsOfUploadComponent = {
  listType:"picture-card",
  fileList:fileList,
  onPreview:handlePreview,
  onChange:handleChange,
  onDrop:handleDrop,
  multiple:true,
    headers: {
      authorization: "authorization-text",
    },
    action: "",
    name: "file",
    accept: ".pdf,.doc,.docx",
    beforeUpload: (file: any) => {
      const acceptedFormats = ["pdf", "doc", "docx"];
      return acceptedFormats.includes(file.name.split(".")[1])
        ? true
        : Upload.LIST_IGNORE;
    },
  }; */

  const props: UploadProps = {
    name: "file",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        setFileList(info.fileList);
        info.file && handlePreview(info.file);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    fileList: fileList,
    //onPreview: handlePreview,
    onDrop: handleDrop,
  };

  const [src, setSrc] = useState<string | null>(null);

  return (
    <>
      {contextHolder}
      <div className={styles.product}>
        <div
          className={[
            styles.wrapper,
            hiddenList ? styles.wrapper_hiddenList : "",
          ].join(" ")}
        >
          <div
            className={[
              styles.leftPart,
              hiddenList ? styles.leftPart_hiddenList : "",
            ].join(" ")}
          >
            <div className={styles.header}>
              <div className={styles.header__text}>
                <div>Prevew</div>
                <div></div>
              </div>
            </div>
            {hiddenList &&
              (previewImage || errorPreview) &&
              isOpportunityEdit && (
                <div
                  className={styles.highlights}
                  style={{ marginTop: 0, marginBottom: 10 }}
                >
                  <Upload
                    fileList={fileList}
                    multiple={true}
                    //showUploadList={false}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    style={{ height: 200, width: 200 }}
                    maxCount={5}
                    accept={".pdf,.doc,.docx, .mp4"}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    <Button
                      style={{ marginBottom: 20 }}
                      icon={<UploadOutlined />}
                      loading={loading}
                    >
                      Upload new item
                    </Button>
                  </Upload>
                </div>
              )}
            <div className={styles.img_block}>
              {errorPreview && (
                <div className={styles.img}>
                  <div>
                    {errorPreview}
                    <a href={previewImage} download>
                      the file
                    </a>
                  </div>
                </div>
              )}
              {!errorPreview && (
                <>
                  {previewImage ? (
                    <div className={styles.img}>
                      <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={[
                          {
                            uri: previewImage,
                            fileName: previewTitle,
                            //fileType: "doc",
                          },
                        ]}
                        config={{
                          header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false,
                          },

                          pdfZoom: {
                            defaultZoom: 1, // 1 as default,
                            zoomJump: 0.1, // 0.1 as default,
                          },
                        }}
                        className={styles.viewer}
                      />
                    </div>
                  ) : (
                    <>
                      {isOpportunityEdit ? (
                        <div className={styles.img}>
                          <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            onDrop={handleDrop}
                            multiple={true}
                            accept={".pdf,.doc,.docx, .mp4"}
                            beforeUpload={() => {
                              return false;
                            }}
                          >
                            <div>
                              <BsCardImage size={70} color="#878D9C" />
                            </div>
                          </Upload>
                        </div>
                      ) : (
                        <Alert
                          message="Please, select video or manual for a preview"
                          type="warning"
                          showIcon
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {!hiddenList && (
            <div className={styles.rightPart}>
              {(previewImage || errorPreview) && isOpportunityEdit && (
                <div className={styles.highlights}>
                  <Upload
                    fileList={fileList}
                    multiple={true}
                    //showUploadList={false}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    style={{ height: 200, width: 200 }}
                    maxCount={5}
                    accept={".pdf,.doc,.docx, .mp4"}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    <Button
                      style={{ marginBottom: 20 }}
                      icon={<UploadOutlined />}
                      loading={loading}
                    >
                      Upload new item
                    </Button>
                  </Upload>
                  <div
                    className={styles.buttons}
                    style={{ marginTop: 20 }}
                    onClick={saveDoc}
                  >
                    <Button>Save </Button>
                  </div>
                </div>
              )}

              <Tabs
                defaultActiveKey="1"
                onChange={(activeKey: string) => {
                  setKeyMenu(activeKey);
                }}
                items={keys.map((item, i) => {
                  const id = String(i + 1);
                  const Icon = item.icon;
                  return {
                    key: id,
                    label: item.label,
                    children: (
                      <div className={styles.highlights}>
                        <Table
                          columns={columns}
                          dataSource={item.data}
                          //loading={true}
                          showHeader={false}
                          rowClassName={styles.row}
                          //className={styles.table}
                          pagination={false}
                          bordered
                        />
                      </div>
                    ),
                    icon: <Icon size={24} />,
                  };
                })}
              />
            </div>
          )}
        </div>
      </div>
      {/*    <BottomFixBlock>
        <div className={styles.buttons}>
          <Button>Save changes</Button>
        </div>
      </BottomFixBlock> */}
    </>
  );
}

export default ProductDoc;
