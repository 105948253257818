import React, { useState, useEffect } from "react";
import OneGood from "../../components/Goods/OneGood";
import { ProductType } from "../../redux/slices/product_main.slice";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { getUserId } from "../../utilities";
import { pageSize } from "../../variables";
import { Pagination } from "antd";
import {
  selectAll as selectAllProductWithStatus,
  setUpdateListLikeNoAuth,
} from "../../redux/slices/product_with_status.slice";
import styles from "./index.module.scss";
import { getItemObject } from "../../utilities/product";

function Likes() {
  const { isAuthorized } = useAppSelector((state) => state.auth);
  const [products, setProducts] = useState<ProductType[]>([]);
  const { numberOfLikes } = useAppSelector((state) => state.product);
  const idUser = getUserId();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const defaultQueryParams = `?sort=user_id&search=${idUser}&search=Likes&sort=status&take=${pageSize}&page=`;
  const likes = useAppSelector(selectAllProductWithStatus);
  const { count, successList, errorList, loadingList, updateListLikeNoAuth } =
    useAppSelector((state) => state.productWithStatus);

  useEffect(() => {
    if (!isAuthorized) {
      let listOfLike: string | null | ProductType =
        localStorage.getItem("listLikeProduct");
      listOfLike = listOfLike ? JSON.parse(listOfLike) : listOfLike;
      Array.isArray(listOfLike) && setProducts(listOfLike);
    } else {
      console.log("hello");
      getProducts(1);
    }
  }, []);

  useEffect(() => {
    if (updateListLikeNoAuth && !isAuthorized) {
      let listOfLike: string | null | ProductType =
        localStorage.getItem("listLikeProduct");
      listOfLike = listOfLike ? JSON.parse(listOfLike) : listOfLike;
      Array.isArray(listOfLike) && setProducts(listOfLike);
      dispatch(setUpdateListLikeNoAuth(false));
    }
  }, [updateListLikeNoAuth]);

  const getProducts = (page: number) => {
    dispatch({
      type: "GET_ALL_PRODUCT_WITH_STATUS",
      payload: {
        query_params: defaultQueryParams + page,
      },
    });
  };

  const onChangePage = (page: number) => {
    getProducts(page);
    setPage(page);
  };

  return (
    <>
      {!isAuthorized &&
        products.map((product) => (
          <div key={product.id}>
            <OneGood
              direction="horizontal"
              leaveFeedback={true}
              data={product}
            />
          </div>
        ))}
      {isAuthorized &&
        likes.map((item) => (
          <div key={item.id}>
            <OneGood
              idItemForUpdateLikeList={item.id}
              direction="horizontal"
              leaveFeedback={true}
              data={getItemObject(item)}
              /*   idItemLike={item.productItems?.likesItem || undefined}
              idItemCompare={item.productItems?.compareItem || undefined}
              idItemCart={item.productItems?.cartItem || undefined} */
            />
          </div>
        ))}
      {count > pageSize && (
        <Pagination
          current={page}
          defaultCurrent={1}
          total={count}
          pageSize={pageSize}
          className={styles.pagination}
          onChange={onChangePage}
        />
      )}
    </>
  );
}

export default Likes;
