import {
  Tooltip,
  Pagination,
  Select,
  Tag,
  Menu,
  Row,
  Col,
  Button,
  Table,
  Spin,
  notification,
} from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./index.module.scss";
import OneOrder from "../../components/OneOrder";
import { statusOrder, OrderI } from "../../redux/slices/orders.slice";
import { useDispatch } from "react-redux";
import { ReactNode, useEffect, useState } from "react";
import { pageSize } from "../../variables";
import { selectAll as selectAllOrders } from "../../redux/slices/orders.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import FilterSelect from "../../components/FilterSelect";
import { MdExpandMore } from "react-icons/md";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { MenuProps } from "antd";
import { BiSolidCategoryAlt, BiSolidCategory } from "react-icons/bi";
import { BsFilterCircleFill, BsShieldLock } from "react-icons/bs";
import { LuArchiveRestore } from "react-icons/lu";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineHighlight,
} from "react-icons/ai";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import OneGood from "../../components/Goods/OneGood";
import {
  ProductType,
  selectAll as selectAllProducts,
} from "../../redux/slices/product_main.slice";
import {
  CategoryI,
  selectAll as selectAllCategory,
} from "../../redux/slices/category.slice";
import { selectAll as selectAllSubCategory } from "../../redux/slices/subcategory.slice";
import {
  SpecValueI,
  selectAll as selectAllSpecValue,
} from "../../redux/slices/spec_value.slice";
import {
  SpecNameI,
  selectAll as selectAllSpecName,
} from "../../redux/slices/spec_name.slice";
import { OneSubCategoryI } from "../../redux/ts/structureEntities/categoties";

const status_options: { value: statusOrder; label: statusOrder }[] = [
  {
    value: "Created",
    label: "Created",
  },
  {
    value: "Delivery",
    label: "Delivery",
  },
  {
    value: "Completed",
    label: "Completed",
  },
];

const tagRender = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const status: statusOrder = value;
  let color = "#479323";
  if (value === "Delivery") color = "#bf6f00";
  if (value === "Created") color = "#0400e2";
  return (
    <>
      {label && (
        <Tag
          color={color}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          //style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      )}
    </>
  );
};

interface DataType {
  id?: string;
  name: string;
  deletedAt?: Date;
  //restore?: string;
}

const sizeIcon = 20;

const items: MenuProps["items"] = [
  {
    label: "Products",
    key: "product",
    icon: <AiOutlineShoppingCart size={16} />,
  },
  {
    label: "Category",
    key: "category_main",
    icon: <BiSolidCategoryAlt size={16} />,
    children: [
      {
        label: "categories",
        key: "category",
      },
      {
        label: "subcategories",
        key: "subcategory",
      },
    ],
  },
  {
    label: "Specs",
    key: "spec",
    icon: <BsFilterCircleFill size={16} />,
    children: [
      {
        label: "spec name",
        key: "spec_name",
      },
      {
        label: "spec value",
        key: "spec_value",
      },
    ],
  },
];

type menuKeyT =
  | "product"
  | "category"
  | "subcategory"
  | "spec_name"
  | "spec_value";

export function ArchiveManagment({
  hiddenFilter,
  blockChangeStatus,
  hiddenUser,
  userId,
  defStatus,
  getOneOrderC,
  title,
}: {
  hiddenFilter?: true;
  blockChangeStatus?: true;
  hiddenUser?: true;
  userId?: string | null;
  defStatus?: statusOrder[];
  getOneOrderC?: (data: OrderI) => JSX.Element;
  title?: string;
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [current, setCurrent] = useState<menuKeyT>(
    (params?.keyMenu as menuKeyT) || "product"
  );
  const [api, contextHolder] = notification.useNotification();

  const products = useAppSelector(selectAllProducts);
  const categories = useAppSelector(selectAllCategory);
  const subcategories = useAppSelector(selectAllSubCategory);
  const spec_name = useAppSelector(selectAllSpecName);
  const spec_values = useAppSelector(selectAllSpecValue);

  /*   const { countProduct, countSubCategories, countSpecValue, countSpecName } =
    useAppSelector((state) => {
      const {product, subcategory, spec_value, spec_name } = state;
      return {
        countProduct: product.count,
        countSubCategories: subcategory.count,
        countSpecValue: spec_value.count,
        countSpecName: spec_name.count,
        loadingListProduct: product.loadingList,
        loadingProduct: product.loading,
        success: product.success
      };
    }); */

  //product, subcategory, category, spec_value, spec_name

  const getItemsElements = (
    key: menuKeyT
  ): {
    data:
      | ProductType[]
      | SpecNameI[]
      | SpecValueI[]
      | OneSubCategoryI[]
      | CategoryI[];
    type_getAll: string;
    type_restore: string;
  } => {
    switch (key) {
      case "product":
        return {
          data: products,
          type_getAll: "GET_ALL_PRODUCT_ARCHIVE",
          type_restore: "RESTORE_PRODUCT",
        };
      case "spec_name":
        return {
          data: spec_name,
          type_getAll: "GET_ALL_SPEC_NAME_ARCHIVE",
          type_restore: "RESTORE_SPEC_NAME",
        };
      case "spec_value":
        return {
          data: spec_values,
          type_getAll: "GET_ALL_SPEC_VALUE_ARCHIVE",
          type_restore: "RESTORE_SPEC_VALUE",
        };
      case "subcategory":
        return {
          data: subcategories,
          type_getAll: "GET_ALL_SUBCATEGORY_ARCHIVE",
          type_restore: "RESTORE_SUBCATEGORY",
        };
      default:
        return {
          data: categories,
          type_getAll: "GET_ALL_AREA_ARCHIVE",
          type_restore: "RESTORE_AREA",
        };
    }
  };

  const {
    loading,
    loadingList,
    error,
    success,
    count,
    data,
    type_getAll,
    type_restore,
  } = useAppSelector((state) => {
    const { data, type_getAll, type_restore } = getItemsElements(current);
    const { loading, loadingList, error, success, count } = state[current];
    return {
      data,
      type_getAll,
      type_restore,
      loading,
      loadingList,
      error,
      success,
      count,
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date(archiving)",
      dataIndex: "deletedAt",
      render: (_, { deletedAt }) => moment(deletedAt).format("LL"),
    },
    {
      title: <LuArchiveRestore style={{ marginRight: 5 }} size={18} />,
      dataIndex: "restore",
      render: (_, { name, id }) => (
        <div
          onClick={() => !loading && restoreItem(id)}
          style={{ cursor: "pointer" }}
        >
          <LuArchiveRestore size={sizeIcon} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (current) {
      dispatch({ type: type_getAll, payload: { page } });
    }
  }, [current]);

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key as menuKeyT);
    navigate("/admin/archive/" + e.key);
  };

  const restoreItem = (id: string) => {
    dispatch({ type: type_restore, payload: id });
  };

  /*
  const users = useAppSelector(selectAllUsers);
  const { count: count_user } = useAppSelector((state) => state.users);
  const [status, setStatus] = useState<statusOrder[] | null>(defStatus || null);
  const [filterUserId, setFilterUserId] = useState<string | null>(
    userId || null
  );
  const location = useLocation();
  const path = location.pathname.slice(9);

  const getMoreOrders = (page: number = 1) => {
    let params = "?";

    console.log(status, "status");
    if (status) {
      status.forEach((st) => {
        if (st) params += `sort=or: status&search=${st}&`;
      });
    }
    if (filterUserId) params += `sort=user_id&search=${filterUserId}&`;

    params += `page=${page}&take=${pageSize}&`;
    dispatch({ type: "GET_ALL_ORDER", payload: { params } });
    setPage(page);
  };


  useEffect(() => {
    //dispatch({ type: "GET_ALL_ORDER", payload: { page } });
    getMoreOrders();
    dispatch({ type: "GET_ALL_USERS" });
  }, []);


  
 */

  const onChangePage = (page: any) => {
    setPage(page);
    dispatch({ type: type_getAll, payload: { page } });
  };
  useEffect(() => {
    if (success) {
      api.success({
        message: success,
        duration: 3,
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: error,
        duration: 3,
      });
    }
  }, [error]);

  return (
    <div className={styles.product}>
      {contextHolder}
      <div className={styles.title}>Archive managment</div>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        className={styles.menu}
      />
      <div className={styles.feedback}>
        {current === "product" && (
          <Row>
            {data.map((product) => (
              <Col className="gutter-row" xs={24} md={12} xl={8}>
                <OneGood
                  direction="vertical"
                  hiddenLike={true}
                  hiddenCheck={true}
                  data={product as ProductType}
                  footer={() => (
                    <div className={styles.footer}>
                      <Button
                        className={styles.footer_button}
                        onClick={() => restoreItem(product.id)}
                      >
                        <LuArchiveRestore
                          style={{ marginRight: 5 }}
                          size={18}
                        />
                        Restore
                      </Button>
                    </div>
                  )}
                />
              </Col>
            ))}
          </Row>
        )}
        {current !== "product" && (
          <Table columns={columns} dataSource={data} loading={loadingList} />
        )}
      </div>
      {count > 1 && (
        <Pagination
          current={page}
          defaultCurrent={1}
          total={count}
          pageSize={pageSize}
          className={styles.pagination}
          onChange={onChangePage}
        />
      )}
    </div>
  );
}

export default ArchiveManagment;
