import { takeLeading, call, put } from "redux-saga/effects";
import { categoryService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  setAllCategory,
  addOneCategory,
  updateOneCategory,
  removeOneCategory,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  setCount,
} from "../slices/category.slice";
import { PickSubCategoryI } from "../slices/subcategory.slice";
import { setPickCategory } from "../slices/product_main.slice";
//
function* getAll(action: PayloadAction<{}>) {
  yield put(editLoadingList(true));
  try {
    const response: {} = yield call(categoryService.getAll);
    yield put(setAllCategory(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* editOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const body = action.payload;
    const id = action.payload.id;
    yield call(categoryService.update, id, body);
    yield put(updateOneCategory(body));
    yield put(editSuccess(`The category  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(action: PayloadAction<{ name: string }>) {
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    const body = action.payload;
    const response: {} = yield call(categoryService.create, body);
    yield put(addOneCategory(response));
    yield put(
      editSuccess(`The category "${action.payload.name}" has been added`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    yield call(categoryService.delete, action.payload.id);
    yield put(removeOneCategory(action.payload.id));
    yield put(
      editSuccess(`The category "${action.payload.name}" has been deleted`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getOne(action: PayloadAction<{ id: string } | { name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    let id = null;
    let name = null;
    let method: "getOne" | "getByName" = "getOne";
    let payload = "";
    if ("name" in action.payload) {
      name = action.payload.name;
      method = "getByName";
      payload = name;
    } else {
      id = action.payload.id;
      payload = id;
    }

    const response: { id: string; name: string } = yield call(
      categoryService[method],
      payload
    );

    yield put(setPickCategory(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAllArchive(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  yield put(editLoadingList(true));
  yield put(editErrorList(null));
  try {
    const response: { rows?: []; count: number } = yield call(
      categoryService.getAllArchive,
      { page: action.payload.page }
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllCategory(response.rows ? response.rows : response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* restoreFormArchive(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(categoryService.restoreOne, action.payload);
    console.log("response", response);
    yield put(removeOneCategory(action.payload));
    yield put(editSuccess("The category has been restored"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchAreaSaga() {
  yield takeLeading("GET_ALL_AREA", getAll);
  yield takeLeading("GET_ONE_CATEGORY", getOne);
  yield takeLeading("ADD_AREA", addOne);
  yield takeLeading("EDIT_AREA", editOne);
  yield takeLeading("DELETE_AREA", deleteOne);

  yield takeLeading("GET_ALL_AREA_ARCHIVE", getAllArchive);
  yield takeLeading("RESTORE_AREA", restoreFormArchive);
}
