import { takeLeading, call, put } from "redux-saga/effects";
import { authService, itemsService, usersService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  editAuth,
  editLoading,
  editError,
  editSuccess,
  editIsCodeSent,
  editLoadingApp,
  editIsAdmin,
  clearAuthSlice,
  setIsOpenConfirmResetLikeCompareInAccount,
} from "../slices/auth.slice";
import { logOut as logOutActions } from "../actions/auth.actions";
//import EncryptedStorage from 'react-native-encrypted-storage';
import jwt_decode from "jwt-decode";
import { PrimaryUserDataI, ExtendUserDataI } from "../ts/interface";
import { TToken, TResponseCheckTikenAuth } from "../ts/type";
import { AUTH_SAGA_TYPE } from "../ts/enum";
import { setUser, UserDataI } from "../slices/account.slice";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  ProductType,
  setNumberOfCart,
  setNumberOfCompared,
  setNumberOfLikes,
} from "../slices/product_main.slice";
import {
  getProductIdsWithStatusFromLocalStorage,
  getUserId,
} from "../../utilities";
import { ProductWithStatusI } from "../slices/product_with_status.slice";
import { statusItemsT } from "../slices/product_main.slice";
export interface DataSignUpResponse {
  user: {
    id: string;
    address: null | string;
    status: "Active" | "Disabled";
    surname: string;
    name: string;
    email: string;
    role: "Admin" | "Customer";
    phone: string;
    numberOfReturns: number;
    numberOfPurchases: number;
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
}

export const getToken = (type: TToken): string | null => {
  let token = null;
  try {
    const data = localStorage.getItem(type);
    if (data) token = data;
  } catch (error) {
    console.log(error);
  } finally {
    return token;
  }
};
export const setToken = (type: TToken, value: string): void => {
  try {
    localStorage.setItem(type, value);
  } catch (error) {
    console.log(error);
  }
};

export const resetToken = (type: TToken | "all"): void => {
  try {
    if (type !== "all") localStorage.removeItem(type);
    if (type === "all") {
      resetToken("accessToken");
      resetToken("refreshToken");
      resetToken("accessVerifyToken");
      resetToken("refreshVerifyToken");
    }
  } catch (error) {
    console.log(error);
  }
};

export function* checkTokenAuth(error: unknown) {
  /*   if (
    request.isAxiosError(error) &&
    error?.response &&
    error.response.status < 500
  ) {
    let message = error.response.data.message;
    if (
      message === 'uthe' ||
      message === 'uu' ||
      message === 'uunf' ||
      message === 'Token is expired' ||
      message === 'Token has expired'
    ) {
      try {
        const response: AxiosResponse<{
          accessToken: string;
          refreshToken: string;
        } > = yield call(authService.getTokens);
        yield call(setToken, 'accessToken', response.data.accessToken);
        yield call(setToken, 'refreshToken', response.data.refreshToken);
        return 'auth';
      } catch (error) {
        yield put(editAuth(false));
        return 'notAuth';
      }
    } else {
      return {message};
    }
  } */
}

const getCountCompareLikeCart = (data: ProductWithStatusI[]) => {
  const counts: { [status: string]: number } = {
    Likes: 0,
    Compare: 0,
    Cart: 0,
  };
  data.map((item) => (counts[item.status] += 1));

  return counts;
};

const getLikeCompareId = () => {
  const dataCompareJson = localStorage.getItem("listComparedGoods");
  const dataLikeJson = localStorage.getItem("listLikeProduct");
  const dataCompare: ProductType[] | [] = dataCompareJson
    ? JSON.parse(dataCompareJson)
    : [];
  const dataLike: ProductType[] | [] = dataLikeJson
    ? JSON.parse(dataLikeJson)
    : [];

  return {
    idsLike: dataLike.map((dl) => dl.id),
    idsCompare: dataCompare.map((dc) => dc.id),
  };
};

export const clearLikeCompareInLocalStorage = () => {
  localStorage.removeItem("listLikeCompareProductByCategory");
  localStorage.removeItem("listLikeProduct");
  localStorage.removeItem("listComparedGoods");
};

function* transferLikeCopmareInAcc(
  action: PayloadAction<{ Likes?: number; Compare?: number; userId: string }>
) {
  yield put(editLoading(true));
  yield put(editError(null));
  try {
    const { Likes, Compare, userId } = action.payload;
    const { idsCompare, idsLike } = getLikeCompareId();
    if (idsCompare.length > 0 || idsLike.length > 0) {
      if (Likes || Compare) {
        yield put(setIsOpenConfirmResetLikeCompareInAccount(true));
      } else {
        if (idsCompare.length > 0) {
          yield call(
            itemsService.likedOrCompareProducts,
            {
              status: "Compare",
              products: idsCompare,
            },
            userId
          );

          yield put(setNumberOfCompared(idsCompare.length));
        }

        if (idsLike.length > 0) {
          yield call(
            itemsService.likedOrCompareProducts,
            {
              status: "Likes",
              products: idsLike,
            },
            userId
          );
          yield put(setNumberOfLikes(idsLike.length));
        }

        clearLikeCompareInLocalStorage();
        yield put(setIsOpenConfirmResetLikeCompareInAccount(false));
      }
    }
    yield put(editSuccess("Congratulations, you have successfully login!"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* signIn(action: PayloadAction<PrimaryUserDataI>) {
  yield put(editLoading(true));

  yield put(editError(null));

  try {
    const response: DataSignUpResponse = yield call(
      authService.signIn,
      action.payload
    );

    if (response.user.status === "Disabled")
      throw Error("Your account is blocked. Please connect with administrator");
    yield call(setToken, "accessToken", response.tokens.accessToken);
    yield call(setToken, "refreshToken", response.tokens.refreshToken);
    if (response.user.role === "Admin") yield put(editIsAdmin(true));
    yield put(editError(null));
    yield put(editAuth(true));
    yield put(editSuccess("Congratulations, you have successfully login!"));

    const responseItems: { rows: ProductWithStatusI[] } = yield call(
      itemsService.getByFilter,
      `?user_id=${response.user.id}&sort=or: status&search=Compare&sort=or: status&search=Likes&sort=or: status&search=Cart`
    );
    const { Likes, Compare, Cart } = getCountCompareLikeCart(
      responseItems.rows
    );
    yield put(setNumberOfCart(Cart));
    yield put(setNumberOfCompared(Compare));
    yield put(setNumberOfLikes(Likes));

    yield call(transferLikeCopmareInAcc, {
      type: "TRANSFER_LIKE_COMPARE",
      payload: { Likes, Compare, userId: response.user.id },
    });
    /*  const { idsCompare, idsLike } = getLikeCompareId();
    if (idsCompare.length > 0 || idsLike.length > 0) {
      if (Likes || Compare) {
        yield put(setIsOpenConfirmResetLikeCompareInAccount(true));
      } else {
        if (idsCompare.length > 0) {
          const responseC: {} = yield call(
            itemsService.likedOrCompareProducts,
            {
              status: "Compare",
              products: idsCompare,
            },
            response.user.id
          );
          console.log("response", responseC);
          yield put(setNumberOfCompared(idsCompare.length));
        }

        if (idsLike.length > 0) {
          const responseL: {} = yield call(
            itemsService.likedOrCompareProducts,
            {
              status: "Likes",
              products: idsLike,
            },
            response.user.id
          );
          console.log("response", responseL);

          yield put(setNumberOfLikes(idsLike.length));
        }

        clearLikeCompareInLocalStorage();
      }
    }
 */
    /*  const { likesIds, comparesIds } = getProductIdsWithStatusFromLocalStorage();
    if (likesIds) {
      yield call(itemsService.setLikesProduct, likesIds, getUserId() || "");
    } */
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
      return;
    }
    if (error instanceof Error) {
      yield put(editError(error.message));
      return;
    }
    yield put(editError("Something has gone wrong. Please try again later"));
  } finally {
    yield put(editLoading(false));
  }
}

function* signUp(action: PayloadAction<ExtendUserDataI>) {
  yield put(editLoading(true));
  yield put(editError(null));
  try {
    const response: DataSignUpResponse = yield call(
      authService.signUp,
      action.payload
    );
    yield call(setToken, "accessToken", response.tokens.accessToken);
    yield call(setToken, "refreshToken", response.tokens.refreshToken);
    /*   const { likesIds, comparesIds } = getProductIdsWithStatusFromLocalStorage();
    if (likesIds) {
      yield call(itemsService.setLikesProduct, likesIds, getUserId() || "");
    } */
    yield put(editAuth(true));
    yield put(editError(null));
    yield put(
      editSuccess("Congratulations, you have successfully registered!")
    );
  } catch (error: any) {
    if (typeof error.message === "string") {
      yield put(editError(error.message));

    } else {
      yield put(editError("Something has gone wrong. Please try again later"));

    }
  } finally {
    yield put(editLoading(false));
  }
}

function* logOut() {
  yield put(editLoading(true));
  try {
    yield call(authService.logOut);
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    yield put(editAuth(false));
    yield put(editIsAdmin(false));
    yield put(clearAuthSlice());
    yield put(setNumberOfCart(0));
    yield put(setNumberOfLikes(0));
    yield put(setNumberOfCompared(0));
  }
}

function* authUser() {
  yield put(editLoadingApp(true));
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const data = jwtDecode<{ exp: number; id: string; role: string }>(
        accessToken
      );
      const response: UserDataI = yield call(usersService.getOne, data.id);
      if (response.role === "Admin") yield put(editIsAdmin(true));
      if (response.status !== "Disabled") yield put(editAuth(true));
    }
  } catch (error: unknown) {
    //added check auth token
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoadingApp(false));
  }
}

//____________________________
function* verifyPhone(
  action: PayloadAction<{ phone: string; verificationCode: number }>
) {
  yield put(editLoading(true));
  try {
    yield call(authService.verifyCode, action.payload);
    yield put(editSuccess("Code is correct"));
  } catch (error: unknown) {
    /*     if (request.isAxiosError(error) && error?.response) {
      yield put(editError(error.response.data.message));
    } else {
      yield put(editError('Something has gone wrong. Please try again later'));
    } */
  } finally {
    yield put(editLoading(false));
  }
}

function* sendCode(action: PayloadAction<{ phone: string }>) {
  yield put(editLoading(true));
  try {
    yield call(authService.sendVerificationCode, action.payload);
    //yield put(editSuccess('Code has been sent to your email'));
    yield put(editIsCodeSent(true));
  } catch (error: unknown) {
    /*    console.log(error, 'error');
    if (request.isAxiosError(error) && error?.response) {
      yield put(editError(error.response.data.message));
    } else {
      yield put(editError('Something has gone wrong. Please try again later'));
    } */
  } finally {
    yield put(editLoading(false));
  }
}

function* createNewPassword(
  action: PayloadAction<{
    phone: string;
    verificationCode: number;
    password: string;
  }>
) {
  yield put(editLoading(true));
  try {
    const response: {} = yield call(
      authService.createNewPassword,
      action.payload
    );
    console.log(response, "response");
    yield put(editSuccess("Password has been update"));
  } catch (error: unknown) {
    /*    console.log("error");
    if (request.isAxiosError(error) && error?.response) {
      yield put(editError(error.response.data.message));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    } */
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchAuthSaga() {
  yield takeLeading("LOG_IN", signIn);
  yield takeLeading("SIGN_UP", signUp);
  yield takeLeading("LOG_OUT", logOut);
  yield takeLeading("AUTH_USER", authUser);
  yield takeLeading("TRANSFER_LIKE_COMPARE", transferLikeCopmareInAcc);

  //________________

  yield takeLeading("VERIFY_PHONE", verifyPhone);
  yield takeLeading("CREATE_NEW_PASSWORD", createNewPassword);
  yield takeLeading("SEND_CODE", sendCode);
}
