import {ProductWithStatusI} from "../redux/slices/product_with_status.slice";

export const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => {
  return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
};

export const doesSubcategoryExist=(categories: any,category:any)=> {
  for (let i = 0; i < categories.length; i++) {
    if(categories[i].name===category.name) {
      return categories[i].sub_categories.length > 0;
    }
  }

}
export const getSum = (data: ProductWithStatusI[]) => {
  const sum = data.reduce((accumulator: number, item: ProductWithStatusI) => {
    const price = item?.product?.price || 0;
    const newValue = item.count * price;
    return accumulator + newValue;
  }, 0);
  return sum;
};

export const formatAddress = (addressData:any) => {
  if (!addressData || !addressData.value || !addressData.value.address) {
    return '';
  }

  const { name } = addressData.value;
  const { line1, line2, city, state, postal_code, country } = addressData.value.address;

  const addressParts = [
    name,
    line1,
    line2,
    city,
    state,
    postal_code,
    country
  ].filter(part => part && part.trim() !== '');

  return addressParts.join(', ');
};