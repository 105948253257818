import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";

export interface IXLSXProductType {
  id: string;
  amount: number;
  description: string;
  name: string;
  price: number;
  sku: string;
  comment: number;
  specNames: [];
}

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | IXLSXProductType;
  searchProduct: IXLSXProductType[];
  searchCount: number;
  loadingSearch: boolean;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  searchCount: 0,
  pickItem: null,
  searchProduct: [],
  loadingSearch: false,
};

export const xlsxProductAdapter = createEntityAdapter<IXLSXProductType>({
  selectId: (xlsx_pr) => xlsx_pr.id,
});

export const initialState = xlsxProductAdapter.getInitialState(init);

const xlsxProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setAllProduct: (state, action) => {
      xlsxProductAdapter.setAll(state, action.payload);
    },
    addOneProduct: (state, action) => {
      xlsxProductAdapter.addOne(state, action.payload);
    },
    updateOneProduct: (state, action) => {
      xlsxProductAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneProduct: (state, action) => {
      xlsxProductAdapter.removeOne(state, action.payload);
    },
    setAllSearchProduct: (state, action) => {
      state.searchProduct = action.payload;
    },
    setSearchCount: (state, action) => {
      state.searchCount = action.payload;
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editLoadingSearch: (state, action) => {
      state.loadingSearch = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    setPickItem: (state, action: PayloadAction<null | IXLSXProductType>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = xlsxProductSlice;

export const {
  setCount,
  clearResponseStatusofForm,
  setAllProduct,
  addOneProduct,
  updateOneProduct,
  removeOneProduct,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setPickItem,
  setAllSearchProduct,
  setSearchCount,
  editLoadingSearch,
} = actions;

export default reducer;
export const { selectAll } = xlsxProductAdapter.getSelectors<RootState>(
  (state) => state.xlsx_product
);
