import { fork, all } from "redux-saga/effects";
import watchAuthSaga from "./auth.saga";
import watchAreaSaga from "./category.saga";
import watchHighlightsSaga from "./highlights.saga";
import watchUsersSaga from "./users.saga";
import watchSubcategorySaga from "./subcategory.saga";
import watchProductSaga from "./product_main.saga";
import watchCommentSaga from "./comment.saga";
import watchProductWithStatusSaga from "./product_with_status.saga";
import watchSpecValueSaga from "./specValue.saga";
import watchSpecNameSaga from "./specName.saga.";
import watchProductSpecSaga from "./product_spec.saga";
import watchOrderSaga from "./order.saga";
import watchMediaSaga from "./media.saga";
import watchImportXLSXSaga from "./importXLSX.saga";
import watchXLSXProductSaga from "./xlsx_product.saga";
import watchUploadXlsxToProductSaga from "./upload_xlsx_to_product.saga";
import watchCountProductWithStatusSaga from "./count_product_with_status.saga";
import watchCreditCardSaga from "./creditCards.saga";

export default function* rootSaga() {
  yield all([
    fork(watchAuthSaga),
    fork(watchAreaSaga),
    fork(watchHighlightsSaga),
    fork(watchUsersSaga),
    fork(watchSubcategorySaga),
    fork(watchProductSaga),
    fork(watchCommentSaga),
    fork(watchProductWithStatusSaga),
    fork(watchSpecValueSaga),
    fork(watchSpecNameSaga),
    fork(watchProductSpecSaga),
    fork(watchOrderSaga),
    fork(watchMediaSaga),
    fork(watchImportXLSXSaga),
    fork(watchXLSXProductSaga),
    fork(watchUploadXlsxToProductSaga),
    fork(watchCountProductWithStatusSaga),
    fork(watchCreditCardSaga),
  ]);
}
