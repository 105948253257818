import { takeLeading, call, put } from "redux-saga/effects";
import {
  commentsService,
  itemsService,
  productService,
  specProductService,
} from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  ProductType,
  setNumberOfCart,
  setNumberOfCompared, setNumberOfLikes,
  statusItemsT
} from "../slices/product_main.slice";
import {editLoadingList, setCount} from "../slices/file.slice";
import {
  deleteCompareItem,
  editError,
  editErrorList,
  editLoading,
  editSuccess,
  ProductWithStatusI, removeOneCountProductWithStatus,
  setAllCountProductWithStatus, updateCount, updateOneCountProductWithStatus
} from "../slices/count_product_with_status.slice";



interface ItemsCreateI {
  user_id?: string;
  product_id: string;
  status: statusItemsT;
  product: ProductType;
  idItemForUpdateLikeList?: string;
}

interface OneItemI extends ItemsCreateI {
  id: string;
}

interface ProductTypeWithItemId extends ProductType {
  idItem: string;
}

export interface PrSpI {
  id: string;
  product_id: string;
  specName_id: string;
  specName: {
    id: string;
    name: string;
  };
  specValues: {
    id: string;
    name: string;
  }[];
}

function* getAll(action: PayloadAction<{ query_params?: string }>) {
  yield put(editLoadingList(true));

  try {
    const response: { rows: ProductWithStatusI[]; count: number } = yield call(
        itemsService.getAll,
        action.payload.query_params
    );
    yield put(setAllCountProductWithStatus(response.rows));
    yield put(setCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
          editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* editOne(action: PayloadAction<{ id: string; count: number }>) {
  yield put(editLoading(true));
  try {
    const body = { count: action.payload.count };
    const id = action.payload.id;
    const response: {} = yield call(itemsService.update, id, body, "PATCH");
    yield put(updateCount(action.payload));
    yield put(editSuccess("Count of items has been updated"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));

    } else {
      yield put(editError("Something has gone wrong. Please try again later"));

    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getCount(action: PayloadAction<{ query_params?: string }>) {
  yield put(editLoadingList(true));

  try {
    const response: { rows: ProductWithStatusI[]; count: number } = yield call(
      itemsService.getAll,
      action.payload.query_params
    );

    let counts = {
      likes: 0,
      compare: 0,
      cart: 0,
    };
    response.rows.map((it) => {
      if (it.status === "Likes") counts.likes += 1;
      if (it.status === "Compare") counts.compare += 1;
      if (it.status === "Cart") counts.cart += 1;
    });

    yield put(setNumberOfCart(counts.cart));
    yield put(setNumberOfCompared(counts.compare));
    yield put(setNumberOfLikes(counts.likes));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}
function* deleteOne(
    action: PayloadAction<{ id: string; status: string; deleteFromComare?: true }>
) {
  yield put(editLoading(true));
  try {
    const { id, status } = action.payload;
    const response: {} = yield call(itemsService.delete, id);
    yield put(removeOneCountProductWithStatus(action.payload.id));
    console.log(action.payload, "fkkd");
    if (action.payload.deleteFromComare) {
      yield put(deleteCompareItem(id));
    }
    yield put(editSuccess("The item has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchCountProductWithStatusSaga() {
  yield takeLeading("GET_ALL_PRODUCT_WITH_STATUS_FOR_PRICE", getAll);
  yield takeLeading("GET_COUNT_ALL_PRODUCT_WITH_STATUS_FOR_PRICE", getCount);
  yield takeLeading("EDIT_COUNT_PRODUCT_WITH_STATUS", editOne);
  yield takeLeading("DELETE_PRODUCT_WITH_COUNT_STATUS", deleteOne);
}
