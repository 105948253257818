import {Tooltip, Pagination, Select, Tag} from "antd";
import {AiOutlineDelete} from "react-icons/ai";
import styles from "./index.module.scss";
import OneOrder from "../../components/OneOrder";
import {statusOrder, OrderI} from "../../redux/slices/orders.slice";
import {useDispatch} from "react-redux";
import {ReactNode, useEffect, useState} from "react";
import {pageSize} from "../../variables";
import {selectAll as selectAllOrders} from "../../redux/slices/orders.slice";
import {useAppSelector} from "../../redux/ts/hooks/hooks";
import FilterSelect from "../../components/FilterSelect";
import {MdExpandMore} from "react-icons/md";
import {selectAll as selectAllUsers} from "../../redux/slices/users.slice";
import type {CustomTagProps} from "rc-select/lib/BaseSelect";
import {useLocation} from "react-router-dom";
import Orders from "../../components/Orders";

const status_options: { value: statusOrder; label: statusOrder }[] = [
    {
        value: "Created",
        label: "Created",
    },
    {
        value: "Delivery",
        label: "Delivery",
    },
    {
        value: "Completed",
        label: "Completed",
    },
];

const tagRender = (props: CustomTagProps) => {
    const {label, value, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const status: statusOrder = value;
    let color = "#479323";
    if (value === "Delivery") color = "#bf6f00";
    if (value === "Created") color = "#0400e2";
    return (
        <>
            {label && (
                <Tag
                    color={color}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    //style={{ marginRight: 3 }}
                >
                    {label}
                </Tag>
            )}
        </>
    );
};

export function OrderManagment({
                                   hiddenFilter,
                                   blockChangeStatus,
                                   hiddenUser,
                                   userId,
                                   defStatus,
                                   getOneOrderC,
                                   title,
                               }: {
    hiddenFilter?: true;
    blockChangeStatus?: true;
    hiddenUser?: true;
    userId?: string | null;
    defStatus?: statusOrder[];
    getOneOrderC?: (data: OrderI) => JSX.Element;
    title?: string;
}) {
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(1);
    const orders = useAppSelector(selectAllOrders);
    const {count} = useAppSelector((state) => state.orders);
    const users = useAppSelector(selectAllUsers);
    const {count: count_user} = useAppSelector((state) => state.users);
    const [status, setStatus] = useState<statusOrder[] | null>(defStatus || null);
    const [filterUserId, setFilterUserId] = useState<string | null>(
        userId || null
    );
    const location = useLocation();
    const path = location.pathname.slice(9);

    const getMoreOrders = (page: number = 1) => {
        let params = "?";

        console.log(status, "status");
        if (status) {
            status.forEach((st) => {
                if (st) params += `sort=or: status&search=${st}&`;
            });
        }
        if (filterUserId) params += `sort=user_id&search=${filterUserId}&`;

        params += `page=${page}&take=${pageSize}&`;
        dispatch({type: "GET_ALL_ORDER", payload: {params}});
        setPage(page);
    };

    const onChangeSelect = (value: statusOrder[]) => {
        console.log(value);
        setStatus(value);
    };

    const setValueUserId = (id: string) => {
        setFilterUserId(id);
    };

    useEffect(() => {
        //dispatch({ type: "GET_ALL_ORDER", payload: { page } });
        getMoreOrders();
        dispatch({type: "GET_ALL_USERS"});
    }, []);

    useEffect(() => {
        if (defStatus) setStatus(defStatus);
    }, [defStatus]);

    useEffect(() => {
        getMoreOrders();
    }, [filterUserId, status]);

    const onChangePage = (page: any) => {
        getMoreOrders(page);
    };

    return (
        <div className={styles.product}>
            <div className={styles.title}>{title || "Order management"}</div>
            {!hiddenFilter && (
                <div className={styles.filter}>
                    <div className={styles.filterTitle}>Filter:</div>
                    <FilterSelect
                        data={users}
                        count={count_user}
                        dispatchType="GET_ALL_USERS"
                        suffixIcon="user"
                        setValue={setValueUserId}
                    />
                    <Select
                        tagRender={tagRender}
                        mode="multiple"
                        className={styles.select}
                        value={status}
                        onChange={onChangeSelect}
                        style={{width: "100%"}}
                        options={status_options}
                        allowClear
                        suffixIcon={
                            <div className={styles.suffix} style={{marginRight: 10}}>
                                <div>status</div>
                                <MdExpandMore size={24}/>
                            </div>
                        }
                    />
                </div>
            )}
            {/*<Orders/>*/}
            <div className={styles.feedback}>
                {orders.map((one_order) =>
                    getOneOrderC ? (
                        getOneOrderC(one_order)
                    ) : (
                        <div key={one_order.id}>
                            <OneOrder data={one_order} moreDetails={true}/>
                        </div>
                    )
                )}
            </div>
            {count > 1 && (
                <Pagination
                    current={page}
                    defaultCurrent={1}
                    total={count}
                    pageSize={pageSize}
                    className={styles.pagination}
                    onChange={onChangePage}
                />
            )}
        </div>
    );
}

export default OrderManagment;
