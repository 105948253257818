import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";
import { UserDataI } from "./account.slice";
import { InitStateRequestStatusI } from "../ts/interface";
import { TMedia, IMedia } from "./image.slice";
import * as XLSX from "xlsx";
import { TFolders } from "../sagas/importXLSX.saga";

export interface IImportXLSX {
  key: string;
  name: string;
  defValue?: string;
  oldDefValue?: string;
}

type TOpt = { value: string; label: string }[];
export const importXLSXAdapter = createEntityAdapter<IImportXLSX>({
  selectId: (video) => video.key,
});

type TValueOptSing =
  | "avatar_url"
  | "sku"
  | "name"
  | "price"
  | "description"
  | "comment";
type TValueOptMult = "specname";

function isValueOptMult(value: TValueOptSing | TValueOptMult): boolean {
  return Boolean(value === "specname");
}

enum EValueOptions {
  "AVATAR" = "AVATAR",
  "MANUAL" = "MANUAL",
  "STATUS" = "STATUS",
  "NAME" = "NAME",
  "AMOUNT" = "AMOUNT",
  "PRICE" = "PRICE",
  "HIGHLIGHTS" = "HIGHLIGHTS",
  "DESCRIPTION" = "DESCRIPTION",
  "COMMENT_OF_CATEGORY" = "COMMENT_OF_CATEGORY",
  "SPECNAME" = "SPECNAME",
}

type TDataReqCreatingXLSXProduct = Array<{
  nameFields: string;
  value: EValueOptions;
}>;

const options = [
  { value: "avatar_url", label: "avatar" }, //Image URL
  { value: "name", label: "name" },
  { value: "sku", label: "sku" },

  { value: "price", label: "price" }, //List Price
  { value: "description", label: "description" }, // Description
  {
    value: "comment", //Product Category
    label: "comment for category/subcategory",
  },
  { value: "specname", label: "specname" },
];

export interface IFileXLSX {
  id: string;
  name: string;
  url: string;
  folderName: TFolders;
  createdAt: Date;
  updatedAt: Date;
}

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number; //???
  pickItem: null | IImportXLSX; //??
  options: TOpt;
  xlsxList: { [nameFolder in TFolders]: IFileXLSX[] } | {};
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
  options,
  xlsxList: {},
};

export const initialState = importXLSXAdapter.getInitialState(init);

const importXLSSlice = createSlice({
  name: "importXLSX",
  initialState,
  reducers: {
    addFiles: (
      state,
      action: PayloadAction<{ files: IFileXLSX[]; folder: TFolders }>
    ) => {
      const { files, folder } = action.payload;
      state.xlsxList = { ...state.xlsxList, [folder]: files };
    },
    setAllImportXLS: (state, action: PayloadAction<ArrayBuffer>) => {
      const data = new Uint8Array(action.payload);
      //const data = action.payload; //File
      const workbook = XLSX.read(data, { type: "buffer", sheetRows: 2 });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const excelData: any = XLSX.utils.sheet_to_json(worksheet);

      let dataXLXS: IImportXLSX[] = [];

      if (excelData && Array.isArray(excelData)) {
        for (let name in excelData[0]) {
          dataXLXS.push({ name, key: name });
        }
      }
      importXLSXAdapter.setAll(state, dataXLXS.slice(0, 9)); //change on production
    },
    addOneImportXLS: (state, action) => {
      importXLSXAdapter.addOne(state, action.payload);
    },

    updateOneImportXLS: (state, action: PayloadAction<IImportXLSX>) => {
      console.log(action.payload);
      const { defValue, oldDefValue } = action.payload;
      let newOptions: { value: string; label: string }[] = [];
      if (
        defValue &&
        !isValueOptMult(defValue as TValueOptSing | TValueOptMult)
      ) {
        newOptions = state.options.filter((op) => op.value !== defValue);
      }

      if (
        oldDefValue &&
        !isValueOptMult(oldDefValue as TValueOptSing | TValueOptMult)
      ) {
        newOptions = [
          {
            label: oldDefValue,
            value: oldDefValue,
          },
          ...state.options,
        ];
      }

      if (newOptions.length > 0) state.options = newOptions;

      importXLSXAdapter.updateOne(state, {
        id: action.payload.key,
        changes: action.payload,
      });
    },
    removeOneImportXLS: (state, action: PayloadAction<string>) => {
      importXLSXAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearImportXLSSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | IImportXLSX>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = importXLSSlice;

export const {
  setAllImportXLS,
  addOneImportXLS,
  updateOneImportXLS,
  removeOneImportXLS,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearImportXLSSlice,
  setCount,
  setPickItem,
  addFiles,
} = actions;

export default reducer;
export const { selectAll } = importXLSXAdapter.getSelectors<RootState>(
  (state) => state.importXLSX
);
