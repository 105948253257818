import { takeLeading, call, put } from "redux-saga/effects";
import { commentsService, mediaService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllFile,
  addOneFile,
  updateOneFile,
  removeOneFile,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  setCount,
  setManyFile,
} from "../slices/file.slice";
import {
  setAllVideo,
  addOneVideo,
  updateOneVideo,
  removeOneVideo,
  setManyVideo,
} from "../slices/video.slice";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { IMedia, TMedia } from "../slices/image.slice";

function* getAll(action: PayloadAction<{ product_id: string }>) {
  yield put(editLoadingList(true));
  try {
    let params: { product_id: string; type: TMedia } = {
      product_id: action.payload.product_id,
      type: "File",
    };
    const responseFile: IMedia[] = yield call(mediaService.getAll, params);
    yield put(setAllFile(responseFile));
    params.type = "Video";
    const responseVideo: IMedia[] = yield call(mediaService.getAll, params);
    yield put(setAllVideo(responseVideo));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* addOne(
  action: PayloadAction<{ formDataFile?: FormData; formDataVideo?: FormData }>
) {
  yield put(editLoading(true));
  try {
    const { formDataFile, formDataVideo } = action?.payload;
    if (formDataVideo) {
      const response: {} = yield call(mediaService.uploadVideo, formDataVideo);
      yield put(setManyVideo(response));
    }
    if (formDataFile) {
      const response: {} = yield call(mediaService.uploadFiles, formDataFile);
      yield put(setManyFile(response));
    }

    yield put(editSuccess("Video/Files have been added"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; type: TMedia }>) {
  yield put(editLoading(true));
  try {
    const { type, id } = action.payload;
    const response: {} = yield call(mediaService.delete, id);
    if (type === "File") {
      yield put(removeOneFile(id));
    }
    if (type === "Video") {
      yield put(removeOneVideo(id));
    }
    const answer =
      type === "File"
        ? "The file has been deleted"
        : "The video has been deleted";

    yield put(editSuccess(answer));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchMediaSaga() {
  yield takeLeading("GET_ALL_MEDIA", getAll);
  yield takeLeading("ADD_MEDIA", addOne);
  yield takeLeading("DELETE_MEDIA", deleteOne);
}
