import { takeLeading, call, put } from "redux-saga/effects";
import {
  commentsService,
  itemsService,
  productService,
  specProductService,
} from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

/* import {
  setAllComments,
  addOneComments,
  updateOneComments,
  removeOneComments,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearCommentsSlice,
  setCount,
} from "../slices/comments.slice"; */
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  addOneProductWithStatus,
  removeOneProductWithStatus,
  setAllProductWithStatus,
  updateOneProductWithStatus,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearProductWithStatusSlice,
  setCount,
  ProductWithStatusI,
  setCompareList,
  deleteCompareItem,
  CompareItemI, updateCount,
} from "../slices/product_with_status.slice";
import {
  ProductType,
  statusItemsT,
  updateOneProduct,
  setNumberOfLikes,
  setNumberOfCompared,
  setNumberOfCart,
  setAllProduct,
} from "../slices/product_main.slice";
import Likes from "../../pages/Likes";
import { SpecForProductService } from "../../services/SpecForProductService";
import {
  setAllProductSpec,
  specNameRenderT,
  specValueRenderT,
  mapKeyInSpecValueRenderByIdProducT,
  setSpecNameRender,
  setSpecValueRender,
  setMapKeyInSpecValueRenderByIdProduct,
  updateRenderCompare,
} from "../slices/product_spec.slice";



interface ItemsCreateI {
  user_id?: string;
  product_id: string;
  status: statusItemsT;
  product: ProductType;
  idItemForUpdateLikeList?: string;
}

interface OneItemI extends ItemsCreateI {
  id: string;
}

function* getAll(action: PayloadAction<{ query_params?: string }>) {
  yield put(editLoadingList(true));

  try {
    const response: { rows: ProductWithStatusI[]; count: number } = yield call(
      itemsService.getAll,
      action.payload.query_params
    );
    yield put(setAllProductWithStatus(response.rows));
    yield put(setCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

interface ProductTypeWithItemId extends ProductType {
  idItem: string;
}

export interface PrSpI {
  id: string;
  product_id: string;
  specName_id: string;
  specName: {
    id: string;
    name: string;
  };
  specValues: {
    id: string;
    name: string;
  }[];
}

function* getAllCompare(
  action: PayloadAction<{
    idUser?: string;
    products?: ProductType[];
  }>
) {
  yield put(editLoadingList(true));

  try {
    const { products, idUser } = action.payload;
    let query_params_for_get_products: string = "?";
    let specNameRender: specNameRenderT = [null];
    let specValueRender: specValueRenderT = [];
    let mapKeyInSpecValueRenderByIdProduc: mapKeyInSpecValueRenderByIdProducT =
      {};

    if (!products) {
      let params = `?page=1&take=5&sort=status&search=Compare&sort=user_id&search=${idUser}`;
      const response: { rows: ProductWithStatusI[]; count: number } =
        yield call(itemsService.getAll, params);

      response.rows.forEach((prWs) => {
        query_params_for_get_products += `&sort=or: product_id&search=${prWs.product.id}`;
        const key =
          specValueRender.push([
            {
              ...prWs.product,
              idItem: prWs.id,
            },
          ]) - 1;
        mapKeyInSpecValueRenderByIdProduc[prWs.product.id] = key;
      });
    } else {
      products.forEach((pr) => {
        query_params_for_get_products += `&sort=or: product_id&search=${pr.id}`;
        const key = specValueRender.push([pr]) - 1;
        mapKeyInSpecValueRenderByIdProduc[pr.id] = key;
      });
    }

    const responseSp: {
      rows?: PrSpI[];
      count: number;
    } = yield call(
      specProductService.getByFilter,
      query_params_for_get_products
    );

    responseSp.rows?.forEach((chrS) => {
      let findIndexFirstLevel =
        mapKeyInSpecValueRenderByIdProduc[chrS.product_id];
      console.log(findIndexFirstLevel, "findIndexFirstLevel");
      let findIndexSecondLevel: number;
      findIndexSecondLevel = specNameRender.findIndex(
        (spN) => spN?.id === chrS.specName.id
      );
      if (findIndexSecondLevel === -1) {
        findIndexSecondLevel =
          specNameRender.push({
            id: chrS.specName.id,
            name: chrS.specName.name,
          }) - 1;
      }
      specValueRender[findIndexFirstLevel][findIndexSecondLevel] =
        chrS.specValues.map((spv) => spv.name).join(", ");
    });
    yield put(setAllProductSpec(responseSp.rows));
    yield put(setSpecNameRender(specNameRender));
    yield put(setSpecValueRender(specValueRender));
    yield put(
      setMapKeyInSpecValueRenderByIdProduct(mapKeyInSpecValueRenderByIdProduc)
    );
  } catch (error: unknown) {
    console.log("error", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* deleteFromCompare(
  action: PayloadAction<{ idItem?: string; prSpc: PrSpI[]; idProduct: string }>
) {
  yield put(editLoading(true));
  try {
    const { idItem, idProduct, prSpc } = action.payload;
    if (idItem) yield call(itemsService.delete, idItem);

    yield put(setAllProductSpec(prSpc));
    yield put(updateRenderCompare({ idProduct, prS: prSpc }));
    //yield put(removeOneProductWithStatus(action.payload.id));
    yield put(setNumberOfCompared(-1));
    yield put(editSuccess("The item has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getCount(action: PayloadAction<{ query_params?: string }>) {
  yield put(editLoadingList(true));

  try {
    const response: { rows: ProductWithStatusI[]; count: number } = yield call(
      itemsService.getAll,
      action.payload.query_params
    );

    let counts = {
      likes: 0,
      compare: 0,
      cart: 0,
    };
    response.rows.map((it) => {
      if (it.status === "Likes") counts.likes += 1;
      if (it.status === "Compare") counts.compare += 1;
      if (it.status === "Cart") counts.cart += 1;
    });

    yield put(setNumberOfCart(counts.cart));
    yield put(setNumberOfCompared(counts.compare));
    yield put(setNumberOfLikes(counts.likes));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  /*   yield put(editLoading(true));
  try {
    const response: {} = yield call(categoryService.getOne, action.payload);
    //yield put(removeOneCategory(action.payload));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  } */
}

function* editOne(action: PayloadAction<{ id: string; count: number }>) {
  yield put(editLoading(true));
  try {
    const body = { count: action.payload.count };

    const id = action.payload.id;
    const response: {} = yield call(itemsService.update, id, body, "PATCH");
    yield put(updateCount(action.payload));
    // yield put(updateCount(action.payload.count));
    yield put(editSuccess("Count of items has been updated"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));

    } else {
      yield put(editError("Something has gone wrong. Please try again later"));

    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(action: PayloadAction<ItemsCreateI>) {
  yield put(editLoading(true));
  try {
    const { status, idItemForUpdateLikeList } = action.payload;
    const responseItem: { product_id: string } = yield call(
      itemsService.create,
      action.payload
    );
    const response: ProductType = yield call(
      productService.getOne,
      action.payload.product_id
    );

    if (status === "Cart") yield put(setNumberOfCart(+1));
    if (status === "Compare") yield put(setNumberOfCompared(+1));
    if (status === "Likes") yield put(setNumberOfLikes(+1));
    console.log(responseItem);
    yield put(addOneProductWithStatus(responseItem));
    const responseGetOneProduct: ProductType = yield call(
      productService.getOne,
      responseItem.product_id
    );
    if (idItemForUpdateLikeList) {
      const responseGetItem: ProductType = yield call(
        itemsService.getOne,
        idItemForUpdateLikeList
      );
      yield put(updateOneProductWithStatus(responseGetItem));
    }
    yield put(updateOneProduct(responseGetOneProduct));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(
  action: PayloadAction<{ id: string; status: string; deleteFromComare?: true }>
) {
  yield put(editLoading(true));
  try {
    const { id, status } = action.payload;
    const response: {} = yield call(itemsService.delete, id);
    yield put(removeOneProductWithStatus(action.payload.id));
    console.log(action.payload, "fkkd");
    if (status === "Cart") yield put(setNumberOfCart(-1));
    if (status === "Compare") yield put(setNumberOfCompared(-1));
    if (status === "Likes") yield put(setNumberOfLikes(-1));
    if (status === "Order") {
    }
    if (action.payload.deleteFromComare) {
      yield put(deleteCompareItem(id));
    }
    yield put(editSuccess("The item has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* resetCompareList(action: PayloadAction<string>) {
  yield put(editLoading(true));
  try {
    if (!action.payload) return;
    const query_params = `?sort=user_id&search=${action.payload}&search=Compare&sort=status`;
    const response: { rows: ProductWithStatusI[]; count: number } = yield call(
      itemsService.getAll,
      query_params
    );
    if (!response) return;
    const listCompare = response.rows.map((it) => it.id);
    let formData: any = new URLSearchParams();
    listCompare.map((it) => formData.append("items[]", it));
    const responseResetCompare = itemsService.deleteCompareList(formData, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    yield put(setNumberOfCompared(0));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchProductWithStatusSaga() {
  yield takeLeading("GET_ALL_PRODUCT_WITH_STATUS", getAll);
  yield takeLeading("GET_COUNT_ALL_PRODUCT_WITH_STATUS", getCount);
  yield takeLeading("GET_ONE_PRODUCT_WITH_STATUS", getOne);
  yield takeLeading("ADD_PRODUCT_WITH_STATUS", addOne);
  yield takeLeading("EDIT_PRODUCT_WITH_STATUS", editOne);
  yield takeLeading("DELETE_PRODUCT_WITH_STATUS", deleteOne);
  yield takeLeading("RESET_COMPARE_LIST", resetCompareList);

  yield takeLeading("GET_ALL_PRODUCT_WITH_STATUS_COMPARE", getAllCompare);
  yield takeLeading("DELETE_PRODUCT_FROM_COMPARE", deleteFromCompare);
}
