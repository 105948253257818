import React, { useEffect, useState } from "react";
import {
  Divider,
  Radio,
  Table,
  Button,
  Tooltip,
  Input,
  Modal,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaEnvelopeOpenText } from "react-icons/fa";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  SpecNameI,
  editErrorList,
  editSuccess,
  selectAll as selectAllSpecName,
} from "../../redux/slices/spec_name.slice";
import { pageSize } from "../../variables";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
const sizeIcon = 24;

interface DataType {
  key: React.Key;
  name: string;
  value: [];
  category: string;
}

interface DataType_sub_spec {
  key: React.Key;
  name: string;
  spec: string;
  edit?: string;
  delete?: string;
}

function SpecsManagement() {
  //variables
  const [openListArea, setOpenListArea] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectAllSpecName);
  const specNames = Array.isArray(data) ? data : [];
  const navigate = useNavigate();
  const { loadingList, errorList, count, error, success, loading } =
    useAppSelector((state) => state.spec_name);
  const [searchText, setSearchText] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [pickSpecName, setPickSpecName] = useState<null | {
    id: string;
    name: string;
  }>(null);

  //useEffect
  useEffect(() => {
    dispatch({ type: "GET_ALL_SPEC_NAME" });
    return () => {
      dispatch(editErrorList(false));
    };
  }, []);

  useEffect(() => {
    if (!searchText) dispatch({ type: "GET_ALL_SPEC_NAME" });
  }, [searchText]);

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 3,
      });
      setOpenModal(false);
      dispatch(editSuccess(null));
    }
  }, [success]);

  //functions
  const changePage = (page: any) => {
    const payload: { page: number; search?: string } = { page };
    if (searchText) {
      payload.search = searchText;
    }
    dispatch({ type: "GET_ALL_SPEC_NAME", payload });
  };

  const onSearch = (value: string) => {
    console.log(value);
    dispatch({ type: "GET_ALL_SPEC_NAME", payload: { search: value } });
  };

  const onChange = (e: any) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };

  const onChangeOne = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const goOneElement = (id: string) => {
    navigate("/admin/filters/" + id);
  };

  const columns: ColumnsType<SpecNameI> = [
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
      //render: (text: string) => <a>{text}</a>,
    },
    {
      title: <div className={styles.headerColumn}>Values</div>,
      dataIndex: "value",
      render: (_, data) => (
        <div>
          {data?.specValues?.length > 0
            ? data?.specValues?.map((el) => el.name).join(", ")
            : "-"}
        </div>
      ),
    },
    {
      title: <div className={styles.headerColumn}>Category/SubCategory</div>,
      dataIndex: "category",
      render: (_, data) => (
        <div>{data?.subCategory?.name || data?.category?.name || "-"}</div>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
      dataIndex: "edit",
      render: (_, { id }) => (
        <a href={`/admin/filters/${id}`}>
          <GrFormEdit size={sizeIcon} />
        </a>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, { id, name }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            setPickSpecName({ id, name });
            setOpenModal(true);
          }}
        >
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
    },
  ];

  const handleOk = () => {
    if (pickSpecName?.id)
      dispatch({ type: "DELETE_SPEC_NAME", payload: pickSpecName });
  };

  const handleCancel = () => {
    setOpenModal(false);
    setPickSpecName(null);
  };

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.title}>
        <div className={styles.title__text}>Spec management</div>
        <div className={styles.title__search}>
          <Input.Search
            placeholder="Enter name of a spec"
            enterButton="Search"
            size="large"
            loading={loadingList}
            onSearch={onSearch}
            onChange={onChange}
            allowClear
          />
        </div>

        {!openListArea && (
          <div className={styles.listButtons}>
            <Button
              className={styles.button}
              onClick={() => goOneElement("null")}
            >
              <AiOutlinePlusCircle size={sizeIcon} />
              <div> Add new spec</div>
            </Button>
          </div>
        )}
      </div>

      <Table
        columns={columns}
        dataSource={specNames}
        loading={loadingList}
        pagination={{
          pageSize,
          total: count,
          position: ["bottomRight"],
          onChange: changePage,
        }}
        rowClassName={styles.row}
        className={styles.table}
        //scroll={{ y: 700 }}
        bordered
      />
      {contextHolder}
      <Modal
        title={"Delete " + pickSpecName?.name}
        open={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ textAlign: "center" }}>
          <ResponseStatusComponent error={error} loading={loading} />
          Are you sure you want delete the spec name
          <b>{" " + pickSpecName?.name}?</b>
          <br />
          This item will be deleted in all products
        </div>
      </Modal>
    </div>
  );
}

export default SpecsManagement;
