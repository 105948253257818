import { takeLeading, call, put } from "redux-saga/effects";
import { highlightsService, xlsxFileService } from "../../services";
import { Action, PayloadAction } from "@reduxjs/toolkit";
import {
  setAllImportXLS,
  addOneImportXLS,
  updateOneImportXLS,
  removeOneImportXLS,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearImportXLSSlice,
  setCount,
  setPickItem,
  IFileXLSX,
  addFiles,
} from "../slices/importXLSX.slice";
import axios from "axios";
import { ExelFileService } from "../../services/ExelFileService";
//

export type TFolders =
  | "MarathonElectricMotors"
  | "MarathonACDCControls"
  | "LeesonElectricMotors"
  | "LeesonACDCControls"
  | "GenteqElectricMotors"
  | "GenteqBlowers"
  | "FascoElectricMotors"
  | "FascoBlowers"
  | "CenturyElectricMotors"
  | "CenturyBlowers"
  | "BrowningMechanical"
  | "BrowningBearings"
  | "BrowningCouplings"
  | "BrowningGearing"
  | "BrowningIndustrialChain";

function* getAll(
  action: PayloadAction<{ sort?: "folderName"; search: TFolders }>
) {
  yield put(editLoadingList(true));
  try {
    const response: { rows?: IFileXLSX[]; count: number } = yield call(
      xlsxFileService.getAll,
      action.payload
    );
    console.log("response", response);
    if (action.payload?.search && response.rows)
      yield put(
        addFiles({
          files: response.rows,
          folder: action.payload.search,
        })
      );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<{ name?: string; id?: string }>) {
  yield put(editLoading(true));

  try {
    const { name, id } = action.payload;
    let response: IFileXLSX | null = null;
    if (name) {
      response = yield call(xlsxFileService.getByName, name, true);
    }

    if (response) {
      const url = response.url;
      //@ts-ignore
      const responseGetFile = yield call(axios.get, url, {
        responseType: "arraybuffer",
      });
      yield put(setAllImportXLS(responseGetFile.data));
      //@ts-ignore
      /* const responseGetFile: any = yield call(fetch, url);
      const x = responseGetFile.body.arrayBuffer();
      yield put(setAllImportXLS(x)); */
      //console.log(responseGetFile.body.arrayBuffer(), "responseGetFile");
    }

    //console.log(response, "response");

    //yield put(editSuccess(`The highlight  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* create(action: PayloadAction<URLSearchParams>) {
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    const response: {} = yield call(
      xlsxFileService.createExelProducts,
      action.payload
    );

    console.log(response, "response");
    /*  yield put(updateOneHighlights(response));
    yield put(editSuccess(`The highlight  has been updated`)); */
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* uploadOne(action: PayloadAction<TFolders>) {
  yield put(editLoading(true));

  try {
    console.log("hello");
    const response: {} | null = yield call(
      xlsxFileService.uploadExelProducts,
      action.payload
    );

    console.log(response, "response");

    const responseGetCLSX: { rows?: IFileXLSX[]; count: number } = yield call(
      xlsxFileService.getAll,
      { sort: "folderName", search: action.payload }
    );

    console.log(responseGetCLSX, "responseGetCLSX");

    if (responseGetCLSX.rows)
      yield put(
        addFiles({
          files: responseGetCLSX.rows,
          folder: action.payload,
        })
      );

    yield put(editSuccess(`Files has been upload`));
  } catch (error: unknown) {
    console.log("error", error);
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

//  uploadExelProducts
export default function* watchImportXLSXSaga() {
  yield takeLeading("UPLOAD_XLSX_FILES", uploadOne);
  yield takeLeading("GET_ALL_XLSX_FILES", getAll);
  yield takeLeading("GET_ONE_XLSX_FILES", getOne);
  yield takeLeading("CREATE_XLSX_PRODUCT", create);
  /* yield takeLeading("ADD_HIGHLIGHTS", addOne);
  yield takeLeading("EDIT_HIGHLIGHTS", editOne);
  yield takeLeading("DELETE_HIGHLIGHTS", deleteOne); */
}
