import React, {useEffect, useState} from "react";
import {Layout, Button, Input, Divider, Pagination, notification, Spin} from "antd";

import OneGood from "../../components/Goods/OneGood";
import {AiOutlineShoppingCart} from "react-icons/ai";
import styles from "./index.module.scss";
import {getUserId} from "../../utilities";
import {useAppDispatch, useAppSelector} from "../../redux/ts/hooks/hooks";
import {pageSize} from "../../variables";
import {
    ProductWithStatusI,
    selectAll as selectAllProductWithStatus,
} from "../../redux/slices/product_with_status.slice";
import {GoodFooter} from "../../components/SubCategoryItem";
import {error} from "console";
import {
    ProductType,
    selectAll as selectAllProduct
} from "../../redux/slices/product_main.slice";
import {getItemObject} from "../../utilities/product";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../redux/store";
import {useSelector} from "react-redux";
import {selectAll} from '../../redux/slices/count_product_with_status.slice'
import {
    ExtraInitStateI,
} from "../../redux/slices/count_product_with_status.slice";
import {getSum} from "../../utilities/other";

const arrayTest = [1, 1, 1];
const iconSize = 24;

function Cart() {
    const idUser = getUserId();
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(1);
    const defaultQueryParams = `?sort=user_id&search=${idUser}&search=Cart&sort=status&take=${pageSize}&page=`;
    const allQueryParams = `?sort=user_id&search=${idUser}&search=Cart&sort=status&take&page=`;
    const products = useAppSelector(selectAllProductWithStatus);
    const allProductsArray = useSelector((state: RootState) => selectAll(state));

    const {count, successList, errorList, loadingList, success, loading} = useAppSelector(
        (state) => state.productWithStatus
    );
    const navigate = useNavigate()
    const [totalPrice, setTotalPrice] = useState(0)
    const {numberOfCart} = useAppSelector(
        (state) => state.product
    );
    const [allCartProduct, setAllCardProduct] = useState([])
    const {error: errorOrder, success: succesOrder} = useAppSelector(
        (state) => state.orders
    );
    //const [sum, setSum] = useState(0);
    //const sum = getSum(products);
    const [api, contextHolder] = notification.useNotification();

    const getProducts = (page: number) => {
        dispatch({
            type: "GET_ALL_PRODUCT_WITH_STATUS",
            payload: {
                query_params: defaultQueryParams + page,
            }
        });
    };
    const getAllProductsPrice = () => {
        dispatch({
            type: "GET_ALL_PRODUCT_WITH_STATUS_FOR_PRICE",
            payload: {
                query_params: allQueryParams,
            }
        });
    };

    useEffect(() => {
        if (products.length === 0 && allProductsArray.length > 0) {

            setPage(1)
            getProducts(1)
        }
    }, [products.length]);

    useEffect(() => {
        if (products.length === 0 && allProductsArray.length === 0 && success === 'The item has been deleted') {
            navigate('/')
        }
    }, [numberOfCart, allProductsArray]);

    useEffect(() => {
        getProducts(page);
        getAllProductsPrice()
        getSum(allProductsArray);
    }, [products.length, page]);

    useEffect(() => {
        if (allProductsArray.length > 0) {
            setTotalPrice(getSum(allProductsArray));
        }
    }, [allProductsArray, products]);

    useEffect(() => {
        if (succesOrder) {
            api.success({
                message: <div className={styles.succes}>{succesOrder}</div>,
                duration: 10,
            });
        }
    }, [succesOrder]);

    useEffect(() => {
        if (errorOrder) {
            api.error({
                message: <div className={styles.succes}>{errorOrder}</div>,
                duration: 10,
            });
        }
    }, [errorOrder]);

    const onChangePage = (page: number) => {
        getProducts(page);
        setPage(page);
    };

    const makeOrder = () => {
        navigate('/account/payment')
    };

    console.log("CartProductStatus", products)
    return (
        <>
            {loadingList ? (
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spin size="large"/>
                </div>
            ) : (<>{contextHolder}
                <div className={styles.cart_wrapper}>
                    <div className={styles.cart_goods}>
                        {products.map((item) => {
                            return (
                                <div key={item.id}>
                                    <OneGood
                                        direction="horizontal"
                                        hiddenLike={true}
                                        data={getItemObject(item)}
                                        //idItemCart={item.id}
                                        hiddenCheck={true}
                                        defCount={item.count}
                                        showCount={true}
                                        changeCountOnServer={true}
                                        setTotalPrice={setTotalPrice}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.cart_payment}>
                        <div className={styles.cart_payment__title}>Details</div>
                        <div className={styles.cart_payment__price}>
                            <div>Count item(s)</div>
                            <div>{numberOfCart} </div>
                            {/*<div>{count} </div>*/}
                        </div>
                        <div className={styles.cart_payment__price}>
                            <div>Address</div>
                            <div>-</div>
                        </div>
                        <div className={styles.cart_payment__price}>
                            <div>Card</div>
                            <div>-</div>
                        </div>
                        <Divider/>
                        <div
                            className={[
                                styles.cart_payment__price,
                                styles.cart_payment__total,
                            ].join(" ")}
                        >
                            <div>Payment Summary:</div>
                            <div>$ {totalPrice}</div>
                        </div>
                        <Button className={styles.button_item} onClick={makeOrder}>
                            <div className={styles.cart_button}>
                                <AiOutlineShoppingCart size={iconSize - 5}/>
                                <div> Make an Order</div>
                            </div>
                        </Button>
                    </div>
                    {count > pageSize && (
                        <Pagination
                            current={page}
                            defaultCurrent={1}
                            total={count}
                            pageSize={pageSize}
                            className={styles.pagination}
                            onChange={onChangePage}
                        />
                    )}
                </div>
            </>)}

        </>
    );
}

export default Cart;
