import { CommonService } from "./CommonService";
import { RequestService } from "./RequestService";
import jwt_decode from "jwt-decode";
import { TBodyUpdateContact, IBodyUpdateAboutMe } from "./interfaces";
import { pageSize } from "../variables";

export type mediaT = "Image" | "File" | "Video";
export class MediaService extends CommonService {
  constructor() {
    super("media");
  }

  uploadVideo = (body: FormData, headers?: {}) =>
    this.request({
      method: "post",
      body,
      path: "videos",
      headers: { "Content-Type": "multipart/form-data", ...headers },
    });

  uploadFiles = (body: FormData, headers?: {}) =>
    this.request({
      method: "post",
      body,
      path: "files",
      headers: { "Content-Type": "multipart/form-data", ...headers },
    });
}
