import { InitStateRequestStatusI, FilterInitStateI } from "./ts/interface";

export const requestStatusInitState: InitStateRequestStatusI = {
  loading: false,
  error: null,
  success: null,
  loadingList: false,
  errorList: null,
  successList: null,
};

export const filterInitState: FilterInitStateI = {
  count: 0,
  foundList: [],
  pickItem: null,
};
