import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import store, { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType, statusItemsT } from "./product_main.slice";
import { InitStateRequestStatusI } from "../ts/interface";
import { PrSpI } from "../sagas/product_with_status.saga";

export interface ProductWithStatusI {
  id: string;
  product_id: string;
  user_id: string;
  count: number;
  status: statusItemsT;
  order_id: null | string;

  product: ProductType;
  productItems: {
    cartItem: null | string;
    compareItem: null | string;
    likesItem: null | string;
  };
}
export const productWithStatusAdapter = createEntityAdapter<ProductWithStatusI>(
  {
    selectId: (productWithStatus) => productWithStatus.id,
  }
);

export interface ProductTypeForCompare extends ProductType {
  specNames: {
    id: string;
    name: string;
    specValues: { id: string; name: string };
  };
}
export interface CompareItemI extends ProductWithStatusI {
  product: ProductTypeForCompare;
}

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  currentListType: statusItemsT | null;
  compareList: CompareItemI[];
  updateListLikeNoAuth: boolean;
}

const initState: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  currentListType: null,
  compareList: [],
  updateListLikeNoAuth: false,
};

export const initialState = productWithStatusAdapter.getInitialState(initState);

const productWithStatusSlice = createSlice({
  name: "productWithStatus",
  initialState,
  reducers: {
    setAllProductWithStatus: (state, action) => {
      productWithStatusAdapter.setAll(state, action.payload);
    },

    addOneProductWithStatus: (state, action) => {
      if (state.currentListType === action.payload.status) {
        productWithStatusAdapter.addOne(state, action.payload);
      }
    },
    updateOneProductWithStatus: (state, action) => {
      productWithStatusAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload.count,
      });
    },
    updateCount: (state, action) => {
      const product = state.entities[action.payload.id];
      if (product) {
        product.count = action.payload.count;
      }
    },
    removeOneProductWithStatus: (state, action) => {
      productWithStatusAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },

    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearProductWithStatusSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setCompareList: (state, action: PayloadAction<CompareItemI[]>) => {
      state.compareList = action.payload;
    },
    deleteCompareItem: (state, action: PayloadAction<string>) => {
      state.compareList = state.compareList.filter(
        (item) => item.id !== action.payload
      );
    },
    setCurrentListType: (state, action: PayloadAction<statusItemsT | null>) => {
      state.currentListType = action.payload;
    }, //updateListLikeNoAuth
    setUpdateListLikeNoAuth: (state, action: PayloadAction<boolean>) => {
      state.updateListLikeNoAuth = action.payload;
    },
  },
});

const { actions, reducer } = productWithStatusSlice;

export const {
  setAllProductWithStatus,
  addOneProductWithStatus,
  updateOneProductWithStatus,
  removeOneProductWithStatus,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearProductWithStatusSlice,
  setCount,
  setCurrentListType,
  setCompareList,
  deleteCompareItem,
  setUpdateListLikeNoAuth,
  updateCount
} = actions;

export default reducer;
export const { selectAll } = productWithStatusAdapter.getSelectors<RootState>(
  (state) => state.productWithStatus
);
