import { takeLeading, call, put } from "redux-saga/effects";
import {
  productService,
  usersService,
  specValuesService,
  specNamesService,
  specProductService,
  mediaService,
} from "../../services";
import { PayloadAction, createAction } from "@reduxjs/toolkit";

import {
  setAllProduct,
  addOneProduct,
  updateOneProduct,
  removeOneProduct,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  ProductType,
  ProductTypeIForForm,
  setAllSearchProduct,
  setSearchCount,
  editLoadingSearch,
  setIsOtherProduct,
} from "../slices/product_main.slice";
import { setSpecialItemsForProduct } from "../slices/spec_name.slice";
import { getAllByFilter } from "./specName.saga.";
import { setAllProductSpec } from "../slices/product_spec.slice";
import { setAllImg } from "../slices/image.slice";
import { IReturnDataGetPayloadProductDesc } from "../../utilities/product";

function* getAll(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
    queryParams?: string;
    otherProduct?: boolean;
  }>
) {
  //++
  yield put(editLoadingList(true));
  console.log(action.payload);
  try {
    const { search, page, filter, sort } = action?.payload || {};
    let queryParams: any = action?.payload;
    if (filter) {
      queryParams = {
        ...queryParams,
        ...filter,
      };
      delete queryParams.filter;
    }
    let servicesMethod:
      | "getAll"
      | "searchByName"
      | "getByFilter"
      | "getByName"
      | "search" = "getAll";
    if (filter) servicesMethod = "getByFilter";
    if (search) servicesMethod = "searchByName";
    if (sort && search) servicesMethod = "search";

    const response: { rows?: []; count: number } = yield call(
      productService[servicesMethod],
      action?.payload?.queryParams || queryParams || {}
    );

    yield put(setCount(response?.count || 0));

    if (response?.count > 0 && action?.payload?.otherProduct) {
      yield put(setIsOtherProduct(true));
    }
    yield put(setAllProduct(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getAllWithSpec(
  action: PayloadAction<{
    query_params: string;
  }>
) {
  yield put(editLoadingList(true));
  try {
    const response: { rows?: []; count: number } = yield call(
      productService.filterWithSpecs,
      action?.payload?.query_params
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllProduct(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getAllForSearch(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  //++
  yield put(editLoadingSearch(true));
  console.log(action.payload);
  try {
    const { search, page, filter, sort } = action?.payload || {};
    let queryParams: any = action?.payload;
    if (filter) {
      queryParams = {
        ...queryParams,
        ...filter,
      };
      delete queryParams.filter;
    }
    let servicesMethod:
      | "getAll"
      | "searchByName"
      | "getByFilter"
      | "getByName"
      | "search" = "getAll";
    if (filter) servicesMethod = "getByFilter";
    if (search) servicesMethod = "searchByName";
    if (sort && search) servicesMethod = "search";

    const response: { rows?: []; count: number } = yield call(
      productService[servicesMethod],
      queryParams
    );

    /*   const response: { rows?: []; count: number } = !action?.payload?.search
      ? yield call(productService.getAll, action.payload)
      : yield call(productService.searchByName, action.payload);
 */

    yield put(setSearchCount(response?.count || 0));
    yield put(setAllSearchProduct(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingSearch(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: ProductType = yield call(
      productService.getOne,
      action.payload
    );

    const baseFilter = [
      { sort: "or: name", search: "brand" },
      { sort: "or: name", search: "supplier" },
      {
        sort: response.subCategory_id ? "subCategory_id" : "category_id",
        search: (response.subCategory_id
          ? response.subCategory_id
          : response.category_id) as string,
      },
    ];

    yield call(getAllByFilter, {
      payload: baseFilter,
      type: "GET_ALL_SPEC_NAME_BY_FILTER",
    });

    const responseGetImages: [] = yield call(mediaService.getAll, {
      product_id: action.payload,
      type: "Image",
    });

    console.log(responseGetImages);

    yield put(setPickItem(response));
    yield put(setAllImg(responseGetImages));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* checkAndCreateExistingBrandAndSupplier(
  action: PayloadAction<ProductTypeIForForm>
) {
  /*  const { brand, supplier, ...b } = action.payload;
  let body: any = {};

  if (brand.id) body.brand = brand.id;
  if (supplier.id) body.supplier = supplier.id;

  if (brand.name && !brand.id) {
    const responseSpecValue: { id: string; name: string } = yield call(
      specValuesService.create,
      { name: action.payload.brand.name }
    );
    const responseSpecName: { id: string } = yield call(
      specNamesService.create,
      {
        name: "brand",
        specValues: [responseSpecValue.id],
        category_id: action.payload.category_id,
        subCategory_id: action.payload.subCategory_id,
      }
    );
    yield put(
      setSpecialItemsForProduct({
        brand: [{ id: responseSpecValue.id, name: responseSpecValue.name }],
      })
    );
    body.brand = responseSpecValue.id;
  }

  if (supplier.name && !supplier.id) {
    const responseSpecValue: { id: string; name: string } = yield call(
      specValuesService.create,
      { name: action.payload.supplier.name }
    );
    const responseSpecName: { id: string } = yield call(
      specNamesService.create,
      {
        name: "supplier",
        specValues: [responseSpecValue.id],
        category_id: action.payload.category_id,
        subCategory_id: action.payload.subCategory_id,
      }
    );
    yield put(
      setSpecialItemsForProduct({
        supplier: [{ id: responseSpecValue.id, name: responseSpecValue.name }],
      })
    );
    body.supplier = responseSpecValue.id;
  }

  return { ...b, ...body }; */
}

function* editOne(action: PayloadAction<IReturnDataGetPayloadProductDesc>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    /*     const body: ProductTypeIForForm = yield call(
      checkAndCreateExistingBrandAndSupplier,
      action
    ); */
    const body = action.payload.bodyInFormData;

    const headers = { "Content-Type": "multipart/form-data" };
    const id = action.payload.id;

    const response: {} = yield call(
      productService.update,
      id as string,
      body,
      "PATCH",
      headers
    );

    if (action.payload.confirmDeleteAllSpec && id) {
      yield call(specProductService.deletAllByProductId, id);
    }

    if (action.payload.confirmDeleteAllSpec && id) {
      yield call(specProductService.deletAllByProductId, id);
    }

    if (action.payload.formDataForImgs) {
      yield call(
        productService.updateImages,
        action.payload.formDataForImgs,
        headers
      );
    }

    yield put(setAllProductSpec([]));
    yield put(updateOneProduct(response));

    yield put(
      editSuccess(`The product ${action.payload.name}  has been updated`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(
  action: PayloadAction<{
    id: string;
    name: string;
    bodyInFormData: {};
  }>
) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    /*  const body: ProductTypeIForForm = yield call(
      checkAndCreateExistingBrandAndSupplier,
      action
    ); */
    const body = action.payload.bodyInFormData;
    const headers = { "Content-Type": "multipart/form-data" };
    const response: {} = yield call(productService.create, body, headers);
    yield put(addOneProduct(response));
    yield put(
      editSuccess(`The product ${action.payload.name}  has been added`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(productService.delete, action.payload);
    yield put(removeOneProduct(action.payload));
    yield put(editSuccess("The product has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAllArchive(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  yield put(editLoadingList(true));
  yield put(editErrorList(null));
  try {
    const response: { rows?: []; count: number } = yield call(
      productService.getAllArchive,
      { page: action.payload.page }
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllProduct(response.rows ? response.rows : response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* restoreFormArchive(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(productService.restoreOne, action.payload);
    console.log("response", response);
    yield put(removeOneProduct(action.payload));
    yield put(editSuccess("The product has been restored"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchProductSaga() {
  yield takeLeading("GET_ALL_PRODUCT", getAll);
  yield takeLeading("GET_ALL_PRODUCT_WITH_SPEC", getAllWithSpec);
  yield takeLeading("GET_ALL_PRODUCT_FOR_SEARCH", getAllForSearch);
  yield takeLeading("GET_ONE_PRODUCT", getOne);
  yield takeLeading("ADD_PRODUCT", addOne);
  yield takeLeading("EDIT_PRODUCT", editOne);

  yield takeLeading("DELETE_PRODUCT", deleteOne);
  yield takeLeading("GET_ALL_PRODUCT_ARCHIVE", getAllArchive);
  yield takeLeading("RESTORE_PRODUCT", restoreFormArchive);
}
