import { takeLeading, call, put } from "redux-saga/effects";
import { ordersService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import { PickSubCategoryI } from "../slices/subcategory.slice";
import { setNumberOfCart, setPickCategory } from "../slices/product_main.slice";
import {
  clearProductWithStatusSlice,
  setAllProductWithStatus
} from "../slices/product_with_status.slice";
import {
  addOneOrders,
  editLoading,
  editError,
  editSuccess,
  clearResponseStatusofForm,
  editLoadingList,
  editErrorList,
  setAllOrders,
  setPickItem,
  OrderI,
  setCount,
  statusOrder,
  updateOneOrders,
} from "../slices/orders.slice";
import {addPaymentWithCreditCard, PaymentData} from "../../services/api";
import {setAllCountProductWithStatus} from "../slices/count_product_with_status.slice";
//
function* getAll(action: PayloadAction<{ page: number; params: string }>) {
  yield put(editLoadingList(true));
  try {
    const response: { rows: OrderI[]; count: number } = yield call(
      ordersService.getAll,
      action?.payload?.params || action.payload
    );
    console.log(response, "response");
    yield put(setAllOrders(response.rows));
    yield put(setCount(response.count));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoadingList(true));
  try {
    const response: OrderI = yield call(ordersService.getOne, action.payload);
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* editOne(action: PayloadAction<{ id: string; status: statusOrder }>) {
  yield put(editLoading(true));
  yield put(editSuccess(null));
  try {
    const status = action.payload.status;
    const id = action.payload.id;
    yield call(ordersService.update, id, { status }, "PATCH");

    const response: OrderI = yield call(ordersService.getOne, id);
    yield put(setPickItem(response));
    yield put(editSuccess(`The status  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(
  action: PayloadAction<{ user_id: string; card_id: string; address: string }>
) {
  console.log(action.payload);
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    const body = action.payload;
    const response: {} = yield call(ordersService.create, body);
    yield put(addOneOrders(response));
    yield put(editSuccess(`The order has been made`));
    yield put(setAllProductWithStatus([]));
    yield put(setNumberOfCart(0));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}
function* createOne(
    action: PayloadAction<PaymentData>
) {
  console.log(action.payload);
  yield put(editLoading(true));
  yield put(clearResponseStatusofForm());
  try {
    const body = action.payload;
    const response: {} = yield call(addPaymentWithCreditCard, body);
    yield put(addOneOrders(response));
    yield put(editSuccess(`The order has been made`));
    yield put(setAllProductWithStatus([]));
    yield put(setAllCountProductWithStatus([]));

    yield put(setNumberOfCart(0));
  } catch (error: unknown) {

    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}
function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  /*   yield put(editLoading(true));
  try {
    yield call(ordersService.delete, action.payload.id);
    yield put(removeOneCategory(action.payload.id));
    yield put(
      editSuccess(`The category "${action.payload.name}" has been deleted`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  } */
}

export default function* watchOrderSaga() {
  yield takeLeading("GET_ALL_ORDER", getAll);
  yield takeLeading("GET_ONE_ORDER", getOne);
  yield takeLeading("ADD_ORDER", addOne);
  yield takeLeading("CREATE_ORDER", createOne);
  yield takeLeading("EDIT_ORDER", editOne);
  yield takeLeading("DELETE_ORDER", deleteOne);
}
