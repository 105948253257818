import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { BsCardImage } from "react-icons/bs";
import { getBase64 } from "../../utilities/product";
import { selectAll as selectAllImg } from "../../redux/slices/image.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import styles from "./index.module.scss";
import { IExtraImg } from ".";

export function ExtraImg({
  setExtraFiles,
}: {
  setExtraFiles: (data: {
    file: UploadFile | null;
    fileList: UploadFile[];
  }) => void;
}) {
  //variables

  const [previewImageExtra, setPreviewImageExtra] = useState("");
  const imgs = useAppSelector(selectAllImg);
  const [fileListExtra, setFileListExtra] = useState<UploadFile[]>([]);
  const { pickItem: pickItemProduct, success } = useAppSelector(
    (state) => state.product
  );

  //useEffect

  useEffect(() => {
    const files = imgs.map((img) => {
      return { url: img.media_url, uid: img.id, name: img.name };
    });
    setFileListExtra(files);
    setExtraFiles({ file: null, fileList: files });
    setPreviewImageExtra(files[0]?.url);
  }, [imgs]);

  useEffect(() => {
    if (success && !pickItemProduct) {
      setFileListExtra([]);
      setPreviewImageExtra("");
    }
  }, [success]);

  //functions

  const handlePreviewExtra = async (file: UploadFile) => {
    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImageExtra(file.url || (file.preview as string));

    /* setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    ); */
  };

  const handleChangeExtra: UploadProps["onChange"] = async ({
    file: uploadFile,
    fileList: newFileList,
  }) => {
    setExtraFiles({ file: uploadFile, fileList: newFileList });
    setFileListExtra(newFileList);
    const file = newFileList[newFileList.length - 1];
    file && (await handlePreviewExtra(file));

    /*   
    let newData: any = {};
    if (newFileList.length > 0) newData.fileListExtra = newFileList;
    if (updateFiles.deleteId || updateFiles.uploadImage)
      newData.updateFiles = updateFiles;
    if (Object.keys(newData).length !== 0) setExtraFiles(newData); */
  };

  const handleDropExtra = (event: React.DragEvent) => {
    console.log("handleDrop", event);
  };

  return (
    <>
      <div
        className={[
          styles.img_block,
          /* errorImg ? styles.img_block_error : "" */
          ,
        ].join(" ")}
        style={{
          marginTop: 10,
          height: previewImageExtra ? 445 : 345,
          //overflowX: "auto",
        }}
      >
        <div className={styles.imgTitle}>
          <div>Extra images</div>
        </div>
        <div
          className={styles.picter}
          style={{
            flexDirection: "column",
            width: "100%",
            height: previewImageExtra ? "400px" : "300px",
          }}
        >
          {previewImageExtra && (
            <div
              className={styles.img}
              style={{
                width: "100%",
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="example"
                style={{
                  maxHeight: 200,
                  objectFit: "contain",
                  width: "90%",
                }}
                src={previewImageExtra}
              />
            </div>
          )}
          <div
            className={
              previewImageExtra
                ? styles.extraPrevieImages
                : styles.extraPrevieImagesDef
            }
          >
            <Upload
              maxCount={5}
              listType="picture-card"
              fileList={fileListExtra}
              onPreview={handlePreviewExtra}
              onChange={handleChangeExtra}
              onDrop={handleDropExtra}
              /*  className={
                      previewImage ? styles.upload_preview : styles.upload
                    } */

              /*  customRequest={customRequestExtra} */
            >
              <div>
                <BsCardImage size={70} color="#878D9C" />
              </div>
            </Upload>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExtraImg;
