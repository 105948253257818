import React, { useEffect, useState } from "react";
import { Divider, Radio, Table, Input } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import styles from "./index.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { useSelector } from "react-redux";
import {
  editErrorList,
  selectAll as selectAllUsers,
} from "../../redux/slices/users.slice";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import { editError } from "../../redux/slices/auth.slice";
import { pageSize } from "../../variables";

const sizeIcon = 24;
interface DataType {
  id: string;
  name: string;
  email: string;
  phone: string;
  status: string;
  numberOfPurchases: number;
  numberOfReturns: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: <div className={styles.headerColumn}>Name</div>,
    dataIndex: "name",
    //render: (text: string) => <a>{text}</a>,
  },
  {
    title: <div className={styles.headerColumn}>Email</div>,
    dataIndex: "email",
  },
  {
    title: <div className={styles.headerColumn}>Phone</div>,
    dataIndex: "phone",
  },
  {
    title: <div className={styles.headerColumn}>Status</div>,
    dataIndex: "status",
    render: (text: string) => (
      <div
        className={[
          styles.status,
          text === "Disabled" && styles.status_disabled,
        ].join(" ")}
      >
        {text}
      </div>
    ),
  },
  {
    title: (
      <div className={styles.headerColumn}>
        Number of <br />
        purchases
      </div>
    ),
    dataIndex: "numberOfPurchases",
  },
  {
    title: (
      <div className={styles.headerColumn}>
        Number of <br />
        returns
      </div>
    ),
    dataIndex: "numberOfReturns",
  },
  {
    title: (
      <div className={styles.headerColumn}>
        <GrFormEdit size={sizeIcon} />
      </div>
    ),
    dataIndex: "edit",
    render: (_, { id }) => (
      <a href={"/admin/users/" + id}>
        <GrFormEdit size={sizeIcon} />
      </a>
    ),
  },
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record: DataType) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};
const onUser = () => {
  console.log("hello");
};

function UserManagement() {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectAllUsers);
  const users = Array.isArray(data) ? data : [];
  const { loadingList, errorList, count } = useAppSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch({ type: "GET_ALL_USERS" });
    return () => {
      dispatch(editErrorList(false));
    };
  }, []);

  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (!searchText) dispatch({ type: "GET_ALL_USERS" });
  }, [searchText]);

  const changePage = (page: any) => {
    const payload: { page: number; search?: string; sort?: "name" } = { page };
    if (searchText) {
      payload.search = searchText;
      payload.sort = "name";
    }
    dispatch({ type: "GET_ALL_USERS", payload });
  };
  const onSearch = (value: string) => {
    console.log(value);
    dispatch({
      type: "GET_ALL_USERS",
      payload: { search: value, sort: "name" },
    });
  };

  const onChange = (e: any) => {
    setSearchText(e.target.value);
    console.log(e.target.value);
  };

  console.log(count, "count");

  return (
    <div className={styles.table_wrapper}>
      <div className={styles.title}>
        <div className={styles.title__text}> User management</div>
        <div className={styles.title__search}>
          <Input.Search
            placeholder="Enter user's name"
            enterButton="Search"
            size="large"
            loading={loadingList}
            onSearch={onSearch}
            onChange={onChange}
            allowClear
          />
        </div>
      </div>

      <div style={{ marginBottom: 15 }}>
        <ResponseStatusComponent error={errorList} />
      </div>
      <Table
        columns={columns}
        dataSource={users as DataType[]}
        loading={loadingList}
        pagination={{
          pageSize,
          total: count,
          position: ["bottomRight"],
          onChange: changePage,
        }}
        rowClassName={styles.row}
        className={styles.table}
        //scroll={{ y: 700 }}
        bordered
      />
    </div>
  );
}

export default UserManagement;
