import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
export const subspecAdapter = createEntityAdapter<CommentType>({
  selectId: (subspec) => subspec.id,
});

export const initialState = subspecAdapter.getInitialState({
  ...requestStatusInitState,
  count: 0,
});

const subspecSlice = createSlice({
  name: "subspec",
  initialState,
  reducers: {
    setAllSubspec: (state, action) => {
      subspecAdapter.setAll(state, action.payload);
    },
    addOneSubspec: (state, action) => {
      subspecAdapter.addOne(state, action.payload);
    },
    updateOneSubspec: (state, action) => {
      subspecAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneSubspec: (state, action) => {
      subspecAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
  },
});

const { actions, reducer } = subspecSlice;

export const {
  setAllSubspec,
  addOneSubspec,
  updateOneSubspec,
  removeOneSubspec,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
} = actions;

export default reducer;
export const { selectAll } = subspecAdapter.getSelectors<RootState>(
  (state) => state.subspec
);
