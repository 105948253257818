import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";

export interface SpecValueI {
  id: string;
  name: string;
}
export const specValueAdapter = createEntityAdapter<SpecValueI>({
  selectId: (spec_value) => spec_value.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | SpecValueI;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};
export const initialState = specValueAdapter.getInitialState(init);

const specNameSlice = createSlice({
  name: "spec_value",
  initialState,
  reducers: {
    setAllSpecValue: (state, action) => {
      specValueAdapter.setAll(state, action.payload);
    },
    addOneSpecValue: (state, action) => {
      specValueAdapter.addOne(state, action.payload);
    },
    updateOneSpecValue: (state, action) => {
      specValueAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneSpecValue: (state, action) => {
      specValueAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | SpecValueI>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = specNameSlice;

export const {
  setAllSpecValue,
  addOneSpecValue,
  updateOneSpecValue,
  removeOneSpecValue,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
} = actions;

export default reducer;
export const { selectAll } = specValueAdapter.getSelectors<RootState>(
  (state) => state.spec_value
);
