import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";
import { UserDataI } from "./account.slice";
import { InitStateRequestStatusI } from "../ts/interface";

export type TMedia = "Image" | "Video" | "File";
export interface IMedia {
  id: string;
  name: string;
  media_url: string;
  product_id: string;
  type: TMedia;
  poster?: null;
}

export const imgAdapter = createEntityAdapter<IMedia>({
  selectId: (img) => img.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | IMedia;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};

export const initialState = imgAdapter.getInitialState(init);

const imgSlice = createSlice({
  name: "img",
  initialState,
  reducers: {
    setAllImg: (state, action) => {
      imgAdapter.setAll(state, action.payload);
    },
    addOneImg: (state, action) => {
      imgAdapter.addOne(state, action.payload);
    },
    updateOneImg: (state, action) => {
      imgAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneImg: (state, action) => {
      imgAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearImgSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | IMedia>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = imgSlice;

export const {
  setAllImg,
  addOneImg,
  updateOneImg,
  removeOneImg,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearImgSlice,
  setCount,
  setPickItem,
} = actions;

export default reducer;
export const { selectAll } = imgAdapter.getSelectors<RootState>(
  (state) => state.img
);
