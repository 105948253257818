import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import auth from "./slices/auth.slice";
import category from "./slices/category.slice";
import highlights from "./slices/highlights.slice";
import users from "./slices/users.slice";
import account from "./slices/account.slice";
import rootSaga from "./sagas";
import subspec from "./slices/subspec.slice";
import subcategory from "./slices/subcategory.slice";
import product from "./slices/product_main.slice";
import comments from "./slices/comments.slice";
import productWithStatus from "./slices/product_with_status.slice";
import spec_name from "./slices/spec_name.slice";
import spec_value from "./slices/spec_value.slice";
import product_spec from "./slices/product_spec.slice";
import orders from "./slices/orders.slice";
import img from "./slices/image.slice";
import file from "./slices/file.slice";
import video from "./slices/video.slice";
import importXLSX from "./slices/importXLSX.slice";
import xlsx_product from "./slices/xlsx_product.slice";
import productWithCountStatus from "./slices/count_product_with_status.slice";
import creditCard from "./slices/creditCard.slice";


const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    product,
    auth,
    category,
    highlights,
    users,
    account,
    subspec,
    subcategory,
    comments,
    productWithStatus,
    spec_name,
    spec_value,
    product_spec,
    orders,
    img,
    file,
    video,
    importXLSX,
    xlsx_product,
    productWithCountStatus,
    creditCard,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  //devTools: process.env.NODE_ENV !== 'production',
  devTools: true,
});

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
