import React, { ReactNode, useDeferredValue, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Image, Table, Tooltip, Spin, notification } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { BsCardImage } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import { Select } from "antd";
import { Form, Input, Radio } from "antd";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { LiaCartPlusSolid } from "react-icons/lia";
import type { ColumnsType } from "antd/es/table";
import {
  selectAll,
  selectAll as selectAllCategory,
} from "../../redux/slices/category.slice";
import testIcon from "../../assets/imgs/calendar-event.png";
import styles from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import CategoryList from "../../components/CategoryList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { BottomFixBlock } from "../../components/OneProductDescAdmin";
import {
  editSuccess,
  setPickItem,
  editError,
} from "../../redux/slices/subcategory.slice";

import ResponseStatusComponent from "../../components/ResponseStatusComponent";
type LayoutType = Parameters<typeof Form>[0]["layout"];
const sizeIcon = 24;

const getDataForSelect = (
  data: any | null,
  fieldForLabel: string
): Array<{}> => {
  if (Array.isArray(data))
    return data.map((item) => ({ value: item.id, label: item[fieldForLabel] }));
  return [];
};
export function OneCategory() {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
  const params = useParams();
  const [form] = Form.useForm();
  const id = params.idCategory;
  const dispatch = useDispatch();
  const [errorImg, setErrorImg] = useState<null | string>(null);
  const categories = useAppSelector(selectAllCategory);
  const { loadingList } = useAppSelector((state) => state.category);
  const { error, loading, success, pickItem } = useAppSelector(
    (state) => state.subcategory
  );

  const [api, contextHolder] = notification.useNotification();
  const options = getDataForSelect(categories, "name");
  const navigate = useNavigate();
  const [valueSelect, setValueSelect] = useState<null | {
    label: ReactNode;
    value: string;
  }>();
  const onSearch = () => console.log("hello");
  const [openListArea, setOpenListArea] = useState(false);
  const [file, setFile] = useState<File | null>();
  const idCategory = params.idCategory;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch({ type: "GET_ALL_AREA" });
    if (idCategory && idCategory !== "null") {
      dispatch({ type: "GET_ONE_SUBCATEGORY", payload: { id: idCategory } });
    }

    return () => {
      dispatch(setPickItem(null));
      dispatch(editError(null));
      dispatch(editSuccess(null));
    };
  }, []);

  useEffect(() => {
    if (pickItem) setPreviewImage(pickItem?.image_url);
  }, [pickItem]);
  console.log(error);
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
      : null;

  const buttonItemLayout =
    formLayout === "horizontal"
      ? { wrapperCol: { span: 14, offset: 4 } }
      : null;

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    console.log("handleChange");
    setFileList(newFileList);
    const file = newFileList[newFileList.length - 1];
    file && (await handlePreview(file));
    console.log(file);
  };

  const deleteImage = () => {
    setFileList([]);
    setPreviewImage("");
    setFile(null);
  };

  const handleChangeSelect = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    setValueSelect(value);
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  const handleDrop = (event: React.DragEvent) => {
    console.log("handleDrop");
  };

  const closeListCategory = () => {
    setOpenListArea(false);
  };

  const getPayload = () => {
    const dataForm = form.getFieldsValue();
    let formData: any = new FormData();
    let id: undefined | string = pickItem?.id;
    formData.append("name", dataForm.name);
    if (file) formData.append("file", file);
    formData.append("category_id", dataForm.category_id);
    if (id) formData.append("id", id);

    return {
      bodyInFormData: formData,
      name: dataForm.name,
      id,
      category_id: dataForm.category_id,
    };
  };

  const onSave = () => {
    dispatch(editError(null));
    dispatch(editSuccess(null));
    if (!file && !previewImage) {
      setErrorImg("Please upload image");
      return;
    }
    try {
      form.validateFields();
      dispatch({
        type: !pickItem ? "ADD_SUBCATEGORY" : "EDIT_SUBCATEGORY",
        payload: getPayload(),
      });
      console.log("validate");
    } catch (e) {}
  };

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 3,
      });

      if (!pickItem) {
        form.resetFields();
        setFileList([]);
        setPreviewImage("");
        setFile(null);
      }
      if (success === "The subcategory has been deleted") {
        navigate("/admin/categories");
      }
      //dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: <div className={styles.error}>{error}</div>,
        duration: 10,
      });
      dispatch(editError(null));
    }
  }, [error]);

  useEffect(() => {
    setErrorImg(null);
  }, [file]);

  useEffect(() => {
    if (pickItem) {
      console.log(form.getFieldsValue());
      form.setFieldValue("name", pickItem.name);
      form.setFieldValue("category_id", pickItem?.category.id);
    }
  }, [pickItem]);

  const customRequest = (options: any) => {
    setFile(options.file);
  };

  const deleteSubCategory = () => {
    dispatch({
      type: "DELETE_SUBCATEGORY",
      payload: { id: pickItem?.id, name: pickItem?.name },
    });
  };
  const confirmDeleteSubCategory = () => {
    setOpenModal(true);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.product}>
      {contextHolder}
      <Modal
        title={`Delete ${pickItem?.name}`}
        open={openModal}
        onOk={deleteSubCategory}
        onCancel={handleCancel}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div> Are you sure you want to delete the subcategory?</div>
          <div>All products will be deleted</div>
        </div>
      </Modal>
      <div className={styles.title}>
        <div>
          {params.idCategory !== "null"
            ? "Edit subcategory"
            : "Add  subcategory"}
        </div>
        {!openListArea && (
          <div className={styles.listButtons}>
            {params.idCategory !== "null" && (
              <Button
                onClick={confirmDeleteSubCategory}
                className={styles.button}
              >
                <RiDeleteBinLine size={sizeIcon - 2} />
                <div> Delete subcategory</div>
              </Button>
            )}

            <Button
              onClick={() => setOpenListArea(true)}
              className={styles.button}
            >
              <FaEnvelopeOpenText size={sizeIcon - 7} />
              <div> Show all category</div>
            </Button>
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.leftPart}>
          <div className={styles.header}>
            <div className={styles.header__text}>
              <div>Images</div>
              <div></div>
            </div>
          </div>
          {previewImage && (
            <div className={styles.close} onClick={deleteImage}>
              <AiOutlineClose size={24} />
            </div>
          )}
          <div className={styles.img_block}>
            {previewImage ? (
              <div className={styles.img}>
                <img
                  alt="example"
                  style={{ width: "100%", height: "100%" }}
                  src={previewImage}
                />
              </div>
            ) : (
              <Upload
                maxCount={1}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onDrop={handleDrop}
                className={previewImage ? styles.upload_preview : styles.upload}
                customRequest={customRequest}
              >
                <div>
                  <BsCardImage size={70} color="#878D9C" />
                </div>
              </Upload>
            )}
          </div>
          <ResponseStatusComponent error={errorImg} />
        </div>

        <div className={styles.rightPart}>
          <div className={styles.header__text}>Details</div>
          <Form
            {...formItemLayout}
            //layout={formLayout}
            form={form}
            initialValues={{ layout: formLayout }}
            onValuesChange={onFormLayoutChange}
            className={styles.form}
            layout="vertical"

            ///style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
          >
            <div className={styles.form__row}>
              <Form.Item
                label="Name"
                name="name"
                className={styles.form__item}
                rules={[{ required: true, message: "Please input name!" }]}
              >
                <Input
                  placeholder="Enter name"
                  className={styles.form__input}
                  defaultValue={pickItem?.name}
                />
              </Form.Item>
              <Form.Item
                label="Category"
                name="category_id"
                className={styles.form__item}
                rules={[{ required: true, message: "Please select category!" }]}
              >
                <Select
                  className={styles.select}
                  //labelInValue
                  notFoundContent={loadingList ? <Spin size="small" /> : null}
                  onChange={handleChangeSelect}
                  options={options}
                  loading={loadingList}
                  placeholder="Pick category"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        {openListArea && <CategoryList closeListCategory={closeListCategory} />}
      </div>
      <BottomFixBlock>
        <div className={styles.buttons} onClick={onSave}>
          <Button loading={loading}>Save changes </Button>
        </div>
      </BottomFixBlock>
    </div>
  );
}

export default OneCategory;
