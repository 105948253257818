import { takeLeading, call, put } from "redux-saga/effects";
import { commentsService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllComments,
  addOneComments,
  updateOneComments,
  removeOneComments,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearCommentsSlice,
  setCount,
  CommentI,
} from "../slices/comments.slice";
import { jwtDecode, JwtPayload } from "jwt-decode";

function* getAll(
  action: PayloadAction<{ page?: number; params?: string; withText: boolean }>
) {
  yield put(editLoadingList(true));
  try {
    const response: { rows: CommentI[]; count: number } = yield call(
      commentsService[action.payload.withText ? "getAllWithText" : "getAll"],
      action?.payload?.params || action.payload
    );
    yield put(setCount(response.count));
    yield put(setAllComments(response.rows));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  /*   yield put(editLoading(true));
  try {
    const response: {} = yield call(categoryService.getOne, action.payload);
    //yield put(removeOneCategory(action.payload));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  } */
}

function* editOne(action: PayloadAction<{ id: string; status: string }>) {
  yield put(editLoading(true));
  try {
    const response: {} = yield call(
      commentsService.updateStatus,
      action.payload.id,
      { status: action.payload },
      "PATCH"
    );
    yield put(updateOneComments(response));
    //yield put(editSuccess(`The spec ${name}  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

interface CommentCreateI {
  user_id?: string;
  product_id: string;
  text?: string;
  stars: string;
}
function* addOne(action: PayloadAction<CommentCreateI>) {
  yield put(editLoading(true));
  try {
    const body: CommentCreateI = action.payload;
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const data = jwtDecode<{ exp: number; id: string; role: string }>(
        accessToken
      );
      body["user_id"] = data.id;
    }

    const response: {} = yield call(commentsService.create, body);
    console.log("response");
    yield put(
      editSuccess(
        "Thanks, for your feedback.The comment has been sent to administrator to approving"
      )
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  try {
    const response: {} = yield call(commentsService.delete, action.payload);
    yield put(removeOneComments(action.payload));
    yield put(setCount(-1));
    yield put(editSuccess("The feedback has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchCommentSaga() {
  yield takeLeading("GET_ALL_COMMENT", getAll);
  yield takeLeading("GET_ONE_COMMENT", getOne);
  yield takeLeading("ADD_COMMENT", addOne);
  yield takeLeading("EDIT_COMMENT", editOne);
  yield takeLeading("DELETE_COMMENT", deleteOne);
}
