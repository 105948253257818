import { Tooltip, Pagination, Modal, notification, Select } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "./index.module.scss";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  CommentI,
  editError,
  editSuccess,
  selectAll as selectAllComments,
  statusComment,
} from "../../redux/slices/comments.slice";
import OneFeedback from "../../components/OneFeedback";
import { pageSize } from "../../variables";
import { useSelector } from "react-redux";
import { selectAll as selectAllUsers } from "../../redux/slices/users.slice";
import { selectAll as selectAllProducts } from "../../redux/slices/product_main.slice";
import FilterSelect from "../../components/FilterSelect";
import { MdExpandMore } from "react-icons/md";
const status_options = [
  {
    value: "Approved",
    label: "Approved",
  },
  {
    value: "Waiting",
    label: "Waiting",
  },
];

export function ProductFeedbackAdmin() {
  const dispatch = useAppDispatch();
  const comments = useAppSelector(selectAllComments);
  const { count, success, error } = useAppSelector((state) => state.comments);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickComment, setPickCommnet] = useState<null | CommentI>(null);
  const [api, contextHolder] = notification.useNotification();
  const users = useAppSelector(selectAllUsers);
  const product = useAppSelector(selectAllProducts);
  const { count: count_user } = useAppSelector((state) => state.users);
  const { count: count_product } = useAppSelector((state) => state.product);
  const [status, setStatus] = useState<statusComment | null>(null);
  const [filterUserId, setFilterUserId] = useState<string | null>(null);
  const [filterProductId, setFilterProductId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch({ type: "GET_ALL_COMMENT" });
    dispatch({ type: "GET_ALL_USERS" });
    dispatch({ type: "GET_ALL_PRODUCT" });
  }, []);

  useEffect(() => {
    if (success) {
      handleCancel();
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 10,
      });
      dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      handleCancel();
      api.error({
        message: <div className={styles.error}>{error}</div>,
        duration: 10,
      });
      dispatch(editError(null));
    }
  }, [error]);

  useEffect(() => {
    getMoreComments();
  }, [filterUserId, filterProductId, status]);

  const getMoreComments = (page: number = 1) => {
    let params = "?";
    if (status) params += `sort=status&search=${status}&`;
    if (filterUserId) params += `sort=user_id&search=${filterUserId}&`;
    if (filterProductId) {
      params += `sort=product_id&search=${filterProductId}&`;
    }
    params += `page=${page}&take=${pageSize}&`;
    dispatch({ type: "GET_ALL_COMMENT", payload: { params } });
    setPage(page);
  };

  const changeStatus = (comment_id: string, currentStatus: statusComment) => {
    dispatch({
      type: "EDIT_COMMENT",
      payload: {
        id: comment_id,
        status: currentStatus === "Approved" ? "Waiting" : "Approved",
      },
    });
  };

  const onChangePage = (page: any) => {
    getMoreComments(page);
  };

  const showModal = (fb: CommentI) => {
    setPickCommnet(fb);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch({ type: "DELETE_COMMENT", payload: pickComment?.id });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPickCommnet(null);
  };
  const onChangeSelect = (value: statusComment) => {
    setStatus(value);
  };

  const setValueUserId = (id: string) => {
    setFilterUserId(id);
  };

  const setValueProductId = (id: string) => {
    setFilterProductId(id);
  };

  return (
    <div className={styles.product}>
      {contextHolder}
      <div className={styles.title}>Feedback management</div>
      <div className={styles.filter}>
        <div className={styles.filterTitle}>Filter: </div>
        <FilterSelect
          data={users}
          count={count_user}
          dispatchType="GET_ALL_USERS"
          suffixIcon="user"
          setValue={setValueUserId}
        />

        <FilterSelect
          data={product}
          count={count_product}
          dispatchType="GET_ALL_PRODUCT"
          suffixIcon="product"
          setValue={setValueProductId}
        />
        <Select
          className={styles.select}
          value={status}
          onChange={onChangeSelect}
          style={{ width: "100%" }}
          options={status_options}
          allowClear
          suffixIcon={
            <div className={styles.suffix} style={{ marginRight: 10 }}>
              <div>status</div>
              <MdExpandMore size={24} />
            </div>
          }
        />
      </div>
      <div className={styles.feedback}>
        {comments.map((fb) => (
          <OneFeedback
            isAdmin={true}
            fb={fb}
            header={() => (
              <div className={styles.feedback__header}>
                <div
                  className={styles.feedback__status}
                  onClick={() => changeStatus(fb.id, fb.status)}
                >
                  <Tooltip title="Click for change status">
                    {fb.status !== "Waiting" ? (
                      <div className={styles.feedback__status_active}>
                        Approved
                      </div>
                    ) : (
                      <div className={styles.feedback__status_notactive}>
                        Waiting approved
                      </div>
                    )}
                  </Tooltip>
                </div>
                <div
                  className={styles.feedback__delete}
                  onClick={() => showModal(fb)}
                >
                  <Tooltip title="Click for delete">
                    <AiOutlineDelete size={22} />
                  </Tooltip>
                </div>
              </div>
            )}
          />
        ))}
      </div>
      <Pagination
        current={page}
        defaultCurrent={1}
        total={count}
        pageSize={pageSize}
        className={styles.pagination}
        onChange={onChangePage}
      />
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div style={{ textAlign: "center" }}>
          Are you sure you want delete this feedback?
        </div>
      </Modal>
    </div>
  );
}

export default ProductFeedbackAdmin;
