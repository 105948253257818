import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Details from "../../pages/Details";
import MenuAccount from "../../components/MenuAccount";
import type { MenuProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineAccountCircle } from "react-icons/md";
import { BsCartPlus } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import Deliveries from "../../pages/Deliveries";
import Purchases from "../../pages/Purchases";
import { Select, Button, Modal } from "antd";
import { LiaSaveSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  RiWallet3Line,
  RiLogoutBoxRLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";

const colorIcon = "#878D9C";
const sizeIconAI = "30px";
const sizeIconLia = "32px";
const sizeIconAcc = 18;
const colorBage = "#005FEF";

function Component1() {
  return (
    <div className={styles.oneIcon}>
      <AiOutlineHeart color={colorIcon} size={sizeIconAcc} />
      <span className={styles.textIcon}>Details</span>
    </div>
  );
}
function Component2() {
  return (
    <div className={styles.oneIcon}>
      <BsCartPlus color={colorIcon} size={sizeIconAcc} />
      <span className={styles.textIcon}>History of Purchases</span>
    </div>
  );
}
function Component3() {
  return (
    <div className={styles.oneIcon}>
      <TbTruckDelivery color={colorIcon} size={sizeIconAcc + 2} />
      <span className={styles.textIcon}>Deliveries</span>
    </div>
  );
}
const items = [
  {
    key: "account",
    label: <Component1 />,
  },
  {
    key: "purchases",
    label: <Component2 />,
  },
  {
    key: "deliveries",
    label: <Component3 />,
  },
];

export function OneUser() {
  const [current, setCurrent] = useState<
    "account" | "purchases" | "deliveries"
  >("account");
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { dataUser } = useAppSelector((state) => state.account);
  const { loading, error, success } = useAppSelector((state) => state.users);
  const [chаnge, setChange] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setDefaultStatus({ value: dataUser?.status, label: dataUser?.status });
  }, [dataUser]);

  const [defaultValueStatus, setDefaultStatus] = useState<any>({
    value: "Active",
    label: "Active",
  });

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key as "account" | "purchases" | "deliveries");
    setSubMenuOpen(true);
  };
  const handleChangeSelect = (value: {
    value: string;
    label: React.ReactNode;
  }) => {
    setDefaultStatus(value);
    setChange(true);
  };

  const params = useParams();
  const onSave = () => {
    if (chаnge) {
      const payload = {
        ...dataUser,
        status: defaultValueStatus.value,
      };
      dispatch({ type: "EDIT_USERS", payload });
    }
  };

  console.log("OneUSer");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteUser = () => {
    dispatch({ type: "DELETE_USERS", payload: dataUser?.id });
  };

  return (
    <>
      {/*       <div className={styles.wrapperButtonD}>
        <Button
          type="default"
          htmlType="submit"
          className={[styles.button__delete, styles.button].join(" ")}
          onClick={onSave}
          loading={loading}
          onClickCapture={() => setIsModalOpen(true)}
        >
          <RiDeleteBin6Line size={18} />
          <span>Delete</span>
        </Button>
      </div> */}
      <div className={styles.account}>
        {/*         <div className={styles.account_menu}>
          <div className={styles.title}> Sam Smith </div>
          <Menu
            style={{ background: "transparent", border: "none" }}
            onClick={onClick}
            selectedKeys={[current]}
            mode="vertical"
            items={items}
          />
        </div> */}
        <div className={styles.account_content}>
          {current === "account" && (
            <Details>
              <div className={styles.header}>
                <div className={styles.header__text}>
                  <div>Status</div>
                  <div>Here you can change status of the user</div>
                </div>
                <div className={styles.header__select}>
                  <Select
                    loading={loading}
                    labelInValue
                    defaultValue={defaultValueStatus}
                    className={styles.select}
                    //style={{ width: "250px" }}
                    onChange={handleChangeSelect}
                    value={defaultValueStatus}
                    options={[
                      {
                        value: "Active",
                        label: "Active",
                      },
                      {
                        value: "Disabled",
                        label: "Disabled",
                      },
                    ]}
                  />
                </div>
                {
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.button}
                    onClick={onSave}
                    loading={loading}
                  >
                    <LiaSaveSolid size={24} />
                    <span>Save</span>
                  </Button>
                }
              </div>
            </Details>
          )}
          {current === "purchases" && <Purchases />}
          {current === "deliveries" && <Deliveries />}
        </div>
      </div>

      <Modal
        title={"Delete user with name " + dataUser?.name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Are you sure you want delete the user?
          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            onClick={deleteUser}
            loading={loading}
            style={{ marginTop: 10 }}
          >
            <RiDeleteBin6Line size={20} />
            <span>Delete</span>
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default OneUser;
