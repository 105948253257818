import {call, put, takeLeading} from "redux-saga/effects";
import {
    editLoading,
    editError,
    editSuccess, setCreditCards, deleteOneCard, addCreditCard,
} from "../slices/creditCard.slice";
import {addOneCreditCard, deleteOneCreditCard, getCreditCards} from "../../services/api";
import {PaymentMethod} from "../../components/OneProductDescAdmin/type";
import {PayloadAction} from "@reduxjs/toolkit";


function* setPayments() {
    yield put(editLoading(true));
    yield put(editError(''));
    yield put(editSuccess(''));

    try {
        const response: PaymentMethod[] = yield call(getCreditCards)
        console.log("responseSaga", response);
        yield put(setCreditCards(response));

    } catch (error: unknown) {
        if (typeof error === "string") {
            yield put(editError(error));
        } else {
            yield put(
                editError("Something has gone wrong. Please try again later")
            );
        }
    } finally {
        yield put(editLoading(false));
    }
}

function* deletePayment(action: PayloadAction<string>,) {
    yield put(editLoading(true));
    yield put(editError(''));
    yield put(editSuccess(''));
    const card_id = action.payload
    try {
        const response: PaymentMethod = yield call(deleteOneCreditCard, card_id)
        if(response){
            yield put(deleteOneCard(card_id));
        }
        console.log("responseSaga", response);

    } catch (error: unknown) {
        if (typeof error === "string") {
            yield put(editError(error));
        } else {
            yield put(
                editError("Something has gone wrong. Please try again later")
            );
        }
    } finally {
        yield put(editLoading(false));
    }
}

function* addNewCard(action: PayloadAction<string>) {
    yield put(editLoading(true));
    yield put(editError(''));
    yield put(editSuccess(''));
    const card_id = action.payload
    try {
        const response: PaymentMethod = yield call(addOneCreditCard, card_id)
        if(response){
            yield put(addCreditCard(response));
        }
        console.log("responseSaga", response);

    } catch (error: unknown) {
        if (typeof error === "string") {
            yield put(editError(error));
        } else {
            yield put(
                editError("Something has gone wrong. Please try again later")
            );
        }
    } finally {
        yield put(editLoading(false));
    }
}

export default function* watchCreditCardSaga() {
    yield takeLeading("GET_PAYMENTS", setPayments);
    yield takeLeading("DELETE_PAYMENT", deletePayment);
    yield takeLeading("ADD_NEW_CARD", addNewCard);
}