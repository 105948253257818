import "./App.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import GoodDesc from "./pages/GoodDesc";

import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
  useParams,
} from "react-router-dom";
import Main from "./pages/Main";
import "./assets/fonts/fonts.css";
import { Spin } from "antd";
import Likes from "./pages/Likes";
import Cart from "./pages/Cart";
import Wrapper from "./components/Wrapper";
import Details from "./pages/Details";
import Purchases from "./pages/Purchases";
import Deliveries from "./pages/Deliveries";
import Payment from "./pages/Payment";
import WrapperAdmin from "./components/WrapperAdmin";
import UserManagement from "./pages_admin/UserManagement";
import SubCategoryManagement from "./pages_admin/SubCategoryManagement";
import ProductManagement from "./pages_admin/AllProducts";
import SpecsManagement from "./pages_admin/SpecsManagement";
import LegalManagement from "./pages_admin/LegalManagement";
import OneProduct from "./components/OneProductDescAdmin";
import OneSpec from "./pages_admin/OneSpec";
import OneCategory from "./pages_admin/OneCategory";
import OneUSer, { OneUser } from "./pages_admin/OneUser";
import ManageOneProduct from "./pages_admin/ManageOneProduct";
import AllProducts from "./pages_admin/AllProducts";
import AuthAdmin from "./pages_admin/AuthAdmin";
import { useAppSelector } from "./redux/ts/hooks/hooks";
/* import { createBrowserHistory } from "history";
const history = createBrowserHistory(); */
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  editAuth,
  editIsAdmin,
  editLoadingApp,
} from "./redux/slices/auth.slice";
import ProductFeedbackAdmin from "./pages_admin/ProductFeedbackAdmin";
import OrderManagment from "./pages_admin/OrderManagment";
import ComparisonList from "./pages/ComparisonList";
import PageOneOrder from "./pages/PageOneOrder";
import ArchiveManagment from "./pages_admin/ArchiveManagment";
import ProductImportManagement from "./pages_admin/ProductImportManagement";
import ListFolders from "./pages_admin/ListFolders";
import AllXLSXProducts from "./pages_admin/AllXLSXProducts";
import ManageOneXLSXProduct from "./pages_admin/ManageOneXLSXProduct";
import OrderDescription from "./components/OrderDescription";
import '@stripe/react-stripe-js'

const likesItems = [
  {
    title: <a href="/">Home</a>,
  },
  {
    title: "My Likes",
  },
];

const cartItems = [
  {
    title: <a href="/">Home</a>,
  },
  {
    title: "My Cart",
  },
];

const googItems = [
  {
    title: <a href="/">Home</a>,
  },
  {
    title: "One good",
  },
];

const accountItems = [
  {
    title: <a href="/">Home</a>,
  },
  {
    title: "My account",
  },
];

function App() {
  /* const dispatch = useDispatch(); */
  const { isAdmin, isAuthorized, loadingApp } = useAppSelector(
    (state) => state.auth
  );
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "AUTH_USER" });
  }, []);

  const isAuth = isAuthorized;
  const getPath = (): string => {
    if (isAdmin) return "/admin";
    return "/";
  };

  useEffect(() => {
    const handleWindowBeforeUnload = () => {
      localStorage.setItem("test", "close");
    };
    window.addEventListener("beforeunload", handleWindowBeforeUnload);
    return () =>
      window.removeEventListener("beforeunload", handleWindowBeforeUnload);
  }, []);

  return (
    <BrowserRouter>
      {loadingApp ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Wrapper BaseComponent={Main} />} />
          <Route
            path="/c/:categoryName/sbc/:subCategoryName?"
            element={<Wrapper BaseComponent={Main} />}
          />
          <Route
            path="/likes"
            element={<Wrapper BaseComponent={Likes} items={likesItems} />}
          />
          <Route
            path="/compare"
            element={<Wrapper BaseComponent={ComparisonList} />}
          />
          <Route
            path="/cart"
            element={<Wrapper BaseComponent={Cart} items={cartItems} />}
          />
          <Route
            path="/c/:categoryName/sb/:subCategoryName/g/:goodId/:keyMenu?"
            element={<Wrapper BaseComponent={GoodDesc} items={googItems} />}
          />

          {isAuth && (
            <>
              <Route
                path="/account"
                element={
                  <Wrapper BaseComponent={Details} items={accountItems} />
                }
              />
              <Route
                path="/account/purchases/:idOrder?"
                element={
                  <Wrapper BaseComponent={Deliveries} items={accountItems} />
                }
              />
              <Route
                path="/account/deliveries/:idOrder?"
                element={
                  <Wrapper BaseComponent={Deliveries} items={accountItems} />
                }
              />
              <Route
                path="/account/payment"
                element={
                  <Wrapper BaseComponent={Payment} items={accountItems} />
                }
              />
            </>
          )}
          {isAuth && isAdmin && (
            <>
              <Route
                path="/admin"
                element={<WrapperAdmin BaseComponent={UserManagement} />}
              />
              <Route
                path="/admin/users/:idUser"
                element={<WrapperAdmin BaseComponent={OneUser} />}
              />
              <Route
                path="admin/categories"
                element={<WrapperAdmin BaseComponent={SubCategoryManagement} />}
              />
              <Route
                path="admin/categories/:idCategory"
                element={<WrapperAdmin BaseComponent={OneCategory} />}
              />
              <Route
                path="admin/products"
                element={<WrapperAdmin BaseComponent={AllProducts} />}
              />
              <Route
                path="admin/xlsxProducts"
                element={<WrapperAdmin BaseComponent={AllXLSXProducts} />}
              />
              <Route
                path="admin/xlsxProducts/:idProduct"
                element={<WrapperAdmin BaseComponent={ManageOneXLSXProduct} />}
              />
              <Route
                path="admin/products/:idProduct/:keyMenu?"
                element={<WrapperAdmin BaseComponent={ManageOneProduct} />}
              />

              <Route
                path="admin/filters"
                element={<WrapperAdmin BaseComponent={SpecsManagement} />}
              />
              <Route
                path="admin/filters/:idFilter"
                element={<WrapperAdmin BaseComponent={OneSpec} />}
              />

              <Route
                path="admin/comments"
                element={<WrapperAdmin BaseComponent={ProductFeedbackAdmin} />}
              />
              <Route
                path="/admin/orders"
                element={<WrapperAdmin BaseComponent={OrderManagment} />}
              />
              <Route
                path="/admin/archive/:keyMenu?"
                element={<WrapperAdmin BaseComponent={ArchiveManagment} />}
              />
              <Route
                path="/admin/orders/:idOrder"
                element={<WrapperAdmin BaseComponent={PageOneOrder} />}
              />
              <Route
                path="admin/legal"
                element={<WrapperAdmin BaseComponent={LegalManagement} />}
              />
              <Route
                path="/admin/importFiles"
                element={<WrapperAdmin BaseComponent={ListFolders} />}
              />
              <Route
                path="/admin/importFiles/:nameFiles"
                element={
                  <WrapperAdmin BaseComponent={ProductImportManagement} />
                }
              />
            </>
          )}

          {!isAuth && <Route path="/admin" element={<AuthAdmin />} />}
          <Route path="/*" element={<Navigate replace to={getPath()} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
//export { history };
