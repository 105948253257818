import React, { useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  Radio,
  Form,
  Checkbox,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { RadioChangeEvent } from "antd";
import AuthCode from "react-auth-code-input";

const { Search } = Input;

type FieldType = {
  email?: string;
};

const emailValidator = (_: any, value:any) => {
  if (!value) {
    return Promise.reject(new Error('Please input your email!'));
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cyrillicRegex = /[\u0400-\u04FF]/;

  if (cyrillicRegex.test(value)) {
    return Promise.reject(new Error('Email should not contain Cyrillic letters!'));
  }

  if (!emailRegex.test(value)) {
    return Promise.reject(new Error('The input is not a valid email!'));
  }

  return Promise.resolve();
};

function ForgotPasswordForm() {
  const [openPageSendCode, setOpenPageSentCode] = useState<boolean>(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
    setOpenPageSentCode(true);
  };

  const [result, setResult] = useState<string>("");
  const handleOnChange = (res: string) => {
    setResult(res);
  };

  const resendCode = () => {
    console.log("resendCode");
  };

  const sendCode = () => {
    console.log("sentcode");
  };
  return (
    <>
      {!openPageSendCode && (
        <Form
          name="auth"
          style={{ maxWidth: 600 }}
          layout="vertical"
          //onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item<FieldType>
            name="email"
            label="Email"
            className={styles.input_wrapper}
            rules={[{ validator: emailValidator }]}
          >
            <Input className={styles.input} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
      {openPageSendCode && (
        <>
          <div className={styles.code}>
            <AuthCode
              onChange={handleOnChange}
              allowedCharacters="numeric"
              length={6}
            />
          </div>

          <div className={styles.forgot_password} onClick={resendCode}>
            resend code
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.button}
            onClick={sendCode}
          >
            Submit
          </Button>
        </>
      )}
    </>
  );
}

export default ForgotPasswordForm;
