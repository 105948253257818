import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";

export interface SpecNameI {
  id: string;
  name: string;
  specValues: { id: string; name: string }[];
  category: { id: string; name: string } | null;
  subCategory: { id: string; name: string } | null;
}
export const specNameAdapter = createEntityAdapter<SpecNameI>({
  selectId: (spec_name) => spec_name.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | SpecNameI;
  specialItemsForProduct: {
    brand: null | { id: string; name: string }[];
    supplier: null | { id: string; name: string }[];
  };
  loadingSpecialItemsForProduct: boolean;
  successSpecialItemsForProduct: null | string;
  errorSpecialItemsForProduct: null | string;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
  specialItemsForProduct: {
    brand: null,
    supplier: null,
  },
  loadingSpecialItemsForProduct: false,
  successSpecialItemsForProduct: null,
  errorSpecialItemsForProduct: null,
};
export const initialState = specNameAdapter.getInitialState(init);

const specNameSlice = createSlice({
  name: "spec_name",
  initialState,
  reducers: {
    setAllSpecName: (state, action) => {
      specNameAdapter.setAll(state, action.payload);
    },
    addOneSpecName: (state, action) => {
      specNameAdapter.addOne(state, action.payload);
    },
    addManySpecName: (state, action) => {
      specNameAdapter.addMany(state, action.payload);
    },
    updateOneSpecName: (state, action) => {
      specNameAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneSpecName: (state, action) => {
      specNameAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | SpecNameI>) => {
      state.pickItem = action.payload;
    },
    setSpecialItemsForProduct: (
      state,
      action: PayloadAction<null | {
        brand?: { id: string; name: string }[] | null;
        supplier?: { id: string; name: string }[] | null;
      }>
    ) => {
      if (action.payload === null) {
        state.specialItemsForProduct = {
          brand: null,
          supplier: null,
        };
      }
      if (action.payload?.brand || action.payload?.supplier) {
        state.specialItemsForProduct = {
          ...state.specialItemsForProduct,
          ...action.payload,
        };
      }
    },
    editLoadingSpecialItemsForProduct: (state, action) => {
      state.loadingSpecialItemsForProduct = action.payload;
    },
    editErrorSpecialItemsForProduct: (state, action) => {
      state.errorSpecialItemsForProduct = action.payload;
    },
    editSuccessSpecialItemsForProduct: (state, action) => {
      state.successSpecialItemsForProduct = action.payload;
    },
  },
});

const { actions, reducer } = specNameSlice;

export const {
  setAllSpecName,
  addOneSpecName,
  addManySpecName,
  updateOneSpecName,
  removeOneSpecName,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  setSpecialItemsForProduct,
  editLoadingSpecialItemsForProduct,
  editErrorSpecialItemsForProduct,
  editSuccessSpecialItemsForProduct,
} = actions;

export default reducer;
export const { selectAll } = specNameAdapter.getSelectors<RootState>(
  (state) => state.spec_name
);
