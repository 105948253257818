import { CommonService } from "./CommonService";
import { RequestService } from "./RequestService";
import jwt_decode from "jwt-decode";
import { TBodyUpdateContact, IBodyUpdateAboutMe } from "./interfaces";
import { pageSize } from "../variables";

export class CommentService extends CommonService {
  constructor() {
    super("comments");
  }

  getAllWithText = (queryParams?: {} | string) =>
    this.request({
      method: "get",
      path: "withText",
      queryParams:
        typeof queryParams !== "string"
          ? {
              page: 1,
              take: pageSize,
              ...queryParams,
            }
          : queryParams,
    });

  updateStatus = (id: string, body: {}, method?: "PATCH", headers?: {}) =>
    this.request({
      method: method || "put",
      id,
      body,
      headers,
      path: "status",
    });
}
