import { takeLeading, call, put } from "redux-saga/effects";
import { subCategoriesService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllSubcategory,
  addOneSubcategory,
  updateOneSubcategory,
  removeOneSubcategory,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  PickSubCategoryI,
} from "../slices/subcategory.slice";
import { setPickSubCategory } from "../slices/product_main.slice";

function* getAll(
  action: PayloadAction<{ sort?: string; search?: string; page?: number }>
) {
  //++
  yield put(editLoadingList(true));
  try {
    const response: { rows?: []; count: number } = yield call(
      subCategoriesService.getAll,
      action.payload
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllSubcategory(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<{ id: string } | { name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    let id = null;
    let name = null;
    let method: "getOne" | "getByName" = "getOne";
    let payload = "";
    if ("name" in action.payload) {
      name = action.payload.name;
      method = "getByName";
      payload = name;
    } else {
      id = action.payload.id;
      payload = id;
    }

    const response: PickSubCategoryI = yield call(
      subCategoriesService[method],
      payload
    );
    console.log("response", response);
    yield put(setPickSubCategory(response));
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* editOne(
  action: PayloadAction<{
    id: string;
    name: string;
    category_id: string;
    bodyInFormData: {};
  }>
) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const body = action.payload.bodyInFormData;
    const headers = { "Content-Type": "multipart/form-data" };
    const id = action.payload.id;
    yield call(subCategoriesService.update, id, body, "PATCH", headers);
    const response: PickSubCategoryI = yield call(
      subCategoriesService.getOne,
      id
    );
    yield put(setPickItem(response));
    yield put(updateOneSubcategory(response));
    yield put(editSuccess("The Subcategory has been updated"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(
  action: PayloadAction<{
    name: string;
    category_id: string;
    bodyInFormData: {};
  }>
) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const body = action.payload.bodyInFormData;
    const headers = { "Content-Type": "multipart/form-data" };
    const response: {} = yield call(subCategoriesService.create, body, headers);
    yield put(addOneSubcategory(response));
    yield put(
      editSuccess(`The Subcategory ${action.payload.name}  has been added`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(
      subCategoriesService.delete,
      action.payload.id
    );
    yield put(removeOneSubcategory(action.payload.id));
    yield put(editSuccess("The subcategory has been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAllArchive(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  yield put(editLoadingList(true));
  yield put(editErrorList(null));
  try {
    const response: { rows?: []; count: number } = yield call(
      subCategoriesService.getAllArchive,
      { page: action.payload.page }
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllSubcategory(response.rows ? response.rows : response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* restoreFormArchive(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(
      subCategoriesService.restoreOne,
      action.payload
    );
    console.log("response", response);
    yield put(removeOneSubcategory(action.payload));
    yield put(editSuccess("The subcategory has been restored"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchSubcategorySaga() {
  yield takeLeading("GET_ALL_SUBCATEGORY", getAll);
  yield takeLeading("GET_ONE_SUBCATEGORY", getOne);
  yield takeLeading("ADD_SUBCATEGORY", addOne);
  yield takeLeading("EDIT_SUBCATEGORY", editOne);
  yield takeLeading("DELETE_SUBCATEGORY", deleteOne);

  yield takeLeading("GET_ALL_SUBCATEGORY_ARCHIVE", getAllArchive);
  yield takeLeading("RESTORE_SUBCATEGORY", restoreFormArchive);
}
