import {
    AddressElement,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import styles from "./index.module.scss";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {selectAll} from "../../redux/slices/count_product_with_status.slice";
import {formatAddress, getSum} from "../../utilities/other";
import {useAppDispatch} from "../../redux/ts/hooks/hooks";
import {PaymentMethod} from "../../components/OneProductDescAdmin/type";
import {notification} from "antd";
import CreditCard from "../../components/CreditCard";

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

export interface CreateCardPaymentProps {
    onSuccessCard: (id: string) => void;
}

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [totalPrice, setTotalPrice] = useState(0)
    const allProductsArray = useSelector((state: RootState) => selectAll(state));
    const dispatch = useAppDispatch();
    const [addCard, setAddCard] = useState<boolean>(false)
    const [pick, setPick] = useState<PaymentMethod | null>(null)
    const [address, setAddress] = useState<any>('');
    const [api, contextHolder] = notification.useNotification();
    const [error, setError] = useState('');
    const [succsec, setSuccsec] = useState('');

    const orders = useSelector((state: RootState) => state.orders)
    const {
        loading,
        payments
    } = useSelector((state: RootState) => state.creditCard);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const cardNumberElement = elements?.getElement(CardNumberElement);
        const cardExpiryElement = elements?.getElement(CardExpiryElement);

        if (cardNumberElement) {
            const {error, paymentMethod} = await stripe?.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    name,
                },
            });
            if (!error && paymentMethod?.id) {
                dispatch({type: 'ADD_NEW_CARD', payload: paymentMethod.id});
            } else {
                console.log('Stripe error:', error);
            }
        }
    }
    const handleOrderSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        if (!pick) {
            setError('Please select a payment method.');
            return;
        }
        if (!address || !address.complete) {
            setError('Please complete the address before proceeding with payment.');
            return;
        }
        dispatch({
            type: "CREATE_ORDER", payload: {
                id: pick.id,
                address: formatAddress(address),
                amount: totalPrice * 100
            }
        })
    };

    const cardNumberOptions = {style: elementStyles};
    const cardExpiryOptions = {style: elementStyles};
    const cardCvcOptions = {style: elementStyles};

    useEffect(() => {
        if (allProductsArray.length > 0) {
            setTotalPrice(getSum(allProductsArray));
        } else {
            setTotalPrice(0)
        }
    }, [allProductsArray]);


    useEffect(() => {
        if (orders.success) {
            api.success({
                message: <div className={styles.succes}>{orders.success}</div>,
                duration: 10,
            });
        }
    }, [orders.success]);

    useEffect(() => {
        if (succsec) {
            api.success({
                message: <div className={styles.succes}>{succsec}</div>,
                duration: 10,
            });
        }
    }, [succsec]);

    useEffect(() => {
        if (error) {
            api.error({
                message: <div className={styles.succes}>{error}</div>,
                duration: 10,
            });
        }
    }, [error]);

    useEffect(() => {
        if (orders.error) {
            api.error({
                message: <div className={styles.succes}>{orders.error}</div>,
                duration: 10,
            });
        }
    }, [orders.error]);

    useEffect(() => {
        dispatch({type: "GET_PAYMENTS"})
    }, [allProductsArray]);

    useEffect(() => {
        setPick(payments[payments.length - 1])
    }, [loading]);


    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5,
            partialVisibilityGutter: 40
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            partialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            partialVisibilityGutter: 30
        }
    };

    return (
        <div className={styles.container}>
            {contextHolder}
            <div style={{
                paddingLeft: '10px',
                fontFamily: 'Manrope',
                fontSize: "large",
                fontWeight: "bold",
                paddingBottom: "10px",
            }}>Payment
            </div>
            <Carousel responsive={responsive} partialVisible={true} focusOnSelect={true}>
                {payments && payments.map((payment, index) => (
                    <div
                        className={pick?.id === payment.id ? styles.active : styles.card}
                        onClick={() => {
                            setPick(payment)
                        }} key={payment.id}>
                        {pick && <CreditCard payment={payment} pick={pick}/>}
                    </div>
                ))}

            </Carousel>
            <div className={styles.addNew}>
                <button onClick={() => setAddCard(!addCard)} disabled={loading}
                        className={styles.button}>{loading ? 'Loading...' : addCard ? 'Close' : 'Add new card'}</button>
            </div>
            {addCard &&
                <form onSubmit={handleSubmit} className={styles.paymentForm}>
                    <label htmlFor="card-number-element">Card Number</label>
                    <div className={styles.cardField}>
                        <CardNumberElement options={cardNumberOptions}
                                           id="card-number-element"/>
                    </div>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={styles.inputField}
                        placeholder="Enter your name"

                    />
                    <label htmlFor="card-expiry-element">Expiration Date</label>
                    <div className={styles.cardField}>
                        <CardExpiryElement options={cardExpiryOptions}
                                           id="card-expiry-element"/>
                    </div>
                    <label htmlFor="card-cvc-element">CVC</label>
                    <div className={styles.cardField}>
                        <CardCvcElement options={cardCvcOptions} id="card-cvc-element"/>
                    </div>
                    <button id="submit" disabled={!stripe || loading}
                            className={styles.button}>Add
                        card
                    </button>
                </form>}
            {!addCard && totalPrice !== 0 &&
                <form onSubmit={handleOrderSubmit} className={styles.orderForm}>
                    <AddressElement options={{mode: 'shipping'}}
                                    onChange={(event) => setAddress(event)}/>
                    <button id="submit" disabled={!stripe || totalPrice == 0}
                            className={styles.button}>Pay
                        ${totalPrice}
                    </button>
                </form>
            }
        </div>

    );
};

export default CheckoutForm;

const elementStyles = {
    base: {
        color: '#0e0e0e',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        border: "1px solid transparent",
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    complete: {
        color: '#5388f8',
        iconColor: '#5388f8',
    },
    invalid: {
        color: '#ef0032',
        iconColor: '#ef0032',
    },
};