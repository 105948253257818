import type { ColumnsType } from "antd/es/table";
import { DataType } from "./type";
import styles from "./index.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import { Image } from "antd";

const sizeIcon = 24;

export function getColumns(
  deleteHlFormList: (id: string) => void
): ColumnsType<DataType> {
  return [
    {
      title: <div className={styles.headerColumn}>Icon</div>,
      dataIndex: "icon",
      render: (_, { image_url }) => (
        <Image src={image_url} style={{ width: 30, height: 30 }} />
      ),
    },
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineClose size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, pickHl) => {
        return (
          <div
            className={styles.rightNavbarList_icon}
            onClick={() => deleteHlFormList(pickHl.id)}
          >
            <AiOutlineClose size={sizeIcon - 6} />
          </div>
        );
      },
    },
  ];
}
