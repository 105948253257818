import React, { useState, useEffect } from "react";
import OneGood, {
  listLikeCompareProductByCategoryI,
} from "../../components/Goods/OneGood";

import {
  ProductType,
  removeOneProduct,
} from "../../redux/slices/product_main.slice";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { getUserId } from "../../utilities";
import { pageSize } from "../../variables";
import { Pagination } from "antd";
import {
  deleteCompareItem,
  selectAll as selectAllProductWithStatus,
} from "../../redux/slices/product_with_status.slice";
import { selectAll as selectAllProduct } from "../../redux/slices/product_main.slice";
import {
  selectAll as selectAllProductSpec,
  setAllProductSpec,
} from "../../redux/slices/product_spec.slice";
import { IoMdClose } from "react-icons/io";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateRenderCompare } from "../../redux/slices/product_spec.slice";
import { PrSpI } from "../../redux/sagas/product_with_status.saga";

function ComparisonList() {
  const { isAuthorized } = useAppSelector((state) => state.auth);
  //const [products, setProducts] = useState<ProductType[]>([]);
  const { numberOfLikes } = useAppSelector((state) => state.product);
  const idUser = getUserId();
  const dispatch = useAppDispatch();
  const defaultQueryParams = `?sort=user_id&search=${idUser}&search=Compare&sort=status`;
  const { count, successList, errorList, loadingList, compareList } =
    useAppSelector((state) => state.productWithStatus);
  const { specNameRender, specValueRender } = useAppSelector(
    (state) => state.product_spec
  );
  const { numberOfCompared } = useAppSelector((state) => state.product);
  const products = useAppSelector(selectAllProduct);
  const productsSpec = useAppSelector(selectAllProductSpec);
  const navigate = useNavigate();

  useEffect(() => {
    let listOfCompare: string | null | ProductType =
      localStorage.getItem("listComparedGoods");
    listOfCompare = listOfCompare ? JSON.parse(listOfCompare) : listOfCompare;
    let payload: { products?: ProductType[]; idUser?: string } = {};
    if (Array.isArray(listOfCompare)) payload.products = listOfCompare;
    if (idUser) payload.idUser = idUser;
    dispatch({
      type: "GET_ALL_PRODUCT_WITH_STATUS_COMPARE",
      payload,
    });
  }, []);

  useEffect(() => {
    if (numberOfCompared === 0) navigate("/");
  }, [numberOfCompared]);

  interface ProductTypeWithItemId extends ProductType {
    idItem: string;
  }

  const getValues = (values: string[]) => {
    let valuesRender = [];

    let valueLength = values.length;
    let specNameLength = specNameRender.length - 1;
    if (specNameLength !== valueLength) {
      valueLength += specNameLength - valueLength;
    }
    for (let i = 0; i < valueLength; i++) {
      valuesRender[i] = (
        <div className={styles.filters__item} key={i}>
          {values[i] ? (
            <div>{values[i]}</div>
          ) : (
            <div style={{ visibility: "hidden" }}>-</div>
          )}
        </div>
      );
    }

    return valuesRender;
  };

  const deleteFormComparedList = (
    data: ProductType | ProductTypeWithItemId
  ) => {
    const { id, category_id, subCategory_id } = data;

    const newProductsSpec = productsSpec.filter((pr) => pr.product_id !== id);
    let payload: { idProduct: string; idItem?: string; prSpc: PrSpI[] } = {
      idProduct: id,
      prSpc: newProductsSpec,
    };
    if ("idItem" in data) {
      payload.idItem = data.idItem;
    } else {
      //const dataProductWithStatus = localStorage.getItem('listComparedGoods');
      const dataLikeCompare = localStorage.getItem(
        "listLikeCompareProductByCategory"
      );
      let listLikeCompareProductByCategory: listLikeCompareProductByCategoryI | null =
        dataLikeCompare ? JSON.parse(dataLikeCompare) : null;

      let listOfCompare: string | null | ProductType =
        localStorage.getItem("listComparedGoods");
      listOfCompare = listOfCompare ? JSON.parse(listOfCompare) : listOfCompare;

      const firstLevelKey = subCategory_id || category_id;
      const secondLevel = data.id;
      if (listLikeCompareProductByCategory && firstLevelKey && secondLevel) {
        console.log(firstLevelKey, secondLevel);
        listLikeCompareProductByCategory[firstLevelKey][secondLevel].compare =
          false;
        localStorage.setItem(
          "listLikeCompareProductByCategory",
          JSON.stringify(listLikeCompareProductByCategory)
        );
      }
      if (Array.isArray(listOfCompare)) {
        const newListProduct = listOfCompare.filter((pr) => pr.id !== id);
        localStorage.setItem(
          "listComparedGoods",
          JSON.stringify(newListProduct)
        );
      }
    }

    dispatch({
      type: "DELETE_PRODUCT_FROM_COMPARE",
      payload,
    });

    if (specValueRender.length === 0) {
      navigate("/");
    }
  };
  console.log('specNameRender',specNameRender);
  console.log('specValueRender',specValueRender);
  return (
    <>
      <div className={styles.main_wrapper}>
        {specNameRender.length > 1 && (
          <div className={styles.wrapper__name}>
            <div style={{ visibility: "hidden" }}>
              <OneGood
                direction="vertical"
                hiddenLike={true}
                hiddenCheck={true}
                footer={() => <div></div>}
                header={() => (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    <IoMdClose size={20} />
                  </div>
                )}
              />
            </div>

            <div className={styles.filters}>
              <div className={styles.filters__name}>
                {specNameRender.slice(1).map((sp, i) => (
                  <div className={styles.filters__item} key={sp?.id}>
                    {sp?.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className={styles.wrapper_goods}>
          {specValueRender.map((item, index) => {
            const values = item.slice(1);
            return (
              <div key={index}>
                <div>
                  <OneGood
                    direction="vertical"
                    data={item[0]}
                    hiddenLike={true}
                    hiddenCheck={true}
                    header={() => (
                      <div
                        onClick={() => deleteFormComparedList(item[0])}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <IoMdClose size={20} />
                      </div>
                    )}
                    footer={() => (
                      <div className={styles.filters}>
                        <div className={styles.filters__values}>
                          {getValues(values as string[])}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ComparisonList;
