import React, { useEffect, useState } from "react";
import {Layout, Button, Input, Divider, Form, notification, Select} from "antd";

import { AiOutlineShoppingCart, AiOutlineClose } from "react-icons/ai";
import { LiaSaveSolid } from "react-icons/lia";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import { editError, editSuccess } from "../../redux/slices/users.slice";
import { validate as uuidValidate } from "uuid";

type FieldType = {
  email?: string;
  phone?: string;
  name?: string;
  surname?: string;
  address?: string;
};

const { Option } = Select;
const prefixSelector = (
    <Form.Item name="prefix" noStyle style={{border:"none",boxShadow:"none"}}>
      <Select style={{ width:70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
);

const getIdFromToken = (): string | null => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    const data = jwtDecode<{ exp: number; id: string; role: string }>(
      accessToken
    );
    return data.id;
  }
  return null;
};
function Details({ children }: { children?: JSX.Element }) {
  const { isAdmin } = useAppSelector((state) => state.auth);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  const disabled = isAdmin;
  const dispatch = useDispatch();
  const params = useParams();
  const { dataUser } = useAppSelector((state) => state.account);
  const [form] = Form.useForm();
  const [showButtonCancel, setShowButtonCancel] = useState<boolean>(false);
  const { loading, error, success } = useAppSelector((state) => state.users);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  useEffect(() => {
    const id = params.idUser || getIdFromToken();

    if (id && uuidValidate(id)) {
      dispatch({ type: "GET_ONE_USERS", payload: id });
    } else {
      navigate(params.idUser ? "/admin" : "/");
    }
    form.setFieldsValue(dataUser);
  }, []);

  useEffect(() => {
    form.setFieldsValue(dataUser);
  }, [dataUser]);

  const resetToInitState = () => {
    form.setFieldsValue(dataUser);
    setShowButtonCancel(false);
  };

  const onSave = () => {
    if (showButtonCancel) {
      const payload = {
        ...dataUser,
        ...form.getFieldsValue(),
      };
      dispatch({ type: "EDIT_USERS", payload });
    }
  };

  useEffect(() => {
    if (success) {
      api.success({
        message: <div className={styles.succes}>{success}</div>,
        duration: 10,
      });
      setShowButtonCancel(false);
      dispatch(editSuccess(null));
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: <div className={styles.error}>{error}</div>,
        duration: 10,
      });
      dispatch(editError(null));
    }
  }, [error]);

  return (
    <div className={styles.details}>
      {contextHolder}
      <div className={styles.details_title}>{isAdmin ? "" : "My"} Details</div>
      <div className={styles.details_column}>
        <div className={styles.details_subTitle}>Personal information</div>
        <div style={{ width: "70%" }}>
          <Form
            form={form}
            name="auth"
            className={styles.details_form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onChange={() => {
              setShowButtonCancel(true);
            }}
          >
            <div className={styles.details_formColum}>
              <Form.Item<FieldType>
                className={styles.input_wrapper}
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input className={styles.input} disabled={disabled} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.input_wrapper}
                name="surname"
                label="Surname"
                rules={[
                  { required: true, message: "Please input your surname!" },
                ]}
              >
                <Input className={styles.input} disabled={disabled} />
              </Form.Item>
            </div>
            <div className={styles.details_formColum}>
              <Form.Item<FieldType>
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
                className={styles.input_wrapper}
              >
                <Input className={styles.input} disabled={disabled} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.input_wrapper}
                name="phone"
                label="Phone"
                rules={[
                  { required: true, message: "Please input your phone!", },
                ]}
              >
                <Input  className={styles.input} disabled={disabled} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.input_wrapper}
                name="address"
                label="Address"
                rules={[
                  { required: false, message: "Please input your address!" },
                ]}
              >
                <Input className={styles.input} disabled={disabled} style={{ border: 'none' }}/>
              </Form.Item>
            </div>
          </Form>

          {!children && (
            <div className={styles.button_wrapper}>
              {showButtonCancel && (
                <div>
                  <Button
                    type="default"
                    htmlType="submit"
                    onClick={resetToInitState}
                    className={[styles.button, styles.button_sec].join(" ")}
                  >
                    <AiOutlineClose />
                    <span>Cancel</span>
                  </Button>
                </div>
              )}

              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.button}
                  onClick={onSave}
                  loading={loading}
                >
                  <LiaSaveSolid />
                  <span>Save</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default Details;
