import { Row, Col } from "antd";
import styles from "./index.module.scss";
import OneGood from "../OneGood";
import { ProductType } from "../../../redux/slices/product_main.slice";

function ListGoods({ listGoods }: { listGoods: ProductType[] }) {
  return (
    <div className={styles.goods_wrapper}>
      <Row>
        {listGoods.map((data) => (
          <Col className="gutter-row" xs={24} md={8} xl={6}>
            <OneGood direction="vertical" data={data} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ListGoods;
