import { CommonService } from "./CommonService";
import {RequestService} from "./RequestService";

export class ExelProductService extends CommonService {
  constructor() {
    super("excel-products");
  }
    deleteXlsxOne = (id: string, queryParams?: {}) =>
        this.request({
            method: "delete",
            id,
            queryParams,
        });
}
