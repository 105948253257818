import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AUTH_SAGA_TYPE } from "../ts/enum";
import { IDataUserSignUp, IDataUserForgotPassword } from "../ts/interface";

const initialState: {
  isAuthorized: boolean;
  loading: boolean;
  error: null | string;
  success: null | string;
  isCodeSent: boolean;
  loadingApp: boolean;
  isBlocked: boolean;
  isAdmin: boolean;
  isOpenConfirmResetLikeCompareInAccount: boolean;
} = {
  isAuthorized: false,
  isBlocked: false,
  isCodeSent: false,
  isAdmin: false,
  loadingApp: true,
  loading: false,
  error: null,
  success: null,
  isOpenConfirmResetLikeCompareInAccount: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    editAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    editLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    editLoadingApp: (state, action: PayloadAction<boolean>) => {
      state.loadingApp = action.payload;
    },
    editError: (state, action: PayloadAction<null | string>) => {
      state.error = action.payload;
    },
    editSuccess: (state, action: PayloadAction<null | string>) => {
      state.success = action.payload;
    },
    editIsCodeSent: (state, action: PayloadAction<boolean>) => {
      state.isCodeSent = action.payload;
    },
    editIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    clearResponseStatus: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearAuthSlice: (state) => {
      console.log(" clearAuthSlice");
      state = initialState;
    },
    setIsOpenConfirmResetLikeCompareInAccount: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenConfirmResetLikeCompareInAccount = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;

export default reducer;
export const {
  editAuth,
  editLoading,
  editError,
  editSuccess,
  editIsCodeSent,
  editLoadingApp,
  editIsAdmin,
  clearResponseStatus,
  clearAuthSlice,
  setIsOpenConfirmResetLikeCompareInAccount,
} = actions;
