import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CommentType } from "../ts/type";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";

type statusProductT = "Active" | "Inactive" | "Archived";
export type statusItemsT = "Compare" | "Likes" | "Order" | "Cart";

export interface ProductType {
  id: string;
  amount: number;
  description: string;
  easyReturns: boolean;
  freeShipping: boolean;
  name: string;
  price: number;
  sku: string;
  stars: number | null;
  highlights: { id: string; name: string; image_url: string }[] | [];
  category_id: string | null;
  subCategory_id: string | null;
  commentsAmount: number;
  category: null | { id: string; name: string };
  subCategory: null | { id: string; name: string };
  avatar_url: string;
  items: {
    id: string;
    product_id: string;
    user_id: string;
    status: statusItemsT;
    order_id: null | string;
  }[];
}

export interface IFormMainData {
  amount: number;
  name: string;
  price: string;
  sku: string;
  category_id: string | null;
  subCategory_id: string | null;
  easyReturns: boolean;
  freeShipping: boolean;
  confirmDeleteAllSpec?: boolean;
}

export interface ProductTypeIForForm extends IFormMainData {
  id?: string;
  description: string;
  highlights: { id: string; name: string }[] | string[] | [];
}

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | ProductType;
  pickCategory: null | { id: string; name: string };
  pickSubCategory: null | { id: string; name: string };
  numberOfLikes: number;
  numberOfCompared: number;
  numberOfCart: number;
  searchProduct: ProductType[];
  searchCount: number;
  loadingSearch: boolean;
  isOtherProduct: boolean;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  searchCount: 0,
  pickItem: null,
  pickCategory: null,
  pickSubCategory: null,
  numberOfLikes: 0,
  numberOfCompared: 0,
  numberOfCart: 0,
  searchProduct: [],
  loadingSearch: false,
  isOtherProduct: false,
};

export interface ProductTypeExtra extends ProductType {
  specNames: {
    id: string;
    name: string;
    CharacteristicsSpe: { id: string };
    specValues: { id: string; name: string }[];
  }[];
}
type ProductT = ProductTypeExtra;
export const productAdapter = createEntityAdapter<ProductT>({
  selectId: (category) => category.id,
});

export const initialState = productAdapter.getInitialState(init);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setAllProduct: (state, action) => {
      productAdapter.setAll(state, action.payload);
    },
    addOneProduct: (state, action) => {
      productAdapter.addOne(state, action.payload);
    },
    updateOneProduct: (state, action) => {
      productAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneProduct: (state, action) => {
      productAdapter.removeOne(state, action.payload);
    },
    setAllSearchProduct: (state, action) => {
      state.searchProduct = action.payload;
    },
    setSearchCount: (state, action) => {
      state.searchCount = action.payload;
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editLoadingSearch: (state, action) => {
      state.loadingSearch = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    setPickItem: (state, action: PayloadAction<null | ProductType>) => {
      state.pickItem = action.payload;
    },

    setPickCategory: (
      state,
      action: PayloadAction<null | { id: string; name: string }>
    ) => {
      state.pickCategory = action.payload;
    },
    setPickSubCategory: (
      state,
      action: PayloadAction<null | { id: string; name: string }>
    ) => {
      state.pickSubCategory = action.payload;
    },
    setNumberOfLikes: (state, action: PayloadAction<number | -1 | "1">) => {
      let newValue = state.numberOfLikes;
      if (action.payload === -1 || action.payload === "1"|| action.payload === 1) {
        newValue += +action.payload;
      } else {
        newValue = action.payload;
      }
      state.numberOfLikes = newValue < 1 ? 0 : newValue;
    },
    setNumberOfCompared: (state, action: PayloadAction<number | -1 | "1">) => {
      let newValue = state.numberOfCompared;
      if (action.payload === -1 || action.payload === "1"|| action.payload === 1) {
        newValue += +action.payload;
      } else {
        newValue = action.payload;
      }
      state.numberOfCompared = newValue < 1 ? 0 : newValue;
    },
    setNumberOfCart: (state, action: PayloadAction<number | -1 | "1">) => {
      let newValue = state.numberOfCart;
      if (action.payload === -1 || action.payload === "1"|| action.payload === 1) {
        newValue += +action.payload;
      } else {
        newValue = action.payload;
      }
      state.numberOfCart = newValue < 1 ? 0 : newValue;
    },
    setIsOtherProduct: (state, action: PayloadAction<boolean>) => {
      state.isOtherProduct = action.payload;
    },
  },
});

const { actions, reducer } = productSlice;

export const {
  setCount,
  clearResponseStatusofForm,
  setAllProduct,
  addOneProduct,
  updateOneProduct,
  removeOneProduct,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setPickItem,
  setPickCategory,
  setPickSubCategory,
  setNumberOfLikes,
  setNumberOfCompared,
  setNumberOfCart,
  setAllSearchProduct,
  setSearchCount,
  editLoadingSearch,
  setIsOtherProduct,
} = actions;

export default reducer;
export const { selectAll } = productAdapter.getSelectors<RootState>(
  (state) => state.product
);
