import { takeLeading, call, put } from "redux-saga/effects";
import { usersService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllUsers,
  addOneUsers,
  updateOneUsers,
  removeOneUsers,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
} from "../slices/users.slice";
import { setUser, UserDataI } from "../slices/account.slice";

function* getAll(action: PayloadAction<{ search?: string; page?: number }>) {
  //++
  yield put(editLoadingList(true));
  try {
    const response: { rows?: []; count: number } = !action?.payload?.search
      ? yield call(usersService.getAll, action.payload)
      : yield call(usersService.search, action.payload);
    yield put(setCount(response?.count || 0));
    yield put(setAllUsers(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  try {
    const response: UserDataI = yield call(usersService.getOne, action.payload);
    yield put(setUser(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* editOne(action: PayloadAction<UserDataI>) {
  yield put(editLoading(true));
  try {
    const { name, surname, email, phone, address, status } = action.payload;
    const body = { name, surname, phone, address, status, email };
    const id = action.payload.id;
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const response: {} = yield call(usersService.update, id, body, "PATCH");
    yield put(updateOneUsers(action.payload));
    yield put(editSuccess("The data has been updated"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(action: PayloadAction<{}>) {
  yield put(editLoading(true));
  try {
    const body = action.payload;
    const response: {} = yield call(usersService.create, body);
    yield put(addOneUsers(response));
    yield put(editSuccess("The category have been added"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  try {
    const response: {} = yield call(usersService.delete, action.payload);
    yield put(removeOneUsers(action.payload));
    yield put(editSuccess("The category have been deleted"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchUsersSaga() {
  yield takeLeading("GET_ALL_USERS", getAll);
  yield takeLeading("GET_ONE_USERS", getOne);
  yield takeLeading("ADD_USERS", addOne);
  yield takeLeading("EDIT_USERS", editOne);
  yield takeLeading("DELETE_USERS", deleteOne);
}
