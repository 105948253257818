import { jwtDecode } from "jwt-decode";
export const getUserId = (): null | string => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    const data = jwtDecode<{ exp: number; id: string; role: string }>(
      accessToken
    );
    return data.id;
  }
  return null;
};
