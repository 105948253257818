import React, { useEffect, useRef, useState } from "react";

import { Button, Carousel, Image, Rate } from "antd";
import styles from "./index.module.scss";
import test_image from "../../assets/imgs/test_img_for_card_1.png";
import GoodFooter from "../../components/Goods/GoodFooter";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { AiOutlineCalendar, AiOutlineEdit } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import ListFeedback from "../../components/ListFeedback";
import { editError, editSuccess } from "../../redux/slices/comments.slice";
import { pageSize } from "../../variables";
import OneGood from "../../components/Goods/OneGood";

import { selectAll as selectAllPrSpec } from "../../redux/slices/product_spec.slice";
import {
  IMedia,
  selectAll as selectAllImg,
} from "../../redux/slices/image.slice";
import ProductDoc from "../../components/ProductDoc";

const options = ["Product info", "Videos/Manuals", "Reviews"]; //"You may need"

const getMoreComments = (page: number = 1) => {};

function GoodDesc() {
  const carouselRef = useRef<any>(null);
  const [pickImgIndex, setPickImgIndex] = useState<null | number>(null);
  const params = useParams();
  const defKeyMenu = params?.keyMenu ? +params?.keyMenu : null;
  const [activeMenu, setActiveMenu] = useState<number | null>(defKeyMenu || 0);
  const img = useSelector(selectAllImg);

  const dispatch = useDispatch();
  const { pickItem } = useAppSelector((state) => state.product);
  const id = params.goodId;
  const [stars, setStars] = useState(pickItem?.stars || 0);
  const filters = useAppSelector(selectAllPrSpec);
  const [listImages, setListImages] = useState<IMedia[]>([]);

  useEffect(() => {
    if (id) {
      dispatch({ type: "GET_ONE_PRODUCT", payload: id });
      dispatch({
        type: "GET_ALL_SPEC_ONE_PRODUCT",
        payload: { params: "?sort=product_id&search=" + id },
      });
    }
  }, []);

  useEffect(() => {
    setStars(pickItem?.stars || 0);
  }, [pickItem]);

  useEffect(() => {
    if (pickItem) {
      setListImages([
        {
          id: "avatar",
          media_url: pickItem?.avatar_url as string,
          type: "Image",
          name: "",
          product_id: pickItem.id,
        },
        ...img,
      ]);
    }
  }, [pickItem, img]);

  return (
    <>
      <div className={styles.goodDesc_wrapper}>
        <div className={styles.carousel}>
          <div style={{ height: 180, width: "100%" }}>
            <Carousel
              ref={carouselRef}
              dots={false}
              style={{
                height: 180,
                width: "100%",
              }}
            >
              {listImages.map((item) => (
                <div
                  style={{
                    height: 170,
                    width: "100%",
                  }}
                >
                  <div className={styles.mainImg}>
                    <img src={item.media_url} style={{ maxHeight: 170 }} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>

          <div className={styles.carousel_preview}>
            {listImages.map((item, i) => (
              <div
                onClick={() => {
                  carouselRef?.current?.goTo(i);
                  setPickImgIndex(i);
                }}
                className={
                  pickImgIndex === i ? styles.carousel_preview__active : ""
                }
              >
                <div
                  style={{
                    height: 100,
                    width: 70,
                    margin: 5,
                  }}
                >
                  <img
                    src={item.media_url}
                    style={{ height: 100, width: 70 }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.desc}>
          <div className={styles.desc_title}>{pickItem?.name}</div>
          <div className={styles.sub_desc}>
            <div className={styles.desc_artical}>{pickItem?.sku}</div>
            {/*     <div className={styles.desc_supplier}>Honeywell Home</div> */}
            <Rate
              disabled
              allowHalf
              defaultValue={pickItem?.stars || 0}
              value={stars}
            />
            <span style={{ marginLeft: 10 }}>({pickItem?.commentsAmount})</span>
          </div>
          {pickItem && (
            <div style={{ width: "50%" }}>
              <OneGood
                direction="horizontal"
                data={pickItem}
                onlyFooter={true}
              />
            </div>
          )}

          <div className={styles.desc_advantages}>
            {pickItem?.freeShipping && (
              <div>
                <span>Free Shipping </span> On orders over $99
              </div>
            )}
            {pickItem?.easyReturns && (
              <div>
                <span>Easy Returns </span> No restocking fee for 90 days
              </div>
            )}
          </div>
          <div className={styles.highlights}>
            <div className={styles.highlights_title}>Product Highlights</div>
            <div className={styles.highlights_desc}>
              {pickItem?.highlights.map((hli) => (
                <div className={styles.highlights_item}>
                  <div className={styles.highlights_icon}>
                    <img
                      src={hli.image_url}
                      style={{ width: 30, height: 30 }}
                    ></img>
                  </div>
                  <span>{hli.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.menu}>
        {options.map((op, i) => (
          <Button
            onClick={() => {
              setActiveMenu(i);
              dispatch(editError(null));
              dispatch(editSuccess(null));
            }}
            className={[
              styles.menu_button,
              activeMenu === i && styles.menu_button__active,
            ].join(" ")}
          >
            {op}
          </Button>
        ))}
      </div>

      <div className={styles.content}>
        {activeMenu === 0 && (
          <div className={styles.fulldesc}>
            <div className={styles.fulldesc_title}>Description</div>
            <div className={styles.fulldesc_text}>{pickItem?.description}</div>
            {filters.length > 0 && (
              <>
                <div className={styles.fulldesc_title}>Specs</div>
                <div className={styles.table}>
                  {filters.map((sp, i) => (
                    <div
                      className={styles.table_row}
                      style={{
                        background: i % 2 === 0 ? "#F0F0F0" : "#FFFFFF",
                      }}
                    >
                      <div className={styles.table_value}>
                        {sp.specName.name}
                      </div>
                      <div className={styles.table_name}>
                        {sp.specValues.map((sv) => sv.name).join(", ")}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {/*  <ListGoods />added in one good spec */}
        {/*     {activeMenu === 2 && <ListGoods />} */}
        {activeMenu === 2 && <ListFeedback />}
        {activeMenu === 1 && <ProductDoc isOpportunityEditBlock={true} />}
      </div>
    </>
  );
}

export default GoodDesc;
