import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import store, { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { InitStateRequestStatusI } from "../ts/interface";
import { ProductType } from "./product_main.slice";

export interface SpecProductI {
  id: string;
  product_id: string;
  specName_id: string;
  specValues: {
    id: string;
    name: string;
  }[];
  specName: {
    id: string;
    name: string;
  };
  //CharacteristicsSpec?: { id: string };
}

interface ProductTypeWithItemId extends ProductType {
  idItem: string;
}

export type specNameRenderT = [null, ...{ id: string; name: string }[]];
export type specValueRenderT = [
  ProductTypeWithItemId | ProductType,
  ...string[]
][];
export type mapKeyInSpecValueRenderByIdProducT = {
  [productId: string]: number;
};

export const productSpecAdapter = createEntityAdapter<SpecProductI>({
  selectId: (product_spec) => product_spec.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | SpecProductI;
  specNameRender: specNameRenderT;
  specValueRender: specValueRenderT;
  mapKeyInSpecValueRenderByIdProduc: mapKeyInSpecValueRenderByIdProducT;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
  specNameRender: [null],
  specValueRender: [],
  mapKeyInSpecValueRenderByIdProduc: {},
};
export const initialState = productSpecAdapter.getInitialState(init);

const productSpecSlice = createSlice({
  name: "product_spec",
  initialState,
  reducers: {
    setAllProductSpec: (state, action) => {
      productSpecAdapter.setAll(state, action.payload);
    },
    addOneProductSpec: (state, action) => {
      productSpecAdapter.addOne(state, action.payload);
    },
    updateOneProductSpec: (state, action) => {
      productSpecAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeOneProductSpec: (state, action) => {
      productSpecAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | SpecProductI>) => {
      state.pickItem = action.payload;
    },
    updateRenderCompare: (
      state,
      action: PayloadAction<{ idProduct: string; prS: SpecProductI[] }>
    ) => {
      const { prS, idProduct } = action.payload;
      let specNameRender: specNameRenderT = [null];
      let mapKeyInSpecValueRenderByIdProduc: mapKeyInSpecValueRenderByIdProducT =
        {};
      let specValueRender: specValueRenderT = [];

      state.specValueRender.forEach((spVR, i) => {
        if (spVR[0].id !== idProduct) {
          const index = specValueRender.push(spVR) - 1;
          mapKeyInSpecValueRenderByIdProduc[spVR[0].id] = index;
        }
      });

      prS.forEach((chrS) => {
        let findIndexFirstLevel =
          mapKeyInSpecValueRenderByIdProduc[chrS.product_id];
        let findIndexSecondLevel: number;
        findIndexSecondLevel = specNameRender.findIndex(
          (spN) => spN?.id === chrS.specName.id
        );
        if (findIndexSecondLevel === -1) {
          findIndexSecondLevel =
            specNameRender.push({
              id: chrS.specName.id,
              name: chrS.specName.name,
            }) - 1;
        }
        specValueRender[findIndexFirstLevel][findIndexSecondLevel] =
          chrS.specValues.map((spv) => spv.name).join(", ");
      });

      state.mapKeyInSpecValueRenderByIdProduc =
        mapKeyInSpecValueRenderByIdProduc;
      state.specNameRender = specNameRender;
      state.specValueRender = specValueRender;
    },
    setSpecNameRender: (state, action: PayloadAction<specNameRenderT>) => {
      state.specNameRender = action.payload;
    },
    setSpecValueRender: (state, action: PayloadAction<specValueRenderT>) => {
      state.specValueRender = action.payload;
    },
    setMapKeyInSpecValueRenderByIdProduct: (
      state,
      action: PayloadAction<mapKeyInSpecValueRenderByIdProducT>
    ) => {
      state.mapKeyInSpecValueRenderByIdProduc = action.payload;
    },
  },
});

const { actions, reducer } = productSpecSlice;

export const {
  setAllProductSpec,
  addOneProductSpec,
  updateOneProductSpec,
  removeOneProductSpec,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  setSpecNameRender,
  setSpecValueRender,
  setMapKeyInSpecValueRenderByIdProduct,
  updateRenderCompare,
} = actions;

export default reducer;
export const { selectAll } = productSpecAdapter.getSelectors<RootState>(
  (state) => state.product_spec
);
