import {Checkbox, TreeSelect, notification, Modal, Alert} from "antd";
import {Form, Input} from "antd";
import {
    CategoryI,
    selectAll as selectAllCategory
} from "../../redux/slices/category.slice";
import {selectAll as selectAllProductFilter} from "../../redux/slices/product_spec.slice";
import {useAppSelector} from "../../redux/ts/hooks/hooks";
import {useEffect, useState} from "react";
import ResponseStatusComponent from "../ResponseStatusComponent";
import {
    FieldType,
    TreeSelectSubategoryI,
    TreeSelectI,
    LayoutType,
} from "./type";
import {getTreeData, getBase64, filterOption} from "../../utilities/product";
import styles from "./index.module.scss";
import {IFormMainData} from "../../redux/slices/product_main.slice";
import {TreeNode} from "antd/es/tree-select";
import {IXLSXProductType} from "../../redux/slices/xlsx_product.slice";
import {doesSubcategoryExist} from "../../utilities/other";

export function FormMainData({
                                 isValidForm,
                                 onSave,
                                 data,
                             }: {
    isValidForm: (result: false | IFormMainData) => void;
    onSave: boolean;
    data?: IXLSXProductType | null;
}) {
    const [form] = Form.useForm();
    const categories = useAppSelector(selectAllCategory);
    const {loadingList} = useAppSelector((state) => state.category);

    const {loadingList: loadingListSpecName} = useAppSelector(
        (state) => state.spec_name
    );
    const [easyReturns, setEasyReturns] = useState(false);
    const [freeShipping, setFreeShipping] = useState(false);
    const [valueCategory, setValueCategory] = useState<string>("");
    const productFilter = useAppSelector(selectAllProductFilter);
    const {
        loading: loadingProduct,
        pickItem: pickItemProduct,
        error: errorProduct,
        success: succesProduct,
    } = useAppSelector((state) => state.product);
    const [category, setCategory] = useState<null | {
        category: {
            name: string;
            id: string;
        };
        subCategory?: {
            name: string;
            id: string;
        };
    }>(null);
    const [selectValueCategory, setSelectValueCategory] = useState<{
        value: string;
        data: TreeSelectSubategoryI | TreeSelectI;
    } | null>(null);

    const [valueStatus, setValueStatus] = useState<"Active" | "Out Of Stock">(
        "Active"
    );
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmDeleteSpec, setConfirmDeleteSpec] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [
        isModalConfirmDeleteSpecProductOpen,
        setIsModalConfirmDeleteSpecProductOpen,
    ] = useState(false);

    useEffect(() => {
        if (pickItemProduct) {
            console.log(pickItemProduct)
            const subcategory = doesSubcategoryExist(categories, pickItemProduct.category?.name)
            form.setFieldsValue(pickItemProduct);
            setEasyReturns(pickItemProduct.easyReturns);
            setFreeShipping(pickItemProduct.freeShipping);

            if (pickItemProduct.subCategory) {
                setCategory({
                    category: {
                        name: pickItemProduct.category?.name as string,
                        id: pickItemProduct.category?.id as string,
                    },
                    subCategory: {
                        name: subcategory ? pickItemProduct.subCategory.name : '',
                        id: subcategory ? pickItemProduct.subCategory.id : '',
                    },
                });
            } else {
                setCategory({
                    category: {
                        name: pickItemProduct.category?.name as string,
                        id: pickItemProduct.category?.id as string,
                    },
                });
            }
            setValueCategory(
                chooseCategory(pickItemProduct)
            );

            form.setFieldValue("isCategoryPick", true);
        }
    }, [pickItemProduct]);

    const chooseCategory = (item: any) => {
        if (item.subCategory?.category_id !== item.category.id) {
            return item.category.id
        } else if (item.subCategory?.id) {
            return item.subCategory?.id
        } else {
            return item.category.id
        }
    }

    const checkForm = async () => {
        try {
            await form.validateFields();
            const {amount, name, price, sku} = await form.getFieldsValue();
            const data: IFormMainData = {
                amount,
                name,
                price,
                sku,
                category_id: category?.category?.id || null,
                subCategory_id: category?.subCategory?.id || null,
                easyReturns: easyReturns,
                freeShipping: freeShipping,
                //??
                confirmDeleteAllSpec:
                    confirmDeleteSpec &&
                    Boolean(category?.category?.id || category?.subCategory?.id),
            };
            isValidForm(data);
            console.log('data', data)
        } catch (e) {
            console.log("catch");
            isValidForm(false);
        }
    };
    useEffect(() => {
        if (onSave) checkForm();
    }, [onSave]);

    const handleOkConfirmDeleteSpecProduct = () => {
        if (selectValueCategory) {
            const {value, data} = selectValueCategory;
            onChangeCategory(value, data, true);
        }
        setIsModalConfirmDeleteSpecProductOpen(false);
    };

    const handleCancelConfirmDeleteSpecProduct = () => {
        setIsModalConfirmDeleteSpecProductOpen(false);
        setConfirmDelete(false);
        setSelectValueCategory(null);
    };

    const onChangeCategory = (
        value: string,
        data: TreeSelectSubategoryI | TreeSelectI,
        confirm?: boolean
    ) => {

        if (productFilter.length !== 0 && !confirm) {
            setSelectValueCategory({value, data});
            setIsModalConfirmDeleteSpecProductOpen(true);
            return;
        }
        if (confirm) setConfirmDeleteSpec(true);
        if ("subCategory_id" in data) {
            setCategory({
                category: {
                    name: data.category_name,
                    id: data.category_id,
                },
                subCategory: {
                    name: data.title,
                    id: data.subCategory_id,
                },
            });
            setValueCategory(data.value);
            form.setFieldValue("isCategoryPick", true);
        } else {
            if (data?.children?.length > 0) {
                api.error({
                    message: (
                        <div className={styles.error}>
                            The category {data.title} has subcategory. Please select
                            subcategory
                        </div>
                    ),
                    placement: "topRight",
                    duration: 5000,
                });
            } else {
                setCategory({
                    category: {
                        name: data.title,
                        id: data.value,
                    },
                });
                setValueCategory(data.value);
                form.setFieldValue("isCategoryPick", true);
            }
        }
    };

    return (
        <Form layout="vertical" form={form} className={styles.form}>
            {contextHolder}
            <div className={styles.form__row}>
                <Form.Item<FieldType>
                    name="name"
                    label="Name"
                    className={styles.form__item}
                    rules={[{required: true, message: ""}]}
                >
                    <Input placeholder="input name" className={styles.form__input}/>
                </Form.Item>

                <Form.Item<FieldType>
                    name="sku"
                    label="SKU"
                    className={styles.form__item}
                    rules={[{required: true, message: ""}]}
                >
                    <Input placeholder="input SKU" className={styles.form__input}/>
                </Form.Item>
            </div>
            <div className={styles.form__row}>
                <Form.Item<FieldType>
                    name="amount"
                    label="Amount"
                    className={styles.form__item}
                    rules={[
                        {
                            required: true,
                            message: "",
                        },
                    ]}
                >
                    <Input
                        placeholder="input Amount"
                        className={styles.form__input}
                        onChange={(e) => {
                            +e.target.value < 1
                                ? setValueStatus("Out Of Stock")
                                : setValueStatus("Active");
                            const newValue = +e.target.value;
                            if (!isNaN(+e.target.value)) {
                                form.setFieldValue("amount", +e.target.value);
                            } else {
                                form.setFieldValue("amount", 0);
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    name="price"
                    label="Price"
                    className={styles.form__item}
                    rules={[{required: true, message: ""}]}
                >
                    <Input
                        placeholder="input price"
                        suffix="USD"
                        className={styles.form__input}
                        onChange={(e) => {
                            if (!isNaN(+e.target.value)) {
                                form.setFieldValue("price", +e.target.value);
                            } else {
                                form.setFieldValue("price", 0);
                            }
                        }}
                    />
                </Form.Item>
            </div>

            <div className={styles.form__row} style={{marginBottom: 10}}>
                <div
                    className={styles.titleSelect}
                    style={{wordWrap: "normal", width: "50%"}}
                >
                    <div>Category / Subcategory</div>
                </div>
                <div style={{width: "50%", height: 40}}>
                    <Form.Item<FieldType>
                        name="isCategoryPick"
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                        ]}
                    >
                        <div></div>
                        <TreeSelect
                            //treeCheckable={true}

                            //showCheckedStrategy={"SHOW_PARENT"}
                            showSearch
                            dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                                minWidth: 300,

                                padding: 10,
                            }}
                            placeholder="Please select"
                            placement="bottomLeft"
                            allowClear
                            treeDefaultExpandAll
                            treeData={getTreeData(categories)}
                            className={styles.select}
                            onSelect={onChangeCategory}
                            disabled={loadingList || loadingListSpecName}
                            loading={loadingList || loadingListSpecName}
                            value={valueCategory}
                            filterTreeNode={filterOption}
                        />
                    </Form.Item>
                </div>
            </div>
            {data && (
                <div style={{marginTop: 20, marginBottom: 10}}>
                    <Alert message={data.comment} type="info" showIcon/>
                </div>
            )}
            <div className={styles.form__row}>
                <Form.Item<FieldType> name="freeShipping" className={styles.form__item}>
                    <Checkbox
                        checked={freeShipping}
                        onChange={() => setFreeShipping((f) => !f)}
                    >
                        <div className={styles.checkbox}>
                            <span>Free Shipping</span> On orders over $99
                        </div>
                    </Checkbox>
                </Form.Item>
                <Form.Item<FieldType> name="easyReturns" className={styles.form__item}>
                    <Checkbox
                        checked={easyReturns}
                        onChange={() => setEasyReturns((e) => !e)}
                    >
                        <div className={styles.checkbox}>
                            <span>Easy Returns</span> No restocking fee for 90 days
                        </div>
                    </Checkbox>
                </Form.Item>
            </div>
            <Modal
                title={"Change categore/subcategory"}
                open={isModalConfirmDeleteSpecProductOpen}
                onOk={handleOkConfirmDeleteSpecProduct}
                onCancel={handleCancelConfirmDeleteSpecProduct}
            >
                <div style={{textAlign: "center"}}>
                    <ResponseStatusComponent error={errorProduct}/>
                    Are you sure you want change the category/subcategory
                    <br/>
                    All product's spec will be delete if you change category/subcategory
                </div>
            </Modal>
        </Form>
    );
}
