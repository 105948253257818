import React, { useEffect, useState } from "react";

import { Button, Pagination, Tooltip } from "antd";
import styles from "./index.module.scss";
import test_banner from "../../assets/imgs/test_banner.png";
import ListGoods from "../Goods/ListGoods";
import { useDispatch, useSelector } from "react-redux";
import {
  SpecNameI,
  selectAll as selectAllSpecName,
} from "../../redux/slices/spec_name.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import ResponseStatusComponent from "../ResponseStatusComponent";
import { pageSize } from "../../variables";
import { useParams } from "react-router-dom";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const sizeIcon = 24;

function SpecValueC({
  spv,
  defCheck,
  setCheckValue,
  idSpecName,
}: {
  idSpecName: string;
  spv: { id: string; name: string };
  defCheck?: boolean;
  setCheckValue?: (
    idSpecValue: string,
    idSpecName: string,
    check: boolean
  ) => void;
}) {
  const [check, setCheck] = useState(defCheck || false);

  const onChange = (e: CheckboxChangeEvent) => {
    setCheck((check) => {
      if (setCheckValue) setCheckValue(spv.id, idSpecName, !check);
      return !check;
    });
  };

  return (
    <div>
      <Checkbox onChange={onChange} value={check} checked={check}>
        <div className={styles.filter__value}>{spv.name}</div>
      </Checkbox>
    </div>
  );
}
function SpecNameC({
  spn,
  onChange,
  setCheckValue,
  checkedValues,
}: {
  spn: SpecNameI;
  onChange: (e: CheckboxChangeEvent) => void;
  checkedValues?: { [specValueId: string]: boolean };
  setCheckValue?: (
    idSpecValue: string,
    idSpecName: string,
    check: boolean
  ) => void;
}) {
  const [openValue, setOpenValue] = useState(true);

  const onArrow = () => {
    setOpenValue((openValue) => !openValue);
  };

  return (
    <div className={styles.filter__block}>
      <div className={styles.filter__name}>
        <div>{spn.name}</div>
        <div onClick={onArrow}>
          {!openValue ? (
            <IoIosArrowDown size={sizeIcon} />
          ) : (
            <IoIosArrowUp size={sizeIcon} />
          )}
        </div>
      </div>

      {openValue &&
        spn.specValues.map((spv) => (
          <SpecValueC
            spv={spv}
            idSpecName={spn.id}
            setCheckValue={setCheckValue}
            defCheck={checkedValues?.[spv.id]}
          />
        ))}
    </div>
  );
}

function FiltersOfProduct({
  category_id,
  subCategory_id,
}: {
  category_id: string | undefined;
  subCategory_id: string | undefined;
}) {
  const { count, errorList, loadingList } = useAppSelector(
    (state) => state.spec_name
  );

  const specNames = useSelector(selectAllSpecName);
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const allCountPages = Math.ceil(count / pageSize);
  const [disabledSeeMore, setDisabledSeeMore] = useState(false);
  const [checkedValue, setCheckedValue] = useState<{
    [specNameId: string]: { [specValueId: string]: boolean };
  }>({});

  useEffect(() => {
    if (category_id || subCategory_id) {
      getMoreOrders();
    }
  }, []);

  useEffect(() => {
    if (category_id || subCategory_id) {
      getMoreOrders();
    }
  }, [category_id, subCategory_id]);

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const setCheckValue = (
    idSpecValue: string,
    idSpecName: string,
    check: boolean
  ) => {
    console.log("setCheckValue", idSpecValue, idSpecName, check);
    setCheckedValue((checkedValue) => {
      const newValues = { ...checkedValue };
      if (newValues[idSpecName]) {
        newValues[idSpecName][idSpecValue] = check;
      } else {
        newValues[idSpecName] = { [idSpecValue]: check };
      }

      const keys = Object.keys(newValues);
      let body: { specNameId: string; specValuesIds: string[] }[] = [];

      keys.map((idSpecName) => {
        let itemBody = {
          specNameId: idSpecName,
          specValuesIds: Object.keys(newValues[idSpecName]).filter(
            (idSpecValue) => newValues[idSpecName][idSpecValue]
          ),
        };
        if (itemBody.specValuesIds.length > 0) body.push(itemBody);
      });

      dispatch({ type: "GET_ALL_PRODUCT_BY_FILTER", payload: body });
      return newValues;
    });
  };

  useEffect(() => {
    console.log(checkedValue, "checkedValue");
  }, [checkedValue]);

  const getMoreOrders = (page: number = 1) => {
    let params = "?";
    if (category_id) params += `sort=category_id&search=${category_id}&`;
    if (subCategory_id)
      params += `sort=subCategory_id&search=${subCategory_id}&`;

    params += `page=${page}&take=${pageSize}&`;
    dispatch({
      type: "GET_ALL_SPEC_NAME",
      payload: {
        query_params: params,
        addNewValuesInList: page === 1 ? undefined : true,
      },
    });
    setPage(page);
  };

  const changePageProduct = (page: any) => {
    const payload: { page: number; search?: string } = { page };

    //dispatch({ type: "GET_ALL_PRODUCT", payload });
  };

  const getMoreSpec = () => {
    const newPage = allCountPages === page ? 1 : page + 1;
    if (allCountPages === page) {
      setDisabledSeeMore(true);
    } else {
      setPage((page) => {
        getMoreOrders(page + 1);
        return page + 1;
      });
    }
  };

  return (
    <>
      {specNames.length > 0 && (
        <div className={styles.filter}>
          <div className={styles.filter__header}>Total:{specNames.length} </div>
          {specNames.map((spn) => {
            //console.log(checkedValue, spn.id, spn.name);
            return (
              <SpecNameC
                spn={spn}
                onChange={onChange}
                setCheckValue={setCheckValue}
                checkedValues={checkedValue[spn.id]}
              />
            );
          })}
          {loadingList && (
            <div className={styles.filter__loading}>
              <ResponseStatusComponent loading={loadingList} />
            </div>
          )}

          {allCountPages > 1 && !disabledSeeMore && (
            <Tooltip title={"Click to see more filters"}>
              <div className={styles.filter__more} onClick={getMoreSpec}>
                See more
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
}

export default FiltersOfProduct;
