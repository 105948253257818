import { RiFeedbackLine } from "react-icons/ri";
import { FaRegFileVideo } from "react-icons/fa";
import { MdOutlineDescription } from "react-icons/md";
import { BsFilterCircle } from "react-icons/bs";
import { Tabs } from "antd";
import styles from "./index.module.scss";
import OneProductDescAdmin, {
  BottomFixBlock,
} from "../../components/OneProductDescAdmin";

import ProductFilter from "../../components/ProductFilter";
import ProductDoc from "../../components/ProductDoc";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const menu = [
  {
    icon: MdOutlineDescription,
    label: "Main description",
    value: "desc",
    component: OneProductDescAdmin,
    key: 1,
  },
  {
    icon: BsFilterCircle,
    label: "Spec",
    value: "video",
    component: ProductFilter,
    key: 2,
  },
  /*   {
    icon: RiFeedbackLine,
    label: "Feedback",
    value: "feedback",
    component: ProductFeedbackAdmin,
    key: 3,
  }, */
  {
    icon: FaRegFileVideo,
    label: "Video and manual",
    value: "filters",
    component: ProductDoc,
    key: 4,
  },
];

export function ManageOneProduct() {
  const params = useParams();
  const { keyMenu, idProduct } = params;
  const [activeKey, setActiveKey] = useState(keyMenu ? keyMenu : "1");
  const navigate = useNavigate();
  const itemsMenu = (params.idProduct === "null" ? [menu[0]] : menu).map(
    (info, i) => {
      const id = String(i + 1);
      const Icon = info.icon;
      const Component = info.component;
      return {
        label: (
          <div className={styles.menu}>
            <Icon size={22} />
            <div>{info.label}</div>
          </div>
        ),
        key: id,
        children: <Component />,
      };
    }
  );

  useEffect(() => {
    if (keyMenu) setActiveKey(keyMenu);
  }, [keyMenu]);

  const changeTab = (activeKey: string) => {
    setActiveKey(activeKey);
    navigate("/admin/products/" + idProduct + "/" + activeKey);
  };

  return (
    <div className={styles.product}>
      <Tabs
        defaultActiveKey="1"
        items={itemsMenu}
        activeKey={activeKey}
        onChange={changeTab}
      />
    </div>
  );
}

export default ManageOneProduct;
