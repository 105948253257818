import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Rate, Button, Divider, Space, Select, Tag, notification } from "antd";
import styles from "./index.module.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import moment from "moment";
import { Tooltip } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import {
  OrderI,
  editError,
  editSuccess,
  statusOrder,
} from "../../redux/slices/orders.slice";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";

const itemsOptions = [
  {
    label: (
      <div
        className={styles.feedback__status_completed}
        style={{ height: 20, width: "85%" }}
      >
        Completed
      </div>
    ),
    value: "Completed",
  },
  {
    label: (
      <div
        className={styles.feedback__status_delivery}
        style={{ height: 20, width: "85%" }}
      >
        Delivery
      </div>
    ),
    value: "Delivery",
  },
  {
    label: (
      <div
        className={styles.feedback__status_created}
        style={{ height: 20, width: "85%" }}
      >
        Created
      </div>
    ),
    value: "Created",
  },
];

export function OneOrder({
  data,
  isAdmin,
  moreDetails,
  deleteOrder,
  header,
  blockChangeStatus,
  hiddenUserInfo,
  navigateMoreDetails,
}: {
  data: OrderI;
  isAdmin?: boolean;
  moreDetails?: boolean;
  deleteOrder?: boolean;
  header?: ReactNode;
  blockChangeStatus?: true;
  hiddenUserInfo?: true;
  navigateMoreDetails?: string;
}) {
  const date = moment(data.updatedAt).format("LL");
  const navigate = useNavigate();
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(true);
  const dispatch = useAppDispatch();
  const { loading, error, success, loadingList } = useAppSelector(
    (state) => state.orders
  );
  const [api, contextHolder] = notification.useNotification();

  const status = data.status;

  useEffect(() => {
    return () => {
      dispatch(editSuccess(null));
      dispatch(editError(null));
    };
  }, []);

  useEffect(() => {
    if (success) {
      setOpenChangeStatus(false);
      dispatch(editSuccess(null));
      //setOpenDropDown(false);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      api.error({
        message: <div className={styles.succes}>{error}</div>,
        duration: 3,
      });
      dispatch(editError(null));
    }
  }, [error]);

  const handleChange = (value: statusOrder | "" | { value: ""; label: "" }) => {
    if (value && typeof value !== "object") {
      dispatch({
        type: "EDIT_ORDER",
        payload: { status: value, id: data.id },
      });
    }
  };

  let propsTooltip: { title: string; open?: boolean } = {
    title: "Click for change status",
  };
  if (blockChangeStatus) propsTooltip.open = false;

  return (
    <div className={styles.feedback_block}>
      {header}
      {contextHolder}
      <div className={styles.feedback_header}>
        <div className={styles.feedback__header}>
          <div>
            {!hiddenUserInfo && (
              <div className={styles.feedback_who}>
                <span>user: </span>
                {data?.user?.name} <span>({data?.user.email})</span>
              </div>
            )}

            <div className={styles.feedback_who}>
              <span>address: </span>
              {data?.address}
            </div>
            <div className={styles.feedback_who}>
              <span>date: </span>
              {date}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {openChangeStatus ? (
              <div
                className={styles.feedback__status}
                //onClick={() => changeStatus(fb.id, fb.status)}
              >
                <Select
                  value={{ label: "", value: "" }}
                  loading={loading}
                  style={{ width: 120, height: 35 }}
                  placeholder="select  status"
                  open={openDropDown}
                  onChange={handleChange}
                  dropdownRender={(menu) => (
                    <>
                      <div
                        className={styles.close}
                        onClick={() => setOpenChangeStatus(false)}
                      >
                        <IoMdClose size={16} />
                      </div>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                  options={itemsOptions}
                />
              </div>
            ) : (
              <div
                className={styles.feedback__status}
                onClick={() => !blockChangeStatus && setOpenChangeStatus(true)}
              >
                <Tooltip {...propsTooltip}>
                  {status === "Completed" && (
                    <div
                      className={styles.feedback__status_completed}
                      style={{ textAlign: "center" }}
                    >
                      Completed
                    </div>
                  )}
                  {status === "Delivery" && (
                    <div className={styles.feedback__status_delivery}>
                      Delivery
                    </div>
                  )}
                  {status === "Created" && (
                    <div className={styles.feedback__status_created}>
                      Created
                    </div>
                  )}
                </Tooltip>
              </div>
            )}

            {moreDetails && (
              <div
                className={styles.feedback__delete}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate((navigateMoreDetails || "/admin/orders/") + data.id)
                }
              >
                <Tooltip title="Click for more details">
                  <MdArrowForwardIos size={18} />
                </Tooltip>
              </div>
            )}
            {deleteOrder && (
              <div
                className={styles.feedback__delete}
                //onClick={() => showModal(fb)}
              >
                <Tooltip title="Click for delete">
                  <AiOutlineDelete size={22} />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneOrder;
