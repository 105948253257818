import React, { useEffect, useState } from "react";
import { Layout, Button, Input, Divider, Pagination, notification } from "antd";

import OneGood from "../../components/Goods/OneGood";
import { AiOutlineShoppingCart } from "react-icons/ai";
import styles from "./index.module.scss";
import { getUserId } from "../../utilities";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { pageSize } from "../../variables";
import {
  ProductWithStatusI,
  selectAll as selectAllProductWithStatus,
} from "../../redux/slices/product_with_status.slice";
import GoodFooter from "../../components/Goods/GoodFooter";
import { error } from "console";
import { useParams } from "react-router-dom";
import OneOrder from "../../components/OneOrder";
import { setPickItem } from "../../redux/slices/subcategory.slice";
const arrayTest = [1, 1, 1];
const iconSize = 24;

function PageOneOrder({
  hiddenDetails,
  getGoodFooter,
}: {
  hiddenDetails?: true;
  getGoodFooter?: (count: number, price: number) => JSX.Element;
}) {
  const idUser = getUserId();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const defaultQueryParams = `?sort=user_id&search=${idUser}&search=Cart&sort=status&take=${pageSize}&page=`;
  const products = useAppSelector(selectAllProductWithStatus);
  const {
    count,
    successList,
    errorList,
    loadingList,
    success: successProductWithStatus,
  } = useAppSelector((state) => state.productWithStatus);
  const {
    error: errorOrder,
    success: succesOrder,
    pickItem,
    loading,
  } = useAppSelector((state) => state.orders);
  const [sum, setSum] = useState(0);
  const [api, contextHolder] = notification.useNotification();

  const params = useParams();
  const idOrder = params.idOrder;

  useEffect(() => {
    console.log("hello");
    dispatch({ type: "GET_ONE_ORDER", payload: idOrder });
    return () => {
      setPickItem(null);
    };
  }, []);

  /* useEffect(() => {
    if (idOrder) dispatch({ type: "GET_ONE_ORDER", payload: idOrder });
  }, [idOrder]);
 */
  const getProducts = (page: number) => {
    dispatch({
      type: "GET_ALL_PRODUCT_WITH_STATUS",
      payload: {
        query_params: defaultQueryParams + page,
      },
    });
  };

  /*   useEffect(() => {
    getProducts(1);
    getSum(products);
  }, []);

  useEffect(() => {
    setSum(sum);
    getSum(products);
  }, [products]);

  useEffect(() => {
    if (succesOrder) {
      api.success({
        message: <div className={styles.succes}>{succesOrder}</div>,
        duration: 10,
      });
    }
  }, [succesOrder]);

  useEffect(() => {
    if (errorOrder) {
      api.error({
        message: <div className={styles.succes}>{errorOrder}</div>,
        duration: 10,
      });
    }
  }, [errorOrder]); */

  useEffect(() => {
    if (successProductWithStatus)
      dispatch({ type: "GET_ONE_ORDER", payload: idOrder });
  }, [successProductWithStatus]);

  const onChangePage = (page: number) => {
    getProducts(page);
    setPage(page);
  };

  const getSum = (data: ProductWithStatusI[]) => {
    const sum = data.reduce((accumulator: number, item: ProductWithStatusI) => {
      const price = item?.product?.price || 0;
      const newValue = item.count * price;
      return accumulator + newValue;
    }, 0);
    setSum(sum);
  };

  /*   const makeOrder = () => {
    const card_id = "5ec06a6c-1636-43a9-8c3a-2cdedc30bef5";
    const address = "USA";
    const user_id = getUserId();
    dispatch({ type: "ADD_ORDER", payload: { card_id, address, user_id } });
  }; */

  return (
    <>
      {contextHolder}
      <div className={styles.cart_wrapper}>
        <div
          className={styles.cart_goods}
          style={{ width: hiddenDetails && "100%" }}
        >
          {pickItem?.products.map((item) => (
            <OneGood
              direction="horizontal"
              hiddenLike={true}
              data={item}
              hiddenCheck={true}
              defCount={item.Item.count}
              showCount={true}
              hiddenStar={true}
              idItemOrder={item.Item.id}
              changeCountOnServer={true}
              defUserId={pickItem.user_id}
              footer={
                getGoodFooter &&
                (() => getGoodFooter(item.Item.count, item.price))
              }
            />
          ))}
        </div>
        {!hiddenDetails && (
          <div className={styles.cart_payment}>
            {pickItem && (
              <OneOrder
                data={pickItem}
                header={
                  <div className={styles.cart_payment__title}>Details</div>
                }
              />
            )}
          </div>
        )}

        {count > pageSize && (
          <Pagination
            current={page}
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            className={styles.pagination}
            onChange={onChangePage}
          />
        )}
      </div>
    </>
  );
}

export default PageOneOrder;
