import React, {useEffect, useRef, useState} from "react";
import {Button, Spin, Tooltip, notification} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, {HeartOutlined} from "@ant-design/icons";
import {AiOutlineShoppingCart, AiOutlineMinusCircle} from "react-icons/ai";
import {statusItemsT} from "../../../redux/slices/product_main.slice";
import {useAppSelector} from "../../../redux/ts/hooks/hooks";
import {PropsChangeItemI} from "../OneGood";
import {useDispatch} from "react-redux";
import {error} from "console";
import {
    editError,
    editSuccess,
} from "../../../redux/slices/product_with_status.slice";
import {useLocation} from "react-router-dom";

function GoodFooter({
                        direction,
                        amount,
                        price,
                        product_id,
                        inOrder,
                        idItemCart,
                        changeItemsProductOnServer,
                        defCount,
                        showCount,
                        changeCountOnServer,
                        hiddenButton,
                        idItemOrder,
                        setTotalPrice
                    }: {
    direction: "horizontal" | "vertical";
    amount?: number;
    price?: number;
    product_id?: string;
    idItemCart?: string;
    idItemOrder?: string;
    defCount?: number;
    inOrder?:boolean;
    changeItemsProductOnServer?: (props: PropsChangeItemI) => void;
    showCount?: boolean;
    changeCountOnServer?: true;
    hiddenButton?: true;
    setTotalPrice?: any,
}) {
    const sizeIcon = 24;
    const [count, setCount] = useState<number>(defCount || 1);
    const [isIntoCart, setIsIntoCart] = useState<boolean>(
        idItemCart || idItemOrder ? true : false
    );
    const dispatch = useDispatch();
    const {isAuthorized} = useAppSelector((state) => state.auth);
    const [isToolptipOpen, setIsTooltipOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const {
        error,
        success,
        currentListType,
        loading,
    } = useAppSelector((state) => state.productWithStatus);
    const [newValue, setNewValue] = useState(defCount || 1);
    const location = useLocation()
    const prevValueRef = useRef(newValue);
    console.log(isIntoCart, idItemCart, "isIntoCart");
    console.log(currentListType, "currentListType");
    console.log(idItemCart, "idItemCart");

    const isCard = location.pathname
    // useEffect(() => {
    //     if (isCard === '/cart') {
    //         setIsIntoCart(true)
    //     }
    // }, [isCard]);

    useEffect(() => {
        if (error) {
            api.error({
                message: <div className={styles.succes}>{error}</div>,
                duration: 3,
            });
            dispatch(editError(null));
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            dispatch(editSuccess(null));
// setPrice()
            setCount(newValue);
        }
    }, [success]);

    useEffect(() => {

    }, [newValue, price]);

    const changeStatusGoodIntoCart = () => {
        if (!isAuthorized) {
            setIsTooltipOpen(true);
            return;
        }
        if (changeItemsProductOnServer) {

            setIsIntoCart((isIntoCart) => {
                isIntoCart
                    ? changeItemsProductOnServer({
                        action: "delete",
                        item_id: idItemCart || idItemOrder,
                        status: idItemOrder ? "Order" : "Cart",
                    })
                    : changeItemsProductOnServer({
                        action: "add",
                        status: "Cart",
                        count,

                    });
                return !isIntoCart;
            });
        }
    };

    const handleSetCount = (e: React.ChangeEvent<HTMLInputElement>) => {
        let isNumber = !isNaN(+e.target.value);
        isNumber && setCount(+e.target?.value);
    };

    const getNewCount = (count: number, step: -1 | 1): number => {
        if (count === 1 && step === -1) return count;
        if (count === amount && step === 1) return count;
        return count + step;
    };

    const changeCount = (step: -1 | 1) => {
        setCount((count) => {
            let newValue = getNewCount(count, step);
            if (changeCountOnServer && (idItemCart || idItemOrder)) {
                dispatch({
                    type: "EDIT_PRODUCT_WITH_STATUS",
                    payload: {id: idItemCart || idItemOrder, count: newValue},
                });
                dispatch({
                    type: 'EDIT_COUNT_PRODUCT_WITH_STATUS',
                    payload: {id: idItemCart || idItemOrder, count: newValue},
                })
                setNewValue(newValue);
                return count;
            }
            return newValue;
        });
    };

    return (
        <div
            className={[
                styles.good_footer,
                direction === "horizontal" && styles.good_footer__horiz,
            ].join(" ")}
        >
            {contextHolder}
            <div className={styles.good_coast}>
                {showCount || !isIntoCart ? (
                    <div className={styles.count_block}>
                        <Spin size="small" spinning={false}>
                            <button onClick={(e) => changeCount(-1)}>-
                            </button>
                            <input value={count} onChange={handleSetCount}/>
                            <button onClick={(e) => changeCount(1)}>+
                            </button>
                        </Spin>
                    </div>
                ) : (
                    <div style={{ /* minWidth: 92, */ height: 35}}></div>
                )}

                <div className={styles.price}>
                    {showCount || !isIntoCart
                        ? price
                            ? "$" + price * count
                            : "Contact us to get price"
                        : price
                            ? "$" + price + " (1 item)"
                            : "Contact us to get price"}
                </div>
            </div>
            {!hiddenButton && !inOrder && (
                <Tooltip
                    title="Please login in your account"
                    open={isToolptipOpen}
                    placement="topRight"
                >
                    <Button
                        className={isIntoCart ? styles.button_item_out : styles.button_item}
                        onClick={changeStatusGoodIntoCart}
                        onMouseLeave={() => setIsTooltipOpen(false)}
                        // disabled={loading}
                    >
                        <div className={styles.good_button}>
                            {isIntoCart ? (
                                <AiOutlineMinusCircle size={sizeIcon - 5}/>
                            ) : (
                                <AiOutlineShoppingCart size={sizeIcon - 5}/>
                            )}

                            <div>{isIntoCart ? "Out of Stock" : "Add to Cart"} </div>
                        </div>
                    </Button>
                </Tooltip>
            )}
        </div>
    );
}

export default GoodFooter;
