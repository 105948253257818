import axios, {AxiosResponse} from 'axios';
import {getToken, resetToken, setToken} from "../redux/sagas/auth.saga";
import {CreditCard, PaymentMethod} from "../components/OneProductDescAdmin/type";


export const instance = axios.create({
    // baseURL:  "http://68.65.210.62:8000"
     baseURL:  "https://ecommerce-parts-api.rc.vironitdev.com"
    // baseURL: "http://192.168.2.9:3000"
});
instance.interceptors.request.use(
    (config) => {
        const token = getToken('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

const refreshToken = async (): Promise<string> => {
    const refreshToken = getToken('refreshToken');
    if (!refreshToken) throw new Error('No refresh token available');

    const response: AxiosResponse<{ accessToken: string }> = await axios.post(
        'https://ecommerce-parts-api.rc.vironitdev.com',
        { refreshToken }
    );
    const newAccessToken = response.data.accessToken;
    setToken('accessToken', newAccessToken);
    return newAccessToken;
};

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const newAccessToken = await refreshToken();
                axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axios(originalRequest);
            } catch (refreshError) {
                resetToken('all');
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
)

export const deleteXlsxOneAxios = async (id: string): Promise<any> => {
    const res = await instance.delete(`excel-products/${id}`, {});
    return res.data;
}

export const getCreditCards = async (): Promise<CreditCard[]> => {
    try {
        const res = await instance.get(`/credit-cards`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return res.data.data;
    } catch (error) {
        console.error('Error fetching credit cards:', error);
        throw error;
    }
};

export const addOneCreditCard = async (id: string): Promise<any> => {
    try {
        const res = await instance.post(`/credit-cards`, {
            paymentMethodId: id
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.data;
    } catch (error) {
        console.error('Error adding credit card:', error);
        throw error;
    }
};

export const deleteOneCreditCard = async (id: string): Promise<any> => {
    try {
        const res = await instance.delete(`/credit-cards?paymentMethodId=${id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.data;
    } catch (error) {
        console.error('Error deleting credit card:', error);
        throw error;
    }
};
export type PaymentData = {
    id: string,
    amount: number,
    address: string
}

export const addPaymentWithCreditCard = async (payload: PaymentData): Promise<{ }> => {
    const { id, amount, address } = payload;

    try {
        const res = await instance.post(`/credit-cards/charge`, {
            paymentMethodId: id,
            amount: amount,
            address: address
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res.data;
    } catch (error) {
        console.error('Error processing payment with credit card:', error);
        throw error;
    }
};

