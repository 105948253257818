import { Tooltip, Pagination, Select, Tag, Table } from "antd";
import styles from "./index.module.scss";
import OneOrder from "../../components/OneOrder";
import { statusOrder, OrderI } from "../../redux/slices/orders.slice";
import { useDispatch } from "react-redux";
import { ReactNode, useEffect, useState } from "react";
import { pageSize } from "../../variables";
import {
  SpecProductI,
  selectAll as selectAllSpec,
} from "../../redux/slices/product_spec.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import FilterSelect from "../../components/FilterSelect";
import { MdExpandMore } from "react-icons/md";
import { selectAll as selectAllUsers } from "../../redux/slices/users.slice";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useLocation, useParams } from "react-router-dom";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { IoCloseOutline } from "react-icons/io5";
const sizeIcon = 24;

interface IXLSXSpec {
  exists?: true;
  name: string | { id: string; name: string };
  value: string | { id: string; name: string }[];
}

const dataMock = [
  {
    exists: true,
    name: { id: "1", name: "Spec1" },
    value: [
      { id: "2", name: "specvalue1" },
      { id: "3", name: "specvalue3" },
    ],
  },
];

function SpecName({ name }: { name: string | { id: string; name: string } }) {
  const [value, setValue] = useState<any>(
    typeof name === "string" ? name : name.name
  );
  return (
    <input
      className={styles.inputTable}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={typeof name !== "string"}
    />
  );
}

function SpecValue({
  values,
}: {
  values: string | { id: string; name: string }[];
}) {
  const [value, setValue] = useState<any>(
    typeof values === "string" ? values : values.map((sv) => sv.name).join(", ")
  );

  return (
    <input
      className={styles.inputTable}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={typeof values !== "string"}
    />
  );
}

const columns: ColumnsType<IXLSXSpec> = [
  {
    title: <div className={styles.headerColumn}>Spec's name </div>,
    dataIndex: "name",
    render: (_, { exists, name }) => <SpecName name={name} />,
    fixed: true,
  },
  {
    title: <div className={styles.headerColumn}>Values</div>,
    dataIndex: "values",
    render: (_, { value }) => <SpecValue values={value} />,
  },

  {
    title: (
      <div className={styles.headerColumn}>
        <AiOutlineDelete size={sizeIcon - 6} />
      </div>
    ),
    dataIndex: "delete",
    render: (_, data) => (
      <div
        className={styles.rightNavbarList_icon}
        //onClick={() => deleteItem(data)}
      >
        <AiOutlineDelete size={sizeIcon - 6} />
      </div>
    ),
  },
];

export function ListSpecOfXLSXProduct({
  addPickSpecName,
  isOpenProductSpec,
  closeListSpecOfProduct,
}: {
  addPickSpecName?: (data: SpecProductI | null) => void;
  isOpenProductSpec?: boolean;
  closeListSpecOfProduct?: () => void;
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const specs = useAppSelector(selectAllSpec);
  const { count } = useAppSelector((state) => state.product_spec);
  const { pickItem } = useAppSelector((state) => state.xlsx_product);

  const params = useParams();
  const idProduct = params.idProduct;

  const deleteItem = (data: SpecProductI) => {};
  const data = pickItem?.specNames
    ? [...dataMock, ...pickItem?.specNames]
    : dataMock;

  return (
    <div
      className={[
        styles.leftPart,
        isOpenProductSpec ? styles.leftPart_open : "",
      ].join(" ")}
    >
      <div className={styles.header}>
        <div className={styles.header__text}>
          <div>All specs of this product</div>
          <div></div>
        </div>
        <div onClick={closeListSpecOfProduct} className={styles.close}>
          <IoCloseOutline size={sizeIcon} />
        </div>
      </div>
      <div className={styles.rightNavbarList}>
        <div className={styles.rightNavbarList_header}></div>
        <Table
          style={{ width: "100%" }}
          //@ts-ignore
          columns={columns}
          dataSource={data}
          //loading={true}
          showHeader={true}
          rowClassName={styles.row}
          className={styles.table}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
}

export default ListSpecOfXLSXProduct;
