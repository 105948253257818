import { CommonService } from "./CommonService";
import { AuthService } from "./AuthService";
import { CommentService } from "./CommentService";
import { ItemService } from "./ItemService";
import { ProductService } from "./ProductService";
import { SpecForProductService } from "./SpecForProductService";
import { MediaService } from "./MediaService";
import { ExelFileService } from "./ExelFileService";
import { ExelProductService } from "./ExelProductService";

export const authService = new AuthService();
export const categoryService = new CommonService("categories");
export const subCategoriesService = new CommonService("subCategories");
export const highlightsService = new CommonService("highlights");
export const usersService = new CommonService("users");

export const ordersService = new CommonService("orders");
export const specValuesService = new CommonService("specValues");
export const specNamesService = new CommonService("specNames");

export const specProductService = new SpecForProductService();
export const productService = new ProductService();
export const commentsService = new CommentService();
export const itemsService = new ItemService();
export const mediaService = new MediaService();

export const xlsxFileService = new ExelFileService();
export const xlsxProductService = new ExelProductService();
