import React, { ReactElement, useEffect, useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import { Layout, Space, Image, Button, FloatButton, Breadcrumb } from "antd";
import styles from "./index.module.scss";
import HeaderC from "../../components/HeaderC";
import { GrFacebookOption, GrTwitter } from "react-icons/gr";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import MenuC from "../../components/MenuC";
import test_banner from "../../assets/imgs/test_banner.png";
import ListGoods from "../Goods/ListGoods";
import OneGood, { listLikeCompareProductByCategoryI } from "../Goods/OneGood";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { componentsAccount } from "../../components/HeaderC";
import MenuAccount from "../MenuAccount";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import { getUserId } from "../../utilities";
import {
  ProductType,
  setNumberOfCompared,
  setNumberOfLikes,
} from "../../redux/slices/product_main.slice";
import { setCurrentListType } from "../../redux/slices/product_with_status.slice";
const { Header, Footer, Content } = Layout;

function Wrapper({
  BaseComponent,
  items,
}: {
  BaseComponent: any;
  items?: Array<BreadcrumbItemType>;
}) {
  const iconSize = 24;
  let location = useLocation();
  const path = location?.pathname.slice(1, 8);
  const isAccount = location?.pathname && path === "account";
  const dispatch = useAppDispatch();
  const { numberOfCompared } = useAppSelector((state) => state.product);
  const userId = getUserId();
  const { isAuthorized } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && isAuthorized) {
      console.log("Wrapper. UseEffect[]");
      dispatch({
        type: "GET_COUNT_ALL_PRODUCT_WITH_STATUS",
        payload: {
          query_params: `?sort=user_id&search=${userId}&sort=or: status&search=Likes&sort=or: status&search=Cart&sort=or: status&search=Compare`,
        },
      });
    } else {
      let listOfLike: string | null | ProductType[] =
        localStorage.getItem("listLikeProduct");
      let listOfCompare: string | null | ProductType[] =
        localStorage.getItem("listComparedGoods");
      listOfLike = listOfLike ? JSON.parse(listOfLike) : listOfLike;
      listOfCompare = listOfCompare ? JSON.parse(listOfCompare) : listOfCompare;
      const likes_count = listOfLike?.length || 0;
      const compares_count = listOfCompare?.length || 0;
      console.log(compares_count);
      dispatch(setNumberOfLikes(likes_count));
      dispatch(setNumberOfCompared(compares_count));
    }
    if (path === "compare") dispatch(setCurrentListType("Compare"));
    if (path === "likes") dispatch(setCurrentListType("Likes"));
    if (path === "cart") dispatch(setCurrentListType("Cart"));
  }, []);

  const resetCompareList = () => {
    if (isAuthorized) {
      dispatch({ type: "RESET_COMPARE_LIST", payload: userId });
    } else {
      localStorage.removeItem("listComparedGoods");

      let listOfLikes: string | null | ProductType =
        localStorage.getItem("listLikeProduct");
      listOfLikes = listOfLikes ? JSON.parse(listOfLikes) : listOfLikes;
      if (Array.isArray(listOfLikes) && listOfLikes.length === 0) {
        localStorage.removeItem("listLikeCompareProductByCategory");
        dispatch(setNumberOfCompared(0));
        return;
      }
      console.log(
        Array.isArray(listOfLikes) && listOfLikes.length > 0,
        listOfLikes
      );
      if (Array.isArray(listOfLikes) && listOfLikes.length > 0) {
        let newListLikeCompareProductByCategory: listLikeCompareProductByCategoryI =
          {};
        listOfLikes.map((pr) => {
          const firstKey = pr.subCategory_id || pr.category_id;
          console.log(firstKey, "firstKey ");
          const secondKey = pr.id;

          newListLikeCompareProductByCategory[firstKey] = {
            ...(newListLikeCompareProductByCategory[firstKey] || {}),
            [secondKey]: {
              like: true,
              compare: false,
            },
          };
        });

        localStorage.setItem(
          "listLikeCompareProductByCategory",
          JSON.stringify(newListLikeCompareProductByCategory)
        );
      }

      dispatch(setNumberOfCompared(0));
    }
  };

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <HeaderC />
      </Header>
      <MenuC />
      {items && (
        <div className={styles.menu_breadcrumb}>
          <Breadcrumb items={items} />
        </div>
      )}
      <Content
        className={[
          styles.content,
          !items && styles.content_withoutBreadcrumb,
          isAccount && styles.content_withoutFooter,
        ].join(" ")}
      >
        {isAccount ? (
          <div className={styles.account}>
            <div className={styles.account_menu}>
              <MenuAccount />
            </div>
            <div className={styles.account_content}>
              <BaseComponent />
            </div>
          </div>
        ) : (
          <BaseComponent />
        )}
      </Content>
      {!isAccount && (
        <>
          <Footer className={styles.footer}>
            <div className={styles.footer_menu}>
              <div>About us</div>
              <div>Support</div>
              <div>Legal information</div>
            </div>
            <div className={styles.social_network}>
              <GrFacebookOption size={iconSize} />
              <GrTwitter size={iconSize} />
              <AiOutlineGooglePlus size={iconSize + 5} />
            </div>
          </Footer>
          {numberOfCompared > 0 && (
            <div
              onClick={() => {
                dispatch(setCurrentListType("Compare"));
                navigate("/compare");
              }}
            >
              <FloatButton.Group shape="square" className={styles.float_button}>
                <div>{numberOfCompared} Item(s) in Comparison list</div>
                <div onClick={resetCompareList}>
                  <RiDeleteBinLine size={24} />
                </div>
              </FloatButton.Group>
            </div>
          )}
        </>
      )}
    </Layout>
  );
}

export default Wrapper;
