import React, { useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Badge,
  Button,
  Modal,
  Menu,
  Col,
  Divider,
  Row,
  Avatar,
  Dropdown,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import testImgForCard1 from "../../assets/imgs/test_img_for_card_1.png";
import testImgForCard2 from "../../assets/imgs/test_img_for_card_2.png";
import test_avatar from "../../assets/imgs/test_avatar.svg";
import Contacts from "../Contacts";
import { getItemsForDropDowm, componentsAccount } from "../HeaderC";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TbLogout } from "react-icons/tb";
const { Search } = Input;

const categories = [
  "Heating",
  "Ventilation",
  "Air",
  "Conditioning",
  "Refrigeration",
];

function MenuAccount() {
  const [current, setCurrent] = useState(categories[0]);
  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
    setSubMenuOpen(true);
  };
  const [mouseEnterItem, setMouseEnterItem] = useState<number>(0);
  const navigate = useNavigate();
  const params = useParams();
  const idUser: string | undefined = params?.idUser;
  const dispatch = useDispatch();
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
  const goTo = (path: string | null) => {
    if (path) navigate(path);
  };

  const logout = () => {
    dispatch({ type: "LOG_OUT" });
  };

  return (
    <div>
      <Menu
        style={{ background: "transparent", border: "none" }}
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        items={getItemsForDropDowm(componentsAccount, goTo)}
      />
      <Divider />
      <div
        className={[styles.oneIcon, styles.logout].join(" ")}
        onClick={logout}
      >
        <TbLogout size={20} />
        <span>Log Out</span>
      </div>
    </div>
  );
}

export default MenuAccount;
