import React, {useEffect, useState} from "react";
import {Image, Input, Rate, Checkbox, notification} from "antd";
import styles from "./index.module.scss";
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import no_image from "../../../assets/imgs/images.png";
import {useNavigate} from "react-router-dom";
import {
    ProductType,
    setNumberOfCompared,
    setNumberOfLikes,
    statusItemsT,
} from "../../../redux/slices/product_main.slice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../redux/ts/hooks/hooks";
import GoodFooter from "../GoodFooter";
import {getUserId, getDefaultProductStatus} from "../../../utilities";
import {setUpdateListLikeNoAuth} from "../../../redux/slices/product_with_status.slice";
import {IXLSXProductType} from "../../../redux/slices/xlsx_product.slice";

export interface PropsChangeItemI {
    action: "add" | "delete";
    status: statusItemsT;
    item_id?: string;
    count?: number;
    productId?: string;
}

export interface listLikeCompareProductByCategoryI {
    [cat_subcat_id: string]: {
        [product_id: string]: {
            like: boolean;
            compare: boolean;
        };
    };
}

const sizeIcon = 24;

function OneGood({
                     leaveFeedback,
                     direction,
                     hiddenLike,
                     hiddenCheck,
                     hiddenFooter,
                     footer,
                     header,
                     data,
                     inOrder,
                     idItemCart,
                     idItemLike,
                     idItemCompare,
                     defCount,
                     showCount,
                     changeCountOnServer,
                     hiddenStar,
                     defUserId,
                     idItemOrder,
                     idItemForUpdateLikeList,
                     onlyFooter,
                     setTotalPrice
                 }: {
    leaveFeedback?: boolean;
    direction: "vertical" | "horizontal";
    hiddenLike?: true;
    hiddenCheck?: boolean;
    hiddenFooter?: boolean;
    footer?: () => JSX.Element;
    header?: () => JSX.Element;
    data?: ProductType;
    inOrder?:boolean;
    idItemCart?: string;
    idItemLike?: string;
    idItemCompare?: string;
    defCount?: number;
    showCount?: true | false;
    changeCountOnServer?: true;
    hiddenStar?: true | false;
    defUserId?: string;
    idItemOrder?: string;
    idItemForUpdateLikeList?: string;
    onlyFooter?: true;
    setTotalPrice?: any,
}) {
    //variables
    const dataLikeCompare = localStorage.getItem(
        "listLikeCompareProductByCategory"
    );
    const id_category = data?.subCategory?.id || data?.category?.id || "";
    const id_product = data?.id || "";
    let listLikeCompareProductByCategory: listLikeCompareProductByCategoryI | null =
        dataLikeCompare ? JSON.parse(dataLikeCompare) : null;
    const statusProduct =
        listLikeCompareProductByCategory?.[id_category]?.[id_product];
    const {
        compare: defaultCompare,
        like: defaultLike,
        cart: defaultCart,
    } = getDefaultProductStatus(data?.items || []);

    const [like, setLike] = useState<boolean>(
        defaultLike?.status || statusProduct?.like || (idItemLike && true) || false
    );
    const [api, contextHolder] = notification.useNotification();
    const [compare, setCompare] = useState<boolean>(
        defaultCompare?.status ||
        statusProduct?.compare ||
        (idItemCompare && true) ||
        false
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = defUserId || getUserId();
    const productId = data?.id;
    const {isAuthorized} = useAppSelector((state) => state.auth);
    const {numberOfLikes, numberOfCompared} = useAppSelector(
        (state) => state.product
    );
    const [classNameDesc, setClassNameDesc] = useState<
        "good_desc" | "good_desc__underline"
    >("good_desc");
    const [errorCountItem, setErrorCountItem] = useState<null | string>(null);

    //useEffect

    useEffect(() => {
        if (errorCountItem) {
            api.info({
                message: `${errorCountItem}`,
                duration: 4,
            });
            setErrorCountItem(null); //move to slice
        }
    }, [errorCountItem]);

    useEffect(() => {
        return () => {
            setErrorCountItem(null);
        };
    }, []);
    //functions
    const goToDesc = () => {
        const path = "/c/:categoryName/sb/:subCategoryName/g/" + data?.id;

        navigate("/c/:categoryName/sb/:subCategoryName/g/" + data?.id);
    };

    const setProductWithStatus = (status: "like" | "compare" = "like") => {
        const statusTextForLocalS =
            status === "like" ? "listLikeProduct" : "listComparedGoods";
        const dataProductWithStatus = localStorage.getItem(statusTextForLocalS);
        let listProductWithStatus: ProductType[] = dataProductWithStatus
            ? JSON.parse(dataProductWithStatus)
            : [];

        if (
            !listLikeCompareProductByCategory ||
            !listLikeCompareProductByCategory[id_category] ||
            !listLikeCompareProductByCategory[id_category][id_product]
        ) {
            const allProductInCategory =
                listLikeCompareProductByCategory?.[id_category] || {};
            const allCategoryInList = listLikeCompareProductByCategory || {};
            listLikeCompareProductByCategory = {
                ...allCategoryInList,
                [id_category]: {
                    ...allProductInCategory,
                    [id_product]: {
                        like: status === "like",
                        compare: status !== "like",
                    },
                },
            };
            localStorage.setItem(
                "listLikeCompareProductByCategory",
                JSON.stringify(listLikeCompareProductByCategory)
            );
            data && listProductWithStatus.push(data);
            if (status === "like") {
                dispatch(setNumberOfLikes("1"));
                setLike(true);
            } else {
                dispatch(setNumberOfCompared("1"));
                setCompare(true);
            }

            localStorage.setItem(
                statusTextForLocalS,
                JSON.stringify(listProductWithStatus)
            );
            dispatch(setUpdateListLikeNoAuth(true));
            return;
        }

        const {compare, like} =
            listLikeCompareProductByCategory[id_category][id_product];
        let newValue: { like: boolean; compare: boolean } =
            listLikeCompareProductByCategory[id_category][id_product];
        status === "like" ? (newValue.like = !like) : (newValue.compare = !compare);

        listLikeCompareProductByCategory[id_category] = {
            ...listLikeCompareProductByCategory[id_category],
            [id_product]: newValue,
        };

        const x = status === "like" ? newValue.like : newValue.compare;
        if (x === false) {
            listProductWithStatus = listProductWithStatus.filter(
                (lp) => lp.id !== id_product
            );
        } else {
            listProductWithStatus.push(data as ProductType);
        }

        if (!newValue.compare && !newValue.like) {
            delete listLikeCompareProductByCategory[id_category][id_product];
        }

        if (status === "like") {
            dispatch(setNumberOfLikes(newValue.like ? "1" : -1));
            setLike(newValue.like);
        } else {
            dispatch(setNumberOfCompared(newValue.compare ? "1" : -1));
            setCompare(newValue.compare);
        }

        localStorage.setItem(
            "listLikeCompareProductByCategory",
            JSON.stringify(listLikeCompareProductByCategory)
        );
        localStorage.setItem(
            statusTextForLocalS,
            JSON.stringify(listProductWithStatus)
        );
        dispatch(setUpdateListLikeNoAuth(true));
    };

    const changeItemsProductOnServer = (props: PropsChangeItemI) => {
        const {status, action, item_id} = props;
        if (action === "add" && status) {
            let payload: {
                product_id?: string;
                user_id: string | null;
                status: statusItemsT;
                count?: number;
                idItemForUpdateLikeList?: string;
            } = {
                product_id: productId,
                user_id: userId,
                status: status,
            };
            if (props.count) payload["count"] = props.count;
            if (idItemForUpdateLikeList)
                payload.idItemForUpdateLikeList = idItemForUpdateLikeList;
            dispatch({
                type: "ADD_PRODUCT_WITH_STATUS",
                payload,
            });
        }

        if (action === "delete" && item_id) {
            {
                dispatch({
                    type: "DELETE_PRODUCT_WITH_STATUS",
                    payload: {id: item_id, status},
                });
                dispatch({
                    type: "DELETE_PRODUCT_WITH_COUNT_STATUS",
                    payload: {id: item_id, status},
                });
            }
        }
    };

    const onChange = (status: "like" | "compare" = "like") => {
        if (status === "compare" && numberOfCompared >= 5 && !compare) {
            setErrorCountItem("You can add in comparison list only 5 products");
            return;
        }
        if (status === "like" && numberOfLikes >= 20) {
            setErrorCountItem("You can add in like list only 20 products");
            return;
        }
        if (isAuthorized) {
            status === "like"
                ? setLike((like) => {
                    like
                        ? changeItemsProductOnServer({
                            action: "delete",
                            item_id: defaultLike?.id || idItemLike,
                            status: "Likes",
                        })
                        : changeItemsProductOnServer({action: "add", status: "Likes"});
                    return !like;
                })
                : setCompare((compare) => {
                    compare
                        ? changeItemsProductOnServer({
                            action: "delete",
                            status: "Compare",
                            item_id: defaultCompare?.id || idItemCompare,
                        })
                        : changeItemsProductOnServer({
                            action: "add",
                            status: "Compare",
                        });
                    return !compare;
                });
        } else {
            setProductWithStatus(status);
        }
    };

    const onLeaveFeedback = () => {
        navigate(`/c/:categoryName/sb/:subCategoryName/g/${data?.id}/${2}`);
    };

    return (
        <>
            {onlyFooter ? (
                <GoodFooter
                    inOrder={inOrder}
                    direction={direction}
                    price={data?.price}
                    amount={data?.amount}
                    product_id={data?.id}
                    idItemCart={defaultCart?.id || idItemCart}
                    changeItemsProductOnServer={changeItemsProductOnServer}
                    defCount={defCount}
                    showCount={showCount}
                    changeCountOnServer={changeCountOnServer}
                    idItemOrder={idItemOrder}
                    setTotalPrice={setTotalPrice}
                />
            ) : (
                <div
                    className={[
                        styles.good,
                        direction === "horizontal" && styles.good_horiz,
                    ].join(" ")}
                >
                    <> {contextHolder}</>
                    <div
                        className={[
                            styles.leftPart,
                            direction === "horizontal" && styles.good_leftPart__horiz,
                        ].join(" ")}
                    >
                        {header === undefined ? (
                            <div className={styles.good_header}>
                                {hiddenCheck === undefined && (
                                    <Checkbox
                                        onChange={() => onChange("compare")}
                                        value={compare}
                                        checked={compare}
                                    >
                                        {direction !== "horizontal" && "Compare"}
                                    </Checkbox>
                                )}

                                {hiddenLike === undefined && (
                                    <div
                                        onClick={() => onChange("like")}
                                        style={{marginRight: direction === "horizontal" ? 10 : 0}}
                                    >
                                        {!like ? (
                                            <AiOutlineHeart size={sizeIcon}/>
                                        ) : (
                                            <AiFillHeart color="#F15A29" size={sizeIcon}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            header()
                        )}

                        <div
                            className={[
                                styles.good_img,
                                direction === "horizontal" && styles.good_img__horiz,
                            ].join(" ")}
                        >
                            <Image
                                src={data?.avatar_url || no_image}
                                style={{maxHeight: 200}}
                            />
                        </div>
                        <div className={styles.good_content}>
                            <div
                                className={styles[classNameDesc]}
                                onClick={goToDesc}
                                onMouseEnter={() => setClassNameDesc("good_desc__underline")}
                                onMouseLeave={() => setClassNameDesc("good_desc")}
                            >
                                {data?.name || "Name"}
                            </div>
                            <div className={styles.good_sub_desc}>
                                <div className={styles.good_artical}>
                                    SKU: {data?.sku?.toLocaleLowerCase() || "-"}
                                </div>
                                {/*  <div className={styles.good_supplier}>Honeywell Home</div> */}
                            </div>

                            {!hiddenStar && (
                                <>
                                    <Rate disabled allowHalf
                                          defaultValue={data?.stars || 0}/>
                                    <span style={{marginLeft: 10}}>
                    ({data?.commentsAmount})
                  </span>
                                    {leaveFeedback !== undefined && (
                                        <span
                                            className={styles.good_feedback}
                                            onClick={onLeaveFeedback}
                                        >
                      leaveFeedback
                    </span>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {footer === undefined || hiddenFooter ? (
                        <GoodFooter
                            inOrder={inOrder}
                            direction="vertical"
                            price={data?.price}
                            amount={data?.amount}
                            product_id={data?.id}
                            idItemCart={defaultCart?.id || idItemCart}
                            changeItemsProductOnServer={changeItemsProductOnServer}
                            defCount={defCount}
                            showCount={showCount}
                            changeCountOnServer={changeCountOnServer}
                            idItemOrder={idItemOrder}
                            setTotalPrice={setTotalPrice}
                        />
                    ) : (
                        footer()
                    )}
                </div>
            )}
        </>
    );
}

export default OneGood;
