import React, { useEffect, useState, useRef } from "react";
import { Modal, Table, Tooltip, Tag, notification, Pagination } from "antd";
import { Button } from "antd";
import { Form, Input, Alert } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useAppDispatch, useAppSelector } from "../../redux/ts/hooks/hooks";
import {
  SpecValueI,
  editError,
  editSuccess,
} from "../../redux/slices/spec_value.slice";
import { selectAll as selectAllSpecValue } from "../../redux/slices/spec_value.slice";
import ResponseStatusComponent from "../../components/ResponseStatusComponent";
import { pageSize } from "../../variables";
import styles from "./index.module.scss";

type LayoutType = Parameters<typeof Form>[0]["layout"];
const sizeIcon = 24;

export function ListSpecValues({
  closeListValues,
}: {
  closeListValues?: () => void;
}) {
  // variables
  const [api, contextHolder] = notification.useNotification();
  const [searchText, setSearchText] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickSpecValue, setPickSpecValue] = useState<null | {
    name: string;
    id: string;
  }>();
  const initDeletItem = { delete: false, confirm: false };
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(pickSpecValue?.name || "");
  const [errorName, setErrorName] = useState<string | null>(null);
  const [deleteItem, setDeleteItem] = useState<{
    delete: boolean;
    confirm: boolean;
  }>(initDeletItem);
  const specValue = useAppSelector(selectAllSpecValue);
  const {
    error,
    success,
    loading,
    loadingList,
    errorList,
    successList,
    count,
  } = useAppSelector((state) => state.spec_value);

  // useEffect
  useEffect(() => {
    dispatch({ type: "GET_ALL_SPEC_VALUE" });
  }, []);

  useEffect(() => {
    if (pickSpecValue) {
      setName(pickSpecValue.name);
    }
  }, [pickSpecValue]);

  useEffect(() => {
    if (success && !pickSpecValue) {
      setName("");
    }
    if (success && deleteItem.delete) {
      if (success) {
        handleCancel();
        api.success({
          message: <div className={styles.succes}>{success}</div>,
          duration: 10,
        });
      }
    }
  }, [success]);

  // function
  const handleOk = () => {
    setIsModalOpen(false);
    setErrorName("");
    setName("");
    setPickSpecValue(null);
    setDeleteItem(initDeletItem);
    setErrorName("");
    dispatch(editError(null));
    dispatch(editSuccess(null));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    //dispatch(clearResponseStatusofForm());
    setName("");
    setPickSpecValue(null);
    setDeleteItem(initDeletItem);
    setErrorName("");
    dispatch(editError(null));
    dispatch(editSuccess(null));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const sendRequest = () => {
    if (!name) {
      setErrorName("The field can't be empty");
      return;
    }
    setErrorName("");
    const payload: { name: string; id?: string } = { name };
    if (pickSpecValue) payload.id = pickSpecValue.id;
    dispatch({
      type: pickSpecValue ? "EDIT_SPEC_VALUE" : "ADD_SPEC_VALUE",
      payload,
    });
  };

  const onDeleteItem = (item: { name: string; id: string }) => {
    setPickSpecValue(item);
    setDeleteItem((deleteItem) => ({
      ...deleteItem,
      delete: true,
    }));
    setIsModalOpen(true);
  };

  const changePage = (page: any) => {
    const payload: { page: number; search?: string } = { page };
    if (searchText) {
      payload.search = searchText;
    }
    dispatch({ type: "GET_ALL_SPEC_VALUE", payload });
  };

  const onSearch = (value: string) => {
    dispatch({ type: "GET_ALL_SPEC_VALUE", payload: { search: value } });
  };

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const columnsSubSpec: ColumnsType<SpecValueI> = [
    {
      title: <div className={styles.headerColumn}>Name</div>,
      dataIndex: "name",
      //render: (text: string) => <a>{text}</a>,
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
      dataIndex: "edit",
      render: (_, { name, id }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            setPickSpecValue({ name, id });
            setIsModalOpen(true);
          }}
        >
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, { id, name }) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => onDeleteItem({ name, id })}
        >
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
    },
  ];

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
        className={styles.tag}
      >
        {label}
      </Tag>
    );
  };

  const onDelete = () => {
    setDeleteItem((deleteItem) => ({ ...deleteItem, confirm: true }));
    if (pickSpecValue)
      dispatch({ type: "DELETE_SPEC_VALUE", payload: pickSpecValue });
  };

  const getTextForModal = (): string => {
    if (deleteItem.delete) return "Delete the value";
    if (!pickSpecValue) return "Add a new value";
    return "Edit the value";
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  /*   const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }; */
  return (
    <>
      {contextHolder}
      <div className={styles.wrapper}>
        <div className={styles.leftPart}>
          <div className={styles.rightNavbarList}>
            <div className={styles.rightNavbarList_header}>
              {/*               <div
                className={styles.rightNavbarList_icon}
                onClick={() => showModal()}
              >
                <Tooltip placement="leftBottom" title="Add new spec's values">
                  <IoIosAddCircleOutline size={sizeIcon} />
                </Tooltip>
              </div> */}
              <div className={styles.search}>
                <Input.Search
                  placeholder="enter value of spec "
                  enterButton="Search"
                  size="large"
                  loading={loadingList}
                  onSearch={onSearch}
                  onChange={onChangeSearch}
                  allowClear
                />
              </div>
              <div
                onClick={closeListValues}
                className={styles.rightNavbarList_icon}
              >
                <AiOutlineClose size={sizeIcon} />
              </div>
            </div>
            <Table
              columns={columnsSubSpec}
              dataSource={specValue}
              //loading={true}
              showHeader={true}
              rowClassName={styles.row}
              className={styles.table}
              bordered
            />
            {count > pageSize && (
              <Pagination
                defaultCurrent={1}
                pageSize={pageSize}
                total={count}
                className={styles.pagination}
                onChange={changePage}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        title={getTextForModal()}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className={styles.modalButton}>
            <Button
              loading={loading}
              className={styles.button}
              onClick={deleteItem.delete ? onDelete : sendRequest}
            >
              {getTextForModal()}
            </Button>
          </div>,
        ]}
      >
        <ResponseStatusComponent error={error} success={success} />
        {deleteItem.delete && (
          <>
            <div style={{ textAlign: "center" }}>
              Are you sure you want delete the value?
              <br />
              {<b>{pickSpecValue?.name}</b>}. This value will be deleted in all
              specs and products
            </div>
          </>
        )}
        {!deleteItem.delete && (
          <>
            <label className={styles.label}>Name</label>
            <Input
              className={styles.input}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Alert
              className={styles.alert}
              message="This value will be updated in all specs and products"
              type="warning"
            />

            <ResponseStatusComponent error={errorName} />
          </>
        )}
      </Modal>
    </>
  );
}

export default ListSpecValues;
