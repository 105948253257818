import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AUTH_SAGA_TYPE } from "../ts/enum";
import { IDataUserSignUp, IDataUserForgotPassword } from "../ts/interface";

export interface UserDataI {
  id: string;
  name: string;
  surname: string;
  email: string;
  status: "Active" | "Disabled";
  phone: string;
  address: string | null;
  isAuthorized: boolean;
  isBlocked: boolean;
  role?: "Admin" | "Customer";
}
const initialState: {
  dataUser: UserDataI | null;
  cards: [];
  loading: boolean;
  error: null | string;
  success: null | string;
} = {
  dataUser: null,
  cards: [],
  loading: false,
  error: null,
  success: null,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    editLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    editError: (state, action: PayloadAction<null | string>) => {
      state.error = action.payload;
    },
    editSuccess: (state, action: PayloadAction<null | string>) => {
      state.success = action.payload;
    },
    clearResponseStatus: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearAuthSlice: (state) => {
      state = initialState;
    },
    setUser: (state, action: PayloadAction<UserDataI | null>) => {
      state.dataUser = action.payload;
    },
  },
});

const { actions, reducer } = accountSlice;

export default reducer;
export const {
  editLoading,
  editError,
  editSuccess,
  clearResponseStatus,
  clearAuthSlice,
  setUser,
} = actions;
