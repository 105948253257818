import React from 'react';
import style from './index.module.scss'
import {Button} from "antd";
import {PaymentMethod} from "../OneProductDescAdmin/type";
import visa from '../../assets/imgs/visa.png'
import chip from '../../assets/imgs/pngwing.com.png'
import {useAppDispatch} from "../../redux/ts/hooks/hooks";
import { MdDeleteForever } from "react-icons/md";
import {TiDeleteOutline} from "react-icons/ti";

type Props = {
    payment: PaymentMethod
    pick: PaymentMethod
}

const CreditCard = ({payment, pick}: Props) => {
    const dispatch = useAppDispatch();

    const handleDelete =  (id: string) => {
        dispatch({type: 'DELETE_PAYMENT', payload: id});
    }

    return (
        <div className={style.container}>
            <div className={style.visa}>
                <img src={visa} alt="Visa Logo"
                     style={{width: "50px", objectFit: "cover", height: "30px"}}/>
                {pick?.id === payment.id &&
                   <TiDeleteOutline  className={style.delete} title={'Delete'} onClick={() => {
                        handleDelete(payment.id)
                    }}/>}

            </div>
            <div className={style.chip}>
                <img
                    className="card-chip"
                    alt="Chip"
                    src={chip}
                    width={50}
                    height={45}
                />
            </div>
            <div className={style.number}>
                {`************${payment.card.last4}`}
            </div>
            <div className={style.exp}>
                {payment.card.exp_year}
            </div>
        </div>
    );
};

export default CreditCard;