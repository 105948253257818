import { takeLeading, call, put } from "redux-saga/effects";
import { specNamesService } from "../../services";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  setAllSpecName,
  addOneSpecName,
  addManySpecName,
  updateOneSpecName,
  removeOneSpecName,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  setCount,
  setPickItem,
  SpecNameI,
  setSpecialItemsForProduct,
  editLoadingSpecialItemsForProduct,
  editErrorSpecialItemsForProduct,
  editSuccessSpecialItemsForProduct,
} from "../slices/spec_name.slice";
import { removeOneProductSpec } from "../slices/product_spec.slice";

function* getAll(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    query_params?: string;
    addNewValuesInList?: true;
  }>
) {
  //++
  yield put(editLoadingList(true));
  console.log(action.payload);
  try {
    const { search, page, filter, query_params } = action?.payload || {};
    let servicesMethod: "getAll" | "searchByName" | "getByFilter" | "search" =
      "getAll";
    let queryParams: string | { [params: string]: any } = {
      ...action?.payload,
    };

    if (search) {
      const data = { sort: "name" };
      queryParams = {
        ...queryParams,
        ...data,
      };
      servicesMethod = "search";
    }
    if (filter) {
      queryParams = {
        ...queryParams,
        ...filter,
      };
      servicesMethod = "getByFilter";
      delete queryParams.filter;
    }

    if (query_params) {
      queryParams = query_params;
      servicesMethod = "search";
    }
    const response: { rows?: []; count: number } = yield call(
      specNamesService[servicesMethod],
      queryParams
    );

    yield put(setCount(response?.count || 0));
    yield put(
      action?.payload?.addNewValuesInList
        ? addManySpecName(response.rows ? response.rows : response)
        : setAllSpecName(response.rows ? response.rows : response)
    );
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

export function* getAllByFilter(
  action: PayloadAction<{ sort: string; search: string }[]>
) {
  yield put(editLoadingList(true));
  yield put(editLoadingSpecialItemsForProduct(true));
  try {
    let queryParams: string = "?";
    action.payload.forEach((filter, i) => {
      const start = i !== 0 ? "&" : "";
      queryParams += start + "sort=" + filter.sort + "&search=" + filter.search;
    });

    const response: { rows?: SpecNameI[]; count: number } = yield call(
      specNamesService.search,
      queryParams
    );

    let brand: null | { id: string; name: string }[] = null;
    let supplier: null | { id: string; name: string }[] = null;

    response.rows?.forEach((specName) => {
      if (specName.name === "brand" && specName?.specValues.length > 0)
        brand = specName?.specValues;
      if (specName.name === "supplier" && specName?.specValues.length > 0)
        supplier = specName?.specValues;
    });

    yield put(setSpecialItemsForProduct({ brand, supplier }));
    yield put(editSuccessSpecialItemsForProduct("Brand and supplier get"));

    yield put(setCount(response?.count || 0));
    yield put(setAllSpecName(response.rows ? response.rows : response));
  } catch (error: unknown) {
    console.log("erros", error);
    if (typeof error === "string") {
      yield put(editErrorList(error));
      yield put(editErrorSpecialItemsForProduct(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
      yield put(
        editErrorSpecialItemsForProduct(
          "Something has gone wrong. Please try again later"
        )
      );
    }
  } finally {
    yield put(editLoadingList(false));
    yield put(editLoadingSpecialItemsForProduct(false));
  }
}

function* getOne(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: SpecNameI = yield call(
      specNamesService.getOne,
      action.payload
    );
    yield put(setPickItem(response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* editOne(action: PayloadAction<any>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    console.log(action.payload, " action.payload");
    let { id, name, category_id, specValuesIds, subCategory_id } =
      action.payload;
    let body: {} = {
      name,
      category_id,
      specValuesIds,
    };
    if (subCategory_id) {
      body = {
        ...body,
        subCategory_id,
      };
    }

    const response: {} = yield call(specNamesService.update, id, body, "PATCH");
    yield put(updateOneSpecName(response));
    yield put(editSuccess(`The spec ${name}  has been updated`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* addOne(action: PayloadAction<any>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    let { name, category_id, specValuesIds, subCategory_id } = action.payload;
    let body: {} = {
      name,
      category_id,
      specValuesIds,
    };
    if (subCategory_id) {
      body = {
        ...body,
        subCategory_id,
      };
    }

    const response: {} = yield call(specNamesService.create, body);
    yield put(addOneSpecName(response));
    yield put(editSuccess(`The spec ${name}  has been added`));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* deleteOne(action: PayloadAction<{ id: string; name: string }>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(specNamesService.delete, action.payload.id);
    yield put(removeOneSpecName(action.payload.id));
    yield put(
      editSuccess(`The spec name  ${action.payload.name} has been deleted`)
    );
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

function* getAllArchive(
  action: PayloadAction<{
    search?: string;
    page?: number;
    filter?: {};
    sort: string;
  }>
) {
  yield put(editLoadingList(true));
  yield put(editErrorList(null));
  try {
    const response: { rows?: []; count: number } = yield call(
      specNamesService.getAllArchive,
      { page: action.payload.page }
    );
    yield put(setCount(response?.count || 0));
    yield put(setAllSpecName(response.rows ? response.rows : response));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editErrorList(error));
    } else {
      yield put(
        editErrorList("Something has gone wrong. Please try again later")
      );
    }
  } finally {
    yield put(editLoadingList(false));
  }
}

function* restoreFormArchive(action: PayloadAction<string>) {
  yield put(editLoading(true));
  yield put(editError(null));
  yield put(editSuccess(null));
  try {
    const response: {} = yield call(
      specNamesService.restoreOne,
      action.payload
    );
    console.log("response", response);
    yield put(removeOneSpecName(action.payload));
    yield put(editSuccess("The spec name has been restored"));
  } catch (error: unknown) {
    if (typeof error === "string") {
      yield put(editError(error));
    } else {
      yield put(editError("Something has gone wrong. Please try again later"));
    }
  } finally {
    yield put(editLoading(false));
  }
}

export default function* watchSpecNameSaga() {
  yield takeLeading("GET_ALL_SPEC_NAME", getAll);
  yield takeLeading("GET_ALL_SPEC_NAME_BY_FILTER", getAllByFilter);
  yield takeLeading("GET_ONE_SPEC_NAME", getOne);
  yield takeLeading("ADD_SPEC_NAME", addOne);
  yield takeLeading("EDIT_SPEC_NAME", editOne);
  yield takeLeading("DELETE_SPEC_NAME", deleteOne);
  yield takeLeading("GET_ALL_SPEC_NAME_ARCHIVE", getAllArchive);
  yield takeLeading("RESTORE_SPEC_NAME", restoreFormArchive);
}
