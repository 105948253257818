import { Tooltip, Pagination, Select, Tag, Table } from "antd";
import styles from "./index.module.scss";
import OneOrder from "../../components/OneOrder";
import { statusOrder, OrderI } from "../../redux/slices/orders.slice";
import { useDispatch } from "react-redux";
import { ReactNode, useEffect, useState } from "react";
import { pageSize } from "../../variables";
import {
  SpecProductI,
  selectAll as selectAllSpec,
} from "../../redux/slices/product_spec.slice";
import { useAppSelector } from "../../redux/ts/hooks/hooks";
import FilterSelect from "../../components/FilterSelect";
import { MdExpandMore } from "react-icons/md";
import { selectAll as selectAllUsers } from "../../redux/slices/users.slice";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useLocation, useParams } from "react-router-dom";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import type { ColumnsType } from "antd/es/table";
import { GrFormEdit } from "react-icons/gr";
import { IoCloseOutline } from "react-icons/io5";
const sizeIcon = 24;

export function ListSpecOfProduct({
  addPickSpecName,
  isOpenProductSpec,
  closeListSpecOfProduct,
}: {
  addPickSpecName: (data: SpecProductI | null) => void;
  isOpenProductSpec: boolean;
  closeListSpecOfProduct: () => void;
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const specs = useAppSelector(selectAllSpec);
  const { count } = useAppSelector((state) => state.product_spec);

  const params = useParams();
  const idProduct = params.idProduct;

  const getMoreOrders = (page: number = 1) => {
    let params = "?";
    if (idProduct) params += `sort=product_id&search=${idProduct}&`;
    params += `page=${page}&take=${pageSize}&`;
    dispatch({ type: "GET_ALL_SPEC_ONE_PRODUCT", payload: { params } });
    setPage(page);
  };

  useEffect(() => {
    getMoreOrders();
  }, []);

  const deleteItem = (data: SpecProductI) => {
    dispatch({
      type: "DELETE_SPEC_ONE_PRODUCT",
      payload: {
        idSpecName: data.specName.id,
        idCharacteristicsSpec: data.id,
      },
    });
  };
  const onChangePage = (page: any) => {
    getMoreOrders(page);
  };

  const columnsSubSpec: ColumnsType<SpecProductI> = [
    {
      title: <div className={styles.headerColumn}>Spec's name </div>,
      dataIndex: "name",
      render: (_, { specName }) => <div>{specName?.name}</div>,
      fixed: true,
    },
    {
      title: <div className={styles.headerColumn}>Values</div>,
      dataIndex: "spec_values",
      render: (_, { specValues }) => (
        <div>{specValues.map((sv) => sv.name).join(", ")}</div>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
      dataIndex: "edit",
      render: (_, data) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => {
            addPickSpecName(data);
            closeListSpecOfProduct();
          }}
        >
          <GrFormEdit size={sizeIcon} />
        </div>
      ),
    },
    {
      title: (
        <div className={styles.headerColumn}>
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
      dataIndex: "delete",
      render: (_, data) => (
        <div
          className={styles.rightNavbarList_icon}
          onClick={() => deleteItem(data)}
        >
          <AiOutlineDelete size={sizeIcon - 6} />
        </div>
      ),
    },
  ];

  console.log(specs, "spec");

  return (
    <div
      className={[
        styles.leftPart,
        isOpenProductSpec ? styles.leftPart_open : "",

      ].join(" ")}
    >
      <div className={styles.header}>
        <div className={styles.header__text}>
          <div>All specs of this product</div>
          <div></div>
        </div>
        <div onClick={closeListSpecOfProduct} className={styles.close}>
          <IoCloseOutline size={sizeIcon} />
        </div>
      </div>
      <div className={styles.rightNavbarList}>
        <div className={styles.rightNavbarList_header}></div>
        <Table
          style={{ width: "100%" }}
          columns={columnsSubSpec}
          dataSource={specs}
          //loading={true}
          showHeader={true}
          rowClassName={styles.row}
          className={styles.table}
          bordered
          pagination={false}
        />
        {count / pageSize > 1 && (
          <Pagination
            current={page}
            defaultCurrent={1}
            total={count}
            pageSize={pageSize}
            className={styles.pagination}
            onChange={onChangePage}
          />
        )}
      </div>
    </div>
  );
}

export default ListSpecOfProduct;
