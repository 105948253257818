import { CommonService } from "./CommonService";
import { RequestService } from "./RequestService";
import jwt_decode from "jwt-decode";
import { TBodyUpdateContact, IBodyUpdateAboutMe } from "./interfaces";
import { pageSize } from "../variables";

export class ProductService extends CommonService {
  constructor() {
    super("products");
  }

  filterWithSpecs = (queryParams?: {} | string) =>
    this.request({
      method: "get",
      path: "filterWithSpecs",
      queryParams:
        typeof queryParams === "string"
          ? queryParams
          : {
              page: 1,
              take: pageSize,
              ...queryParams,
            },
    });

  restoreOne = (id: string, queryParams?: {}) =>
    this.request({
      method: "get",
      path: "restoreFromArchive",
      id,
      queryParams,
    });

  updateImages = (body: {}, headers?: {}) =>
    this.request({
      method: "post",
      body,
      path: "images",
      headers: { "Content-Type": "multipart/form-data", ...headers },
    });
}
