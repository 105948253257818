import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { requestStatusInitState } from "../initState";
import { ProductType } from "./product_main.slice";
import { UserDataI } from "./account.slice";
import { InitStateRequestStatusI } from "../ts/interface";
import { TMedia, IMedia } from "./image.slice";

export const fileAdapter = createEntityAdapter<IMedia>({
  selectId: (file) => file.id,
});

interface ExtraInitStateI extends InitStateRequestStatusI {
  count: number;
  pickItem: null | IMedia;
}

const init: ExtraInitStateI = {
  ...requestStatusInitState,
  count: 0,
  pickItem: null,
};

export const initialState = fileAdapter.getInitialState(init);

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setAllFile: (state, action) => {
      fileAdapter.setAll(state, action.payload);
    },
    addOneFile: (state, action) => {
      fileAdapter.addOne(state, action.payload);
    },
    updateOneFile: (state, action) => {
      fileAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    setManyFile: (state, action) => {
      fileAdapter.setMany(state, action.payload);
    },
    removeOneFile: (state, action) => {
      fileAdapter.removeOne(state, action.payload);
    },
    editLoading: (state, action) => {
      state.loading = action.payload;
    },
    editError: (state, action) => {
      state.error = action.payload;
    },
    editSuccess: (state, action) => {
      state.success = action.payload;
    },
    editLoadingList: (state, action) => {
      state.loadingList = action.payload;
    },
    editErrorList: (state, action) => {
      state.errorList = action.payload;
    },
    editSuccesList: (state, action) => {
      state.successList = action.payload;
    },
    clearResponseStatusofForm: (state) => {
      state.success = null;
      state.error = null;
      state.loading = false;
    },
    clearImgSlice: (state) => {
      state = initialState;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setPickItem: (state, action: PayloadAction<null | IMedia>) => {
      state.pickItem = action.payload;
    },
  },
});

const { actions, reducer } = fileSlice;

export const {
  setAllFile,
  addOneFile,
  updateOneFile,
  removeOneFile,
  editLoading,
  editError,
  editSuccess,
  editLoadingList,
  editErrorList,
  editSuccesList,
  clearResponseStatusofForm,
  clearImgSlice,
  setCount,
  setPickItem,
  setManyFile,
} = actions;

export default reducer;
export const { selectAll } = fileAdapter.getSelectors<RootState>(
  (state) => state.file
);
