import React, { useEffect, useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Badge,
  Button,
  Modal,
  Menu,
  Col,
  Divider,
  Row,
  Avatar,
  Dropdown,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { BiImport } from "react-icons/bi";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineHighlight,
} from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import testImgForCard1 from "../../assets/imgs/test_img_for_card_1.png";
import testImgForCard2 from "../../assets/imgs/test_img_for_card_2.png";
import test_avatar from "../../assets/imgs/test_avatar.svg";
import Contacts from "../Contacts";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getItemsForDropDowm } from "../HeaderC";
import { BiSolidCategoryAlt, BiSolidCategory } from "react-icons/bi";
import { BsFilterCircleFill, BsShieldLock } from "react-icons/bs";
import { TbLogout } from "react-icons/tb";
import { useAppDispatch } from "../../redux/ts/hooks/hooks";
import { RiFeedbackLine } from "react-icons/ri";
import { BsCartPlus } from "react-icons/bs";
import { RiArchive2Line } from "react-icons/ri";
import { BsFiletypeXlsx } from "react-icons/bs";

const categories = [
  "Heating",
  "Ventilation",
  "Air",
  "Conditioning",
  "Refrigeration",
];

const colorIcon = "#878D9C";
const sizeIconAI = "30px";
const sizeIconLia = "32px";
const sizeIconAcc = 20;
const colorBage = "#005FEF";

//Product Highlights

const keyForPath: {
  [path: string]: string;
} = {
  "/admin": "0",
  "/admin/categories": "1",
  "/admin/products": "2",
  "/admin/filters": "3",
  "/admin/comments": "4",
  "/admin/orders": "5",
  "/admin/legal": "8",
  "/admin/importFiles": "6",
  "/admin/xlsxProducts": "7",
};

const getKeyForPath = (path: undefined | string) => {
  if (!path) return "0";
  if (path?.includes("/admin/categories")) return "1";
  if (path?.includes("/admin/products")) return "2";
  if (path?.includes("/admin/filters")) return "3";
  if (path?.includes("/admin/comments")) return "4";
  if (path?.includes("/admin/orders")) return "5";
  if (path?.includes("/admin/archive")) return "6";
  if (path?.includes("/admin/importFiles")) return "7";
  if (path?.includes("/admin/xlsxProducts")) return "8";
  if (path?.includes("/admin/legal")) return "9";
  return "0";
};
function MenuAdmin() {
  let path: string = useLocation().pathname as string;
  const [current, setCurrent] = useState(getKeyForPath(useLocation().pathname));
  console.log(current, "current");
  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    console.log("key", e.key);
    //setSubMenuOpen(true);
  };
  const [mouseEnterItem, setMouseEnterItem] = useState<number>(0);
  const dispatch = useAppDispatch();

  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const goTo = (path: string | null) => {
    if (path) navigate(path);
  };

  const logout = () => {
    dispatch({ type: "LOG_OUT" });
  };

  const componentsMenu: Array<{
    component: () => JSX.Element;
    navigate: string | null;
  }> = [
    {
      component: () => (
        <div className={styles.oneIcon}>
          <LiaUserCircle size={sizeIconAcc + 2} />
          <span className={styles.textIcon}>User management</span>
        </div>
      ),
      navigate: "/admin",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <BiSolidCategoryAlt size={sizeIconAcc} />
          <span className={styles.textIcon}>Category management</span>
        </div>
      ),
      navigate: "/admin/categories",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <AiOutlineShoppingCart size={sizeIconAcc} />
          <span className={styles.textIcon}>Product management</span>
        </div>
      ),
      navigate: "/admin/products",
    },

    {
      component: () => (
        <div className={styles.oneIcon}>
          <BsFilterCircleFill size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Spec management</span>
        </div>
      ),
      navigate: "/admin/filters",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <RiFeedbackLine size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Feedback management</span>
        </div>
      ),
      navigate: "/admin/comments",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <BsCartPlus size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Order management</span>
        </div>
      ),
      navigate: "/admin/orders",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <RiArchive2Line size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Archive management</span>
        </div>
      ),
      navigate: "/admin/archive",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <BiImport size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Product Import management</span>
        </div>
      ),
      navigate: "/admin/importFiles",
    },
    {
      component: () => (
        <div className={styles.oneIcon}>
          <BsFiletypeXlsx size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>XLSX Product management</span>
        </div>
      ),
      navigate: "/admin/xlsxProducts",
    },

    {
      component: () => (
        <div className={styles.oneIcon}>
          <BsShieldLock size={sizeIconAcc - 2} />
          <span className={styles.textIcon}>Legal management</span>
        </div>
      ),
      navigate: "/admin/legal",
    },
  ];

  return (
    <div className={styles.menu_wrapper}>
      <Image src={logo} alt="logo" width={132} height={40} preview={false} />

      <Menu
        style={{
          background: "transparent",
          border: "none",
          marginTop: "40px",
        }}
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        items={getItemsForDropDowm(componentsMenu, goTo)}
      />
      <Divider />
      <div
        className={[styles.oneIcon, styles.logout].join(" ")}
        onClick={logout}
      >
        <TbLogout size={22} />
        <span>Log Out</span>
      </div>
    </div>
  );
}

export default MenuAdmin;
