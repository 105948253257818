import {call, put, takeLeading} from "redux-saga/effects";
import {productService} from "../../services";
import {addOneProduct, editLoading, editSuccess,editError} from "../slices/product_main.slice";

import {PayloadAction} from "@reduxjs/toolkit";
import {removeOneProduct} from "../slices/xlsx_product.slice";
import {deleteXlsxOneAxios} from "../../services/api";


function* uploadXlsxToProduct(
    action: PayloadAction<{
        id: string;
        name: string;
        bodyInFormData: {};
    }>
) {
    yield put(editLoading(true));
    yield put(editError(null));
    yield put(editSuccess(null));

    try {
        const body = action.payload.bodyInFormData;
        const id = action.payload.id;
        const headers = {"Content-Type": "multipart/form-data"};
        const response: {} = yield call(productService.create, body, headers);

        yield put(addOneProduct(response));
        yield call(deleteXlsxOneAxios, id);
        yield put(removeOneProduct(id))
        yield put(
            editSuccess(`The product ${action.payload.name} has been added`)
        );

    } catch (error: unknown) {
        if (typeof error === "string") {
            yield put(editError(error));
        } else {
            yield put(editError("Something has gone wrong. Please try again later"));
        }
    } finally {
        yield put(editLoading(false));
    }
}

export default function* watchUploadXlsxToProductSaga() {
    yield takeLeading("UPLOAD_XLSX_TO_PRODUCT", uploadXlsxToProduct);
    // yield takeLeading("DELETE_PRODUCT", deleteOneProduct);
}