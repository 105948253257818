import React, { useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";        
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  Avatar,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import type { MenuProps } from "antd";
import AuthForm from "../Forms/AuthForm";
import test_avatar from "../../assets/imgs/test_avatar.svg";

import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";

const { Search } = Input;

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item
      </a>
    ),
  },
  {
    key: "3",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item
      </a>
    ),
  },
];
function Contacts() {
  const [mouseEnterContactStyles, setMouseEnterContactStyles] = useState<
    "contacts" | "contacts_enter_mouse"
  >("contacts");
  const iconSize = 22;
  return (
    <Dropdown
      //menu={{ items }}
      placement="bottomRight"
      overlayStyle={{ borderRadius: 10 }}
      dropdownRender={() => (
        <div className={styles.dropdown_content}>
          <div className={styles.dropdown_header}>Contact us</div>
          <div className={styles.dropdown_item}>
            <div className={styles.dropdown_icon}>
              <AiOutlinePhone size={iconSize} />
              <span> Phone</span>
            </div>
            <div className={styles.dropdown_text}>
              <div>+ 1111 1111 1111</div>
              <div>+ 1111 1111 1111</div>
              <div>+ 1111 1111 1111</div>
            </div>
          </div>
          <div className={styles.dropdown_item}>
            <div className={styles.dropdown_icon}>
              <AiOutlineMail size={iconSize} />
              <span>Email</span>
            </div>
            <div className={styles.dropdown_text}>
              <div>email@email.com</div>
              <div>email@email.com</div>
            </div>
          </div>
        </div>
      )}
    >
      <div
        className={styles[mouseEnterContactStyles]}
        onMouseEnter={() => setMouseEnterContactStyles("contacts_enter_mouse")}
        onMouseLeave={() => setMouseEnterContactStyles("contacts")}
      >
        <Avatar src={test_avatar} style={{ width: 40, height: 40 }} />
        <div className={styles.contact_text}>
          <div>Can't Make a decision?</div>
          <div>Contact us!</div>
        </div>
      </div>
    </Dropdown>
  );
}

export default Contacts;
