import React, { useEffect, useState } from "react";

/* import { BiSolidUser } from "react-icons/bi";
<BiSolidUser color="white" size={32} /> */
import {
  Layout,
  Space,
  Image,
  Input,
  Dropdown,
  Badge,
  Button,
  Modal,
  Radio,
  Form,
  Checkbox,
  Spin,
} from "antd";
import styles from "./index.module.scss";
import logo from "../../../assets/imgs/logo.svg";
import Icon, { HeartOutlined } from "@ant-design/icons";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { LiaUserCircle } from "react-icons/lia";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineArrowLeft } from "react-icons/ai";
import type { RadioChangeEvent } from "antd";
import ForgotPasswordForm from "../ForgotPasswordForm";
import useSelection from "antd/es/table/hooks/useSelection";
import { useAppDispatch, useAppSelector } from "../../../redux/ts/hooks/hooks";
import { useDispatch } from "react-redux";
import ResponseStatusComponent from "../../ResponseStatusComponent";
import { clearResponseStatus } from "../../../redux/slices/auth.slice";
import InputMask from "react-input-mask";
const { Search } = Input;

type FieldType = {
  name?: string;
  surname?: string;
  email: string;
  password: string;
  phone?: string;
};

const MaskedInput = (props: any) => (
    <InputMask {...props} mask="+1 (999) 999-9999">
      {(inputProps: any) => <Input {...inputProps} />}
    </InputMask>
);

const emailValidator = (_: any, value:any) => {
  if (!value) {
    return Promise.reject(new Error('Please input your email!'));
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cyrillicRegex = /[\u0400-\u04FF]/;

  if (cyrillicRegex.test(value)) {
    return Promise.reject(new Error('Email should not contain Cyrillic letters!'));
  }

  if (!emailRegex.test(value)) {
    return Promise.reject(new Error('The input is not a valid email!'));
  }

  return Promise.resolve();
};


function AuthForm({
  isModalOpen,
  isAdmin,
}: {
  isModalOpen?: boolean;
  isAdmin?: boolean;
}) {
  const [action, setAction] = useState<"LOG_IN" | "SIGN_UP">("LOG_IN");
  const [form] = Form.useForm();
  const [openForgotPassword, onOpenForgotPassword] = useState<boolean>(false);
  const { isAuthorized, success, error, loading } = useAppSelector(
    (state) => state.auth
  );

  const dispatch = useAppDispatch();
  console.log(isAdmin);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const clearForm = () => {
    dispatch(clearResponseStatus());
    form.resetFields();
  };

  const onFinish = (values: FieldType) => {
    dispatch({ type: action, payload: values });
    // console.log("Success:", values);
  };

  const onChange = (e: RadioChangeEvent) => {
    setAction(e.target.value);
    clearForm();
  };

  const goToForgotPassword = () => {
    onOpenForgotPassword(true);
  };

  const goToLogIn = () => {
    onOpenForgotPassword(false);
    clearForm();
  };

  /*  const { success, error, loading } = {
    success: "vlkfjvlkdfjl",
    error: null,
    loading: false,
  }; */

  useEffect(() => {
    dispatch(clearResponseStatus());
    return () => {
      console.log("return use Effect");
      clearForm();
      dispatch(clearResponseStatus());
    };
  }, []);

  const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;

  return (
    <div className={styles.wrapper_form}>
      <div className={styles.form_header}>
        <Image src={logo} alt="logo" width={132} height={40} preview={false} />
        {!loading && (
          <>
            {openForgotPassword && (
              <div onClick={goToLogIn} className={styles.goBack}>
                <AiOutlineArrowLeft />
                <span>Go back to Log in</span>
              </div>
            )}
            {isAdmin && (
              <div className={styles.title} style={{ marginTop: 20 }}>
                Log In
              </div>
            )}
            {!openForgotPassword && !isAdmin && (
              <>
                <div className={styles.title}>
                  Log In or Create a personal account
                </div>
                <Radio.Group
                  value={action}
                  onChange={onChange}
                  buttonStyle="solid"
                >
                  <Radio.Button value="LOG_IN">Log in</Radio.Button>
                  <Radio.Button value="SIGN_UP">Sign up</Radio.Button>
                </Radio.Group>
              </>
            )}
          </>
        )}
      </div>
      <ResponseStatusComponent
        loading={loading}
        success={success}
        error={error}
      />
      {!loading && (
        <>
          {!openForgotPassword ? (
            <>
              <Form
                form={form}
                name="auth"
                style={{ maxWidth: 600 }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className={styles.form}
              >
                {action !== "LOG_IN" && (
                  <Form.Item<FieldType>
                    className={styles.input_wrapper}
                    label="Name"
                    name="name"
                       rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                )}
                {action !== "LOG_IN" && (
                  <Form.Item<FieldType>
                    className={styles.input_wrapper}
                    label="Surname"
                    name="surname"
                    rules={[
                      { required: true, message: "Please input your surname!" },
                    ]}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                )}
                <Form.Item<FieldType>
                  name="email"
                  label="Email"
                  rules={[{ validator: emailValidator,required: true, }]}
                  className={styles.input_wrapper}
                >
                  <Input className={styles.input} />
                </Form.Item>
                {action !== "LOG_IN" && (
                  <Form.Item<FieldType>
                    className={styles.input_wrapper}
                    label="Phone"
                    name="phone"
                    rules={[
                      { required: true, message: "Please input your phone!" },
                      {
                        pattern: phoneRegex,
                        message: "Please input a valid phone number!",
                      },
                    ]}
                  >
                   <MaskedInput className={styles.input} />
                    </Form.Item>
                )}

                <Form.Item<FieldType>
                  className={styles.input_wrapper}
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password className={styles.input} />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.button}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              {action === "LOG_IN" && (
                <div
                  className={styles.forgot_password}
                  onClick={goToForgotPassword}
                >
                  Forgot Password?{" "}
                </div>
              )}
            </>
          ) : (
            <ForgotPasswordForm />
          )}
        </>
      )}
    </div>
  );
}

export default AuthForm;
